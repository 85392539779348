/* eslint-disable react/no-this-in-sfc */
/* global window:true */
import React from 'react';

import {
    ResponsiveContainer,
    PieChart,
    Pie,
    Sector,
    Tooltip,
    Cell,
} from 'recharts';
import { map, size } from 'underscore';
import { Empty } from 'antd';

import ChartHeader from '../ChartHeader';
import ChartMultiDropDown from '../ChartMultiDropDown';
import './customActiveShapePieChart.scss';
import { getRandomColor, convertFromSeconds } from '../../../../core/utils/functions';

export default function () {
    const {
        data,
        dataKey,
        rightMenuItems,
        rightMenuOnClick,
        tooltipText,
        onFirstOptionChange,
        onSecondOptionChange,
        onThirdOptionChange,
        onFourOptionChange,
        defaultFirstOptionValue,
        defaultSecondOptionValue,
        defaultThirdOptionValue,
        defaultFourOptionValue,
        divisions,
        userDivisions,
        drivers,
        assets,
        fill,
        heightRatio,
        onAllOptionChange,
        dotOnClick,
        chartKey,
    } = this.props;

    const wWidth = window.innerWidth;

    const {
        timeframe, activeIndex, colors, mouseOnChart,
    } = this.state;

    const renderActiveShape = (chartProps) => {
        const { data } = this.props;
        const RADIAN = Math.PI / 180;
        const {
            cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
            fill, payload, percent, value, name,
        } = chartProps;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 15) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';
        let showLabelAndValue = null;

        if (data[0]) {
            if (data[0].show_label_and_value) {
                showLabelAndValue = data[0].show_label_and_value;
            }
        }

        const labelx = ex + (cos >= 0 ? 1 : -1) * 12;
        const labely = ey;

        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill} />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill} />

                {/* { wWidth > 500 ? ( */}
                {/*    <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" /> */}
                {/* ) : null } */}

                {/* { showLabelAndValue != 'data' && wWidth > 500 ? ( */}
                {/*    <text x={labelx} y={labely} style={{ fontSize: '16px' }} textAnchor={textAnchor} fill="#333">{ name }</text> */}
                {/* ) : null } */}

                {/* { showLabelAndValue != 'label' && wWidth > 500 ? ( */}
                {/*    <> */}
                {/*        <text x={labelx} y={labely} dy={18} style={{ fontSize: '12px' }} textAnchor={textAnchor} fill="#999"> */}
                {/*            {`${value}`} */}
                {/*        </text> */}
                {/*        <text x={labelx} y={labely} dy={30} style={{ fontSize: '12px' }} textAnchor={textAnchor} fill="#999"> */}
                {/*            {`(${(percent * 100).toFixed(2)}%)`} */}
                {/*        </text> */}
                {/*    </> */}
                {/* ) : null } */}
            </g>
        );
    };

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent, index,
    }) => {
        let radiusConstY = 0.2;
        let radiusConstX = 0.2;
        let textSize = '18px';

        if (size(data) > 2) {
            radiusConstY = 0.6;
            radiusConstX = 0.3;
            textSize = '14px';
        }

        const radiusX = innerRadius + (outerRadius - innerRadius) * radiusConstX;
        const radiusY = innerRadius + (outerRadius - innerRadius) * radiusConstY;
        const x = cx + radiusX * Math.cos(-midAngle * RADIAN);
        const y = cy + radiusY * Math.sin(-midAngle * RADIAN);
    };

    let widgetHeight = heightRatio * 200;
    let marginVertical = 0;

    if (heightRatio > 1) {
        widgetHeight += ((heightRatio - 1) * 95);
    }

    const innerRadius = (heightRatio * 60) - 20;

    if (widgetHeight > 400) {
        marginVertical = (widgetHeight - 400) / 2;
        widgetHeight = 400;
    }

    let widgetHeightPercentage = '100%';
    if (wWidth <= 500) {
        widgetHeightPercentage = '80%';
    }

    let displayNoData = true;
    map(data, (item) => {
        if (parseInt(item.value, 10) > 0) {
            displayNoData = false;
        }
    });

    // convert to HH:MM:SS for seconds
    let label = '';
    if (data && data[activeIndex]) {
        label = data[activeIndex].value + data[activeIndex].unit;
        if (data[activeIndex].unit == 'h:m:s') {
            label = convertFromSeconds(data[activeIndex].value);
        }
    }

    let showLabelAndValue = null;

    if (data[0]) {
        if (data[0].show_label_and_value) {
            showLabelAndValue = data[0].show_label_and_value;
        }
    }

    return (
        <div className="new-chart-widget bar-chart-widget" style={{}}>
            <ChartHeader
                timeframe={timeframe}
                onTimeframeChange={(timeframeData) => this.onTimeframeChange(timeframeData)}
                rightMenuOnClick={(rightMenuData) => rightMenuOnClick(rightMenuData)}
                rightMenuItems={rightMenuItems}
                tooltipText={tooltipText} />
            <div
                style={{
                    width: '100%', height: widgetHeight, marginTop: marginVertical, marginBottom: marginVertical, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column',
                }}
                id={`modalContent_${chartKey}`}>
                {displayNoData === true
                    ? <Empty />
                    : (
                        <ResponsiveContainer width="100%" height={widgetHeightPercentage}>
                            <PieChart
                                onClick={(payload) => {
                                    if (payload && payload.activePayload && payload.activePayload.length > 0) {
                                        const event = payload.activePayload[0];
                                        const index = data.indexOf(event.payload);
                                        dotOnClick(timeframe, index);
                                    }
                                }}>
                                <Pie
                                    onClick={(payload, dummy, e) => {
                                        e.stopPropagation();
                                        const event = payload.payload;
                                        const index = data.indexOf(event.payload);
                                        dotOnClick(timeframe, index);
                                    }}
                                    innerRadius={innerRadius}
                                    outerRadius={innerRadius + 20}
                                    isAnimationActive={false}
                                    activeIndex={activeIndex}
                                    activeShape={renderActiveShape}
                                    data={data}
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    dataKey={dataKey}
                                    fill={fill}
                                    onMouseEnter={this.onPieEnter.bind(this)}
                                    onMouseLeave={() => this.onPieLeave()}>
                                    {
                                        data.map((a, i) => {
                                            let color = 'rgba(128,128,128,0.5)';
                                            if (colors[i]) {
                                                color = colors[i];
                                            } else {
                                                color = getRandomColor();
                                            }

                                            if (a.color) {
                                                color = a.color;
                                            }

                                            return (
                                                <Cell key={`cell-${i}`} fill={color} />
                                            );
                                        })
                                    }
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    )}
                {
                    data && data[activeIndex] && data[activeIndex].name
                    // && wWidth <= 500
                        ? (
                            <>
                                {showLabelAndValue === 'both' ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <p style={{ fontSize: '16px', color: '#333' }}>{data[activeIndex].name} </p>
                                        <p style={{ fontSize: '12px', color: '#999' }}>{label}</p>
                                    </div>
                                )
                                    : null}

                                {showLabelAndValue === 'label' ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <p style={{ fontSize: '16px', color: '#333' }}>{data[activeIndex].name} </p>
                                    </div>
                                )
                                    : null}

                                {showLabelAndValue === 'data' ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <p style={{ fontSize: '12px', color: '#999' }}>{label}</p>
                                    </div>
                                )
                                    : null}

                                {showLabelAndValue === 'hover' && mouseOnChart ? (
                                    <div style={{ textAlign: 'center', position: 'absolute', bottom: '35px' }}>
                                        <p style={{ fontSize: '16px', color: '#333' }}>{data[activeIndex].name} </p>
                                        <p style={{ fontSize: '12px', color: '#999' }}>{label}</p>
                                    </div>
                                )
                                    : null}
                            </>
                        )
                        : null
                }
            </div>
            <div className="new-chart-widget-footer">
                <ChartMultiDropDown
                    onFirstOptionChange={(firstOption) => onFirstOptionChange(firstOption)}
                    onSecondOptionChange={(secondOption) => onSecondOptionChange(secondOption)}
                    onThirdOptionChange={(thirdOption) => onThirdOptionChange(thirdOption)}
                    onFourOptionChange={(fourOption) => onFourOptionChange(fourOption)}
                    onDataChange={(optionsData) => onAllOptionChange(optionsData)}
                    defaultFirstOptionValue={defaultFirstOptionValue}
                    defaultSecondOptionValue={defaultSecondOptionValue}
                    defaultThirdOptionValue={defaultThirdOptionValue}
                    defaultFourOptionValue={defaultFourOptionValue}
                    divisions={divisions}
                    userDivisions={userDivisions}
                    drivers={drivers}
                    assets={assets} />
            </div>
        </div>
    );
}
