import React from 'react';

const DrivingTimeSVG = () => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 396.9 396.9"
        width="1em"
        height="1em"
        xmlSpace="preserve">
        <g>
            <path d="M115,93.7c59.4-59.4,156.2-59.2,215.8,0.3l11.2,11.2h-28.6v12.5H365V66h-12.5v32.2l-13-13
		c-64.4-64.4-169.2-64.6-233.4-0.3c-9.2,9.2-17.3,19.4-24,30.3l10.6,6.6C99,111.7,106.4,102.2,115,93.7z" />
            <path d="M373.1,135.1l-11.4,5.2c12.7,27.8,16.5,58.6,11.2,88.9c-5.5,30.9-20.2,59.1-42.7,81.5
		c-59.4,59.4-156.2,59.2-215.8-0.3l-10.9-10.9h28.1v-12.5H80v51.7h12.5v-32.6l13.2,13.2c32.3,32.3,74.7,48.4,117,48.4
		c42.2,0,84.3-16,116.4-48.1c24.2-24.2,40.2-54.7,46.1-88.1C391,198.5,386.8,165.2,373.1,135.1z" />
            <polygon points="155.7,271.3 155.7,299.4 304.4,299.4 304.4,146.7 262.9,105.2 155.7,105.2 155.7,125.1 168.2,125.1
		168.2,117.7 257.8,117.7 292,151.9 292,286.9 168.2,286.9 168.2,271.3 	" />
            <polygon points="246.5,126.6 246.5,163.1 283.1,163.1 283.1,150.6 259,150.6 259,126.6 	" />
            <path d="M93.2,273.9c9.4,0,17.4-6.4,19.8-15.1h76h13.3h5c2.4,8.7,10.3,15.1,19.8,15.1s17.4-6.4,19.8-15.1h8.8
		c5.4,0,13.4-3.1,13.4-11.5v-26.6c0-1.5-0.4-2.9-1.1-4.2c-0.4-1.6-1.5-2.9-2.9-3.6c-1.3-1.3-2.8-2.4-4.3-3.5l-0.7,0.9l0.7-0.9
		c-0.8-0.6-2-1.5-2.6-2.1c-0.4-1.6-1.2-4.9-2-8.4l-0.1-0.3c-1.3-5.6-3-12.5-3.9-15.8c-2.2-8.2-9.6-10.4-13.2-10.4h-39.1v-37H52.3
		v28.4h10.7v-17.6H189v26.3V230H52.3v10.7h24.8c-1.7,2.1-3,4.6-3.7,7.3H52.2v10.7h21.2C75.8,267.5,83.8,273.9,93.2,273.9z
		 M93.2,243.7c5.4,0,9.8,4.4,9.8,9.8c0,5.4-4.4,9.8-9.8,9.8s-9.8-4.4-9.8-9.8C83.4,248,87.8,243.7,93.2,243.7z M222.2,212.3
		c-3,0-5.4,2.4-5.4,5.4c0,3,2.4,5.4,5.4,5.4h36.1v11.3h-6.6v10.7h6.6v2.2c0,0,0,0.1,0,0.1c-0.1,0-0.2,0.1-0.5,0.2
		c-0.9,0.4-1.9,0.4-2.2,0.5h-8.8c-2.4-8.7-10.3-15.1-19.8-15.1s-17.4,6.4-19.8,15.1h-5h-2.6v-7.3v-57.6h39.1c0.2,0,2.3,0.1,2.9,2.4
		c0.8,3.2,2.4,9.7,3.8,15.5l0,0.1c1.1,4.7,2,8.4,2.4,9.7c0.1,0.5,0.4,1,0.6,1.5H222.2z M217.3,253.4c0-5.4,4.4-9.8,9.8-9.8
		c5.4,0,9.8,4.4,9.8,9.8c0,5.4-4.4,9.8-9.8,9.8C221.7,263.2,217.3,258.8,217.3,253.4z M189,248.1h-76c-0.7-2.7-2-5.2-3.7-7.3H189
		V248.1z" />
            <path d="M28.6,181h67c3,0,5.4-2.4,5.4-5.4c0-3-2.4-5.4-5.4-5.4h-67c-3,0-5.4,2.4-5.4,5.4C23.2,178.6,25.7,181,28.6,181
		z" />
            <path d="M123.1,196.8c0-3-2.4-5.4-5.4-5.4H11.9c-3,0-5.4,2.4-5.4,5.4c0,3,2.4,5.4,5.4,5.4h105.9
		C120.7,202.1,123.1,199.7,123.1,196.8z" />
            <path d="M81.5,217.9c0-3-2.4-5.4-5.4-5.4H34c-3,0-5.4,2.4-5.4,5.4s2.4,5.4,5.4,5.4h42.1
		C79,223.3,81.5,220.9,81.5,217.9z" />
        </g>
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default DrivingTimeSVG;
