/* eslint-disable react/destructuring-assignment */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isUndefined, isNull } from 'underscore';
import DOM from './ecoScoreReport';
import * as eventsActions from '../../../../core/events/eventsActions';
import * as reportActions from '../../../../core/report/reportActions';
import * as assetActions from '../../../../core/asset/assetActions';
import * as globalActions from '../../../../core/global/globalActions';
import moment from 'moment';

class EcoScoreReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {},
            filterSidebar: true,
            export: false,
        };

        this.view = DOM;
    }

    componentDidMount() {
        const { actions, dataEventToReportParams } = this.props;

        actions.resetReportsRequest();

        if (dataEventToReportParams !== null) {
            const params = {
                ...dataEventToReportParams,
            };
            if (dataEventToReportParams.driver_id) params.driver_id = [dataEventToReportParams.driver_id];

            actions.getEcoScoreReportRequest(params);
            actions.setDataEventToReportParams(null);

            this.setState({
                filters: params,
                filterSidebar: false,
            });
        } else {
            this.onEventsTabFilterChange({
                asset_or_driver: 'assets',
                page: 1,
                date_from: moment().subtract(1, 'weeks'),
                date_to: moment(),
            });
        }
    }

    onEventsTabFilterChange = (values) => {
        const { actions } = this.props;

        const filters = values;
        if (filters.date_from) filters.date_from = values.date_from.format('YYYY-MM-DD');
        if (filters.date_to) filters.date_to = values.date_to.format('YYYY-MM-DD');
        if (!isNull(filters.driver_id) && !isUndefined(filters.driver_id)) filters.driver_id = filters.driver_id;
        if (!isNull(filters.division_id) && !isUndefined(filters.division_id)) filters.division_id = filters.division_id;

        this.setState({ filters });
        actions.getEcoScoreReportRequest(filters);
    }

    render() {
        return this.view({
            update: (newVals) => (
                this.props.actions.UpdateEventNotifications(newVals)
            ),
        });
    }
}

EcoScoreReport.propTypes = {
    actions: PropTypes.object.isRequired,
    ecoScoreReport: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        ecoScoreReport: state.report.ecoScoreReport,
        events: state.events.companyEvents,
        user: state.user,
        export: state.export,
        filterSidebar: state.filterSidebar,
        dataEventToReportParams: state.global.dataEventToReportParams,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...reportActions,
            ...assetActions,
            ...globalActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EcoScoreReport);
