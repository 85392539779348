import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';
import * as userActions from '../../core/user/userActions';
import DOM from './loginForm';

class UserLoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalForgetVisible: false,
        };
        this.view = DOM;
    }

    setModalForgetVisible(modalForgetVisible) {
        this.setState({ modalForgetVisible });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { actions } = this.props;
                actions.loginRequest({
                    username: values.email,
                    password: values.password,
                });
            }
        });
    };

    handleForgotsubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(['email'], (err, values) => {
            if (!err) {
                const { actions } = this.props;
                actions.resetPasswordRequest({ email: values.email });
                this.setState({ modalForgetVisible: false });
            }
        });
    };

    render() {
        return this.view();
    }
}

UserLoginForm.propTypes = {
    form: PropTypes.object.isRequired,
};

const LoginForm = Form.create()(UserLoginForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.user.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoginForm);
