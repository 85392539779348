/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import {
    Button,
    Row,
    Col,
    Modal,
    Input,
    Spin,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';

import LeftSide from './partials/LeftSide';
import ChangePasswordForm from './forms/ChangePasswordForm';
import Layout from '../../components/layout/Layout';
import Avatar from '../../components/elements/Avatar';

export default function () {
    const { modalOpen } = this.state;
    const { user } = this.props;

    const tabTitle = user.profile.full_name;

    return (
        <Layout
            data-test="pages-profile"
            className="drivers-view"
            title={<div>UserView</div>}
            headerTitle={<div>UserView Page</div>}
            showFooter={false}>
            <div className="content-wrap">
                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__header">
                                <div className="section__header-item is-active">
                                    <span>{tabTitle}</span>
                                </div>
                            </div>
                            <div className="section__body">
                                <div className="top-section">
                                    <div className="section__action-bar justify-center">
                                        <span className="text-bold">Profile: {user.profile.full_name}</span>
                                    </div>
                                    <div className="actions__buttons">
                                        <Button
                                            type="primary"
                                            onClick={() => this.submitUpdateProfileForm()}
                                            className="btn">
                                            <SaveOutlined />
                                            <span>
                                                Save
                                            </span>
                                        </Button>
                                    </div>
                                </div>
                                <Spin spinning={user.isFetching}>
                                    <Row
                                        gutter={40}
                                        type="flex"
                                        justify="space-between"
                                        className="data-row"
                                    >
                                        <Col xl={6}>
                                            <LeftSide 
                                                openPasswordChangeModal={() => { this.setState({ modalOpen: true }); }} 
                                                registerSubmitHandler={(f) => { this.submitUpdateProfileForm = f; }} 
                                            />
                                        </Col>
                                        <Col xl={11}>
                                            <div className="actions">
                                                <Row
                                                    type="flex"
                                                    gutter={40}
                                                    justify="space-between"
                                                >
                                                    <Col>
                                                        <Avatar 
                                                            pictureUrl={user.profile.profilePictureFull} 
                                                            onChange={(data) => this.updateProfileImage(data)} 
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </Spin>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <Modal
                visible={modalOpen}
                title="Change password"
                onCancel={() => this.setState({ modalOpen: false })}
                footer={null}>
                <ChangePasswordForm />
            </Modal>
        </Layout>
    );
}
