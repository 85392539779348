/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Tabs, Modal } from 'antd';
import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import FilterForm from './FilterForm';
import AddDeparmentForm from './forms/AddDepartmentForm';
import AddUserForm from './forms/AddUserForm';
import './users.scss';

const { TabPane } = Tabs;

export default function () {
    const { currentTabKey, modalOpen, userCreateModalOpen } = this.state;
    const { props } = this;
    const {
        actions, departments, users, divisions,
    } = props;

    return (
        <Layout data-test="pages-users" className="users" title={<div>Users</div>} headerTitle={<div>Users Page</div>} showFooter={false}>
            <div className="content-wrap">
                <FilterSidebar displayFilter>
                    <FilterForm
                        key={`filter_form_1`}
                        type='user'
                        onSubmit={(filterData) => (this.filterUsers(filterData))} />
                </FilterSidebar>
                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            <Tabs
                                onTabClick={(t) => {
                                    this.setState({ currentTabKey: t });
                                }}
                                defaultActiveKey="1"
                                activeKey={currentTabKey}
                                animated={false}
                                className="tabs-restyled">
                                <TabPane tab="Users" key="1">
                                    <div className="section__body">
                                        {currentTabKey === '1' ? this.usersTabContent() : null}
                                    </div>
                                </TabPane>
                            </Tabs>
                        </section>
                    </div>
                </div>
            </div>
            <Modal
                visible={userCreateModalOpen}
                title="Create user"
                onCancel={() => this.setState({ userCreateModalOpen: false })}
                footer={null}>
                <AddUserForm />
            </Modal>
        </Layout>
    );
}
