/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Alert } from 'antd';

import './adminNotificationBar.css';

export default function () {
    const { unresolvedLogs } = this.props;

    const message = `There are ${unresolvedLogs} unresolved entries in the Shard Log`;

    return (
        <div className="admin-shard-notifications-main-container">
            <Alert message={message} type="error" banner />
        </div>
    );
}
