// APP CONSTATNS
const {
    REACT_APP_API_URL,
    REACT_APP_OSM_URL,
    REACT_APP_APP_NAME,
    REACT_APP_GOOGLE_MAP_API_KEY,
    REACT_APP_MAP_SLIDER_INTERVAL,
    REACT_APP_MAP_LIVE_ASSET_DELAY,
} = process.env;
const app = {
    DEBUG: false,
    MOCK: false,
    APP_NAME: REACT_APP_APP_NAME,
    API_URL: REACT_APP_API_URL,
    OSM_URL: REACT_APP_OSM_URL,
    AUTH_KEY: 'iss-key',
    API_LOGIN_KEY: 'iss-api-key',
    SHARD_KEY: 'shard-id',
    LOCALSTORAGE_EXPIRES_KEY: 'expire-auth',
    GOOGLE_MAP_API_KEY: REACT_APP_GOOGLE_MAP_API_KEY,
    LOCALSTORAGE_EXPIRES: '30', // minutes
    BUGSNAG_KEY: '719ed98fd8d631b89b17e4b188a1f4d8',
    MAP_SLIDER_INTERVAL: parseInt(REACT_APP_MAP_SLIDER_INTERVAL, 10),
    MAP_LIVE_ASSET_DELAY: parseInt(REACT_APP_MAP_LIVE_ASSET_DELAY, 10),
    GEOFENCE_REPORT_FEATURE: 'geofence-report',
};

export default app;
