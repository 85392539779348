import React from 'react';
import { Spin } from 'antd';
import Layout from '../../components/layout/Layout';
import PasswordScreen from './partials/passwordScreen';
import EventScreen from './partials/eventScreen';
import './eventShareView.scss';

import srcISSLogo from '../../assets/images/iss-logo-silver-black.png';
import srcFleetclearLogo from '../../assets/images/fleetclear-logo.svg';

export default function () {
    const { report, match, isFetching } = this.props;
    const {
        isValid, eventId, deviceId, reference, historicVideoId,
    } = report || {};
    const { params } = match;
    const showPasswordScreen = isValid === true && !eventId && !deviceId;
    const showEventScreen = isValid === true
        && (eventId || reference || historicVideoId)
        && report.deviceId;

    return (
        <Layout
            data-test="pages-events-share-view"
            className="events-view"
            title="Event View"
            showHeader={false}
            headerTitle={<div>AssetsView Page</div>}
            showFooter={false}>
            <div className="content-wrap">
                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            {showPasswordScreen
                                ? <PasswordScreen params={params} />
                                : null}
                            {showEventScreen
                                ? <EventScreen params={params} parent="sharedView" />
                                : null}

                            {!showPasswordScreen && !showEventScreen
                                ? (
                                    <div className="loginform-wrap">
                                        <div className="loginform-container">
                                            <div className="d-flex dir-column align-center text-center">
                                                <img src={srcISSLogo} className="login__logo-iss" alt="ISS" />
                                                <img src={srcFleetclearLogo} className="login__logo-fleet" alt="Fleetclear" />
                                                {isFetching ? <Spin style={{ marginTop: 30 }} /> : <h1 className="login__heading">Invalid link!</h1>}
                                            </div>
                                        </div>
                                    </div>
                                )
                                : null}
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
