import React, { Component } from 'react';
import {
    Form,
    DatePicker,
    Select,
    Input,
    Button,
} from 'antd';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import Icon from '../../../components/elements/Icon';

import * as assetActions from '../../../core/asset/assetActions';

const { RangePicker } = DatePicker;

class CachedVideoFilterForm extends Component {
    componentDidMount() {
        const { actions } = this.props;
        actions.getDivisionListRequest();
        actions.getAssetOptionsRequest();
    }

    submitForm = (e) => {
        e.preventDefault();
        const { onSubmit } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // values['status'] = 'cached';
                onSubmit(values);
            }
        });
    }

    render() {
        const {
            form, divisions, driverList, assetReg, mapDate, assetOptions,
        } = this.props;
        const { getFieldDecorator } = form;
        let assetInfoInitialVal = '';
        let dateFromInitialVal = '';

        let startdate = moment().format('YYYY-MM-DD 00:00:00');
        startdate = moment(startdate).subtract(10, 'years');
        dateFromInitialVal = startdate;

        if (window.location.pathname.includes('search')) {
            if (assetReg) {
                assetInfoInitialVal = { initialValue: assetReg };
            }

            if (mapDate) {
                // If user comes from main map screen then use the map date to show events
                dateFromInitialVal = moment(mapDate, 'YYYY-MM-DD');
            }
        }
        const dateFormat = 'DD/MM/YYYY HH:mm:ss';

        return (
            <Form onSubmit={this.submitForm} layout="vertical">
                {/* <Form.Item label="Video ID">
                    {getFieldDecorator('video_id', {

                    })(
                        <Input placeholder="Exact video ID" />,
                    )}
                </Form.Item> */}
                <Form.Item label="My reference">
                    {getFieldDecorator('reference', {
                        rules: [
                        ],
                    })(
                        <Input placeholder="My reference" />,
                    )}
                </Form.Item>
                <Form.Item label="Asset name or reg">
                    {getFieldDecorator('asset_info', {

                    })(
                        <Select
                            mode="multiple"
                            showSearch
                            allowClear
                            filterOption={(txt, obj) => (obj.props.children.toLowerCase().includes(txt.toLowerCase()))}
                            placeholder="Asset name or reg"
                            suffixIcon={<Icon name="triangle-down" />}>
                            {assetOptions.map((a) => (
                                <Select.Option key={a.id} value={a.id}>
                                    {a.name === a.reg || a.reg === '' ? a.name : `${a.name} [${a.reg}]`}
                                </Select.Option>
                            ))}
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Driver">
                    {getFieldDecorator('driver', {

                    })(
                        <Select
                            mode="multiple"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            placeholder="Select driver"
                            suffixIcon={<Icon name="triangle-down" />}>
                            {driverList.map((dl) => <Select.Option key={dl.id} value={dl.id}>{dl.name}</Select.Option>)}
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Division">
                    {getFieldDecorator('division_id', {
                        rules: [
                        ],
                    })(
                        <Select
                            placeholder="Select division"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            mode="multiple"
                            style={{ width: '100%' }}
                            suffixIcon={
                                <Icon name="triangle-down" />
                            }>
                            {
                                divisions.map((v, i) => (
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    <Select.Option key={`${i}`} value={v.id}>{v.name}</Select.Option>
                                ))
                            }

                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Date Range">
                    {getFieldDecorator('date_range', {
                        initialValue: [dateFromInitialVal, moment(moment().format('YYYY-MM-DD 00:00:00')).add(1, 'day')],
                    })(
                        <RangePicker
                            // showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                            placeholder="Date Range"
                            format={dateFormat} />,
                    )}
                </Form.Item>
                { /*
                    <Form.Item label="Channel">
                        {getFieldDecorator('channel', {
                            rules: [
                            ],
                        })(
                            <Select
                                style={{ width: '100%' }}
                                mode="multiple"
                                allowClear
                                placeholder="Select channel"
                                suffixIcon={<Icon name="triangle-down" />}
                            >
                                <Select.Option value="0">1</Select.Option>
                                <Select.Option value="1">2</Select.Option>
                                <Select.Option value="2">3</Select.Option>
                                <Select.Option value="3">4</Select.Option>
                                <Select.Option value="4">5</Select.Option>
                                <Select.Option value="5">6</Select.Option>
                                <Select.Option value="6">7</Select.Option>
                                <Select.Option value="7">8</Select.Option>
                            </Select>,
                        )}
                    </Form.Item>
                    */ }
                <div className="mt-2 text-center">
                    <Button
                        htmlType="submit"
                        type="primary"
                        className="btn extrawide"
                        size="large">
                        Filter
                    </Button>
                </div>
            </Form>
        );
    }
}

CachedVideoFilterForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    divisions: PropTypes.array.isRequired,
    driverList: PropTypes.array.isRequired,
    assetReg: PropTypes.string,
    mapDate: PropTypes.string,
};

const CachedVideoFilter = Form.create({ name: 'event_filter_form' })(CachedVideoFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        event_types: state.asset.event_types,
        divisions: state.asset.divisions,
        driverList: state.driver.driverList,
        assetReg: state.global.videoSearchFilters.assetReg,
        mapDate: state.global.mapDate,
        assetOptions: state.asset.options,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CachedVideoFilter);
