import React from 'react';

const DriverBehaviourSVG = () => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 396.9 396.9"
        width="1em"
        height="1em"
        xmlSpace="preserve">
        <g>
            <path d="M345.6,188.9c-0.1-1-0.2-1.8-0.3-2.6c-0.3-2.7-0.4-4.3-2.3-6.5c-1.1-1.3-2.3-2.7-3.5-4c-0.6-0.7-1.2-1.3-1.8-2
		c-5.1-5.8-9.7-11-14.1-16c-3.8-4.4-7.8-8.9-11.7-13.3c0.7,3.5,1.7,7.1,2.6,10.6c0.5,1.6,0.9,3.3,1.3,5c0.3,1.2,0.7,2.6,1.1,4
		c1.1,3.8,2.4,8.1,2.8,12.3c0.2,2.4,0,4.5-0.5,6.4c-0.6,2.2-1.7,4.2-3.3,5.7c-1.8,1.8-6.2,4.5-10.1,4.5c-0.6,0-1.3-0.1-1.9-0.2
		c-2-0.6-3.4-2-3.9-4.2c-0.6-2.5-0.5-5.4-0.3-8c0.1-1.2,0.1-2.3,0.1-3.3l-0.2-15c0-0.6,0-1.2,0-1.8c0-2.8,0-5.4-1-7.6
		c-1.1-2.5-3.2-4.3-5.8-5.1c-2.8-0.9-5.9-0.5-8.5,1c-1.6,0.9-2.7,2.5-3.4,4.6c-0.7,2.4-0.8,5.1-0.8,7c0,6.6,0.4,14.2,1.2,22.5
		c0.3,3.6,0.4,7.4,0.4,11.1c0,3.3,0.1,6.6,0.3,9.8c0.3,3.3,0.4,5.8,0.6,8c0.2,3,0.3,5.4,0.7,8.6c0.7,6,2.1,11.2,4.6,16.5
		c0.9,1.9,2.3,3.3,3.9,4.8c2,1.9,4,4,5,7l0,0c1.3,3.8,2.3,7.8,3.3,11.7c0.2,0.8,0.4,1.5,0.6,2.3c2.7,10.5,5.1,20.9,7.2,29.9
		l28.5-7.2c0.6-0.2,1.2-0.3,1.8-0.4c0.5-0.1,1.2-0.2,1.5-0.3c0-0.5-0.3-1.7-0.4-2.3c-0.1-0.6-0.3-1.1-0.4-1.6l-3.8-25.4
		c0-0.3-0.1-0.6-0.1-0.9c-0.4-2.8-1-6.3-0.4-9.2c0.6-3,1.2-5,1.9-6.3c1.4-2.6,2.4-6.1,3-9.6c0.3-1.7,0.5-3.4,0.7-5.1
		c0.3-2.1,0.6-4.3,1-6.5l0.2-1.4c0.9-5.6,1.8-11.3,2.7-16.9c0.2-1,0.4-2.1,0.6-3.2C345.3,193.6,345.9,190.9,345.6,188.9z" />
            <path d="M105,241.6c1.5-1.5,3-3,3.9-4.8c2.5-5.3,3.9-10.5,4.6-16.5c0.4-3.2,0.5-5.6,0.7-8.6c0.1-2.2,0.3-4.7,0.6-8
		c0.3-3.2,0.3-6.6,0.3-9.8c0-3.6,0.1-7.4,0.4-11.1c0.8-8.3,1.2-15.8,1.2-22.5c0-1.9-0.1-4.6-0.8-7c-0.7-2.2-1.8-3.7-3.4-4.6
		c-2.6-1.5-5.7-1.9-8.5-1c-2.6,0.8-4.7,2.7-5.8,5.1c-1,2.2-1,4.8-1,7.6c0,0.6,0,1.2,0,1.8l-0.2,15c0,1,0,2.1,0.1,3.3
		c0.1,2.6,0.3,5.5-0.3,8c-0.5,2.1-1.9,3.6-3.9,4.2c-0.6,0.2-1.2,0.2-1.9,0.2c-3.9,0-8.3-2.7-10.1-4.5c-1.6-1.6-2.7-3.5-3.3-5.7
		c-0.5-1.9-0.7-4-0.5-6.4c0.3-4.2,1.6-8.5,2.8-12.3c0.4-1.4,0.8-2.8,1.1-4c0.4-1.7,0.9-3.4,1.3-5c1-3.5,1.9-7.1,2.6-10.6
		c-3.9,4.5-7.9,9-11.7,13.3c-4.3,4.9-8.9,10.1-14.1,16c-0.6,0.7-1.2,1.3-1.8,2c-1.2,1.3-2.4,2.6-3.5,4c-1.9,2.2-2,3.8-2.3,6.5
		c-0.1,0.8-0.2,1.7-0.3,2.6c-0.3,2,0.3,4.8,0.8,7.2c0.2,1.1,0.5,2.2,0.6,3.2c0.9,5.6,1.8,11.4,2.7,16.9l0.2,1.5
		c0.4,2.2,0.7,4.4,1,6.5c0.2,1.7,0.5,3.4,0.7,5.1c0.5,3.5,1.6,7,3,9.6c0.7,1.3,1.2,3.3,1.9,6.3c0.6,3,0.1,6.4-0.4,9.2
		c0,0.3-0.1,0.6-0.1,0.9L58,280.7c-0.1,0.5-0.2,1-0.4,1.6c-0.1,0.6-0.4,1.7-0.4,2.3c0.3,0.1,1,0.3,1.5,0.3c0.5,0.1,1.1,0.2,1.8,0.4
		l28.5,7.2c2.1-9,4.5-19.4,7.2-29.9c0.2-0.8,0.4-1.6,0.6-2.3c1-3.8,2-7.8,3.3-11.7C101,245.5,103.1,243.5,105,241.6z" />
            <path d="M295.7,261.4c-0.9-3.8-1.9-7.6-3.2-11.3v0c-0.2-0.7-0.5-1.3-0.9-1.8c-13.1,25-35.9,44.2-63.4,52.4
		c-8.9,2.7-17.9-4-17.9-13.3v-43.4c0-4.9,2.6-9.4,6.8-12c5.7-3.4,10.5-8.3,13.7-14.2c2.4-4.5,7.1-7.2,12.2-7.2H278
		c-0.1-1.8-0.3-3.9-0.5-6.5c-0.3-3.3-0.3-6.8-0.3-10.1c0-2.8-0.1-5.6-0.2-8.4h-35.8c-4.7,0-9.1-2.4-11.7-6.3
		c-14.8-22.9-48.4-23-63.3,0c-2.6,4-7,6.3-11.7,6.3H120c-0.2,2.8-0.2,5.6-0.2,8.4c0,3.3-0.1,6.8-0.3,10.1c-0.2,2.6-0.4,4.6-0.5,6.5
		h33.7c5.1,0,9.7,2.8,12.2,7.2c3.2,5.9,8,10.8,13.7,14.2c4.2,2.5,6.8,7,6.8,12v43.2c0,9.4-9,16-18,13.3c-27-8.3-49.4-27.1-62.4-51.6
		c-0.2,0.4-0.4,0.8-0.5,1.2l0,0c-1.3,3.7-2.2,7.5-3.2,11.3c-0.2,0.8-0.4,1.6-0.6,2.3c-1,4-2,8-2.9,12c8.1,10.7,18.1,20.3,30,28.4
		c57.9,39.1,132.9,22.4,171.4-29c-0.9-3.7-1.8-7.5-2.8-11.4C296.1,262.9,295.9,262.1,295.7,261.4z M180.6,199.7
		c0-9.7,7.8-17.5,17.5-17.5c9.7,0,17.5,7.8,17.5,17.5c0,9.7-7.8,17.5-17.5,17.5C188.4,217.2,180.6,209.3,180.6,199.7z" />
            <path d="M81.7,176.8c-0.1,1.9,0,3.5,0.3,4.9c0.4,1.5,1.1,2.7,2.1,3.6c1.8,1.7,5.8,3.6,7.5,3.1c0.2-0.1,0.5-0.1,0.7-0.9
		c0.5-1.8,0.3-4.3,0.2-6.7c-0.1-1.3-0.1-2.4-0.1-3.6l0.2-15c0-0.6,0-1.1,0-1.7c0-3,0-6.4,1.4-9.5c0.8-1.9,2.1-3.5,3.6-4.8
		c1.5-1.3,3.2-2.2,5-2.8c2.2-0.7,4.5-0.9,6.7-0.6c18.8-29.2,51.5-48.6,88.7-48.6c37.3,0,70.1,19.4,88.8,48.7
		c2.4-0.5,4.9-0.3,7.3,0.4c1.9,0.6,3.6,1.5,5,2.8c1.5,1.3,2.8,3,3.6,4.8c1.4,3.1,1.4,6.5,1.4,9.5c0,0.6,0,1.2,0,1.7l0.2,15
		c0,1.1,0,2.3-0.1,3.6c-0.1,2.4-0.2,4.8,0.2,6.7c0.2,0.7,0.5,0.8,0.7,0.9c1.7,0.5,5.7-1.4,7.5-3.1c1-1,1.7-2.2,2.1-3.6
		c0.4-1.4,0.5-3,0.3-4.9c-0.3-3.7-1.5-7.8-2.6-11.4c-0.4-1.5-0.9-2.9-1.2-4.2c-0.4-1.6-0.9-3.3-1.3-4.9c-1-3.6-2-7.3-2.7-11.1
		c0-0.2-0.1-0.4-0.1-0.6l0-0.1c0-0.2-0.1-0.3-0.1-0.5l-0.2-0.8c0-0.1,0-0.1,0-0.2l-0.1-0.3c0,0,0-0.1,0-0.1l0-0.2
		c0-0.2-0.1-0.5-0.1-0.7l-0.1-0.4c0,0,0-0.1,0-0.1l0,0c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.2-0.1-0.3l0-0.1c0-0.1-0.1-0.3-0.1-0.4l0-0.2
		c0,0,0-0.1,0-0.1c0-0.1-0.1-0.3-0.1-0.4c0,0,0-0.1,0-0.1l0-0.1c-0.1-0.5-0.2-1-0.3-1.3c-0.2-0.9-0.3-1.5-0.4-2l-0.3-2.6
		c-41.5-67.4-137.5-81.2-196.1-22.7c-6.6,6.6-12.3,13.8-17.2,21.3l-0.5,4c-0.1,0.5-0.2,1-0.4,2c-0.1,0.4-0.2,0.8-0.3,1.3l0,0.1
		c0,0,0,0.1,0,0.1c0,0.1-0.1,0.3-0.1,0.4c0,0,0,0.1,0,0.1l0,0.1c0,0,0,0,0,0l0,0c0,0.1-0.1,0.3-0.1,0.4l-0.1,0.7c0,0,0,0.1,0,0.1
		l-0.1,0.4c0,0.2-0.1,0.4-0.1,0.7h0l0,0.2c0,0,0,0.1,0,0.1l0,0.3c0,0.1,0,0.1,0,0.2l-0.1,0.6c0,0.1,0,0.1,0,0.2
		c0,0.2-0.1,0.3-0.1,0.5l0,0c0,0,0,0.1,0,0.1l0,0c0,0.2-0.1,0.4-0.1,0.6c-0.7,3.8-1.7,7.5-2.7,11.1c-0.4,1.6-0.9,3.3-1.3,4.9
		c-0.3,1.3-0.8,2.7-1.2,4.2C83.2,169,82,173.1,81.7,176.8z" />
        </g>
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default DriverBehaviourSVG;
