/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import {
    Form,
    Input,
    Button,
    Avatar as AntAvatar,
    Modal,
    List,
    Icon,
} from 'antd';

import 'rc-color-picker/assets/index.css';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import {
    ClockCircleOutlined,
    InfoCircleOutlined,
    QuestionCircleOutlined,
    WarningOutlined,
    CheckCircleOutlined,
    HeatMapOutlined,
    ApiOutlined,
    BugOutlined,
    CameraOutlined,
    CarOutlined,
    DashboardOutlined,
    EnvironmentOutlined,
    FireOutlined,
    HeartOutlined,
    HomeOutlined,
    StarOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';

import * as dashboardActions from '../../../../core/dashboard/dashboardActions';
import * as smartWidgetActions from '../../../../core/smartWidget/smartWidgetActions';

import CarbonReportSVG from '../../../../assets/icons/CarbonReportSVG';
import AutoStopLogoColourSVG from '../../../../assets/icons/AutoStopLogoColourSVG';
import CyclearLogoGreySVG from '../../../../assets/icons/CyclearLogoGreySVG';
import DriverBehaviourSVG from '../../../../assets/icons/DriverBehaviourSVG';
import IDClearLogoColourSVG from '../../../../assets/icons/IDClearLogoColourSVG';
import LowFuelSVG from '../../../../assets/icons/LowFuelSVG';
import AdvancedGPSAccuracySVG from '../../../../assets/icons/AdvancedGPSAccuracySVG';
import AntiTamperHardwareSVG from '../../../../assets/icons/AntiTamperHardwareSVG';
import AutomaticHealthStatusReportsSVG from '../../../../assets/icons/AutomaticHealthStatusReportsSVG';
import CustomisableWidgetDashboardSVG from '../../../../assets/icons/CustomisableWidgetDashboardSVG';
import DriverIdentificationSVG from '../../../../assets/icons/DriverIdentificationSVG';
import EasyReportAndAdminControlSVG from '../../../../assets/icons/EasyReportAndAdminControlSVG';
import EventReportingSVG from '../../../../assets/icons/EventReportingSVG';
import FnolSVG from '../../../../assets/icons/FnolSVG';
import FuelEfficiencySVG from '../../../../assets/icons/FuelEfficiencySVG';
import FuelOptimisationSVG from '../../../../assets/icons/FuelOptimisationSVG';
import GeoFencingSVG from '../../../../assets/icons/GeoFencingSVG';
import ISSInstallationAndServiceQualityAssuranceSVG from '../../../../assets/icons/ISSInstallationAndServiceQualityAssuranceSVG';
import MapAndAssetDisplaySVG from '../../../../assets/icons/MapAndAssetDisplaySVG';
import MultiAssetActivitySideBarSVG from '../../../../assets/icons/MultiAssetActivitySideBarSVG';
import NearestVehicleAndPostcodeSearchSVG from '../../../../assets/icons/NearestVehicleAndPostcodeSearchSVG';
import OperationalAlertsSVG from '../../../../assets/icons/OperationalAlertsSVG';
import PTOConnectionGuaranteeSVG from '../../../../assets/icons/PTOConnectionGuaranteeSVG';
import RealTimeTrackingTelematicsAndVideoSVG from '../../../../assets/icons/RealTimeTrackingTelematicsAndVideoSVG';
import RealTimeTrafficOverlaySVG from '../../../../assets/icons/RealTimeTrafficOverlaySVG';
import RemoteTachographDownloadSVG from '../../../../assets/icons/RemoteTachographDownloadSVG';
import RoadRelatedSpeedingAlertsSVG from '../../../../assets/icons/RoadRelatedSpeedingAlertsSVG';
import RouteOptimisationSVG from '../../../../assets/icons/RouteOptimisationSVG';
import RoutePlanningWithLiveTrafficSVG from '../../../../assets/icons/RoutePlanningWithLiveTrafficSVG';
import RXLiteIntegrationSVG from '../../../../assets/icons/RXLiteIntegrationSVG';
import UKInHouseDevelopmentTeamSVG from '../../../../assets/icons/UKInHouseDevelopmentTeamSVG';
import WebBasedTrackingPlatformSVG from '../../../../assets/icons/WebBasedTrackingPlatformSVG';

const CarbonIcon = (props) => <Icon component={CarbonReportSVG} {...props} />;
const AutoStopLogoColourIcon = (props) => <Icon component={AutoStopLogoColourSVG} {...props} />;
const CyclearLogoGreyIcon = (props) => <Icon component={CyclearLogoGreySVG} {...props} />;
const DriverBehaviourIcon = (props) => <Icon component={DriverBehaviourSVG} {...props} />;
const IDClearLogoColourIcon = (props) => <Icon component={IDClearLogoColourSVG} {...props} />;
const LowFuelIcon = (props) => <Icon component={LowFuelSVG} {...props} />;
const AdvancedGPSAccuracyIcon = (props) => <Icon component={AdvancedGPSAccuracySVG} {...props} />;
const AntiTamperHardwareIcon = (props) => <Icon component={AntiTamperHardwareSVG} {...props} />;
const AutomaticHealthStatusReportsIcon = (props) => <Icon component={AutomaticHealthStatusReportsSVG} {...props} />;
const CustomisableWidgetDashboardIcon = (props) => <Icon component={CustomisableWidgetDashboardSVG} {...props} />;
const DriverIdentificationIcon = (props) => <Icon component={DriverIdentificationSVG} {...props} />;
const EasyReportAndAdminControlIcon = (props) => <Icon component={EasyReportAndAdminControlSVG} {...props} />;
const EventReportingIcon = (props) => <Icon component={EventReportingSVG} {...props} />;
const FnolIcon = (props) => <Icon component={FnolSVG} {...props} />;
const FuelEfficiencyIcon = (props) => <Icon component={FuelEfficiencySVG} {...props} />;
const FuelOptimisationIcon = (props) => <Icon component={FuelOptimisationSVG} {...props} />;
const GeoFencingIcon = (props) => <Icon component={GeoFencingSVG} {...props} />;
const ISSInstallationAndServiceQualityAssuranceIcon = (props) => <Icon component={ISSInstallationAndServiceQualityAssuranceSVG} {...props} />;
const MapAndAssetDisplayIcon = (props) => <Icon component={MapAndAssetDisplaySVG} {...props} />;
const MultiAssetActivitySideBarIcon = (props) => <Icon component={MultiAssetActivitySideBarSVG} {...props} />;
const NearestVehicleAndPostcodeSearchIcon = (props) => <Icon component={NearestVehicleAndPostcodeSearchSVG} {...props} />;
const OperationalAlertsIcon = (props) => <Icon component={OperationalAlertsSVG} {...props} />;
const PTOConnectionGuaranteeIcon = (props) => <Icon component={PTOConnectionGuaranteeSVG} {...props} />;
const RealTimeTrackingTelematicsAndVideoIcon = (props) => <Icon component={RealTimeTrackingTelematicsAndVideoSVG} {...props} />;
const RealTimeTrafficOverlayIcon = (props) => <Icon component={RealTimeTrafficOverlaySVG} {...props} />;
const RemoteTachographDownloadIcon = (props) => <Icon component={RemoteTachographDownloadSVG} {...props} />;
const RoadRelatedSpeedingAlertsIcon = (props) => <Icon component={RoadRelatedSpeedingAlertsSVG} {...props} />;
const RouteOptimisationIcon = (props) => <Icon component={RouteOptimisationSVG} {...props} />;
const RoutePlanningWithLiveTrafficIcon = (props) => <Icon component={RoutePlanningWithLiveTrafficSVG} {...props} />;
const RXLiteIntegrationIcon = (props) => <Icon component={RXLiteIntegrationSVG} {...props} />;
const UKInHouseDevelopmentTeamIcon = (props) => <Icon component={UKInHouseDevelopmentTeamSVG} {...props} />;
const WebBasedTrackingPlatformIcon = (props) => <Icon component={WebBasedTrackingPlatformSVG} {...props} />;

const FormItem = Form.Item;

class IconPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            modalParent: null,
            iconSelected: null,
        };
    }

    componentDidMount() {
    }

    setIcon = (item) => {
        const { form, onChange } = this.props;
        const { modalParent } = this.state;

        this.setState({ iconSelected: item.id });
        form.setFieldsValue({ [modalParent]: item.id });

        onChange({ id: item.id });
    }

    removeIcon = (item) => {
        const { form, onChange } = this.props;
        form.setFieldsValue({ [item]: null });

        onChange({ id: null });
    }

    mapIcons = (icons) => icons.reduce((a, { id, title }) => {
        a[id] = title;
        return a;
    }, Object.create(null))

    setVisible = (value, modalParent) => {
        this.setState({ modalVisible: value, modalParent, iconSelected: null });
    };

    render() {
        const {
            form, name, label, defaultIcon,
        } = this.props;
        const { getFieldDecorator } = form;
        const { modalVisible, iconSelected } = this.state;

        // update icon list please also update iconwithvalueform, iconwithvaluesform and multiiconform
        const icons = [
            {
                id: 1,
                title: <ClockCircleOutlined />,
            },
            {
                id: 2,
                title: <InfoCircleOutlined />,
            },
            {
                id: 3,
                title: <QuestionCircleOutlined />,
            },
            {
                id: 4,
                title: <WarningOutlined />,
            },
            {
                id: 5,
                title: <CheckCircleOutlined />,
            },
            {
                id: 6,
                title: <HeatMapOutlined />,
            },
            {
                id: 7,
                title: <ApiOutlined />,
            },
            {
                id: 8,
                title: <BugOutlined />,
            },
            {
                id: 9,
                title: <CameraOutlined />,
            },
            {
                id: 10,
                title: <CarOutlined />,
            },
            {
                id: 11,
                title: <DashboardOutlined />,
            },
            {
                id: 12,
                title: <EnvironmentOutlined />,
            },
            {
                id: 13,
                title: <FireOutlined />,
            },
            {
                id: 14,
                title: <HeartOutlined />,
            },
            {
                id: 15,
                title: <StarOutlined />,
            },
            {
                id: 16,
                title: <HomeOutlined />,
            },
            {
                id: 17,
                title: <CarbonIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 18,
                title: <AutoStopLogoColourIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 19,
                title: <CyclearLogoGreyIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 20,
                title: <DriverBehaviourIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 21,
                title: <IDClearLogoColourIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 22,
                title: <LowFuelIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 23,
                title: <AdvancedGPSAccuracyIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 24,
                title: <AntiTamperHardwareIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 25,
                title: <AutomaticHealthStatusReportsIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 26,
                title: <CustomisableWidgetDashboardIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 27,
                title: <DriverIdentificationIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 28,
                title: <EasyReportAndAdminControlIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 29,
                title: <EventReportingIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 30,
                title: <FnolIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 31,
                title: <FuelEfficiencyIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 32,
                title: <FuelOptimisationIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 33,
                title: <GeoFencingIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 34,
                title: <ISSInstallationAndServiceQualityAssuranceIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 35,
                title: <MapAndAssetDisplayIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 36,
                title: <MultiAssetActivitySideBarIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 37,
                title: <NearestVehicleAndPostcodeSearchIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 38,
                title: <OperationalAlertsIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 39,
                title: <PTOConnectionGuaranteeIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 40,
                title: <RealTimeTrackingTelematicsAndVideoIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 41,
                title: <RealTimeTrafficOverlayIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 42,
                title: <RemoteTachographDownloadIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 43,
                title: <RoadRelatedSpeedingAlertsIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 44,
                title: <RouteOptimisationIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 45,
                title: <RoutePlanningWithLiveTrafficIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 46,
                title: <RXLiteIntegrationIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 47,
                title: <UKInHouseDevelopmentTeamIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
            {
                id: 48,
                title: <WebBasedTrackingPlatformIcon style={{ fill: '#FFFFFF', fontSize: '100%' }} />,
            },
        ];
        const mappedIcons = this.mapIcons(icons);

        return (
            <>
                <FormItem className="icon-field" label={label}>
                    {getFieldDecorator(name, {
                        rules: [
                            {
                                required: true,
                                message: 'Please choose icon',
                            },
                        ],
                        initialValue: defaultIcon,
                    })(
                        <>
                            <Input hidden />
                            <AntAvatar className="icon-avatar" shape="square" size={64} icon={mappedIcons[form.getFieldValue(name)]} />
                            <Button className="icon-button change-button" onClick={() => this.setVisible(true, name)}>Change</Button>
                            <Button className="icon-button danger-button" onClick={() => this.removeIcon(name)}>Remove</Button>
                        </>,
                    )}
                </FormItem>
                <br />

                {/* Icon Modal */}
                <Modal
                    title="Select Icon"
                    centered
                    transitionName=""
                    maskTransitionName=""
                    visible={modalVisible}
                    onOk={() => this.setVisible(false)}
                    onCancel={() => this.setVisible(false)}
                    width={1000}>
                    <List
                        className="icon-list"
                        grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2,
                            md: 4,
                            lg: 4,
                            xl: 4,
                            xxl: 4,
                        }}
                        dataSource={icons}
                        renderItem={(item) => (
                            <List.Item>
                                <AntAvatar
                                    style={{ cursor: 'pointer', border: '2px solid #ccc' }}
                                    shape="square"
                                    size={128}
                                    icon={item.title}
                                    className={(iconSelected === item.id) ? 'icon_active' : null}
                                    onClick={() => this.setIcon(item)} />
                            </List.Item>
                        )} />
                </Modal>
            </>
        );
    }
}

IconPicker.defaultProps = {
    name: 'icon',
    form: [],
    label: 'Icon',
    defaultIcon: null,
    onChange: () => {},
};

IconPicker.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    defaultIcon: PropTypes.number,
    onChange: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...dashboardActions,
            ...smartWidgetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IconPicker);
