/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { isEmpty } from 'underscore';
import { Icon, Avatar as AntAvatar } from 'antd';

import ChartHeader from '../ChartHeader';
import ChartMultiDropDown from '../ChartMultiDropDown';
import './iconChart.scss';

import { convertFromSeconds, getChartIconList } from '../../../../core/utils/functions';

export default function () {
    const {
        data,
        rightMenuItems,
        rightMenuOnClick,
        tooltipText,
        onFirstOptionChange,
        onSecondOptionChange,
        onThirdOptionChange,
        onFourOptionChange,
        onAllOptionChange,
        defaultFirstOptionValue,
        defaultSecondOptionValue,
        defaultThirdOptionValue,
        defaultFourOptionValue,
        divisions,
        userDivisions,
        drivers,
        assets,
        unit,
        subtitle,
        heightRatio,
        imageUrl,
        chartIcon,
        chartKey,
    } = this.props;

    const { timeframe } = this.state;

    let widgetHeight = heightRatio * 200;
    if (heightRatio > 1) {
        widgetHeight += ((heightRatio - 1) * 95);
    }

    const mappedIcons = getChartIconList();

    let displayChartIcon = null;
    let displayValue = 0;
    let mainLabelText = null;
    let secondaryLabelText = null;
    let iconPosition = 'top';
    let iconColor = 'white';
    let customIcon = false;
    if (data[0]) {
        if (data[0].value) {
            if (data[0].unit == 'h:m:s') {
                displayValue = convertFromSeconds(data[0].value);
            } else {
                displayValue = data[0].value;
            }
        }

        if (data[0].color) {
            iconColor = data[0].color;
        }

        if (data[0].main_label_text) {
            mainLabelText = data[0].main_label_text;
        }

        if (data[0].secondary_label_text) {
            secondaryLabelText = data[0].secondary_label_text;
        }

        if (data[0].icon_position) {
            iconPosition = data[0].icon_position;
        }

        if (data[0].icon_id && mappedIcons[data[0].icon_id]) {
            displayChartIcon = mappedIcons[data[0].icon_id];
            if (data[0].icon_id > 16) {
                displayChartIcon = <Icon component={displayChartIcon} style={{ fill: iconColor, fontSize: '100%' }} />;
                if (['18', '19', '21'].includes(data[0].icon_id)) {
                    customIcon = true;
                }
            }
        }
    }

    if (chartIcon) {
        displayChartIcon = chartIcon;
    }

    return (
        <div className="new-chart-widget bar-chart-widget" style={{}}>
            <ChartHeader
                timeframe={timeframe}
                onTimeframeChange={(timeframeData) => this.onTimeframeChange(timeframeData)}
                rightMenuOnClick={(rightMenuData) => rightMenuOnClick(rightMenuData)}
                rightMenuItems={rightMenuItems}
                tooltipText={tooltipText} />
            <div className="icon-chart-content" style={{ width: '100%', height: widgetHeight, overflow: 'hidden' }} id={`modalContent_${chartKey}`}>
                <div className={`icon-chart-content-inner ${iconPosition}`} onClick={() => rightMenuOnClick('see data in table')}>
                    {
                        isEmpty(displayChartIcon) ? null : (
                            <div key="ixon-item" className={`single-icon-item${customIcon ? ' custom-icon' : ''}`} style={{ color: iconColor }}>
                                {displayChartIcon}
                            </div>
                        )
                    }
                    <div className="info-block">
                        <div className="title">{displayValue} {mainLabelText}</div>
                        <div className="subtitle">{secondaryLabelText}</div>
                    </div>
                </div>

            </div>
            <div className="new-chart-widget-footer">
                <ChartMultiDropDown
                    onFirstOptionChange={(firstOption) => onFirstOptionChange(firstOption)}
                    onSecondOptionChange={(secondOption) => onSecondOptionChange(secondOption)}
                    onThirdOptionChange={(thirdOption) => onThirdOptionChange(thirdOption)}
                    onFourOptionChange={(fourOption) => onFourOptionChange(fourOption)}
                    onDataChange={(optionsData) => onAllOptionChange(optionsData)}
                    defaultFirstOptionValue={defaultFirstOptionValue}
                    defaultSecondOptionValue={defaultSecondOptionValue}
                    defaultThirdOptionValue={defaultThirdOptionValue}
                    defaultFourOptionValue={defaultFourOptionValue}
                    divisions={divisions}
                    userDivisions={userDivisions}
                    drivers={drivers}
                    assets={assets} />
            </div>
        </div>
    );
}
