/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Row,
    Col,
    Tabs,
    Popconfirm,
    Spin,
    Modal,
    Space,
    Icon as AntIcon,
    Empty,
    Input,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { size, isEmpty } from 'underscore';
import MapComponent from '../../components/Map';
import app from '../../core/constants';
import history from '../../core/utils/history';
import ShowAssetLocation from '../../components/ShowAssetLocation';
import Layout from '../../components/layout/Layout';
import Avatar from '../../components/elements/Avatar';
import FilterSidebar from '../../components/FilterSidebar';
import Icon from '../../components/elements/Icon';
import GeneralInfo from './components/GeneralInfo';
import AssetJourneyHistoryTab from './tabs/assetJourneyHistory';
import AssetJourneyHistoryFilter from './forms/assetJourneyHistoryFilter';
import AssetEventHistoryTab from './tabs/assetEventHistory';
import AssetEventHistoryFilter from './forms/assetEventHistoryFilter';
import AssetVideoHistoryTab from './tabs/assetVideoHistory';
import AssetVideoHistoryFilter from './forms/assetVideoHistoryFilter';
import AssetServiceAlertsTab from './tabs/assetServiceAlerts';
import AssetServiceAlertsFilter from './forms/assetServiceAlertsFilter';
import getBoundingBox from '../../core/utils/getBoundingBox';
import './viewAsset.scss';

const { GOOGLE_MAP_API_KEY } = app;

const { TabPane } = Tabs;
const { confirm } = Modal;

export default function () {
    const {
        singleAsset, actions, match, isFetching, user,
    } = this.props;
    const {
        journeyHistoryTabLoaded, eventHistoryTabLoaded, videoHistoryTabLoaded, serviceAlertsTabLoaded, currentTabKey,
        selectedAssetLocation, deleteDataConfirmButtonVisible, isDeleteDataModalVisible, displayFilter,
    } = this.state;
    const { asset_id } = match.params;
    let filterContent = null;

    let journeyHistoryTabContent = null;
    let eventHistoryTabContent = null;
    let videoHistoryTabContent = null;
    let serviceAlertsTabContent = null;

    if (currentTabKey === '1') {
        filterContent = <AssetJourneyHistoryFilter asset_id={asset_id} onSubmit={this.onJourneyHistoryTabFilterChange} />;
        journeyHistoryTabContent = (
            <AssetJourneyHistoryTab
                assetId={asset_id}
                onPageChange={this.onJourneyHistoryTabPageChange}
                onTableChange={this.onJourneyHistoryTabSortChange}
                journeyHistoryTabLoaded={journeyHistoryTabLoaded}
                setLoadedState={this.assetJourneyTabLoaded}
                journeyHistoryFirstLoad={this.state.journeyHistoryFirstLoad} />
        );
    } else if (currentTabKey === '2') {
        filterContent = <AssetEventHistoryFilter onSubmit={this.onJourneyEventsTabFilterChange} />;
        eventHistoryTabContent = (
            <AssetEventHistoryTab
                assetId={asset_id}
                onPageChange={this.onEventsHistoryTabPageChange}
                onTableChange={this.onJourneyEventsTabSortChange}
                eventHistoryTabLoaded={eventHistoryTabLoaded}
                setLoadedState={this.eventHistoryTabLoaded}
                eventHistoryFirstLoad={this.state.eventHistoryFirstLoad} />
        );
    } else if (currentTabKey === '3') {
        filterContent = <AssetVideoHistoryFilter onSubmit={this.onVideoHistoryTabFilterChange} />;
        videoHistoryTabContent = (
            <AssetVideoHistoryTab
                assetId={asset_id}
                onPageChange={this.onVideoHistoryTabPageChange}
                onTableChange={this.onVideoHistoryTabSortChange}
                videoHistoryTabLoaded={videoHistoryTabLoaded}
                setLoadedState={this.videoHistoryTabLoaded}
                videoHistoryFirstLoad={this.state.videoHistoryFirstLoad} />
        );
    } else if (currentTabKey === '4') {
        filterContent = <AssetServiceAlertsFilter onSubmit={this.onServiceAlertsTabFilterChange} />;
        serviceAlertsTabContent = (
            <AssetServiceAlertsTab
                assetId={asset_id}
                serviceAlertsTabLoaded={serviceAlertsTabLoaded}
                setLoadedState={this.serviceAlertsTabLoaded} />
        );
    }

    if (isEmpty(singleAsset)) {
        return (
            <Layout
                className="assets"
                title={<div>Assets</div>}
                headerTitle={<div>Assets Page</div>}
                showFooter={false}>
                <div className="content-wrap">
                    <br />
                    <br />
                    <Empty description="You don't have permission to access this asset" />
                </div>
            </Layout>
        );
    }

    const centerMap = {
        lat: singleAsset.lat,
        lng: singleAsset.lon,
    };

    const iframeSrc = `https://maps.google.com/maps?q=${singleAsset.lat}, ${singleAsset.lon}&z=10&output=embed`;
    // let bounds1 = getBoundingBox([singleAsset.lat, singleAsset.lon], 9500);

    let spinning = false;
    for (let i = 0; i < isFetching.length; i++) {
        const item = isFetching[i];
        if (item.GET_ASSET_INFO) {
            spinning = true;
        }
    }

    return (
        <Layout
            data-test="pages-view-asset"
            className="assets"
            title={<div>AssetsView</div>}
            headerTitle={<div>AssetsView Page</div>}
            showFooter={false}>
            <div className="content-wrap">
                <FilterSidebar displayFilter={displayFilter}>{filterContent}</FilterSidebar>
                <div className="content">
                    <div className="content-inner">
                        <Spin spinning={spinning}>
                            <section className="section">
                                <div className="section__header">
                                    <div className="section__header-item">
                                        <Link
                                            to="/assets"
                                            className="d-flex align-center">
                                            <Icon name="arrow-left" />
                                            <span>All assets</span>
                                        </Link>
                                    </div>
                                    <div className="section__header-item is-active">
                                        <span>{singleAsset.registration || singleAsset.name}</span>
                                    </div>
                                </div>
                                <div className="section__body">
                                    <div className="section__action-bar justify-center">
                                        <span style={{ marginRight: 'auto' }}>
                                            {user.permissions.live_video === true ? (
                                                <>
                                                    <Button
                                                        onClick={() => history.push(`/video/live-videos/${singleAsset.id}`)}
                                                        type="primary"
                                                        className="btn"
                                                        style={{ width: '160px' }}
                                                    >
                                                        <Icon name="video" />
                                                        <span>Live Cameras</span>
                                                    </Button>
                                                </>
                                            ) : null}
                                            <Button
                                                onClick={() => history.push(`/assets/edit/${singleAsset.id}`)}
                                                type="primary"
                                                className="btn"
                                                style={{
                                                    width: '160px',
                                                    marginLeft: '16px',
                                                }}
                                                disabled={user.permissions.editing_assets === false}
                                            >
                                                <Icon name="pencil" />
                                                <span>Edit Asset</span>
                                            </Button>
                                        </span>
                                        <span className="text-bold">
                                            Asset:
                                        </span>
                                        <span className="ml-1">{singleAsset.name}</span>
                                        <span style={{ marginLeft: 'auto' }}>
                                            {user.permissions.editing_assets === true ? (
                                                <>
                                                    {user.role === 'admin' || user.role === 'super-admin' ? (
                                                        <>
                                                            <Button
                                                                className="btn btn-bordered"
                                                                onClick={this.showModal}
                                                                style={{ width: '160px' }}
                                                            >
                                                                <Icon name="basket" />
                                                                <span>
                                                                    delete data
                                                                </span>
                                                            </Button>
                                                            &ensp;
                                                        </>
                                                    ) : null}
                                                    <Modal
                                                        icon={<ExclamationCircleOutlined />}
                                                        title="Confirm deletion"
                                                        visible={isDeleteDataModalVisible}
                                                        onOk={this.handleOk}
                                                        onCancel={this.handleCancel}
                                                        okText="Delete data"
                                                        okButtonProps={{ disabled: !deleteDataConfirmButtonVisible }}
                                                    >
                                                        <h3>{`Please confirm you’d like to delete ${singleAsset.name}'s data by typing ‘delete’ into the below field:`}</h3>
                                                        <br />
                                                        <Input
                                                            onChange={(e) => this.saveDeleteText(e.target.value)}
                                                            placeholder="Type delete"
                                                        />
                                                    </Modal>
                                                    <Popconfirm
                                                        title="Are you sure you'd like to delete this asset permanently?"
                                                        onConfirm={() => actions.deleteAssetRequest(singleAsset.id)}
                                                        okText="Delete"
                                                        cancelText="Cancel"
                                                        okType="danger"
                                                    >
                                                        <Button
                                                            className="btn btn-bordered"
                                                            style={{
                                                                width: '160px',
                                                                marginLeft: '8px',
                                                            }}
                                                        >
                                                            <Icon name="basket" />
                                                            <span>
                                                                delete asset
                                                            </span>
                                                        </Button>
                                                    </Popconfirm>
                                                </>
                                            ) : null}
                                        </span>
                                    </div>
                                    <br />
                                    <Row
                                        gutter={40}
                                        type="flex"
                                        justify="space-between"
                                        className="data-row">
                                        <Col md={6}>
                                            <GeneralInfo {/* eslint-disable-line react/jsx-props-no-spreading */ ...singleAsset} />
                                        </Col>
                                        <Col md={7}>
                                            <div className="avatar-uploader">
                                                {/* {!isFetching ? <ShowAssetLocation asset={singleAsset} /> : null} */}
                                                {singleAsset.lat !== null && singleAsset.lon !== null
                                                    ? (
                                                        <MapComponent
                                                            ref={this.googleMapAccess}
                                                            apiKey={GOOGLE_MAP_API_KEY}
                                                            showToolbar={false}
                                                            allowStreetView={false}
                                                            showFindCenterButton={false}
                                                            allowZoom
                                                            yesIWantToUseGoogleMapApiInternals
                                                            onGoogleApiLoaded={({ map, maps }) => {
                                                                this.googleMapAccess.current = map;
                                                            }}
                                                            zoom={11}
                                                            randomNo={Date.now()}
                                                            center={centerMap}
                                                            defaultZoom={11}
                                                            resetBoundsOnResize
                                                            markers={[{
                                                                title: <span style={{ fontSize: '14px', lineHeight: '14px' }}><AntIcon type="caret-down" /></span>,
                                                                lat: singleAsset.lat,
                                                                lng: singleAsset.lon,
                                                                onClick: () => null,
                                                            }]} />
                                                        /*  <iframe src={iframeSrc} width="314px" height="314px" frameBorder="0" style={{ border: 0 }} /> */

                                                    )
                                                    : (
                                                        <div>
                                                            No GPS coordinates specified
                                                        </div>
                                                    )}

                                                <div className="ant-upload-btn">
                                                    <Icon name="location" />
                                                    <div className="ant-upload-text">
                                                        Current location: {singleAsset.location || 'Unknown'}
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={24} md={11}>
                                            <div className="actions">
                                                <Row
                                                    style={{ marginLeft: '40px', marginRight: 0 }}
                                                    type="flex"
                                                    gutter={40}
                                                    justify="space-between">
                                                    <Col>
                                                        <Avatar
                                                            placeholder="car"
                                                            pictureUrl={singleAsset.pictureThumb || null}
                                                            onChange={this.uploadImage} />

                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Tabs
                                        onTabClick={(key) => this.setState({ currentTabKey: key })}
                                        defaultActiveKey="1"
                                        animated={false}
                                        className="tabs-restyled2">
                                        <TabPane tab="journey history" key="1">
                                            {journeyHistoryTabContent}
                                        </TabPane>
                                        <TabPane tab="event history" key="2">
                                            {eventHistoryTabContent}
                                        </TabPane>
                                        {user.permissions.historic_video_downloading === true ? (
                                            <TabPane tab="video history" key="3">
                                                {videoHistoryTabContent}
                                            </TabPane>
                                        ) : null}
                                        <TabPane tab="service alerts" key="4">
                                            {serviceAlertsTabContent}
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </section>
                        </Spin>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
