/* eslint-disable react/no-this-in-sfc */

import './mapLiveVideo.scss';
import '../../components/Filter/filter.scss';

/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import {
    Spin,
    Button,
    Row,
    Col,
} from 'antd';
import Layout from '../../components/layout/Layout';
import AssetSidebar from '../../components/AssetsSidebar';
import VideoPayer from '../../components/elements/VideoPlayer';
import history from '../../core/utils/history';
import { getDistanceInMiles } from '../../core/utils/api';
import LiveVideoScreen from '../Video/partials/LiveVideoScreen';

const { GOOGLE_MAP_API_KEY } = require('../../core/constants').default;

export default function () {
    const {
        isFetching,
        assetLiveVideos,
        match,
        mapSelectedDeviceInfo,
        userMapCenter,
        actions,
        user,
        mapSelectedDeviceTelematicsData,
    } = this.props;
    const { selectedAssetLocation } = this.state;
    const { selectedDate, device } = match.params;
    const mappedDevicePath = this.getMappedDevicePath();
    const currentLocation = (mappedDevicePath && mappedDevicePath[mappedDevicePath.length - 1]) || null;

    const center = {
        lat: currentLocation && parseFloat(currentLocation.lat),
        lng: currentLocation && parseFloat(currentLocation.lng),
    };

    const currentDistance = currentLocation ? getDistanceInMiles({ lat: currentLocation.lat, lng: currentLocation.lng }, { lat: userMapCenter.lat, lng: userMapCenter.lng }) : 0;

    return (
        <Layout
            data-test="pages-map-live-video"
            className="map-live-video-page"
            title={<div>Map</div>}
            headerTitle={<div>Map</div>}
            menuWidth={350}
            menuPlacement="left">
            <div className="content-wrap">
                <div className="content">
                    <Spin spinning={isFetching && isFetching.length > 0}>
                        <div style={{ display: 'flex', height: '83.7vh', flexDirection: 'row' }}>
                            <AssetSidebar
                                assetId={mapSelectedDeviceInfo.id}
                                lastDataReceived={mapSelectedDeviceInfo.last_data_received}
                                backLinkClick={() => history.push('/map?showAssetList=true')}
                                device={device}
                                selectedDate={selectedDate || ''}
                                assetName={mapSelectedDeviceInfo.name || ''}
                                assetLastDataSent={mapSelectedDeviceInfo.last_data_received}
                                distance={mapSelectedDeviceInfo.distance || currentDistance || 0}
                                assetReg={mapSelectedDeviceInfo.registration || ''}
                                assetMake={mapSelectedDeviceInfo.make || ''}
                                assetModel={mapSelectedDeviceInfo.model || ''}
                                assetDriver={mapSelectedDeviceInfo.full_name || ''}
                                assetLocation={selectedAssetLocation || 'Unknown'}
                                assetPicture={mapSelectedDeviceInfo && mapSelectedDeviceInfo.asset_picture || ''}
                                profilePicture={mapSelectedDeviceInfo && mapSelectedDeviceInfo.profile_picture || ''}
                                telematicsData={mapSelectedDeviceTelematicsData}
                                user={user} />

                            <div style={{ flex: 1, height: 'inherit', width: '100%' }}>
                                <div className="history">
                                    <div className="section mt-3">
                                        <div className="section__body">
                                            <LiveVideoScreen />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
            </div>
        </Layout>
    );
}
