import { Record } from 'immutable';

const {
    GET_LIST_COMPANIES_REQUEST,
    GET_LIST_COMPANIES_SUCCESS,
    GET_LIST_COMPANIES_FAILURE,
} = require('./companyActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    companies: [],
});

const companyInitialState = new InitialState();

/**
* ## companyReducer function
* @param {Object} state - initialState
* @param {Object} action - type and payload
*/
function companyReducer(state = companyInitialState, { payload, type }) {
    if (!(state instanceof InitialState)) {
        state = companyInitialState.mergeDeep(state);
    }

    switch (type) {
        case GET_LIST_COMPANIES_REQUEST:
            return state.set('isFetching', true);

        case GET_LIST_COMPANIES_SUCCESS:
            return state.set('isFetching', false)
                .set('companies', payload);

        case GET_LIST_COMPANIES_FAILURE:
            return state.set('isFetching', false)
                .set('error', payload);
        default:
            return state;
    }
}

export {
    companyReducer,
    companyInitialState,
};
