import React from 'react';

const RealTimeTrackingTelematicsAndVideoSVG = () => (
    <svg width={64} height={64} xmlns="http://www.w3.org/2000/svg">
        <g stroke="null">
            <path d="M41.36 61.12A16.64 16.64 0 1158 44.48a16.64 16.64 0 01-16.64 16.64zm0-29.12a12.48 12.48 0 1012.48 12.48A12.48 12.48 0 0041.36 32z" />
            <path d="M44.667 50.72l-5.387-5.387V36.16h4.16v7.467l4.16 4.16-2.933 2.933z" />
            <path d="M55.92 11.2a4.16 4.16 0 00-4.16-4.16h-8.32V2.88h-4.16v4.16H22.64V2.88h-4.16v4.16h-8.32A4.16 4.16 0 006 11.2v41.6a4.16 4.16 0 004.16 4.16h8.32V52.8h-8.32V11.2h8.32v4.16h4.16V11.2h16.64v4.16h4.16V11.2h8.32v12.48h4.16V11.2z" />
        </g>
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default RealTimeTrackingTelematicsAndVideoSVG;
