import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { message } from 'antd';
import { emptyCache } from '../../core/utils/functions';
import DOM from './resetPassword';
import * as globalActions from '../../core/global/globalActions';
import * as userActions from '../../core/user/userActions';

class ResetPasssword extends PureComponent {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            modalOpen: true,
            isValidResetPassword: false,
            isSucessResetPassword: false,
            isFetching: false,
        };
    }

    componentDidMount() {
        const { actions, match, user } = this.props;
        const { token } = match.params;
        if (user && (user.isLoggedIn || user.loginUser != '')) {
            emptyCache();
        }
        actions.verifyResetPasswordRequest({
            token,
        });
        /* this.setState({
             isValidResetPassword: user.isValidResetPassword,
         }); */
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { actions, form } = this.props;
                let error = false;

                if (values.new_password !== values.new_password_confirm) {
                    error = true;
                    message.error('New password do not match!');
                }

                if (!error) {
                    actions.changePasswordRequest(values);
                    form.resetFields();
                }
            }
        });
    };

    render() {
        return this.view();
    }
}

ResetPasssword.propTypes = {
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        user: state.user,
        isValidResetPassword: state.user.isValidResetPassword,
        isValidSucessPassword: state.user.isSucessResetPassword,
        isFetching: state.user.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...userActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResetPasssword);
