import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Icon as AntIcon, Tooltip } from 'antd';
import { isNull } from 'underscore';
import DOM from './journeysView';
import {
    setMarkerPopoverDetails,
    setMarkerPopoverToLoading,
} from '../../core/utils/mapUtils';
import history from '../../core/utils/history';

import * as eventsActions from '../../core/events/eventsActions';
import * as deviceActions from '../../core/device/deviceActions';

class JourneysView extends Component {
    constructor(props) {
        super(props);
        this.centerMap = {};
        this.googleMapAccess = React.createRef();
        this.state = {
            before_time: 15,
            after_time: 15,
            copySuccess: '',
            markerPopover: null,
            currentTabKey: '1',
        };
        this.view = DOM;
    }

    componentDidMount() {
        const { actions, journeyIds } = this.props;

        actions.resetJourneysInfoRequest();
        if (journeyIds) actions.getJourneysInfoRequest({ ids: journeyIds });
        else history.goBack();
        setMarkerPopoverToLoading(this);
    }

    componentDidUpdate(prevProps) {
        const {
            actions,
            selectedJourneys,
            eventJourneysFetched,
            deviceDetailedLocationFetching,
            deviceDetailedLocationInfo,
            isFetching,
        } = this.props;

        if (!isFetching && isNull(selectedJourneys)) history.goBack();

        if (prevProps.selectedJourneys !== selectedJourneys) {
            if (eventJourneysFetched) {
                actions.resetUpdateMapEventDataFlagRequest();
                actions.mapDeviceLocationsRequest({
                    imei: selectedJourneys[0].info.device.imei,
                    date: selectedJourneys[0].info.start,
                });
                setTimeout(() => {
                    if (this.googleMapAccess && this.googleMapAccess.current) {
                        this.googleMapAccess.current.setState({ sliderMaxValue: selectedJourneys[0].endInterval });
                        this.googleMapAccess.current.executeAutoZoom();
                    }
                }, 300);
            }
        }

        if (deviceDetailedLocationFetching === false && deviceDetailedLocationInfo !== prevProps.deviceDetailedLocationInfo) {
            setMarkerPopoverDetails(this);
        }
    }

    handleTabChange = (tabKey) => {
        this.setState({ currentTabKey: tabKey });
    }

    mapDevicePaths = () => {
        const { selectedJourneys } = this.props;

        let foundFirstEvent = false;
        return selectedJourneys && selectedJourneys.map((selectedJourney) => (selectedJourney && selectedJourney.path ? selectedJourney.path.map((event, eventIndex) => {
            const tempEvent = {};
            tempEvent.time_interval = event.time_interval;
            tempEvent.record_id = event.id;
            tempEvent.lat = event.lat;
            tempEvent.lng = event.lng;
            tempEvent.angle = event.angle;
            tempEvent.speed = event.speed;
            tempEvent.position = 'path';
            tempEvent.title = selectedJourney?.info?.name || null;
            tempEvent.registration = selectedJourney?.info?.registration || null;

            if (eventIndex === 0) {
                tempEvent.position = 'end';
            } else if (eventIndex === (selectedJourney.path).length - 1) {
                tempEvent.position = 'start';
            }
            if ((event.priority === '1' && foundFirstEvent === false) || eventIndex === 0) {
                this.centerMap.lat = parseFloat(event.lat);
                this.centerMap.lng = parseFloat(event.lng);
                if (eventIndex > 0) {
                    foundFirstEvent = true;
                }
            }
            if (event.show_in_events === '1') {
                tempEvent.event_id = event.event_id;
                tempEvent.device_id = event.device_id;
                this.centerMap.lat = parseFloat(event.lat);
                this.centerMap.lng = parseFloat(event.lng);
                const iconStyle = {};
                iconStyle.fontSize = '16px';
                iconStyle.color = '#CF2B28';
                tempEvent.position = 'marker';
                tempEvent.currentMarker = {
                    event_id: event.event_id,
                    device_id: event.device_id,
                };
                let eventTitle = event.event_name;

                if (event.event_type == 253) {
                    if (event.green_driving_type == 0) {
                        eventTitle = 'Eco-friendly driving';
                    } else if (event.green_driving_type == 1) {
                        eventTitle = 'Harsh accelerating';
                    } else if (event.green_driving_type == 2) {
                        eventTitle = 'Harsh braking';
                    } else if (event.green_driving_type == 3) {
                        eventTitle = 'Harsh cornering';
                    }
                }

                if (event.icon && event.icon !== '') {
                    tempEvent.currentMarker.icon = <Tooltip placement="top" title={eventTitle}><AntIcon style={iconStyle} theme="filled" type={event.icon} /></Tooltip>;
                } else {
                    tempEvent.currentMarker.icon = <Tooltip placement="top" title={eventTitle}><AntIcon style={iconStyle} theme="filled" type="flag" /></Tooltip>;
                }
            }
            return tempEvent;
        }) : []));
    }

    render() {
        return this.view();
    }
}

JourneysView.propTypes = {
    actions: PropTypes.object.isRequired,
    deviceDetailedLocationFetching: PropTypes.bool.isRequired,
    deviceDetailedLocationInfo: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        selectedJourneys: state.events.selectedJourneys,
        eventJourneysFetched: state.events.eventJourneysFetched,
        isFetching: state.events.isFetching,
        journeyBackLink: state.global.journeyBackLink,
        user: state.user,
        deviceDetailedLocationFetching: state.device.deviceDetailedLocationFetching,
        deviceDetailedLocationInfo: state.device.deviceDetailedLocationInfo,
        journeyIds: state.events.journeysToRequest,
        mapSelectedDeviceLocations: state.device.mapSelectedDeviceLocations,
        uomPreference: state.user.profile.unit_of_measurement_preference,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...eventsActions,
                ...deviceActions,
            },
            dispatch,
        ),
    };
}
export const JourneysViewTest = JourneysView;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(JourneysView);
