/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Spin, Empty } from 'antd';
import { isEmpty } from 'underscore';
import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';

class ReportTab extends PureComponent {
    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange } = this.props;
        onTableChange(pagination, filters, sorter, extra);
    }

    render() {
        const {
            speedReport,
            isFetching,
            toggleFilterSidebar,
        } = this.props;

        const listReports = speedReport.map((report) => (
            {
                ...report,
            }
        ));

        const customEmpty = (
            <Empty
                description={(
                    <div style={{ color: '#64879c' }}>
                        <div style={{ fontSize: '180%', fontWeight: 'bold', marginBottom: '5px' }}>
                            Enter your search requirements to generate a report
                        </div><div>
                            Use the filter bar on the left to select Assets or Drivers,
                            the date and the frequency (if applicable) of the data you would like to display
                        </div>
                    </div>
                )}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{ display: 'none' }} />
        );

        return (
            <Spin spinning={isFetching}>
                <div className="report-table">
                    {isEmpty(listReports) ? customEmpty
                        : (
                            <GridView
                                data={listReports}
                                onChange={this.onTableChange}
                                disablePagination />
                        )}
                </div>
            </Spin>
        );
    }
}

ReportTab.defaultProps = {
    speedReport: [],
    onPageChange: () => null,
    onTableChange: () => null,
};

ReportTab.propTypes = {
    speedReport: PropTypes.array,
    isFetching: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        speedReport: state.report.speedReport,
        isFetching: state.report.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
