/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spin, Empty } from 'antd';
import { isEmpty, size } from 'underscore';
import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';

class ReportTab extends PureComponent {
    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange } = this.props;
        onTableChange(pagination, filters, sorter, extra);
    }

    render() {
        const {
            idleReport,
            isFetching,
            toggleFilterSidebar,
        } = this.props;

        const listReports = idleReport.map((report) => ({
            ...report,
            ...(Array.isArray(report.name) && report.name[0] && {
                name: (
                    <Link to={`/assets/view/${report.name[0]}`} className="btn btn-empty">
                        {report.name[1]}
                    </Link>
                ),
            }),
        }));

        const customEmpty = (
            <Empty
                description={(
                    <span style={{ color: '#64879c', display: 'block' }}>
                        <span style={{
                            fontSize: '180%', fontWeight: 'bold', marginBottom: '5px', display: 'block',
                        }}>
                            Enter your search requirements to generate a report
                        </span>
                        <span style={{ display: 'block' }}>
                            Use the filter bar on the left to select Assets or Drivers,
                            the date and the frequency (if applicable) of the data you would like to display
                        </span>
                    </span>
                )}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{ display: 'none' }} />
        );
        const exportableColumns = listReports && listReports[0] ? Object.keys(listReports[0]) : [];
        return (
            <Spin spinning={isFetching}>
                <div className="report-table">
                    {isEmpty(listReports) ? customEmpty
                        : (
                            <>
                                {size(listReports) === 1
                                    ? <Empty />
                                    : (
                                        <GridView
                                            showExportButton
                                            exportableColumns={exportableColumns}
                                            data={listReports}
                                            onChange={this.onTableChange}
                                            disablePagination />
                                    )}
                            </>
                        )}
                </div>
            </Spin>
        );
    }
}

ReportTab.defaultProps = {
    idleReport: [],
    onPageChange: () => null,
    onTableChange: () => null,
};

ReportTab.propTypes = {
    idleReport: PropTypes.array,
    isFetching: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        idleReport: state.report.idleReport,
        isFetching: state.report.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
