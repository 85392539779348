import { Record } from 'immutable';
import { findIndex } from 'underscore';

const {
    DELETE_TOKEN_REQUEST,
    DELETE_TOKEN_SUCCESS,

    SAVE_TOKEN_REQUEST,
    SAVE_TOKEN_SUCCESS,

    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,

    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,

    TWO_FACTOR_AUTH_REQUEST,
    TWO_FACTOR_AUTH_SUCCESS,
    TWO_FACTOR_AUTH_FAILURE,

    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,

    VERIFY_RESET_PASSWORD_REQUEST,
    VERIFY_RESET_PASSWORD_SUCCESS,
    VERIFY_RESET_PASSWORD_FAILURE,

    GET_COMPANY_USERS_REQUEST,
    GET_COMPANY_USERS_SUCCESS,
    GET_COMPANY_USERS_FAILURE,

    GET_USERS_PAGE_REQUEST,
    GET_USERS_DEPARTMENT_REQUEST,
    GET_USERS_PAGE_SUCCESS,
    GET_USERS_PAGE_FAILURE,

    GET_DIVISION_ASSET_PERMISSIONS_GRID_REQUEST,
    GET_DIVISION_ASSET_PERMISSIONS_GRID_SUCCESS,
    GET_DIVISION_ASSET_PERMISSIONS_GRID_FAILURE,

    GET_ASSET_PERMISSIONS_GRID_REQUEST,
    GET_ASSET_PERMISSIONS_GRID_SUCCESS,
    GET_ASSET_PERMISSIONS_GRID_FAILURE,

    GET_DIVISION_DRIVER_PERMISSIONS_GRID_REQUEST,
    GET_DIVISION_DRIVER_PERMISSIONS_GRID_SUCCESS,
    GET_DIVISION_DRIVER_PERMISSIONS_GRID_FAILURE,

    GET_DRIVER_PERMISSIONS_GRID_REQUEST,
    GET_DRIVER_PERMISSIONS_GRID_SUCCESS,
    GET_DRIVER_PERMISSIONS_GRID_FAILURE,

    GET_REPORT_PERMISSIONS_GRID_REQUEST,
    GET_REPORT_PERMISSIONS_GRID_SUCCESS,
    GET_REPORT_PERMISSIONS_GRID_FAILURE,

    GET_REPORT_ASSET_PERMISSIONS_GRID_REQUEST,
    GET_REPORT_ASSET_PERMISSIONS_GRID_SUCCESS,
    GET_REPORT_ASSET_PERMISSIONS_GRID_FAILURE,

    GET_ACCOUNT_PERMISSIONS_GRID_REQUEST,
    GET_ACCOUNT_PERMISSIONS_GRID_SUCCESS,
    GET_ACCOUNT_PERMISSIONS_GRID_FAILURE,

    GET_USER_DETAIL_REQUEST,
    GET_USER_DETAIL_SUCCESS,
    GET_USER_DETAIL_FAILURE,

    SET_USER_DETAIL_REQUEST,
    SET_USER_DETAIL_SUCCESS,
    SET_USER_DETAIL_FAILURE,

    UPDATE_USER_COMPANY_REQUEST,
    UPDATE_USER_COMPANY_SUCCESS,
    UPDATE_USER_COMPANY_FAILURE,

    UPDATE_USER_PICTURE_REQUEST,
    UPDATE_USER_PICTURE_SUCCESS,
    UPDATE_USER_PICTURE_FAILURE,

    GET_PROFILE_DETAILS_REQUEST,
    GET_PROFILE_DETAILS_SUCCESS,
    GET_PROFILE_DETAILS_FAILURE,

    SET_PROFILE_DETAILS_REQUEST,
    SET_PROFILE_DETAILS_SUCCESS,
    SET_PROFILE_DETAILS_FAILURE,

    UPDATE_PROFILE_PICTURE_REQUEST,
    UPDATE_PROFILE_PICTURE_SUCCESS,
    UPDATE_PROFILE_PICTURE_FAILURE,

    CHANGE_USER_ACCESS_REQUEST_REQUEST,
    CHANGE_USER_ACCESS_REQUEST_SUCCESS,
    CHANGE_USER_ACCESS_REQUEST_FAILURE,

    CHANGE_USER_REPORT_ACCESS_REQUEST_REQUEST,
    CHANGE_USER_REPORT_ACCESS_REQUEST_SUCCESS,
    CHANGE_USER_REPORT_ACCESS_REQUEST_FAILURE,

    SAVE_DIVISION_REQUEST,
    SAVE_REPORT_REQUEST,

    CHANGE_USER_PERMISSION_REQUEST,
    CHANGE_USER_PERMISSION_SUCCESS,
    CHANGE_USER_PERMISSION_FAILURE,

    CHANGE_DRIVER_USER_PERMISSION_REQUEST,
    CHANGE_DRIVER_USER_PERMISSION_SUCCESS,
    CHANGE_DRIVER_USER_PERMISSION_FAILURE,

    UPDATE_USER_PROFILE_REQUEST,
    UPDATE_USER_PROFILE_SUCCESS,
    UPDATE_USER_PROFILE_FAILURE,

    UPDATE_PROFILE_IMAGE_REQUEST,
    UPDATE_PROFILE_IMAGE_SUCCESS,
    UPDATE_PROFILE_IMAGE_FAILURE,

    CHANGE_RESET_PASSWORD_REQUEST,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_RESET_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,

    ADD_ACCESS_TO_DIVISION_REQUEST,
    ADD_ACCESS_TO_DIVISION_SUCCESS,
    ADD_ACCESS_TO_DIVISION_FAILURE,

    ADD_REPORT_ACCESS_TO_DIVISION_REQUEST,
    ADD_REPORT_ACCESS_TO_DIVISION_SUCCESS,
    ADD_REPORT_ACCESS_TO_DIVISION_FAILURE,

    CREATE_USER_REQUEST,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILURE,

    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,

    CLEAR_ASSET_PERMISSIONS_REQUEST,

    CLEAR_DRIVER_PERMISSIONS_REQUEST,

    UPLOAD_NEW_USER_PICTURE_REQUEST,
    UPLOAD_NEW_USER_PICTURE_SUCCESS,
    UPLOAD_NEW_USER_PICTURE_FAILURE,

    RESET_NEW_USER_PICTURE_REQUEST,

    GET_MAP_PREFERENCES_REQUEST,
    GET_MAP_PREFERENCES_SUCCESS,
    GET_MAP_PREFERENCES_FAILURE,

    SET_MAP_PREFERENCES_REQUEST,
    SET_MAP_PREFERENCES_SUCCESS,
    SET_MAP_PREFERENCES_FAILURE,

    SAVE_USER_SEARCH_PARAMS_REQUEST,
    SAVE_USER_ORDER_PARAMS_REQUEST,
    CLEAR_USER_VIEW_FORM_REQUEST,

} = require('./userActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    isLoggedIn: false,
    loginUser: '',
    currentTabKey: 1,
    role: '',
    profile: {},
    userCompany: {},
    companyUsers: [],
    mapPreferences: {
        show_info: 1,
    },
    userDetail: {
        name: '',
        email: '',
        role: 'user',
        department: '1',
        status: '1',
    },
    permissions: {},
    deviceGroups: [],
    users: [],
    usersPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 10,
    },
    divisionAssetPermissions: [],
    divisionAssetPermissionsPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 10,
    },
    assetPermissions: [],
    assetPermissionsPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 10,
    },
    divisionDriverPermissions: [],
    divisionDriverPermissionsPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 10,
    },
    driverPermissions: [],
    driverPermissionsPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 10,
    },
    reportPermissions: [],
    reportPermissionsPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 10,
    },
    reportAssetPermissions: [],
    reportAssetPermissionsPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 10,
    },
    accountPermissions: [],
    accountPermissionsPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 10,
    },
    singleDivision: {},
    singleReportDivision: {},
    divisionId: 0,
    divisionName: '',
    newUserPicture: 0,
    isValidResetPassword: false,
    isSucessResetPassword: false,
    userSearchParams: {},
    userOrderParams: {},
});

const userInitialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */

function userReducer(state = userInitialState, { payload, type }) {
    if (!(state instanceof InitialState)) state = userInitialState.mergeDeep(state);
    switch (type) {
        case LOGIN_REQUEST:
        case TWO_FACTOR_AUTH_REQUEST:
        case DELETE_TOKEN_REQUEST:
        case SAVE_TOKEN_REQUEST:
        case LOGOUT_REQUEST:
        case RESET_PASSWORD_REQUEST:
        case GET_COMPANY_USERS_REQUEST:
        case GET_USERS_PAGE_REQUEST:
        case GET_DIVISION_ASSET_PERMISSIONS_GRID_REQUEST:
        case GET_DIVISION_DRIVER_PERMISSIONS_GRID_REQUEST:
        case GET_REPORT_PERMISSIONS_GRID_REQUEST:
        case GET_ACCOUNT_PERMISSIONS_GRID_REQUEST:
        case GET_USER_DETAIL_REQUEST:
        case SET_USER_DETAIL_REQUEST:
        case UPDATE_USER_COMPANY_REQUEST:
        case UPDATE_USER_PICTURE_REQUEST:
        case GET_PROFILE_DETAILS_REQUEST:
        case SET_PROFILE_DETAILS_REQUEST:
        case UPDATE_PROFILE_PICTURE_REQUEST:
        case CHANGE_USER_ACCESS_REQUEST_REQUEST:
        case CHANGE_USER_REPORT_ACCESS_REQUEST_REQUEST:
        case CHANGE_USER_PERMISSION_REQUEST:
        case CHANGE_DRIVER_USER_PERMISSION_REQUEST:
        case VERIFY_RESET_PASSWORD_REQUEST:
            return state.set('isFetching', true)
                .set('isValidResetPassword', false)
                .set('isSucessResetPassword', false)
                .set('error', null);
        case GET_ASSET_PERMISSIONS_GRID_REQUEST:
        case GET_DRIVER_PERMISSIONS_GRID_REQUEST:
        case GET_REPORT_ASSET_PERMISSIONS_GRID_REQUEST:
            return state.set('isFetching', true)
                .set('isValidResetPassword', false)
                .set('isSucessResetPassword', false)
                .set('error', null)
                .set('divisionId', payload.division_id)
                .set('divisionName', payload.division_name);
        case VERIFY_RESET_PASSWORD_SUCCESS:
            return state
                .set('isFetching', false)
                .set('isSucessResetPassword', false)
                .set('isValidResetPassword', payload.data);
        case VERIFY_RESET_PASSWORD_FAILURE:
            return state
                .set('isFetching', false)
                .set('isValidResetPassword', false)
                .set('isSucessResetPassword', false)
                .set('error', payload);
        case UPDATE_USER_PROFILE_REQUEST:
        case UPDATE_PROFILE_IMAGE_REQUEST:
        case CHANGE_PASSWORD_REQUEST:
        case CHANGE_RESET_PASSWORD_REQUEST:
        case ADD_ACCESS_TO_DIVISION_REQUEST:
        case ADD_REPORT_ACCESS_TO_DIVISION_REQUEST:
        case CREATE_USER_REQUEST:
        case DELETE_USER_REQUEST:
        case UPLOAD_NEW_USER_PICTURE_REQUEST:
        case GET_MAP_PREFERENCES_REQUEST:
            return state.set('isFetching', true)
                .set('error', null);

        case SAVE_DIVISION_REQUEST:
            return state.set('singleDivision', payload);
        case SAVE_REPORT_REQUEST:
            return state.set('singleReportDivision', payload);

        case CLEAR_ASSET_PERMISSIONS_REQUEST: {
            return state.set('assetPermissions', [])
                .set('assetPermissionsPagination', {
                    currentPage: 1,
                    totalRecords: 0,
                    pageCount: 0,
                    perPageCount: 10,
                });
        }

        case CLEAR_DRIVER_PERMISSIONS_REQUEST: {
            return state.set('driverPermissions', [])
                .set('driverPermissionsPagination', {
                    currentPage: 1,
                    totalRecords: 0,
                    pageCount: 0,
                    perPageCount: 10,
                });
        }

        case SET_MAP_PREFERENCES_REQUEST:
            return state.set('mapPreferences', payload)
                .set('error', null);

        case SAVE_USER_SEARCH_PARAMS_REQUEST: {
            return state.set('userSearchParams', payload);
        }

        case SAVE_USER_ORDER_PARAMS_REQUEST: {
            return state.set('userOrderParams', payload);
        }

        case GET_USERS_DEPARTMENT_REQUEST:
            return state.set({ currentTabKey: '1' })
                .set('userSearchParams', payload)
                .set('error', null);

        case SAVE_TOKEN_SUCCESS:
        case LOGIN_SUCCESS:
        case TWO_FACTOR_AUTH_SUCCESS:
            return state.set('isFetching', false)
                .set('loginUser', (payload && payload.data && payload.data.username ? payload.data.username : 'Logged In User'))
                .set('role', (payload && payload.data && payload.data.role ? payload.data.role : ''))
                .set('profile', (payload && payload.data && payload.data.profile ? payload.data.profile : {}))
                .set('permissions', (payload && payload.data && payload.data.permissions ? payload.data.permissions : {}))
                .set('deviceGroups', (payload && payload.data && payload.data.deviceGroups ? payload.data.deviceGroups : []))
                .set('userCompany', (payload && payload.data && payload.data.company ? payload.data.company : {}))
                .set('isLoggedIn', true);

        case DELETE_TOKEN_SUCCESS:
        case LOGOUT_SUCCESS:
            return state.set('isFetching', false)
                .set('loginUser', '')
                .set('isLoggedIn', false);
        case RESET_PASSWORD_SUCCESS:
            return state.set('isFetching', false)
                .set('loginUser', '')
                .set('isLoggedIn', false);
        case GET_COMPANY_USERS_SUCCESS:
            return state.set('isFetching', false)
                .set('companyUsers', payload.data);
        case GET_USERS_PAGE_SUCCESS: {
            const usersPagination = {};
            usersPagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
            usersPagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
            usersPagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
            usersPagination.perPageCount = payload.headers['x-pagination-per-page'] || 20;
            return state.set('isFetching', false)
                .set('users', payload.data)
                .set('usersPagination', usersPagination);
        }
        case GET_DIVISION_ASSET_PERMISSIONS_GRID_SUCCESS: {
            const divisionAssetPermissionsPagination = {};
            divisionAssetPermissionsPagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
            divisionAssetPermissionsPagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
            divisionAssetPermissionsPagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
            divisionAssetPermissionsPagination.perPageCount = payload.headers['x-pagination-per-page'] || 20;
            return state.set('isFetching', false)
                .set('divisionAssetPermissions', payload.data)
                .set('divisionAssetPermissionsPagination', divisionAssetPermissionsPagination);
        }
        case GET_DIVISION_DRIVER_PERMISSIONS_GRID_SUCCESS: {
            const divisionDriverPermissionsPagination = {};
            divisionDriverPermissionsPagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
            divisionDriverPermissionsPagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
            divisionDriverPermissionsPagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
            divisionDriverPermissionsPagination.perPageCount = payload.headers['x-pagination-per-page'] || 20;
            return state.set('isFetching', false)
                .set('divisionDriverPermissions', payload.data)
                .set('divisionDriverPermissionsPagination', divisionDriverPermissionsPagination);
        }
        case GET_REPORT_PERMISSIONS_GRID_SUCCESS: {
            const reportPermissionsPagination = {};
            reportPermissionsPagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
            reportPermissionsPagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
            reportPermissionsPagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
            reportPermissionsPagination.perPageCount = payload.headers['x-pagination-per-page'] || 20;
            return state.set('isFetching', false)
                .set('reportPermissions', payload.data)
                .set('reportPermissionsPagination', reportPermissionsPagination);
        }
        case GET_ASSET_PERMISSIONS_GRID_SUCCESS: {
            const assetPermissionsPagination = {};
            assetPermissionsPagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
            assetPermissionsPagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
            assetPermissionsPagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
            assetPermissionsPagination.perPageCount = payload.headers['x-pagination-per-page'] || 20;
            return state.set('isFetching', false)
                .set('assetPermissions', payload.data)
                .set('assetPermissionsPagination', assetPermissionsPagination);
        }
        case GET_DRIVER_PERMISSIONS_GRID_SUCCESS: {
            const driverPermissionsPagination = {};
            driverPermissionsPagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
            driverPermissionsPagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
            driverPermissionsPagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
            driverPermissionsPagination.perPageCount = payload.headers['x-pagination-per-page'] || 20;
            return state.set('isFetching', false)
                .set('driverPermissions', payload.data)
                .set('driverPermissionsPagination', driverPermissionsPagination);
        }
        case GET_REPORT_ASSET_PERMISSIONS_GRID_SUCCESS: {
            const assetPermissionsPagination = {};
            assetPermissionsPagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
            assetPermissionsPagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
            assetPermissionsPagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
            assetPermissionsPagination.perPageCount = payload.headers['x-pagination-per-page'] || 20;
            return state.set('isFetching', false)
                .set('reportAssetPermissions', payload.data)
                .set('reportAssetPermissionsPagination', assetPermissionsPagination);
        }
        case GET_ACCOUNT_PERMISSIONS_GRID_SUCCESS: {
            const accountPermissionsPagination = {};
            accountPermissionsPagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
            accountPermissionsPagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
            accountPermissionsPagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
            accountPermissionsPagination.perPageCount = payload.headers['x-pagination-per-page'] || 20;
            return state.set('isFetching', false)
                .set('accountPermissions', payload.data)
                .set('accountPermissionsPagination', accountPermissionsPagination);
        }
        case GET_USER_DETAIL_SUCCESS:
        case SET_USER_DETAIL_SUCCESS:
            return state.set('userDetail', payload.data).set('isFetching', false);
        case UPDATE_USER_COMPANY_SUCCESS:
            return state.set('isFetching', false)
                .set('deviceGroups', (payload && payload.data && payload.data.deviceGroups ? payload.data.deviceGroups : []))
                .set('userCompany', (payload && payload.data && payload.data.company ? payload.data.company : {}));
        case UPDATE_USER_PICTURE_SUCCESS:
            return state.set('isFetching', false);
        case GET_PROFILE_DETAILS_SUCCESS:
            return state.set('isFetching', false);
        case SET_PROFILE_DETAILS_SUCCESS:
            return state.set('isFetching', false);
        case UPDATE_PROFILE_PICTURE_SUCCESS:
            return state.set('isFetching', false);
        case CHANGE_USER_ACCESS_REQUEST_SUCCESS: {
            const { assetPermissions, divisionAssetPermissions } = state;

            if (payload.type === 'asset') {
                const assetIndex = findIndex(assetPermissions, (val) => val.asset_id === payload.asset_id);
                if (assetIndex !== -1) {
                    assetPermissions[assetIndex].access = payload.access;
                }

                const divisionIndex = findIndex(divisionAssetPermissions, (val) => val.division_id === payload.division_id);
                if (divisionIndex !== -1) {
                    let addAccess = 1;
                    if (payload.access === 0) {
                        addAccess = -1;
                    }
                    divisionAssetPermissions[divisionIndex].user_has_access = parseInt(divisionAssetPermissions[divisionIndex].user_has_access, 10) + addAccess;
                }
            }

            return state.set('isFetching', false)
                .set('divisionAssetPermissions', divisionAssetPermissions)
                .set('assetPermissions', assetPermissions);
        }
        case CHANGE_USER_REPORT_ACCESS_REQUEST_SUCCESS: {
            const { reportAssetPermissions, reportPermissions } = state;

            if (payload.type === 'asset') {
                const assetIndex = findIndex(reportAssetPermissions, (val) => val.asset_id === payload.asset_id);
                if (assetIndex !== -1) {
                    reportAssetPermissions[assetIndex].access = payload.access;
                }

                const divisionIndex = findIndex(reportPermissions, (val) => val.division_id === payload.division_id);
                if (divisionIndex !== -1) {
                    let addAccess = 1;
                    if (payload.access === 0) {
                        addAccess = -1;
                    }
                    reportPermissions[divisionIndex].user_has_access = parseInt(reportPermissions[divisionIndex].user_has_access, 10) + addAccess;
                }
            }

            return state.set('isFetching', false)
                .set('reportPermissions', reportPermissions)
                .set('reportAssetPermissions', reportAssetPermissions);
        }

        case CHANGE_USER_PERMISSION_SUCCESS: {
            const { accountPermissions } = state;

            const permissionIndex = findIndex(accountPermissions, (val) => val.id === payload.permission_id);
            if (permissionIndex !== -1) {
                accountPermissions[permissionIndex].access = payload.access;
            }

            return state.set('isFetching', false)
                .set('accountPermissions', accountPermissions);
        }

        case CHANGE_DRIVER_USER_PERMISSION_SUCCESS: {
            const { driverPermissions, divisionDriverPermissions } = state;

            const permissionIndex = findIndex(driverPermissions, (val) => val.driver_id === payload.driver_id);
            if (permissionIndex !== -1) {
                driverPermissions[permissionIndex].access = payload.access;
            }

            const divisionIndex = findIndex(divisionDriverPermissions, (val) => val.division_id === payload.division_id);
            if (divisionIndex !== -1) {
                let addAccess = 1;
                if (payload.access === 0) {
                    addAccess = -1;
                }
                divisionDriverPermissions[divisionIndex].user_has_access = parseInt(divisionDriverPermissions[divisionIndex].user_has_access, 10) + addAccess;
            }

            return state.set('isFetching', false)
                .set('divisionDriverPermissions', divisionDriverPermissions)
                .set('driverPermissions', driverPermissions);
        }

        case UPDATE_USER_PROFILE_SUCCESS:
        case UPDATE_PROFILE_IMAGE_SUCCESS: {
            return state.set('isFetching', false)
                .set('profile', payload.data.profile);
        }

        case CHANGE_PASSWORD_SUCCESS:
            return state.set('isFetching', false);
        case CHANGE_RESET_PASSWORD_SUCCESS:
            return state.set('isSucessResetPassword', true)
                .set('error', null)
                .set('isFetching', false);

        case ADD_ACCESS_TO_DIVISION_SUCCESS:
        case ADD_REPORT_ACCESS_TO_DIVISION_SUCCESS:
            return state;
        case CREATE_USER_SUCCESS:
            return state.set('isFetching', false);
        case DELETE_USER_SUCCESS: {
            const { users } = state;
            const userIndex = findIndex(users, (val) => val.user_id == payload.data);

            if (userIndex !== -1) {
                users.splice(userIndex, 1);
            }
            return state.set('users', users)
                .set('isFetching', false);
        }
        case UPLOAD_NEW_USER_PICTURE_SUCCESS: {
            return state.set('isFetching', false)
                .set('newUserPicture', payload.data);
        }

        case GET_MAP_PREFERENCES_SUCCESS:
        case SET_MAP_PREFERENCES_SUCCESS:
            return state
                .set('mapPreferences', payload.data)
                .set('isFetching', false);

        case RESET_NEW_USER_PICTURE_REQUEST:
            return state.set('newUserPicture', 0);

        case CLEAR_USER_VIEW_FORM_REQUEST:
            return state.set('userDetail',
                {
                    name: '',
                    email: '',
                    role: 'user',
                    department: '1',
                    status: '1',
                });
        case LOGIN_FAILURE:
        case LOGOUT_FAILURE:
        case RESET_PASSWORD_FAILURE:
        case GET_COMPANY_USERS_FAILURE:
            return state.set('isFetching', false)
                .set('isLoggedIn', false)
                .set('error', payload);
        case GET_USERS_PAGE_FAILURE:
        case GET_DIVISION_ASSET_PERMISSIONS_GRID_FAILURE:
        case GET_DIVISION_DRIVER_PERMISSIONS_GRID_FAILURE:
        case GET_REPORT_PERMISSIONS_GRID_FAILURE:
        case GET_ASSET_PERMISSIONS_GRID_FAILURE:
        case GET_REPORT_ASSET_PERMISSIONS_GRID_FAILURE:
        case GET_DRIVER_PERMISSIONS_GRID_FAILURE:
        case GET_ACCOUNT_PERMISSIONS_GRID_FAILURE:
        case GET_USER_DETAIL_FAILURE:
        case SET_USER_DETAIL_FAILURE:
        case UPDATE_USER_COMPANY_FAILURE:
        case UPDATE_USER_PICTURE_FAILURE:
        case GET_PROFILE_DETAILS_FAILURE:
        case SET_PROFILE_DETAILS_FAILURE:
        case UPDATE_PROFILE_PICTURE_FAILURE:
        case CHANGE_USER_ACCESS_REQUEST_FAILURE:
        case CHANGE_USER_REPORT_ACCESS_REQUEST_FAILURE:
        case CHANGE_USER_PERMISSION_FAILURE:
        case CHANGE_DRIVER_USER_PERMISSION_FAILURE:
        case UPDATE_USER_PROFILE_FAILURE:
        case UPDATE_PROFILE_IMAGE_FAILURE:
        case CHANGE_PASSWORD_FAILURE:
        case ADD_ACCESS_TO_DIVISION_FAILURE:
        case ADD_REPORT_ACCESS_TO_DIVISION_FAILURE:
        case CREATE_USER_FAILURE:
        case DELETE_USER_FAILURE:
        case UPLOAD_NEW_USER_PICTURE_FAILURE:
        case GET_MAP_PREFERENCES_FAILURE:
        case SET_MAP_PREFERENCES_FAILURE:
            return state.set('isFetching', false)
                .set('error', payload);
        default:
            return state;
    }
}

export {
    userReducer,
    userInitialState,
};
