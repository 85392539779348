import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';

import defaultStyles from './styles';
import TimeRangeContext from '../../context';

export const SliderRail = ({ getRailProps }) => {
    const { styles } = useContext(TimeRangeContext);

    return (
        <>
            <div
                className="railOuter"
                {...css(defaultStyles.railOuter, styles.railOuter)}
                {...getRailProps()} />
            <div
                className="railInner"
                {...css(defaultStyles.railInner, styles.railInner)} />
        </>
    );
};

SliderRail.propTypes = {
    getRailProps: PropTypes.func.isRequired,
};
SliderRail.defaultProps = {
};

export default SliderRail;
