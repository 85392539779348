import React from 'react';

const PTOConnectionGuaranteeSVG = () => (
    <svg width={64} height={64} xmlns="http://www.w3.org/2000/svg">
        <g stroke="null">
            <path d="M58.495 12.539a11.991 11.991 0 00-16.987 0l2.838 2.838a7.994 7.994 0 1111.331 11.331L39.69 42.696a7.994 7.994 0 11-11.331-11.311l2.818-2.838-2.818-2.838-2.838 2.838a11.991 11.991 0 000 16.987 11.991 11.991 0 008.494 3.458 11.991 11.991 0 008.533-3.518l15.988-15.988a11.991 11.991 0 00-.04-16.947z" />
            <path d="M8.412 48.632a7.994 7.994 0 010-11.332l15.989-15.988a7.994 7.994 0 0111.331 0 7.874 7.874 0 012.279 5.696 7.994 7.994 0 01-2.339 5.696L31.435 37l2.838 2.837 4.237-4.236a11.991 11.991 0 00-17.007-17.008L5.515 34.582a11.991 11.991 0 000 17.008 11.991 11.991 0 008.513 3.397 12.131 12.131 0 008.554-3.517l-2.838-2.838a7.994 7.994 0 01-11.332 0z" />
        </g>
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default PTOConnectionGuaranteeSVG;
