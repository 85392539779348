import React, { Component } from 'react';
import {
    Form,
    DatePicker,
    TimePicker,
    Select,
    Button,
} from 'antd';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty, indexOf, isUndefined } from 'underscore';
import Icon from '../../../components/elements/Icon';

import * as assetActions from '../../../core/asset/assetActions';
import { secondsToHms } from '../../../core/utils/functions';

const {
    MAP_SLIDER_INTERVAL
} = require('../../../core/constants').default;

class VideoSearchFilterForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            locationSearch: false,
            val: '',
            latitude: null,
            longitude: null,
            geometry: null,
            loadScript: true,
        };
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.getDivisionListRequest();
        actions.getAssetOptionsRequest();

        setTimeout(() => {
            this.submitForm();
        }, 1000);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log('locationSearchChange', prevProps.locationSearch, this.props.locationSearch, prevState.locationSearch, this.state.locationSearch);

        // if somehow the map or the location search box is triggered, don't allow the location search box to load script again
        if (
            (!prevState.locationSearch && this.state.locationSearch)
            || (!prevProps.locationSearch && this.props.locationSearch)
        ) {
            this.setState({ loadScript: false });
        }
    }

    onUpdate = (place) => {
        const {
            formatted_address,
            geometry,
        } = place;
        const latitude = geometry.location.lat();
        const longitude = geometry.location.lng();
        this.setState({
            latitude,
            longitude,
            geometry,
        });
        const { form: { setFieldsValue } } = this.props;
        setFieldsValue({
            location: formatted_address,
        });
    }

    submitForm = (e) => {
        if (e) {
            e.preventDefault();
        }
        const {
            latitude,
            longitude,
            geometry,
        } = this.state;
        const {
            onSubmit,
            form: { validateFields },
        } = this.props;
        validateFields((err, values) => {
            if (!err) {
                const newValues = values;
                newValues.latitude = latitude;
                newValues.longitude = longitude;
                newValues.geometry = geometry;
                onSubmit(newValues);
            }
        });
    }

    setLocationSearch = (e) => {
        const locationSearch = e.target.checked;

        this.setState({ locationSearch });

        if (!locationSearch) {
            this.setState({
                latitude: null,
                longitude: null,
                geometry: null,
            });
        }
    }

    render() {
        const {
            form,
            divisions,
            driverList,
            assetReg,
            assetOptions,
            mapSearchVideoSelectedDate,
            mapSearchVideoSliderValue,
            params,
        } = this.props;

        const { getFieldDecorator } = form;
        let dateInitialVal = moment();
        let timeFrom = moment();
        timeFrom = timeFrom.subtract(1, 'hours');
        let timeFromInitialVal = timeFrom;
        let timeToInitialVal = dateInitialVal;

        if (params?.assetId === 'location') {
            let startTimeDecrease = 60 * 5;
            let endTimeIncrease = 60 * 5;

            if (mapSearchVideoSelectedDate === new moment().format('YYYY-MM-DD')) {
                startTimeDecrease = 60 * 60 * 3;
                endTimeIncrease = 0;
            }

            const startTime = `${secondsToHms(((mapSearchVideoSliderValue * 60) * MAP_SLIDER_INTERVAL) - startTimeDecrease, 'time')}:00`;
            const endTime = `${secondsToHms(((mapSearchVideoSliderValue * 60) * MAP_SLIDER_INTERVAL) + endTimeIncrease, 'time')}:00`;
            dateInitialVal = moment(mapSearchVideoSelectedDate);
            timeFromInitialVal = moment(startTime, 'HH:mm:ss');
            timeToInitialVal = moment(endTime, 'HH:mm:ss');
        }

        let initialAssetReg;
        if (assetReg) {
            initialAssetReg = assetOptions.find((asset) => asset.reg === assetReg);
        }

        return (
            <Form onSubmit={this.submitForm} layout="vertical">
                <Form.Item label="Asset name or reg">
                    {getFieldDecorator('asset_id', {
                        initialValue: initialAssetReg ? [initialAssetReg.id] : [],
                    })(
                        <Select
                            mode="multiple"
                            showSearch
                            allowClear
                            filterOption={(txt, obj) => (obj.props.children.toLowerCase().includes(txt.toLowerCase()))}
                            placeholder="Asset name or reg"
                            suffixIcon={<Icon name="triangle-down"/>}>
                            {assetOptions && assetOptions.map((a) => (
                                <Select.Option key={a.id} value={a.id}>
                                    {a.name === a.reg || a.reg === '' ? a.name : `${a.name} [${a.reg}]`}
                                </Select.Option>
                            ))}
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Driver">
                    {getFieldDecorator('driver', {})(
                        <Select
                            mode="multiple"
                            filterOption={(input, option) => option.props.children.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0}
                            placeholder="Select driver"
                            suffixIcon={<Icon name="triangle-down"/>}>
                            {driverList.map((dl) => <Select.Option key={dl.id} value={dl.id}>{dl.name}</Select.Option>)}
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Division">
                    {getFieldDecorator('division_id', {
                        rules: [],
                    })(
                        <Select
                            placeholder="Select division"
                            filterOption={(input, option) => option.props.children.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0}
                            mode="multiple"
                            style={{ width: '100%' }}
                            suffixIcon={
                                <Icon name="triangle-down"/>
                            }>
                            {
                                divisions.map((v, i) => (
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    <Select.Option key={`${i}`} value={v.id}>{v.name}</Select.Option>
                                ))
                            }

                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Date">
                    {getFieldDecorator('date', {
                        initialValue: dateInitialVal,
                    })(
                        <DatePicker format="DD/MM/YYYY"/>,
                    )}
                </Form.Item>
                <Form.Item label="Time From">
                    {getFieldDecorator('time_from', {
                        initialValue: timeFromInitialVal,
                    })(
                        <TimePicker/>,
                    )}
                </Form.Item>
                <Form.Item label="Time To">
                    {getFieldDecorator('time_to', {
                        initialValue: timeToInitialVal,
                    })(
                        <TimePicker/>,
                    )}
                </Form.Item>

                <div className="mt-2 text-center">
                    <Button
                        htmlType="submit"
                        type="primary"
                        className="btn extrawide"
                        size="large">
                        Filter
                    </Button>
                </div>
            </Form>
        );
    }
}

VideoSearchFilterForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    divisions: PropTypes.array.isRequired,
    driverList: PropTypes.array.isRequired,
    assetReg: PropTypes.string,
    mapDate: PropTypes.string,
    form: PropTypes.object,
};

// ISS-2618, this is done to not reset the filter bar after viewing an event and coming back to the search page
const VideoFileListFilter = Form.create({
    name: 'video_search_filter_form',
    mapPropsToFields(props) {
        const initialData = {};
        const {
            searchParams,
            assetReg,
            assetOptions,
            selectedDate,
            selectedTimeFrom,
            selectedTimeTo,
        } = props;
        if (!isEmpty(searchParams)) {
            Object.keys(searchParams)
                .map((item) => {
                    if (!isEmpty(item)) {
                        if (indexOf(['date', 'time_from', 'time_to'], item) > -1 && searchParams[item]) {
                            // we ignore these fields
                        } else if (Array.isArray(searchParams[item]) && !isEmpty(searchParams[item]) && !isUndefined(searchParams[item][0])) {
                            // underscore isEmpty only works on collections , https://underscorejs.org/#isEmpty
                            initialData[item] = Form.createFormField({
                                value: searchParams[item],
                            });
                        } else if (searchParams[item] !== '') {
                            initialData[item] = Form.createFormField({
                                value: searchParams[item],
                            });
                        }
                    }
                });

            if (searchParams.date) {
                initialData.date = Form.createFormField({
                    value: moment(searchParams.date),
                });
            }

            if (searchParams.time_from) {
                initialData.time_from = Form.createFormField({
                    value: moment(searchParams.time_from, 'HH:mm:ss'),
                });
            }

            if (searchParams.time_to) {
                initialData.time_to = Form.createFormField({
                    value: moment(searchParams.time_to, 'HH:mm:ss'),
                });
            }

            if (assetReg) {
                const initialAssetReg = assetOptions.find((asset) => asset.reg === assetReg);
                initialData.asset_id = initialAssetReg.id;
            }

            if (selectedDate) {
                initialData.date = Form.createFormField({
                    value: moment(selectedDate),
                });
            }

            if (selectedTimeFrom) {
                initialData.time_from = Form.createFormField({
                    value: moment(selectedTimeFrom, 'HH:mm:ss'),
                });
            }

            if (selectedTimeTo) {
                initialData.time_to = Form.createFormField({
                    value: moment(selectedTimeTo, 'HH:mm:ss'),
                });
            }
        }

        return initialData;
    },
})(VideoSearchFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        event_types: state.asset.event_types,
        divisions: state.asset.divisions,
        driverList: state.driver.driverList,
        assetReg: state.global.videoSearchFilters.assetReg,
        selectedDate: state.global.videoSearchFilters.date,
        selectedTimeFrom: state.global.videoSearchFilters.timeFrom,
        selectedTimeTo: state.global.videoSearchFilters.timeTo,
        mapDate: state.global.mapDate,
        assetOptions: state.asset.options,
        mapSearchLocationBoundary: state.video.mapSearchLocationBoundary,
        mapSearchVideoSelectedDate: state.video.mapSearchVideoSelectedDate,
        mapSearchVideoSliderValue: state.video.mapSearchVideoSliderValue,
        searchParams: state.video.historicVideosSearchParams,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VideoFileListFilter);
