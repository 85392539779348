import React from 'react';

const TimesheetSVG = () => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 396.9 396.9"
        width="1em"
        height="1em"
        xmlSpace="preserve">
        <g>
            <path d="M126.5,164.3c-29,0-56,11.6-75.8,31.8v-24.2H35.6v53h53v-15.2H58.7c17.1-19.2,41.5-30.3,67.8-30.3
		c50.1,0,90.9,40.8,90.9,90.9s-40.8,90.9-90.9,90.9s-90.9-40.8-90.9-90.9c0-7.1,0.8-14.2,2.4-21l-14.7-3.5c-1.9,8-2.8,16.2-2.8,24.5
		c0,58.5,47.6,106.1,106.1,106.1s106.1-47.6,106.1-106.1S184.9,164.3,126.5,164.3z" />
            <polygon points="118.9,217.4 118.9,285.5 171.9,285.5 171.9,270.4 134,270.4 134,217.4 	" />
            <polygon points="96.2,156.8 111.3,156.8 111.3,111.3 285.5,111.3 285.5,338.6 217.4,338.6 217.4,353.7 300.7,353.7
		300.7,96.2 96.2,96.2 	" />
            <polygon points="126.5,81 141.6,81 141.6,73.4 323.4,73.4 323.4,300.7 315.8,300.7 315.8,315.8 338.6,315.8
		338.6,58.3 126.5,58.3 	" />
            <polygon points="164.3,20.4 164.3,43.1 179.5,43.1 179.5,35.6 361.3,35.6 361.3,262.8 353.7,262.8 353.7,278
		376.5,278 376.5,20.4 	" />
        </g>
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default TimesheetSVG;
