import { Record } from 'immutable';
import { findIndex, map } from 'underscore';

const {
    GET_SMART_WIDGET_PRESETS_REQUEST,
    GET_SMART_WIDGET_PRESETS_SUCCESS,
    GET_SMART_WIDGET_PRESETS_FAILURE,

    UPDATE_PRESET_REQUEST,
    UPDATE_PRESET_SUCCESS,
    UPDATE_PRESET_FAILURE,

    DELETE_PRESET_REQUEST,
    DELETE_PRESET_SUCCESS,
    DELETE_PRESET_FAILURE,

    CREATE_WIDGET_FROM_PRESET_REQUEST,
    CREATE_WIDGET_FROM_PRESET_SUCCESS,
    CREATE_WIDGET_FROM_PRESET_FAILURE,

    CREATE_SMART_WIDGET_REQUEST,
    CREATE_SMART_WIDGET_SUCCESS,
    CREATE_SMART_WIDGET_FAILURE,

    UPDATE_SMART_WIDGET_REQUEST,
    UPDATE_SMART_WIDGET_SUCCESS,
    UPDATE_SMART_WIDGET_FAILURE,

    GET_SMART_WIDGET_INPUT_DATA_REQUEST,
    GET_SMART_WIDGET_INPUT_DATA_SUCCESS,
    GET_SMART_WIDGET_INPUT_DATA_FAILURE,

    UPLOAD_WIDGET_IMAGE_REQUEST,
    UPLOAD_WIDGET_IMAGE_SUCCESS,
    UPLOAD_WIDGET_IMAGE_FAILURE,

    UPLOAD_WIDGET_IMAGE_ATTR_REQUEST,
    UPLOAD_WIDGET_IMAGE_ATTR_SUCCESS,
    UPLOAD_WIDGET_IMAGE_ATTR_FAILURE,

    GET_CHART_DATA_REQUEST,
    GET_CHART_DATA_SUCCESS,
    GET_CHART_DATA_FAILURE,

    GET_VOR_CHART_DATA_REQUEST,
    GET_VOR_CHART_DATA_SUCCESS,
    GET_VOR_CHART_DATA_FAILURE,

    GET_BEST_DRIVER_CHART_DATA_REQUEST,
    GET_BEST_DRIVER_CHART_DATA_SUCCESS,
    GET_BEST_DRIVER_CHART_DATA_FAILURE,

    GET_SMART_WIDGETS_REQUEST,
    GET_SMART_WIDGETS_SUCCESS,
    GET_SMART_WIDGETS_FAILURE,

    GET_SMART_WIDGET_DATA_REQUEST,
    GET_SMART_WIDGET_DATA_SUCCESS,
    GET_SMART_WIDGET_DATA_FAILURE,

    DELETE_SMART_WIDGET_REQUEST,
    DELETE_SMART_WIDGET_SUCCESS,
    DELETE_SMART_WIDGET_FAILURE,

    UPDATE_SMART_WIDGET_POSITIONS_REQUEST,
    UPDATE_SMART_WIDGET_POSITIONS_SUCCESS,
    UPDATE_SMART_WIDGET_POSITIONS_FAILURE,

    GET_PRESET_WIDGET_REQUEST,
    GET_PRESET_WIDGET_SUCCESS,
    GET_PRESET_WIDGET_FAILURE,

    RESET_PRESET_WIDGET_REQUEST,
    RESET_PRESET_CHECK_BOX_REQUEST,
    RESET_FETCH_PREVIEW_REQUEST,
    RESET_CLEAR_CREATED_FORM_REQUEST,
    RESET_BASE64_NEW_IMAGE_ID_REQUEST,

    GET_USER_WIDGET_REQUEST,
    GET_USER_WIDGET_SUCCESS,
    GET_USER_WIDGET_FAILURE,

    RESET_CHART_DATA_REQUEST,
} = require('./smartWidgetActions').constants;

const {
    LOGOUT_REQUEST,
} = require('../user/userActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    isFetchingSingleWidget: false,
    isFetchingCustomChart: false,
    smartWidgetPresets: [],
    inputData: [],
    chartTypes: [],
    vorChartData: {},
    bestDriverChartData: {},
    chartData: [],
    chartType: [],
    smartWidgets: [],
    loadingWidgets: [],
    newImageId: null,
    logo: null,
    presetWidget: {},
    fetchPreview: false,
    clearCreatedForm: false,
    resetBase64newImageId: false,
    resetPresetCheckBox: false,
});

const smartWidgetInitialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
function smartWidgetReducer(state = smartWidgetInitialState, { payload, type }) {
    if (!(state instanceof InitialState)) state = smartWidgetInitialState.mergeDeep(state);
    switch (type) {
        case GET_SMART_WIDGET_PRESETS_REQUEST:
        case UPDATE_PRESET_REQUEST:
        case DELETE_PRESET_REQUEST:
        case CREATE_WIDGET_FROM_PRESET_REQUEST:
        case CREATE_SMART_WIDGET_REQUEST:
        case UPDATE_SMART_WIDGET_REQUEST:
        case GET_SMART_WIDGET_INPUT_DATA_REQUEST:
        case UPLOAD_WIDGET_IMAGE_REQUEST:
        case UPLOAD_WIDGET_IMAGE_ATTR_REQUEST:
        case GET_CHART_DATA_REQUEST:
        case DELETE_SMART_WIDGET_REQUEST:
        case GET_PRESET_WIDGET_REQUEST:
        case GET_USER_WIDGET_REQUEST:
            return state.set('isFetching', true)
                .set('error', null);

        case RESET_PRESET_WIDGET_REQUEST:
            return state.set('presetWidget', {})
                .set('resetPresetCheckBox', true)
                .set('newImageId', null)
                .set('clearCreatedForm', true)
                .set('resetBase64newImageId', true);
        case RESET_PRESET_CHECK_BOX_REQUEST:
            return state.set('resetPresetCheckBox', false);
        case RESET_CLEAR_CREATED_FORM_REQUEST:
            return state.set('clearCreatedForm', false)
                .set('newImageId', null)
                .set('resetBase64newImageId', true);
        case RESET_BASE64_NEW_IMAGE_ID_REQUEST:
            return state.set('resetBase64newImageId', false);
        case GET_VOR_CHART_DATA_REQUEST:
        case GET_BEST_DRIVER_CHART_DATA_REQUEST: {
            const { loadingWidgets } = state;

            if (payload && payload.id) {
                loadingWidgets.push(parseInt(payload.id, 10));
            }

            return state.set('isFetchingCustomChart', true)
            // .set('isFetching', true)
                .set('loadingWidgets', loadingWidgets)
                .set('error', null);
        }

        case GET_SMART_WIDGETS_REQUEST:
            return state.set('isFetching', true)
                .set('error', null);

        case UPDATE_SMART_WIDGET_POSITIONS_REQUEST:
            return state.set('isFetching', true)
                .set('error', null);

        case GET_SMART_WIDGET_DATA_REQUEST: {
            const { loadingWidgets } = state;

            if (payload && payload.id) {
                loadingWidgets.push(parseInt(payload.id, 10));
            }

            return state.set('isFetchingSingleWidget', true)
                .set('loadingWidgets', loadingWidgets)
                .set('error', null);
        }

        case LOGOUT_REQUEST:
            return state.set('smartWidgets', [])
                .set('loadingWidgets', []);

        case GET_SMART_WIDGET_PRESETS_SUCCESS: {
            return state.set('isFetching', false)
                .set('smartWidgetPresets', payload.data);
        }

        case UPDATE_PRESET_SUCCESS: {
            const { smartWidgetPresets } = state;

            const presetIndex = findIndex(smartWidgetPresets, (val) => val.id === payload.data?.id);
            if (presetIndex !== -1) {
                smartWidgetPresets[presetIndex] = payload.data;
            }

            return state.set('isFetching', false)
                .set('smartWidgetPresets', smartWidgetPresets);
        }

        case DELETE_PRESET_SUCCESS: {
            const { smartWidgetPresets } = state;

            const presetIndex = findIndex(smartWidgetPresets, (val) => val.id === payload?.id);
            if (presetIndex !== -1) {
                smartWidgetPresets.splice(presetIndex, 1);
            }

            return state.set('isFetching', false)
                .set('smartWidgetPresets', smartWidgetPresets);
        }

        case CREATE_WIDGET_FROM_PRESET_SUCCESS:
            return state.set('isFetching', false);

        case CREATE_SMART_WIDGET_SUCCESS:
        case UPDATE_SMART_WIDGET_SUCCESS: {
            return state.set('isFetching', false);
        }

        case GET_SMART_WIDGET_INPUT_DATA_SUCCESS: {
            return state.set('inputData', payload.data.inputData)
                .set('chartTypes', payload.data.chartTypes)
                .set('isFetching', false);
        }

        case UPLOAD_WIDGET_IMAGE_SUCCESS: {
            return state.set('newImageId', payload.data)
                .set('isFetching', false);
        }

        case GET_PRESET_WIDGET_SUCCESS: {
            return state.set('presetWidget', payload.data)
                .set('fetchPreview', true)
                .set('isFetching', false);
        }

        case GET_USER_WIDGET_SUCCESS:
            return state.set('presetWidget', payload.data)
                .set('fetchPreview', true)
                .set('isFetching', false);

        case RESET_FETCH_PREVIEW_REQUEST: {
            return state.set('fetchPreview', false);
        }

        case UPLOAD_WIDGET_IMAGE_ATTR_SUCCESS: {
            return state.set(payload.attr, payload.data)
                .set('isFetching', false);
        }

        case GET_CHART_DATA_SUCCESS: {
        // return state.set('isFetching', false);

            return state.set('chartData', payload.data)
                .set('isFetching', false);
        }

        case GET_VOR_CHART_DATA_SUCCESS: {
            return state.set('vorChartData', payload.data)
                .set('isFetching', false)
                .set('isFetchingCustomChart', false);
        }

        case GET_BEST_DRIVER_CHART_DATA_SUCCESS: {
            let { loadingWidgets } = state;

            if (payload.smartWidgetId) {
                const widgetId = parseInt(payload.smartWidgetId, 10);
                loadingWidgets = loadingWidgets.filter((e) => e !== widgetId);
            }

            return state.set('bestDriverChartData', payload.data)
                .set('isFetching', false)
                .set('loadingWidgets', loadingWidgets)
                .set('isFetchingCustomChart', false);
        }

        case GET_SMART_WIDGETS_SUCCESS: {
            const { smartWidgets } = state;
            const newWidgets = payload.data;
            let smartWidgetIndex = -1;

            map(smartWidgets, (singleWidget) => {
                smartWidgetIndex = findIndex(newWidgets, (val) => val.id === singleWidget.id);

                if (smartWidgetIndex !== -1) {
                    newWidgets[smartWidgetIndex].chartData = singleWidget.chartData;
                }
            });

            return state.set('smartWidgets', newWidgets)
                .set('isFetching', false);
        }

        case GET_SMART_WIDGET_DATA_SUCCESS: {
            const { smartWidgets } = state;
            let { loadingWidgets } = state;
            const widgetId = parseInt(payload.smartWidgetId, 10);

            if (payload.smartWidgetId) {
                const smartWidgetIndex = findIndex(smartWidgets, (val) => val.id === widgetId);
                if (smartWidgetIndex !== -1) {
                    smartWidgets[smartWidgetIndex].chartData = payload.data;

                    if (payload.datatype) {
                        smartWidgets[smartWidgetIndex].datatype_a = payload.datatype.datatype_a;
                        smartWidgets[smartWidgetIndex].datatype_b = payload.datatype.datatype_b;
                        smartWidgets[smartWidgetIndex].datatype_c = payload.datatype.datatype_c;
                        smartWidgets[smartWidgetIndex].datatype_d = payload.datatype.datatype_d;
                    }

                    if (payload.timeframe) {
                        smartWidgets[smartWidgetIndex].timeframe = payload.timeframe;
                    }
                }

                loadingWidgets = loadingWidgets.filter((e) => e !== widgetId);
            }

            // not VOR chart
            if (payload.chart_type_id !== 11) {
                return state.set('isFetchingSingleWidget', false)
                    .set('smartWidgets', smartWidgets)
                    .set('loadingWidgets', loadingWidgets)
                    .set('isFetching', false);
            }
            return state.set('vorChartData', payload.data)
                .set('isFetchingSingleWidget', false)
                .set('loadingWidgets', loadingWidgets)
                .set('isFetching', false);
        }

        case DELETE_SMART_WIDGET_SUCCESS: {
            const { smartWidgets } = state;

            if (payload && payload.id) {
                const widgetId = parseInt(payload.id, 10);
                const smartWidgetIndex = findIndex(smartWidgets, (val) => val.id === widgetId);
                if (smartWidgetIndex !== -1) {
                    smartWidgets.splice(smartWidgetIndex, 1);
                }
            }

            return state.set('isFetching', false)
                .set('smartWidgets', smartWidgets);
        }

        case RESET_CHART_DATA_REQUEST:
            return state.set('chartData', []);

        case UPDATE_SMART_WIDGET_POSITIONS_SUCCESS:
            return state
            // .set('isFetching', false)        // leave it to GET_SMART_WIDGETS_SUCCESS
                .set('error', null);

        case GET_SMART_WIDGET_PRESETS_FAILURE:
        case UPDATE_PRESET_FAILURE:
        case DELETE_PRESET_FAILURE:
        case CREATE_WIDGET_FROM_PRESET_FAILURE:
        case CREATE_SMART_WIDGET_FAILURE:
        case UPDATE_SMART_WIDGET_FAILURE:
        case GET_SMART_WIDGET_INPUT_DATA_FAILURE:
        case UPLOAD_WIDGET_IMAGE_FAILURE:
        case UPLOAD_WIDGET_IMAGE_ATTR_FAILURE:
        case GET_CHART_DATA_FAILURE:
        case GET_VOR_CHART_DATA_FAILURE:
        case DELETE_SMART_WIDGET_FAILURE:
        case GET_PRESET_WIDGET_FAILURE:
        case GET_USER_WIDGET_FAILURE:
            return state.set('isFetching', false)
                .set('error', payload);

        case GET_BEST_DRIVER_CHART_DATA_FAILURE:
        case GET_SMART_WIDGETS_FAILURE:
            return state.set('isFetchingCustomChart', false)
                .set('error', payload);

        case GET_SMART_WIDGET_DATA_FAILURE:
            return state.set('isFetchingSingleWidget', false)
                .set('error', payload);

        case UPDATE_SMART_WIDGET_POSITIONS_FAILURE:
            return state.set('isFetching', false)
                .set('error', payload);

        default:
            return state;
    }
}

export {
    smartWidgetReducer,
    smartWidgetInitialState,
};
