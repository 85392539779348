/* global navigator:true */
import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { PropTypes } from 'prop-types';
import FlvPlayer from '../../FlvPlayer';
import { timeToSeconds } from '../../../core/utils/functions';
import HowenPlayer from '../../HowenPlayer';
import { EyeInvisibleOutlined } from '@ant-design/icons';

const VideoPlayers = ({
    refs, videos, isFlv, showControls, minHeight, onReady, onRemoved,
}) => {
    const [playerEnded, setPlayerEnded] = useState(false);
    const md = 12;
    // if (videos.length <= 3) {
    //     md = 24;
    // }
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (isFlv === true && isSafari === true) {
        return (
            <Row gutter={8}>
                <Col xs={24} md={24}>
                    <h2 style={{ textAlign: 'center', marginTop: 30, color: '#64879c' }}>Live video streaming is not supported on safari browser, please try a different web browser</h2>
                </Col>
            </Row>
        );
    }

    const height = minHeight !== null && !minHeight ? minHeight : 350;

    return (
        <Row gutter={8}>
            {videos && Array.isArray(videos) && videos.map((channel, channelKey) => {
                const videoId = `video-channel${channelKey}`;
                const videoUrl = channel[0].url || '';
                const videoType = channel[0].video_api || 'icar';
                let segments = [];
                if (channel.length > 0) {
                    segments = channel.map((ch) => ({ duration: ch.duration, url: ch.url }));
                }
                const timePart = timeToSeconds((channel[0]?.start_time || '').split(' ')[1] || '');
                const videoKey = `${channel[0]?.id}#${timePart}#${channel[0].duration}`;
                return (
                    <Col xs={24} md={md} key={videoId}>
                        {isFlv && videoType == 'icar'
                            ? (
                                <FlvPlayer
                                    channelKey={channelKey}
                                    videoKey={videoKey}
                                    refs={refs}
                                    url={videoUrl}
                                    segments={segments}
                                    onReady={onReady}
                                    onRemoved={onRemoved}
                                    id={videoId} />
                            )
                            : null}

                        {isFlv && videoType == 'Howen'
                            ? (
                                <HowenPlayer
                                    channelKey={channelKey}
                                    videoKey={videoKey}
                                    refs={refs}
                                    url={videoUrl}
                                    segments={segments}
                                    height={height}
                                    onReady={onReady}
                                    onRemoved={onRemoved}
                                    id={videoId} />
                            )
                            : null}

                        {!isFlv
                            ? (<div className={`video-container ${playerEnded ? 'ended' : ''}`}>
                                {playerEnded ? (<div className="ended-message"><EyeInvisibleOutlined></EyeInvisibleOutlined> Video ended</div>) : null}
                                <video
                                    ref={(elem) => {
                                        refs[channelKey] = { key: videoKey, element: elem };
                                        return true;
                                    }}
                                    controls={showControls}
                                    autoPlay={false}
                                    width="100%"
                                    onTimeUpdate={(event) => {
                                        if (event.currentTarget.currentTime < event.currentTarget.duration) {
                                            setPlayerEnded(false);
                                        }
                                    }}
                                    onPlay={() => setPlayerEnded(false)}
                                    onEnded={() => setPlayerEnded(true)}
                                    style={{ objectFit: 'fill', minHeight: '175px' }}>
                                    <source src={videoUrl} type="video/mp4" />
                                </video>
                            </div>
                            )
                            : null}
                    </Col>
                );
            })}
        </Row>
    );
};

VideoPlayers.propTypes = {
    refs: PropTypes.array,
    videos: PropTypes.array,
    isFlv: PropTypes.bool,
    showControls: PropTypes.bool,
    minHeight: PropTypes.number,
};

VideoPlayers.defaultProps = {
    refs: [],
    videos: [],
    isFlv: true,
    showControls: false,
    minHeight: 348,
};

export default VideoPlayers;
