/* eslint-disable react/destructuring-assignment */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import DOM from './newPtoIdleReport';
import * as eventsActions from '../../../../core/events/eventsActions';
import * as reportActions from '../../../../core/report/reportActions';
import * as globalActions from '../../../../core/global/globalActions';

class NewPtoIdleReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {},
            filterSidebar: true,
        };
        this.view = DOM;
    }

    componentDidMount() {
        const { actions } = this.props;

        actions.resetReportsRequest();

        this.onEventsTabFilterChange({
            date_to: moment(),
            asset_or_driver: 'assets',
            date_from: moment().subtract(1, 'weeks'),
            page: 1,
        });

    }

    onEventsTabPageChange = (page) => {
        const { actions } = this.props;
        const { filters } = this.state;

        filters.page = page;
        actions.getNewPtoIdleReportRequest(filters);
    }

    onEventsTabSortChange = (pagination, x, sorter, extra) => {
        const {
            actions,
            newPtoIdleReportPagination,
        } = this.props;
        const { filters } = this.state;

        // checking when only order changes
        if (pagination && pagination.current.toString() === newPtoIdleReportPagination.currentPage) {
            const params = { orderBy: `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`, ...filters };
            actions.getNewPtoIdleReportRequest(params);
        }
    }

    onEventsTabFilterChange = (values) => {
        const { actions } = this.props;

        const filters = values;
        if (filters.date_from) {
            filters.date_from = values.date_from.format('YYYY-MM-DD');
        }
        if (filters.date_to) {
            filters.date_to = values.date_to.format('YYYY-MM-DD');
        }
        filters.page = 1;
        this.setState({ filters });
        actions.getNewPtoIdleReportRequest(filters);
    }

    onExportCSV = () => {
        const {
            isExportingNewPtoIdleReportCsv,
            actions,
        } = this.props;
        const { filters } = this.state;

        if (isExportingNewPtoIdleReportCsv) return false;

        const params = { ...filters };
        params.page = 1;
        if (params.date_from) {
            params.date_from = moment(params.date_from)
                .format('YYYY-MM-DD');
        }
        if (params.date_to) {
            params.date_to = moment(params.date_to)
                .format('YYYY-MM-DD');
        }
        actions.getNewPtoIdleReportCsvRequest(params);
    }

    render() {
        return this.view(
            {
                update: (newVals) => (
                    this.props.actions.UpdateEventNotifications(newVals)
                ),
            },
        );
    }
}

NewPtoIdleReport.propTypes = {
    actions: PropTypes.object.isRequired,
    newPtoIdleReport: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        newPtoIdleReport: state.report.newPtoIdleReport,
        events: state.events.companyEvents,
        filterSidebar: state.filterSidebar,
        newPtoIdleReportPagination: state.report.newPtoIdleReportPagination,
        isExportingNewPtoIdleReportCsv: state.report.isExportingNewPtoIdleReportCsv,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...reportActions,
            ...globalActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NewPtoIdleReport);
