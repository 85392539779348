import React, { Component } from 'react';
import {
    Form,
    DatePicker,
    Input,
    Button,
} from 'antd';
import moment from 'moment';
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';
import { isoStandardDateTimeFormat } from '../../../core/utils/dateUtils';

const { RangePicker } = DatePicker;

class assetJourneyHistoryFilterForm extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    submitForm = (e) => {
        e.preventDefault();
        const { onSubmit } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    }

    render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Form onSubmit={this.submitForm} layout="vertical">
                <Form.Item label="Date Range">
                    {getFieldDecorator('date_range', {
                        initialValue: [
                            moment(moment(new Date()).startOf('day').format(isoStandardDateTimeFormat)),
                            moment(new Date()),
                        ],
                    })(
                        <RangePicker
                            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                            placeholder="Date Range"
                            format={isoStandardDateTimeFormat} />,
                    )}
                </Form.Item>
                <Form.Item label="Current Location">
                    {getFieldDecorator('current_location', {

                    })(
                        <Input placeholder="Current location" />,
                    )}
                </Form.Item>
                <div className="mt-2 text-center">
                    <Button
                        htmlType="submit"
                        type="primary"
                        className="btn extrawide"
                        size="large">
                        Filter
                    </Button>
                </div>
            </Form>
        );
    }
}

const assetJourneyHistoryFilter = Form.create({ name: 'event_filter_form' })(assetJourneyHistoryFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(assetJourneyHistoryFilter);
