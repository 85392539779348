/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    Spin, Empty, Button, Switch,
} from 'antd';
import moment from 'moment';
import { isEmpty } from 'underscore';
import { Link } from 'react-router-dom';
import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';
import Icon from '../../../../../components/elements/Icon';
import DeviceTimeline from '../../../../../components/DeviceTimeline';

class ReportTab extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            convertedReport: [],
            journeyOrDay: 'days',
        };
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isFetching && prevProps.isFetching !== this.props.isFetching) {
            const { journeyOrDay } = this.props;
            this.setState({ journeyOrDay });
        }
    }

    toggleUnit = () => {
        const { imperialUnit } = this.state;
        this.setState({ imperialUnit: !imperialUnit });
    }

    render() {
        const {
            timeSheetReport,
            isFetching,
            actions,
            timeSheetReportPagination,
            imperialUnit,
            reportHasBeenFetched,
            companyFeatures,
        } = this.props;
        const { journeyOrDay } = this.state;

        let listReports = [];
        if (timeSheetReport && timeSheetReport.length > 0) {
            listReports = timeSheetReport.map((report) => {
                const tempReport = {
                    ...report,
                };
                if (journeyOrDay === 'days') {
                    const journeyIds = tempReport.journey_ids ? tempReport.journey_ids.split(',') : [];
                    tempReport.actions = (
                        !isEmpty(report) ? (
                            <Link
                                id="journeys-link"
                                to="/journeys"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ float: 'right' }}
                                onClick={() => {
                                    actions.resetJourneysToRequest();
                                    actions.setJourneysToRequest(journeyIds);
                                }}>
                                <Icon
                                    name="chevron-right"
                                    className="ml-2" />
                            </Link>
                        ) : null
                    );
                } else {
                    tempReport.actions = (
                        !isEmpty(report)
                            ? (
                                <Link to={`/journey/${report.id}`} style={{ float: 'right' }}>
                                    <Icon name="chevron-right" className="ml-2" />
                                </Link>
                            )
                            : null
                    );
                }

                const keys = Object.keys(tempReport);
                const mileageConversionRate = 0.621371;
                const mpgConversionRate = 282.481; // L/100km to UK mpg
                return keys.reduce((acc, key) => {
                    acc[key] = tempReport[key];
                    let newKey = key;
                    switch (key) {
                        case 'average_speed':
                        case 'max_speed':
                        case 'trip_odometer_mileage':
                            acc[key] = parseFloat(acc[key]);
                            if (acc[key] && imperialUnit) acc[key] = (acc[key] * mileageConversionRate).toFixed(2);
                            acc[key] = acc[key].toString();
                            if (imperialUnit) {
                                newKey = `${key}_in_Mi`;
                            } else {
                                newKey = `${key}_in_Km`;
                            }
                            acc[newKey] = acc[key];
                            delete acc[key];
                            break;

                        case 'mpg':
                            acc[key] = parseFloat(acc[key]);
                            if (acc[key] && imperialUnit) acc[key] = (mpgConversionRate / acc[key]).toFixed(2);
                            acc[key] = acc[key].toString();
                            if (!imperialUnit) {
                                newKey = 'L_/_100_Km';
                                acc[newKey] = acc[key];
                                delete acc[key];
                            }
                            break;
                    }

                    return acc;
                }, {});
            });
        }
        const SECONDS_IN_A_DAY = 86400;
        const TIMELINE_WIDTH = 1000;
        if (listReports && listReports.length > 0) {
            listReports = listReports.map((report) => {
                let timeDriven = report.time_driven;
                if (!timeDriven) timeDriven = '-';
                let shift = report.shift;
                if (!shift) shift = '-';
                let idleTime = report.idle_time;
                if (!idleTime) idleTime = '-';
                let trueIdleTime = report.true_idle_time;
                if (!trueIdleTime) trueIdleTime = '-';
                let tempReport =  {
                    ...report,
                    shift,
                    time_driven: timeDriven,
                    idle_time: idleTime,
                    true_idle_time: trueIdleTime,
                };

                let tripOdometerMileage = imperialUnit ? report.trip_odometer_mileage_in_Mi : report.trip_odometer_mileage_in_Km;
                if (!parseFloat(tripOdometerMileage)) tripOdometerMileage = '-';
                let mpg = imperialUnit ? report.mpg : report['L_/_100_Km'];
                if (!parseFloat(mpg)) mpg = '-';
                let averageSpeed = imperialUnit ? report.average_speed_in_Mi : report.average_speed_in_Km;
                if (!parseFloat(averageSpeed)) averageSpeed = '-';
                let maxSpeed = imperialUnit ? report.max_speed_in_Mi : report.max_speed_in_Km;
                if (!parseFloat(maxSpeed)) maxSpeed = '-';

                if (imperialUnit) {
                    tempReport.trip_odometer_mileage_in_Mi = tripOdometerMileage;
                    tempReport.mpg = mpg;
                    tempReport.average_speed_in_Mi = parseFloat(averageSpeed).toFixed(0);
                    tempReport.max_speed_in_Mi = parseFloat(maxSpeed).toFixed(0);
                } else {
                    tempReport.trip_odometer_mileage_in_Km = tripOdometerMileage;
                    tempReport['L_/_100_Km'] = mpg;
                    tempReport.average_speed_in_Km = parseFloat(averageSpeed).toFixed(0);
                    tempReport.max_speed_in_Km = parseFloat(maxSpeed).toFixed(0);
                }

                delete tempReport.erroneous;
                // Filter and arrange the columns based on the filter input
                const isFilteredByAssets = tempReport.asset && tempReport.registration;
                if (journeyOrDay === 'days') {
                    tempReport.date = moment(tempReport.start).format('DD-MM-YYYY (ddd)'); // Craft a date to go beside the timeline
                    tempReport.timeline = ( // Add the timeline
                        <div>
                            {isFetching ? null : (
                                <DeviceTimeline
                                    timelineWidth={SECONDS_IN_A_DAY}
                                    scaleX={TIMELINE_WIDTH / SECONDS_IN_A_DAY}
                                    deviceDetails={{
                                        assetId: tempReport.asset_id,
                                        date: tempReport.start,
                                    }}
                                    isInline
                                />
                            )}
                        </div>
                    );
                    if (isFilteredByAssets) {
                        tempReport = {
                            asset: tempReport.asset,
                            driver: tempReport.driver,
                            division: tempReport.division,
                            registration: tempReport.registration,
                            date: tempReport.date,
                            timeline: tempReport.timeline,
                            start_date: tempReport.start.split(' ')[0],
                            start_time: tempReport.start.split(' ')[1],
                            end_date: tempReport.end.split(' ')[0],
                            end_time: tempReport.end.split(' ')[1],
                            shift: tempReport.shift,
                            time_driven: tempReport.time_driven,
                            idle_time: tempReport.idle_time,
                            true_idle_time: tempReport.true_idle_time,
                            start_area_address: tempReport.start_area_address,
                            end_area_address: tempReport.end_area_address,
                            start_area: tempReport.start_area,
                            end_area: tempReport.end_area,
                            [imperialUnit ? "trip_odometer_mileage_in_Mi" : "trip_odometer_mileage_in_Km"]: tripOdometerMileage,
                            [imperialUnit ? "mpg" : "L_/_100_Km"]: imperialUnit ? tempReport.mpg : tempReport['L_/_100_Km'],
                            [imperialUnit ? "average_speed_in_Mi" : "average_speed_in_Km"]: imperialUnit ? tempReport.average_speed_in_Mi : tempReport.average_speed_in_Km,
                            [imperialUnit ? "max_speed_in_Mi" : "max_speed_in_Km"]: imperialUnit ? tempReport.max_speed_in_Mi : tempReport.max_speed_in_Km,
                            action: tempReport.action,
                        };
                    } else {
                        tempReport = {
                            driver: tempReport.driver,
                            asset: tempReport.asset,
                            division: tempReport.division,
                            date: tempReport.date,
                            timeline: tempReport.timeline,
                            start_date: tempReport.start.split(' ')[0],
                            start_time: tempReport.start.split(' ')[1],
                            end_date: tempReport.end.split(' ')[0],
                            end_time: tempReport.end.split(' ')[1],
                            shift: tempReport.shift,
                            time_driven: tempReport.time_driven,
                            idle_time: tempReport.idle_time,
                            true_idle_time: tempReport.true_idle_time,
                            start_area_address: tempReport.start_area_address,
                            end_area_address: tempReport.end_area_address,
                            start_area: tempReport.start_area,
                            end_area: tempReport.end_area,
                            [imperialUnit ? "trip_odometer_mileage_in_Mi" : "trip_odometer_mileage_in_Km"]: tripOdometerMileage,
                            [imperialUnit ? "mpg" : "L_/_100_Km"]: imperialUnit ? tempReport.mpg : tempReport['L_/_100_Km'],
                            [imperialUnit ? "average_speed_in_Mi" : "average_speed_in_Km"]: imperialUnit ? tempReport.average_speed_in_Mi : tempReport.average_speed_in_Km,
                            [imperialUnit ? "max_speed_in_Mi" : "max_speed_in_Km"]: imperialUnit ? tempReport.max_speed_in_Mi : tempReport.max_speed_in_Km,
                            action: tempReport.action,
                        };
                    }
                    const companyFeatureIds = companyFeatures.map((feature) => feature.id);
                    if (!companyFeatureIds.includes('timeline-on-timesheet-report')) {
                        delete tempReport.timeline;
                        delete tempReport.date;
                    }
                } else {
                    if (isFilteredByAssets) {
                        tempReport = {
                            asset: tempReport.asset,
                            driver: tempReport.driver,
                            division: tempReport.division,
                            registration: tempReport.registration,
                            start_date: tempReport.start.split(' ')[0],
                            start_time: tempReport.start.split(' ')[1],
                            end_date: tempReport.end.split(' ')[0],
                            end_time: tempReport.end.split(' ')[1],
                            shift: tempReport.shift,
                            time_driven: tempReport.time_driven,
                            idle_time: tempReport.idle_time,
                            true_idle_time: tempReport.true_idle_time,
                            start_area_address: tempReport.start_area_address,
                            end_area_address: tempReport.end_area_address,
                            start_area: tempReport.start_area,
                            end_area: tempReport.end_area,
                            [imperialUnit ? "trip_odometer_mileage_in_Mi" : "trip_odometer_mileage_in_Km"]: tripOdometerMileage,
                            [imperialUnit ? "mpg" : "L_/_100_Km"]: imperialUnit ? tempReport.mpg : tempReport['L_/_100_Km'],
                            [imperialUnit ? "average_speed_in_Mi" : "average_speed_in_Km"]: imperialUnit ? tempReport.average_speed_in_Mi : tempReport.average_speed_in_Km,
                            [imperialUnit ? "max_speed_in_Mi" : "max_speed_in_Km"]: imperialUnit ? tempReport.max_speed_in_Mi : tempReport.max_speed_in_Km,
                            action: tempReport.action,
                        };
                    } else {
                        tempReport = {
                            driver: tempReport.driver,
                            asset: tempReport.asset,
                            division: tempReport.division,
                            date: tempReport.date,
                            timeline: tempReport.timeline,
                            start_date: tempReport.start.split(' ')[0],
                            start_time: tempReport.start.split(' ')[1],
                            end_date: tempReport.end.split(' ')[0],
                            end_time: tempReport.end.split(' ')[1],
                            shift: tempReport.shift,
                            time_driven: tempReport.time_driven,
                            idle_time: tempReport.idle_time,
                            true_idle_time: tempReport.true_idle_time,
                            start_area_address: tempReport.start_area_address,
                            end_area_address: tempReport.end_area_address,
                            start_area: tempReport.start_area,
                            end_area: tempReport.end_area,
                            [imperialUnit ? "trip_odometer_mileage_in_Mi" : "trip_odometer_mileage_in_Km"]: tripOdometerMileage,
                            [imperialUnit ? "mpg" : "L_/_100_Km"]: imperialUnit ? tempReport.mpg : tempReport['L_/_100_Km'],
                            [imperialUnit ? "average_speed_in_Mi" : "average_speed_in_Km"]: imperialUnit ? tempReport.average_speed_in_Mi : tempReport.average_speed_in_Km,
                            [imperialUnit ? "max_speed_in_Mi" : "max_speed_in_Km"]: imperialUnit ? tempReport.max_speed_in_Mi : tempReport.max_speed_in_Km,
                            action: tempReport.action,
                        };
                    }
                }

                return tempReport;
            });
        }

        const customEmpty = (
            <Empty
                description={(
                    <span style={{ color: '#64879c', display: 'block' }}>
                        <span style={{
                            fontSize: '180%', fontWeight: 'bold', marginBottom: '5px', display: 'block',
                        }}>
                            Enter your search requirements to generate a report
                        </span>
                        <span style={{ display: 'block' }}>
                            Use the filter bar on the left to select Assets or Drivers,
                            the date and the frequency (if applicable) of the data you would like to display
                        </span>
                    </span>
                )}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{ display: 'none' }} />
        );

        const exportableColumns = listReports && listReports[0] ? (Object.keys(listReports[0]).filter((oK) => oK.toLowerCase() !== 'actions')) : [];

        return (
            <Spin spinning={isFetching}>
                <div className="report-table">
                    {isEmpty(listReports)
                        ? reportHasBeenFetched
                            ? <Empty />
                            : customEmpty
                        : (
                            <>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <Button
                                        type="primary"
                                        disabled={this.props.isExportingTimesheetReportCsv}
                                        onClick={() => { this.props.onExportCSV(); }}>
                                        <Spin
                                            size="small"
                                            spinning={this.props.isExportingTimesheetReportCsv}
                                        >
                                            Export CSV
                                        </Spin>
                                    </Button>
                                </div>
                                <Switch
                                    checkedChildren="Miles"
                                    unCheckedChildren="Kilometres"
                                    checked={imperialUnit}
                                    onChange={() => this.props.toggleUnit()}
                                    className="ml-2" />

                                <GridView
                                    data={listReports}
                                    onChange={this.props.onTableChange}
                                    sortableColumns={['asset', 'driver', 'division', 'registration', 'start_date', 'end_date', 'shift', 'time_driven', 'idle_time', 'true_idle_time', 'trip_odometer_mileage_in_km', 'average_speed_in_km', 'max_speed_in_km']}
                                    hiddenColumns={['id', 'asset_id', 'driver_id', 'action']}
                                    exportableColumns={exportableColumns}
                                    pagination={{
                                        total: timeSheetReportPagination.totalRecords,
                                        current: timeSheetReportPagination.currentPage,
                                        pageSize: timeSheetReportPagination.perPageCount,
                                        className: 'custom-pagination',
                                        showSizeChanger: false,
                                    }}
                                />
                            </>
                        )}
                </div>
            </Spin>
        );
    }
}

ReportTab.defaultProps = {
    timeSheetReport: [],
    timeSheetReportPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 20,
    },
    onTableChange: () => null,
};

ReportTab.propTypes = {
    timeSheetReport: PropTypes.array,
    timeSheetReportPagination: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        timeSheetReport: state.report.timeSheetReport,
        timeSheetReportPagination: state.report.timeSheetReportPagination,
        isFetching: state.report.isFetching,
        isExportingTimesheetReportCsv: state.report.isExportingTimesheetReportCsv,
        companyFeatures: state.user.userCompany.features,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
