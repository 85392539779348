/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm, Button } from 'antd';
import { isEmpty } from 'underscore';
import history from '../../../core/utils/history';

import Icon from '../../../components/elements/Icon';
import GridView from '../../../components/elements/GridView/index';
import Loading from '../../../components/elements/Loading';

export default function () {
    const {
        actions,
        users,
        usersPagination,
        usersIsFetching,
    } = this.props;
    const { searchParams, orderParams } = this.state;

    let data = users.map((d) => {
        let displayRole = d.role;

        if (displayRole === 'user') {
            displayRole = 'User';
        }

        if (displayRole === 'admin') {
            displayRole = 'Admin';
        }

        if (displayRole === 'super-admin') {
            displayRole = 'Super Admin';
        }

        if (displayRole === 'manager') {
            displayRole = 'Manager';
        }

        return ({
            id: d.user_id,
            email: d.email,
            role: displayRole,
            name: d.name,
            status: parseInt(d.status, 10) === 1 ? 'Active' : 'Inactive',
            actions: (
                <Link to={`/users/view/${d.user_id}`} className="btn btn-empty ml-2">
                    <Icon name="pencil" />
                </Link>
            ),
            action: (
                <Popconfirm
                    title="Are you sure to delete this user?"
                    onConfirm={(e) => {
                        e.stopPropagation();
                        this.removeUser(d.user_id);
                    }}
                    onCancel={(e) => {
                        e.stopPropagation();
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <div onClick={e => { e.stopPropagation(); }}>
                        <DeleteOutlined />
                    </div>
                </Popconfirm>
            ),
        });
    });

    const paginationChange = (page) => {
        if (page !== usersPagination.currentPage) {
            actions.getUsersPageRequest({ page, searchParams, ...orderParams });
        }
    };

    const gridChange = (pagination, filters, sorter) => {
        if (pagination && pagination.current === parseInt(usersPagination.currentPage, 10)) {
            const params = { orderBy: `${sorter.order == 'descend' ? '-' : ''}${sorter.field}` };
            this.setState({ orderParams: params });
            actions.saveUserOrderParamsRequest(params);
            actions.getUsersPageRequest({ page: usersPagination.currentPage, searchParams, ...params });
        }
    };

    return (

        <div className="section__body">
            {usersIsFetching ? <Loading /> : null}
            <div className="section__action-bar">
                <Button
                    onClick={() => history.push('/users/create')}
                    className="btn ant-btn-primary ant-btn-xs middlewide">
                    Add user
                </Button>
            </div>
            <GridView
                data={data}
                onChange={gridChange}
                sortableColumns={['user_id', 'email', 'role', 'name', 'status']}
                pagination={{
                    total: parseInt(usersPagination.totalRecords, 10),
                    showSizeChanger: false,
                    pageSize: usersPagination.perPage,
                    onChange: paginationChange,
                }} />
        </div>
    );
}
