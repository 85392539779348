import { Component } from 'react';

import {
    Modal,
} from 'antd';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './style.scss';
import * as CompareActions from '../../core/comparison/comparisonActions';
import DOM from './smartWidget';
import * as smartWidgetActions from '../../core/smartWidget/smartWidgetActions';

class SmartWidget extends Component {
    constructor(props) {
        super(props);
        const { location } = this.props;

        let currentKey = '1';
        if (location && location.editWidgetData) {
            currentKey = '2';
        }

        this.state = {
            currentTabKey: currentKey,
            widget: {},
            editWidgetData: location.editWidgetData,
        };

        this.view = DOM;
    }

    componentDidMount() {
        const {
            actions,
        } = this.props;

        const { editWidgetData } = this.state;
        if (editWidgetData) {
            actions.getUserWidgetRequest({ id: editWidgetData.editWidgetId });
        }
    }

    render() {
        const { confirm } = Modal;
        const {
            widget,
        } = this.props;

        return this.view();
    }

    clickTab(key) {
        const { actions } = this.props;

        actions.resetChartDataRequest();
        actions.resetPresetWidgetRequest();
        this.setState({ currentTabKey: key });
    }
}

SmartWidget.defaultProps = {
    widget: {
        id: 'new',
        title: '',
    },
};

SmartWidget.propTypes = {
    widget: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        title: PropTypes.string.isRequired,
        pos: PropTypes.shape({
            x: PropTypes.number.isRequired,
            y: PropTypes.number.isRequired,
            w: PropTypes.number.isRequired,
            h: PropTypes.number.isRequired,
        }),
    }),
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...CompareActions,
            ...smartWidgetActions,
        }, dispatch),
    };
}
export const WidgetTest = SmartWidget;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SmartWidget);
