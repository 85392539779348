/* eslint-disable react/no-this-in-sfc */
/* global window:true */
import React from 'react';
import { Link } from 'react-router-dom';
import {
    Icon as AntIcon, Tooltip, Spin,
} from 'antd';
import moment from 'moment';

import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import Icon from '../../components/elements/Icon';
import EventViewPagePdf from '../../components/EventViewPagePdf';
import DetailView from '../../components/DetailView';
import './eventsView.scss';

export default function () {
    const {
        selectedEvent, match, actions, isFetching,
    } = this.props;
    const { eventId } = match.params;
    const { before_time, after_time } = this.state;

    let eventTitle = '';

    if (selectedEvent && selectedEvent.info && selectedEvent.info.record_time) {
        eventTitle = moment(selectedEvent.info.record_time).format('dddd M/YY h:mm');
    }

    if (selectedEvent && selectedEvent.info && selectedEvent.info.registration) {
        eventTitle += ` - ${selectedEvent.info.registration}`;
    }
    const urlParams = new URLSearchParams(window.location.search);
    const entries = urlParams.entries();
    const params = [];
    for (const pair of entries) {
        params.push(`${pair[0]}=${pair[1]}`);
    }
    const backLink = params.length == 0 ? '/events/reload' : `/events?${params.join('&')}`;

    return (
        selectedEvent
            ? (
                <div className="event-view-pdf">
                    <DetailView
                        showShareBlock={false}
                        showDownloadIframe={false}
                        selectedEvent={selectedEvent} />
                </div>
            )
            : null
    );
}
