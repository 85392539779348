import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Spin, Tooltip, Icon as AntIcon,
} from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getTodayInServerFormat, getYesterdayInServerFormat } from '../../../core/utils/dateUtils';
import GridView from '../../../components/elements/GridView/index';
import Icon from '../../../components/elements/Icon';
import * as assetActions from '../../../core/asset/assetActions';

class AssetEventHistoryTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            searchParams: {},
            orderParams: {},
        };
    }

    componentDidMount() {
        const {
            actions, setLoadedState, assetEventHistoryTabLoaded, assetId,
        } = this.props;
        if (!assetEventHistoryTabLoaded) {
            actions.getAssetEventHistoryRequest({
                assetId,
                page: 1,
                date_from: getYesterdayInServerFormat(),
                date_to: getTodayInServerFormat(),
            });
            setLoadedState();
        }
    }

    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        const { actions, assetId, assetEventHistoryPagination } = this.props;
        const { orderParams } = this.state;
        // onTableChange(pagination, filters, sorter, extra);
        const params = { orderBy: `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`, assetId };
        if (pagination && pagination.current == assetEventHistoryPagination.currentPage) {
            this.setState({ orderParams: params });
            actions.getAssetEventHistoryRequest({
                ...params,
            });
        }
    }

    render() {
        const {
            assetEventHistory, assetEventHistoryPagination, isFetching, eventHistoryFirstLoad,
        } = this.props;
        const listAssetEventHistory = assetEventHistory.map((event, eventIndex) => {
            const iconStyle = {};
            iconStyle.fontSize = '16px';
            iconStyle.color = '#CF2B28';
            const tempEvent = {};

            let eventTitle = event.event_name;

            if (event.event_type == 253) {
                if (event.green_driving_type == 0) {
                    eventTitle = 'Eco-friendly driving';
                } else if (event.green_driving_type == 1) {
                    eventTitle = 'Harsh accelerating';
                } else if (event.green_driving_type == 2) {
                    eventTitle = 'Harsh braking';
                } else if (event.green_driving_type == 3) {
                    eventTitle = 'Harsh cornering';
                }
            }

            tempEvent.event_type = event.icon && event.icon !== ''
                ? <Tooltip placement="top" title={eventTitle}><AntIcon style={iconStyle} theme="filled" type={event.icon} /> {event.event_name}</Tooltip>
                : <Tooltip placement="top" title={eventTitle}><AntIcon style={iconStyle} theme="filled" type="flag" /> {event.event_name}</Tooltip>;

            tempEvent.date = moment(event.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
            tempEvent.time = moment(event.time, 'hh:mm A').format('hh:mm A');
            tempEvent.location = event.location_name;
            tempEvent.driver = <Link to={`/drivers/view/${event.user_id}`}>{event.full_name}</Link>;
            // tempEvent.asset_reg = <Link to={`/assets/view/${event.asset_id}`}>{event.registration}</Link>;
            tempEvent.asset_reg = event.registration;
            tempEvent.actions = (
                <Link to={`/events/custom-view/${event.id}/${event.device_id}`} style={{ float: 'right' }}>
                    <Icon name="chevron-right" className="ml-2" />
                </Link>
            );
            return tempEvent;
        });

        let spinning = false;
        for (let i = 0; i < isFetching.length; i++) {
            const item = isFetching[i];
            if (item.GET_ASSET_EVENT_HISTORY) {
                spinning = true;
            }
        }

        return (
            <Spin spinning={spinning}>
                {eventHistoryFirstLoad ? (
                    <div className="h-centered-text-container">
                        <p>(Filtering by Today)</p>
                    </div>
                ) : null}
                <GridView
                    // filterTemplate='<div><div style="display: inline-block; width: 45%">{{label}}</div><div style="display: inline-block; width: 45%">{{element}}</div><div></div></div>'
                    data={listAssetEventHistory}
                    onChange={this.onTableChange}
                    sortableColumns={['event_type', 'date', 'time', 'location', 'driver', 'asset_reg']}
                    pagination={{
                        total: parseInt(assetEventHistoryPagination.totalRecords, 10),
                        showSizeChanger: false,
                        pageSize: parseInt(assetEventHistoryPagination.perPageCount, 10),
                        onChange: this.onPageChange,
                    }} />
            </Spin>
        );
    }
}

AssetEventHistoryTab.defaultProps = {
    onPageChange: () => null,
};

AssetEventHistoryTab.propTypes = {
    onPageChange: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        assetEventHistory: state.asset.assetEventHistory,
        assetEventHistoryPagination: state.asset.assetEventHistoryPagination,
        isFetching: state.asset.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AssetEventHistoryTab);
