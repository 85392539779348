/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import {
    DatePicker,
    Form,
    Col,
} from 'antd';
import Sticky from 'react-sticky-el';
import 'rc-color-picker/assets/index.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import * as dashboardActions from '../../../../core/dashboard/dashboardActions';
import * as smartWidgetActions from '../../../../core/smartWidget/smartWidgetActions';

import ChartMultiDropDown from '../../ChartComponents/ChartMultiDropDown';
import StackedAreaChart from '../../ChartComponents/StackedAreaChart';


const FormItem = Form.Item;
class StackedAreaChartForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        const { form, saveDatatypeValues, getChartDataFromInput, division, driver, asset, setTimeframe, smartWidget } = this.props;
        const { getFieldDecorator } = form;

        let preview = (
            <div className="smart-widget-preview">
                <h2>Widget Preview</h2>
                <FormItem className="widget-preview-date">
                    {getFieldDecorator('date', {
                        initialValue: moment(new Date(), 'DD/MM/YYYY'),
                        rules: [
                        ],
                    })(
                        <DatePicker
                            onChange={() => getChartDataFromInput()}
                            format="DD/MM/YYYY" />,
                    )}

                </FormItem>
                <br />
                <StackedAreaChart
                    heightRatio={2}
                    timeframe={smartWidget?.presetWidget?.timeframe || 'hourly'}
                    onTimeframeChange={(timeframe) => setTimeframe(timeframe)}
                    rightMenuItems={[]}
                    tooltipText={smartWidget?.presetWidget?.name || 'This is tooltip text'}
                    data={smartWidget.chartData}
                    onAllOptionChange={(optionsData) => { saveDatatypeValues(optionsData); getChartDataFromInput(); }}
                    divisions={[...division.divisionOptions]}
                    drivers={[...driver.driverList]}
                    assets={[...asset.options]} />
            </div>
        );

        const isSticky = window.innerWidth > 767;
        if(isSticky){
            preview = <Sticky>{ preview }</Sticky>;
        }

        return (
            <>
                <Col className="gutter-row first-col" xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="input-data-col">
                        <p className="field-label">Default data view</p>

                        <ChartMultiDropDown
                            onDataChange={(optionsData) => saveDatatypeValues(optionsData)}
                            divisions={[...division.divisionOptions]}
                            drivers={[...driver.driverList]}
                            assets={[...asset.options]} />
                    </div>
                </Col>
                <Col className="smart-widget-preview-row gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}>
                    { preview }
                </Col>
            </>
        );
    }
}

StackedAreaChartForm.defaultProps = {
    inputData: [],
    saveDatatypeValues: () => {},
    getChartDataFromInput: () => {},
    setTimeframe: () => {},
};

StackedAreaChartForm.propTypes = {
    actions: PropTypes.object.isRequired,
    smartWidget: PropTypes.object.isRequired,
    division: PropTypes.object.isRequired,
    driver: PropTypes.object.isRequired,
    asset: PropTypes.object.isRequired,
    saveDatatypeValues: PropTypes.func,
    getChartDataFromInput: PropTypes.func,
    setTimeframe: PropTypes.func,
    // newImageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        inputData: state.smartWidget.inputData,
        smartWidget: state.smartWidget,
        division: state.division,
        driver: state.driver,
        asset: state.asset,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...dashboardActions,
            ...smartWidgetActions
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StackedAreaChartForm);
