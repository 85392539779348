import * as React from 'react';
import src from './google-map-icon-red.png';

function CenterMapMarkerImg(props) {
    return (
        <img 
            src={src} 
            alt="Center Map Marker" 
            {...props} 
        />
    );
}

// eslint-disable-next-line import/prefer-default-export
export default CenterMapMarkerImg;
