/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';
import { isEmpty } from 'underscore';
import LiveVideosSelection from '../partials/LiveVideosSelection';
import LiveVideoScreen from '../partials/LiveVideoScreen';
import * as videoActions from '../../../core/video/videoActions';

class LiveVideosTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const { params, actions } = this.props;
        if (params && params.assetId) {
            // actions.toggleVideoSelectionScreen(true);
        }

        // if comes from main map, back to selection screen so that it can fetch data
        const urlParams = new URLSearchParams(window.location.search);
        const imei = urlParams.get('imei');
        if (!isEmpty(imei)) {
            actions.toggleVideoSelectionScreen(true);
        }
    }

    componentWillUnmount() {
        const { actions } = this.props;
        this.clearLiveVideos();
    }

    toggleVideoScreen = () => {
        const { actions, setShouldShowSidebar } = this.props;
        setShouldShowSidebar(false);
        actions.toggleVideoSelectionScreen(false);
    }

    clearLiveVideos = (e) => {
        if (e) { e.preventDefault(); }
        const { actions, setShouldShowSidebar } = this.props;
        setShouldShowSidebar(true);
        actions.updateVideoCameraSelection([]);
        actions.toggleVideoSelectionScreen(true);
    }

    render() {
        const { showSelectionScreen, selectedCameras, params } = this.props;
        return (
            <>
                <Row style={{ padding: '10px 0px' }}>
                    <Col xs={24}>
                        <span style={{ float: 'right' }}>
                            {`${selectedCameras.length} selected - `}
                            <a
                                href="#"
                                onClick={this.clearLiveVideos}>clear
                            </a>
                        </span>
                    </Col>
                </Row>
                {showSelectionScreen
                    ? <LiveVideosSelection params={params} toggleVideoScreen={this.toggleVideoScreen} clearLiveVideos={this.clearLiveVideos} />
                    : <LiveVideoScreen />}
            </>
        );
    }
}

LiveVideosTab.defaultProps = {
    showSelectionScreen: true,
    selectedCameras: [],
};

LiveVideosTab.propTypes = {
    actions: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    showSelectionScreen: PropTypes.bool,
    selectedCameras: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        showSelectionScreen: state.video.showSelectionScreen,
        selectedCameras: state.video.selectedCameras,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...videoActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LiveVideosTab);
