import React from 'react';

const FnolSVG = () => (
    <svg width={64} height={64} xmlns="http://www.w3.org/2000/svg">
        <g stroke="null">
            <path d="M32 8a24 24 0 1024 24A24 24 0 0032 8zm0 44.571A20.571 20.571 0 1152.571 32 20.571 20.571 0 0132 52.571z" />
            <path d="M30.286 18.286h3.429v18.857h-3.429zM32 42.286a2.571 2.571 0 102.571 2.571A2.571 2.571 0 0032 42.286z" />
        </g>
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default FnolSVG;
