/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Tabs } from 'antd';
import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import DataEventFilter from './forms/DataEventFilter';
import DataEventsTab from './tabs/DataEventsTab';
import './dataEvents.scss';

const { TabPane } = Tabs;

export default function () {
    const {
        events, user, dataEventsSearchParams, reload, assetId, dateFrom,
    } = this.props;
    const {
        eventsTabLoaded,
    } = this.state;

    let filterContent = <DataEventFilter dataEventsTabFilter={dataEventsSearchParams} onSubmit={this.onEventsTabFilterChange} />;
    let eventsTabContent = (
        <DataEventsTab
            dataEventsTabFilter={dataEventsSearchParams}
            setLoadedState={this.eventsTabLoaded}
            eventsTabLoaded={eventsTabLoaded}
            onTableChange={this.onEventsTabSortChange}
            onPageChange={this.onEventsTabPageChange}
            reload={reload} />
    );

    return (
        <Layout
            data-test="pages-data-events"
            className="data-events"
            title={<div>Data Events</div>}
            headerTitle={<div>Data Events Page</div>}>
            <div className="content-wrap">
                <FilterSidebar>
                    {filterContent}
                </FilterSidebar>
                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            <Tabs
                                activeKey={'1'}
                                animated={false}
                                className="tabs-restyled">
                                <TabPane tab="Data Events" key="1">
                                    <div className="section__body">
                                        {eventsTabContent}
                                    </div>
                                </TabPane>
                            </Tabs>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
