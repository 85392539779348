import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import DeviceTimelineHeader from '../../../DeviceTimelineHeader';

class TableView extends Component {
    render() {
        const {
            dataSource, columns, pagination, onChange, onRowClick, id, expandedRowRender,
        } = this.props;
        // const expandedRowRender = record => <p>{record.description}</p>;
        const keyedData = dataSource.map((dataItem, dataItemIndex) => {
            dataItem.key = `scorch-data-row-${dataItemIndex}`;
            return dataItem;
        });
    
    const mappedColumns = columns.map(column => {
      if (column.title !== "Timeline") {
        return column;
      } else {
        return {
          dataIndex: "timeline",
          exportable: false,
          key: "timeline",
          sortDirections: ["descend", "ascend"],
          sorter: false,
          title: () => {
            return (
              <DeviceTimelineHeader reportLength={keyedData.length} />
            )
          },
        }
      }
    });

        return (
            <>
                <Table
                    id={id}
                    onRow={(record, recordIndex) => ({
                        onClick: (e) => {
                          const isMac = navigator.userAgent.toLowerCase().indexOf('mac') > -1;
                          onRowClick(record, isMac ? e.metaKey : e.ctrlKey);
                        },
                      onMouseDown: (e) => {
                        if (e.button === 1) { //middle click
                          onRowClick(record, true);
                        }
                      }
                    })}
                    onChange={onChange}
                    pagination={pagination}
                    dataSource={keyedData}
          columns={mappedColumns}
          expandedRowRender={expandedRowRender} 
        />
            </>
        );
    }
}

TableView.defaultProps = {
    dataSource: [],
    columns: [],
    pagination: {},
    onChange: () => {},
    onRowClick: () => {},
    id: '',
    expandedRowRender: null,
};

TableView.propTypes = {
    dataSource: PropTypes.array,
    columns: PropTypes.array,
    pagination: PropTypes.object,
    onChange: PropTypes.func,
    onRowClick: PropTypes.func,
    id: PropTypes.string,
    expandedRowRender: PropTypes.func,
};

export default TableView;
