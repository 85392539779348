import React from 'react';
import { Form, Input, Button, Spin } from 'antd';

const FormItem = Form.Item;

export default function () {
    const { user, form } = this.props;
    const { getFieldDecorator } = form;

    return (
        <Spin spinning={user.isFetching}>
            <Form onSubmit={this.handleSubmit} layout="vertical" className="form mt-3">
                <FormItem label="Your old password:">
                    {getFieldDecorator('old_password', {
                        rules: [
                            {
                                required: true,
                                message: 'Please enter old password!',
                            }
                        ],
                    })(<Input type="password" size="large" />)}
                </FormItem>
                <FormItem label="Your new password:">
                    {getFieldDecorator('new_password', {
                        rules: [
                            {
                                required: true,
                                message: 'Please enter new password!',
                            },
                            {
                                min: 8,
                                message: 'A new password must be at least 8 characters length!',
                            },
                            {
                                pattern: /\d+/,
                                message: " 1 digit",
                            },
                            {
                                pattern: /[A-Z]+/,
                                message: " 1 uppercase",
                            },
                            {
                                pattern: /[a-z]+/,
                                message: " 1 lowercase",
                            },
                            {
                                pattern: /[!#$@%\-_=+<>\'"`£^&¬\.*\(\)\[\]\?:;\/,{}]/,
                                message: " 1 special character",
                            }
                        ],
                    })(<Input type="password" size="large" />)}
                </FormItem>
                <FormItem label="Your new password (again):">
                    {getFieldDecorator('new_password_confirm', {
                        rules: [
                            {
                                required: true,
                                message: 'Please confirm new password!',
                            },
                            {
                                min: 8,
                                message: 'A new password must be at least 8 characters length!',
                            },
                            {
                                pattern: /\d+/,
                                message: " 1 digit",
                            },
                            {
                                pattern: /[A-Z]+/,
                                message: " 1 uppercase",
                            },
                            {
                                pattern: /[a-z]+/,
                                message: " 1 lowercase",
                            },
                            {
                                pattern: /[!#$@%\-_=+<>\'"`£^&¬\.*\(\)\[\]\?:;\/,{}]/,
                                message: " 1 special character",
                            }
                        ],
                    })(<Input type="password" size="large" />)}
                </FormItem>
                <div className="text-center mt-5">
                    <Button type="primary" htmlType="submit" size="large">
                        Change Password
                    </Button>
                </div>
            </Form>
        </Spin>
    );
}
