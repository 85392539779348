/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { NavLink } from 'react-router-dom';
import {
    Row, Col, Layout, Menu as AntMenu, Dropdown, Icon as AntIcon,
    Modal, Select,
} from 'antd';
import lockr from 'lockr';

import history from '../../../core/utils/history';
import srcFleetclearLogo from '../../../assets/images/fleetclear-logo.svg';
import Menu from '../Menu';
import Icon from '../../elements/Icon';

import './header.scss';

const { MAP_SLIDER_INTERVAL, SHARD_KEY } = require('../../../core/constants').default;

const { Option } = Select;

export default function () {
    const {
        backLink,
        title,
        menuPlacement,
        menuWidth,
        floating,
        beforeHeader,
        afterHeader,
        user,
        companies,
    } = this.props;

    const { changeCompanyModalVisible } = this.state;

    const { deviceGroups, userCompany } = user;

    const allowedRoles = ['super-admin', 'admin', 'manager'];
    const customAllowedRoles = ['super-admin', 'admin', 'manager', 'user'];

    let isVideoVisible = false;
    if (user && user.permissions && (user.permissions.live_video === true || user.permissions.historic_video_downloading === true)) {
        isVideoVisible = true;
    }

    const navlist = [
        {
            label: 'Map', link: '/map', iconName: 'map', visible: true,
        },
        {
            label: 'Events', link: '/events', iconName: 'events', visible: true,
        },
        {
            label: 'Video', link: '/video/video-search', iconName: 'video', visible: isVideoVisible,
        },
        {
            label: 'Dashboard', link: '/dashboard', iconName: 'reports', visible: true,
        },
        {
            label: 'Assets', link: '/assets', iconName: 'drivers', visible: (customAllowedRoles.length ? customAllowedRoles.includes(user.role) : true),
        },
        {
            label: 'Drivers', link: '/drivers', antIcon: 'user', visible: (customAllowedRoles.length ? customAllowedRoles.includes(user.role) : true),
        },
        {
            label: 'Reports', link: '/reports', iconName: 'reports', visible: true,
        },
    ];

    const visibleItems = navlist.filter((item) => item.visible === true);

    const shardId = lockr.get(SHARD_KEY);

    let selectedDeviceGroup = {};
    if (deviceGroups.length > 1) {
        for (let i = 0; i < deviceGroups.length; i++) {
            const deviceGroup = deviceGroups[i];
            if (deviceGroup.id == shardId) {
                selectedDeviceGroup = deviceGroup;
            }
        }
    }

    const regionMenu = (
        <AntMenu>
            {deviceGroups.map((deviceGroup) => {
                if (deviceGroup.id != shardId) {
                    return <AntMenu.Item key={deviceGroup.id} onClick={this.handleShardChange}>{deviceGroup.label ? deviceGroup.label : deviceGroup.name}</AntMenu.Item>;
                }
                return null;
            })}
        </AntMenu>
    );

    const menu = (
        <AntMenu>
            <AntMenu.Item key="0" onClick={() => history.push('/profile')}><AntIcon type="user" /> My profile</AntMenu.Item>
            {allowedRoles.length && allowedRoles.includes(user.role)
                ? <AntMenu.Item key="1" onClick={() => history.push('/users')}><AntIcon type="lock" /> User management</AntMenu.Item>
                : null}
            <AntMenu.Item key="2" onClick={() => window.location = 'https://help.fleetclear.com/'}><AntIcon type="question" /> FleetClear Help</AntMenu.Item>
            <AntMenu.Item key="3" onClick={this.logout}><AntIcon type="logout" /> Logout</AntMenu.Item>
        </AntMenu>
    );

    const companiesSelect = (
        <Select
            onChange={(val) => { this.setState({ companySelectValue: val }); }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            style={{ width: '100%' }}
            placeholder="Select company"
            suffixIcon={
                <Icon name="triangle-down" />
            }>
            {
                companies.map((v, i) => {
                    if (v.id !== userCompany.id) {
                        /* eslint-disable-next-line react/no-array-index-key */
                        return <Option key={i} value={v.id}>{v.title}</Option>;
                    }
                    return null;
                })
            }

        </Select>
    );

    return (
        <div className={`header ${floating ? 'floating-header' : ''}`}>
            {beforeHeader}
            <Layout.Header className="header__inner">
                <Row type="flex" justify="space-between">
                    <Col>
                        {backLink}
                        {visibleItems.length > 0 ? (
                            <Menu
                                items={visibleItems}
                                logo={srcFleetclearLogo}
                                placement={menuPlacement}
                                width={menuWidth} />
                        ) : null}
                        <div className="header__logos">
                            <img src={srcFleetclearLogo} className="header__logo-fleet" alt="Fleetclear" />
                        </div>
                    </Col>
                    <Col>
                        <h3 className="header__title h-4">{title}</h3>
                    </Col>
                    <Col>
                        <Row type="flex" justify="space-between">

                            {user.role === 'super-admin' && (
                                <div className="region">
                                    <div className="region__details">
                                        <div className="profile__username">Company</div>
                                        <div className="profile__user-position">{userCompany.title}</div>
                                    </div>
                                    <div className="region__dropdown" onClick={() => { this.setState({ changeCompanyModalVisible: true }); }}>
                                        <Icon name="triangle-down" />
                                    </div>
                                </div>
                            )}
                            {deviceGroups.length > 1 && (
                                <div className="region">
                                    <div className="region__details">
                                        <div className="profile__username">Region</div>
                                        <div className="profile__user-position">{selectedDeviceGroup.label ? selectedDeviceGroup.label : selectedDeviceGroup.name}</div>
                                    </div>
                                    <Dropdown overlay={regionMenu} trigger={['click']} placement="bottomRight">
                                        <div className="region__dropdown">
                                            <Icon name="triangle-down" />
                                        </div>
                                    </Dropdown>
                                </div>
                            )}
                            <div className="profile">
                                <div className="profile__user">
                                    <div className="profile__username">{(user.profile && user.profile.full_name) || user.loginUser}</div>
                                    <div className="profile__user-position">{user.role}</div>
                                </div>
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <div className="profile__dropdown">
                                        <Icon name="person" />
                                        <Icon name="triangle-down" />
                                    </div>
                                </Dropdown>
                            </div>
                        </Row>
                    </Col>
                </Row>
                <nav className="navlist">
                    {visibleItems.map((item, itemIndex) => (
                        <NavLink
                            to={item.link}
                            className={`navlink ${item.className || ''}`}
                            activeClassName="is-active"
                            key={item.key || `nav-item-${itemIndex}`}
                            onClick={this.clickOnItem}>
                            {item.iconName ? (
                                <div className="navicon">
                                    <Icon name={item.iconName} />
                                </div>
                            ) : null}
                            {item.antIcon ? (
                                <div className="menu__nav-icon">
                                    <AntIcon type={item.antIcon} style={{ paddingRight: 8 }} />
                                </div>
                            ) : null}
                            <span>{item.label}</span>
                        </NavLink>
                    ))}
                </nav>
            </Layout.Header>
            <Modal
                visible={changeCompanyModalVisible}
                onCancel={() => this.setState({ changeCompanyModalVisible: false })}
                onOk={this.handleChangeCompany}
                okText="Change">
                <h3>Change Company</h3>
                {companiesSelect}
            </Modal>
            {afterHeader}
        </div>
    );
}
