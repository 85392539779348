import React from 'react';

const IdleSVG = () => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 396.9 396.9"
        width="1em"
        height="1em"
        xmlSpace="preserve">
        <g>
            <path d="M358.1,16H251.6c-12.6,0-22.8,10.2-22.8,22.8v106.4c0,12.6,10.2,22.8,22.8,22.8h106.4
		c12.6,0,22.8-10.2,22.8-22.8V38.8C380.9,26.2,370.7,16,358.1,16z M365.7,145.2c0,4.2-3.4,7.6-7.6,7.6H251.6c-4.2,0-7.6-3.4-7.6-7.6
		V38.8c0-4.2,3.4-7.6,7.6-7.6h106.4c4.2,0,7.6,3.4,7.6,7.6V145.2z" />
            <path d="M308.7,46.4h-34.2v91.2h15.2v-22.8h19c18.9,0,34.2-15.3,34.2-34.2C342.9,61.7,327.5,46.4,308.7,46.4z
		 M308.7,99.6h-19v-38h19c10.5,0,19,8.5,19,19C327.7,91.1,319.1,99.6,308.7,99.6z" />
            <rect x="107.2" y="46.4" width="106.4" height="15.2" />

            <rect x="92" y="270.3" transform="matrix(0.1644 -0.9864 0.9864 0.1644 -206.2463 343.4435)" width="15.2" height="46.2" />

            <rect x="274.1" y="285.9" transform="matrix(0.9864 -0.1644 0.1644 0.9864 -44.1995 52.8618)" width="46.2" height="15.2" />
            <rect x="145.2" y="289.7" width="106.4" height="15.2" />
            <path d="M193,200.7c-8.4,8.4-19.5,13-31.3,13H76.8v15.2h84.9c15.9,0,30.8-6.2,42.1-17.4c8.4-8.4,19.5-13,31.3-13h100.1
		V244H76.8v15.2h258.5v68.4c0,4.2-3.4,7.6-7.6,7.6H69.2c-4.2,0-7.6-3.4-7.6-7.6v-114H46.4v114c0,12.6,10.2,22.8,22.8,22.8v7.6
		c0,12.6,10.2,22.8,22.8,22.8h22.8c12.6,0,22.8-10.2,22.8-22.8v-7.6h121.6v7.6c0,12.6,10.2,22.8,22.8,22.8h22.8
		c12.6,0,22.8-10.2,22.8-22.8v-7.6c12.6,0,22.8-10.2,22.8-22.8V183.2H235.1C219.2,183.2,204.3,189.4,193,200.7z M122.4,358.1
		c0,4.2-3.4,7.6-7.6,7.6H92c-4.2,0-7.6-3.4-7.6-7.6v-7.6h38V358.1z M312.5,358.1c0,4.2-3.4,7.6-7.6,7.6h-22.8
		c-4.2,0-7.6-3.4-7.6-7.6v-7.6h38V358.1z" />
            <path d="M46.4,198.4h15.2V92h152.1V76.8H61.6V61.6c0-16.8,13.6-30.4,30.4-30.4h121.6V16H92
		c-25.2,0-45.6,20.5-45.6,45.6v15.2h-7.6C26.2,76.8,16,87,16,99.6V130h15.2V99.6c0-4.2,3.4-7.6,7.6-7.6h7.6V198.4z" />
        </g>
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default IdleSVG;
