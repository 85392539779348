import React from 'react';

const EasyReportAndAdminControlSVG = () => (
    <svg width={64} height={64} xmlns="http://www.w3.org/2000/svg">
        <g stroke="null">
            <path d="M30.227 39.091h3.545v7.091h-3.545zM39.091 35.545h3.545v10.636h-3.545zM21.364 28.455h3.545v17.727h-3.545z" />
            <path d="M47.955 12.5h-5.319v-1.773a3.545 3.545 0 00-3.545-3.545H24.909a3.545 3.545 0 00-3.545 3.545V12.5h-5.319a3.545 3.545 0 00-3.545 3.545v37.228a3.545 3.545 0 003.545 3.545h31.91a3.545 3.545 0 003.545-3.545V16.045a3.545 3.545 0 00-3.545-3.545zm-23.046-1.773h14.182v7.091H24.909v-7.09zm23.046 42.546h-31.91V16.045h5.319v5.319h21.272v-5.319h5.319v37.228z" />
        </g>
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default EasyReportAndAdminControlSVG;
