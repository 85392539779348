import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    notification, Input, Checkbox, Button, Spin,
} from 'antd';
import _ from 'underscore';
import {
    CheckOutlined, CloseOutlined, CheckSquareOutlined, BorderOutlined,
} from '@ant-design/icons';
import GridView from '../../../components/elements/GridView/index';
import Icon from '../../../components/elements/Icon';
import * as userActions from '../../../core/user/userActions';

class DriverPermissions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            divisionPermissionsSearchParams: {},
            divisionPermissionsOrderParams: {},
            driverPermissionsSearchParams: {
                has_access: false,
            },
            driverPermissionsOrderParams: {},
        };
        this.timeout = 0;
    }

    componentDidMount() {
        const { actions, userId } = this.props;

        actions.getDivisionDriverPermissionsGridRequest({ user_id: userId });
    }

    selectDivision = (division) => {
        const { actions, userId } = this.props;

        actions.saveDivisionRequest(division);
        this.setState({ divisionId: division.division_id });
        actions.getDriverPermissionsGridRequest({
            division_id: parseInt(division.division_id),
            user_id: userId,
            division_name: division.name,
        });
    }

    changeAccess = (driver, access) => {
        const { actions, userId } = this.props;

        const newAccess = access === 1 ? 0 : 1;
        actions.changeDriverUserPermissionRequest({
            access: newAccess,
            user_id: userId,
            driver_id: driver.driver_id,
            division_id: driver.division_id,
        });
    }

    addAccessToAllDivision(division, event) {
        event.stopPropagation();

        const {
            actions,
            userId,
            divisionDriverPermissionsPagination,
        } = this.props;

        actions.saveDivisionRequest(division);
        actions.addAccessToDivisionRequest({
            division_id: division.division_id,
            division_name: division.name,
            user_id: userId,
            type: 'add',
            data_type: 'drivers',
            page: divisionDriverPermissionsPagination.currentPage,
        });
    }

    removeAccessFromAllDivision(division, event) {
        event.stopPropagation();
        const {
            actions,
            userId,
            divisionDriverPermissionsPagination,
        } = this.props;

        actions.saveDivisionRequest(division);
        actions.addAccessToDivisionRequest({
            division_id: division.division_id,
            division_name: division.name,
            user_id: userId,
            type: 'remove',
            data_type: 'drivers',
            page: divisionDriverPermissionsPagination.currentPage,
        });
    }

    searchDivisions(keyword) {
        const { actions, userId } = this.props;

        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            actions.getDivisionDriverPermissionsGridRequest({
                user_id: userId,
                search: keyword,
            });
        }, 1000);
    }

    searchDrivers(keyword) {
        const { actions, userId, singleDivision } = this.props;
        const { driverPermissionsSearchParams } = this.state;

        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            driverPermissionsSearchParams.search = keyword;
            actions.getDriverPermissionsGridRequest({
                user_id: userId,
                division_id: singleDivision.division_id,
                driverPermissionsSearchParams,
            });
            this.setState(driverPermissionsSearchParams);
        }, 1000);
    }

    changeAccessSetting(access) {
        const { actions, userId, singleDivision } = this.props;
        const { driverPermissionsSearchParams } = this.state;

        driverPermissionsSearchParams.has_access = access;
        if (_.isEmpty(singleDivision)) {
            return notification.error({
                message: 'Error',
                description: 'Please select a division',
            });
        }
        actions.getDriverPermissionsGridRequest({
            user_id: userId,
            division_id: singleDivision.division_id,
            driverPermissionsSearchParams,
        });
        this.setState(driverPermissionsSearchParams);
    }

    render() {
        const {
            divisionDriverPermissions,
            driverPermissions,
            singleDivision,
            loading,
            actions,
            driverPermissionsPagination,
            divisionDriverPermissionsPagination,
        } = this.props;

        const leftData = divisionDriverPermissions.map((d) => {
            let color = 'black';
            const user_has_access = parseInt(d.user_has_access, 10);
            const total_drivers = parseInt(d.total_drivers, 10);
            if (user_has_access > 0 && user_has_access === total_drivers) {
                color = 'green';
            }
            if (user_has_access > 0 && user_has_access < total_drivers) {
                color = 'yellow';
            }

            return ({
                division_id: d.division_id,
                name: (
                    <div className={color}>
                        {color !== 'green' ? (
                            <>
                                <Button
                                    className="btn btn-empty"
                                    onClick={(event) => this.addAccessToAllDivision(d, event)}>
                                    <BorderOutlined style={{ fontSize: 18 }}/>
                                </Button>
                                <span className="ml-1" style={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                }}>{d.name}</span>
                            </>
                        ) : (
                            <>
                                <Button
                                    className="btn btn-empty"
                                    onClick={(event) => this.removeAccessFromAllDivision(d, event)}>
                                    <CheckSquareOutlined style={{ fontSize: 18 }}/>
                                </Button>
                                <span className="ml-1" style={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                }}>{d.name}</span>
                            </>
                        )}
                    </div>
                ),
                total_drivers: `${d.user_has_access}/${d.total_drivers}`,
                actions: (
                    <Button
                        onClick={() => this.selectDivision(d)}
                        className="btn btn-empty ml-2">
                        <Icon name="chevron-right"/>
                    </Button>
                ),
            });
        });
        const rightData = driverPermissions.map((d) => ({
            ...d,
            access: (
                <Button
                    type="link"
                    onClick={() => this.changeAccess(d, parseInt(d.access, 10))}>
                    {parseInt(d.access, 10) > 0 ? (
                        <CheckOutlined style={{ color: '#389e0d' }}/>
                    ) : (
                        <CloseOutlined style={{ color: '#f5222d' }}/>
                    )}
                </Button>
            ),
        }));

        const gridChangeDivisionPermissions = (pagination, filters, sorter) => {
            const {
                divisionPermissionsSearchParams,
                divisionPermissionsOrderParams,
            } = this.state;
            const { userId } = this.props;
            if (pagination && pagination.current === divisionDriverPermissionsPagination.currentPage) {
                divisionPermissionsOrderParams[sorter.field] = sorter.order ? sorter.order : false;
                this.setState({ divisionPermissionsOrderParams });
                actions.getDivisionDriverPermissionsGridRequest({
                    user_id: userId,
                    page: divisionDriverPermissionsPagination.currentPage,
                    divisionPermissionsSearchParams,
                    divisionPermissionsOrderParams,
                });
            }
        };
        const paginationChangeDivisionPermissions = (page) => {
            const {
                divisionPermissionsSearchParams,
                divisionPermissionsOrderParams,
            } = this.state;
            const { userId } = this.props;
            if (page !== divisionDriverPermissionsPagination.currentPage) {
                actions.getDivisionDriverPermissionsGridRequest({
                    user_id: userId,
                    page,
                    divisionPermissionsSearchParams,
                    divisionPermissionsOrderParams,
                });
            }
        };
        const gridChangeDriverPermissions = (pagination, filters, sorter) => {
            const {
                driverPermissionsSearchParams,
                driverPermissionsOrderParams,
            } = this.state;

            const {
                singleDivision,
                userId,
            } = this.props;
            if (pagination && pagination.current === driverPermissionsPagination.currentPage) {
                driverPermissionsOrderParams[sorter.field] = sorter.order ? sorter.order : false;
                this.setState({ driverPermissionsOrderParams });
                actions.getDriverPermissionsGridRequest({
                    user_id: userId,
                    division_id: singleDivision.division_id,
                    page: driverPermissionsPagination.currentPage,
                    driverPermissionsSearchParams,
                    driverPermissionsOrderParams,
                });
            }
        };
        const paginationChangeDriverPermissions = (page) => {
            const {
                driverPermissionsSearchParams,
                driverPermissionsOrderParams,
            } = this.state;
            const {
                singleDivision,
                userId,
            } = this.props;
            if (page !== driverPermissionsPagination.currentPage) {
                actions.getDriverPermissionsGridRequest({
                    user_id: userId,
                    division_id: singleDivision.division_id,
                    page,
                    driverPermissionsSearchParams,
                    driverPermissionsOrderParams,
                });
            }
        };

        return (
            <div className="division-tab-wraper">
                <div className="left-side">
                    <Input
                        placeholder="Search for a division"
                        onChange={(data) => this.searchDivisions(data.target.value)}/>
                    <Spin spinning={loading}>
                        <GridView
                            data={leftData}
                            onChange={gridChangeDivisionPermissions}
                            sortableColumns={['start_time', 'end_time', 'time_driven', 'driver', 'area_driven', 'journey_score', 'journey_events']}
                            pagination={{
                                total: parseInt(divisionDriverPermissionsPagination.totalRecords, 10),
                                showSizeChanger: false,
                                pageSize: divisionDriverPermissionsPagination.perPage,
                                onChange: paginationChangeDivisionPermissions,
                            }}/>
                    </Spin>
                </div>
                <div className="right-side">
                    <h4>Set driver permissions within {singleDivision.name}</h4>
                    <div className="search-bar">
                        <Input
                            placeholder="Search by name"
                            onChange={(data) => this.searchDrivers(data.target.value)}/>
                        <Checkbox onChange={(data) => this.changeAccessSetting(data.target.checked)}>
                            Show only drivers user has access to
                        </Checkbox>
                    </div>
                    <Spin spinning={loading}>
                        <GridView
                            data={rightData}
                            onChange={gridChangeDriverPermissions}
                            pagination={{
                                total: parseInt(driverPermissionsPagination.totalRecords, 10),
                                showSizeChanger: false,
                                pageSize: driverPermissionsPagination.perPage,
                                onChange: paginationChangeDriverPermissions,
                            }}/>
                    </Spin>
                </div>
            </div>
        );
    }
}

DriverPermissions.defaultProps = {
    userId: null,
    actions: {},
    divisionDriverPermissions: [],
    divisionDriverPermissionsPagination: {},
    divisionPermissionsIsFetching: true,
    driverPermissions: [],
    driverPermissionsPagination: {},
    singleDivision: {},
    loading: true,
};

DriverPermissions.propTypes = {
    userId: PropTypes.number,
    actions: PropTypes.object.isRequired,
    divisionDriverPermissions: PropTypes.array,
    divisionDriverPermissionsPagination: PropTypes.object.isRequired,
    divisionPermissionsIsFetching: PropTypes.bool.isRequired,
    driverPermissions: PropTypes.array,
    driverPermissionsPagination: PropTypes.object.isRequired,
    singleDivision: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        divisionDriverPermissions: state.user.divisionDriverPermissions,
        divisionDriverPermissionsPagination: state.user.divisionDriverPermissionsPagination,
        divisionPermissionsIsFetching: state.user.isFetching,
        driverPermissions: state.user.driverPermissions,
        driverPermissionsPagination: state.user.driverPermissionsPagination,
        singleDivision: state.user.singleDivision,
        loading: state.user.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DriverPermissions);
