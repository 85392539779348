import React from 'react';

const AutoStopLogoColourSVG = () => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1179.1 396.9"
        width="1em"
        height="1em"
        xmlSpace="preserve">
        <g>
            <path d="M535.9,307.1h-9.4V305h3.6v-16.5h-3.6v-2.1h9.4v2.1h-3.4V305h3.4V307.1z" />
            <path d="M561.7,307.1H559l-7.4-16.3v16.3h-2.4v-20.7h2.7l7.5,16.2v-16.2h2.4V307.1z" />
            <path d="M585.8,307.1h-2.7l-7.4-16.3v16.3h-2.4v-20.7h2.7l7.5,16.2v-16.2h2.4V307.1z" />
            <path d="M603.7,286c2.3,0,4.1,0.9,5.3,2.7c1.2,1.8,1.8,4.5,1.8,8c0,3.5-0.6,6.1-1.8,8c-1.2,1.8-3,2.8-5.3,2.8
		c-2.3,0-4.1-0.9-5.3-2.8c-1.2-1.8-1.8-4.5-1.8-8c0-3.5,0.6-6.2,1.8-8C599.6,287,601.4,286,603.7,286z M603.7,305.4
		c3.1,0,4.6-2.9,4.6-8.6c0-5.8-1.5-8.6-4.6-8.6c-1.5,0-2.6,0.7-3.4,2.2c-0.8,1.5-1.2,3.6-1.2,6.4c0,2.8,0.4,4.9,1.2,6.4
		C601,304.7,602.2,305.4,603.7,305.4z" />
            <path d="M636,286.4l-6.7,20.7h-2.9l-6.8-20.7h2.5l5.7,17.9l5.7-17.9H636z" />
            <path d="M660.2,307.1h-2.5l-2.3-7.2h-6.8l-2.3,7.2h-2.5l6.8-20.7h2.9L660.2,307.1z M654.7,297.8l-2.7-8.6l-2.7,8.6
		H654.7z" />
            <path d="M683.7,288.4h-6.4v18.7h-2.4v-18.7h-6.4v-1.9h15.2V288.4z" />
            <path d="M704.8,307.1h-9.4V305h3.6v-16.5h-3.6v-2.1h9.4v2.1h-3.4V305h3.4V307.1z" />
            <path d="M732.5,286.4l-6.7,20.7h-2.9l-6.8-20.7h2.5l5.7,17.9l5.7-17.9H732.5z" />
            <path d="M754,307.1h-10.8v-20.7h10.7v2.1h-8.3v6.4h8v2.1h-8v8h8.4V307.1z" />
            <path d="M789.3,288.7c-1.2-0.4-2.4-0.6-3.5-0.6c-1.3,0-2.3,0.3-3.1,0.9c-0.8,0.6-1.2,1.4-1.2,2.4c0,1.3,1,2.4,3.1,3.3
		c1.5,0.7,2.5,1.2,3,1.5c0.5,0.3,1,0.7,1.5,1.1c0.5,0.5,0.9,1,1.2,1.7c0.3,0.6,0.5,1.4,0.5,2.2c0,2-0.7,3.6-2,4.7
		c-1.4,1.1-2.9,1.7-4.8,1.7c-1.8,0-3.3-0.4-4.7-1.1l0.5-2.1c1.6,0.7,2.9,1.1,4.1,1.1c1.4,0,2.5-0.4,3.3-1.1c0.8-0.7,1.2-1.7,1.2-2.9
		c0-0.9-0.3-1.7-0.8-2.3c-0.5-0.6-1.5-1.3-3-2c-1.5-0.7-2.4-1.2-2.9-1.4c-0.4-0.2-0.9-0.6-1.3-1c-0.4-0.4-0.8-0.9-1-1.5
		c-0.2-0.5-0.4-1.1-0.4-1.8c0-1.7,0.6-3,1.8-4c1.2-1,2.8-1.4,4.7-1.4c1.4,0,2.7,0.2,4.1,0.6L789.3,288.7z" />
            <path d="M817.2,307.1h-2.5l-2.3-7.2h-6.8l-2.3,7.2h-2.5l6.8-20.7h2.9L817.2,307.1z M811.7,297.8l-2.7-8.6l-2.7,8.6
		H811.7z" />
            <path d="M838.8,288.5h-8.6v6.8h7.8v2.1h-7.8v9.7h-2.4v-20.7h11V288.5z" />
            <path d="M862.8,307.1H852v-20.7h10.7v2.1h-8.3v6.4h8v2.1h-8v8h8.4V307.1z" />
            <path d="M889,288.4h-6.4v18.7h-2.4v-18.7h-6.4v-1.9H889V288.4z" />
            <path d="M913.2,286.4l-6.5,11.8v8.9h-2.4v-8.9l-6.5-11.8h2.7l4.9,9.3l5-9.3H913.2z" />
        </g>
        <path
            className="st1"
            d="M1037.7,183.5c-0.5,2.8-1.2,5.6-2,8.5c-0.5,1.6-1,3.1-1.5,4.7c-0.5,1.6-1,3.1-1.5,4.5c-0.8,2.5-1.6,4.8-2.5,6.8
	c-0.8,2.1-1.5,3.8-2.1,5.3c-2.5,6.5-4.9,11-7.4,13.4c-2.4,2.4-4.9,3.8-7.4,4.2c-0.6,0.1-1.3,0.2-1.8,0.3c-0.6,0.1-1.2,0.1-1.9,0.1
	h-1.2h-1h-1.2h-1.2h-1.2h-1.2h-17.2l13.6-66.4c0.2-0.1,0.3-0.2,0.5-0.3c0.6-0.4,1.1-0.6,1.6-0.8c0.3-0.1,0.5-0.2,0.9-0.3
	c0.3-0.1,0.6-0.1,0.9-0.1c1.6-0.4,3.1-0.8,4.8-1.2c1.6-0.4,3.2-0.7,4.8-1c1.3-0.3,2.6-0.5,3.9-0.6c1.3-0.1,2.6-0.2,3.9-0.2
	c3.8-0.1,7.2,0.6,10.4,2.1c3.1,1.6,5.4,4.5,6.9,8.8c0.3,0.5,0.5,1.2,0.6,2c0.1,0.8,0.2,1.6,0.2,2.3
	C1038.5,178.1,1038.3,180.7,1037.7,183.5 M1069.6,163.9c0-0.4,0-0.8-0.1-1.3c0-0.5,0-0.9-0.1-1.5c-0.3-5.1-1.3-10-2.9-14.7
	c-1.6-4.7-4.8-8.2-9.4-10.6c-1.5-0.6-3-1.2-4.8-1.8c-1.7-0.5-3.4-0.9-5.1-1.2c-0.7-0.1-1.3-0.2-2.1-0.3c-0.7-0.1-1.4-0.1-2.1-0.1
	h-19c-8.9,0-12.1,5.9-12.1,5.9l-10.4,14.6l2.8-13.5c1.5-7.3-10.1-7.4-10.1-7.4h-0.8h-0.8h-29.7l-0.9,5.7c0,0,11.1,1,9.2,8.8
	l-28.8,134.5c0,0-1.8,8.8,8.4,8.8l29.1-0.2l1.7-8c0,0-5.7,0.3-5.7-3.8l4.8-21.3h34.8c3.8,0,7.3-0.1,10.5-0.4c3.2-0.3,6.2-1,8.9-2.3
	c6.5-3.6,12.1-10,16.9-19c4.7-9,8.5-18.5,11.2-28.6c2.4-8.3,4.1-16.4,5.1-24.1C1069.4,174.8,1069.8,168.6,1069.6,163.9" />
        <path
            className="st1"
            d="M751.3,125.7h-29.3c-0.4,0-0.8,0-1.2,0.1c-0.4,0.1-0.8,0.1-1.2,0.1h-1.4h-1.2c-3.5,0-6.7,0.5-9.7,1.5
	c-2.9,1-5,3.9-6.3,8.9c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.2,0.6-0.2,0.9c-0.4,1.4-0.7,2.8-0.9,4.2c-0.2,1.4-0.4,2.7-0.3,4
	c0.2,2.5,1,4.8,2.4,6.8c1.5,2.1,4.3,3.9,8.5,5.5c0.5,0.1,1,0.3,1.6,0.4c0.5,0.1,1,0.3,1.6,0.4c2.5,0.7,5.2,1.3,8.1,2
	c2.9,0.7,6,1.5,9.1,2.5c3.9,1.3,7.7,3,11.4,5.1c3.7,2.1,6.9,4.9,9.7,8.4c1.3,1.8,2.3,4.3,2.9,7.5c0.6,3.2,1,6.7,1,10.7
	c0,2.6-0.1,5.3-0.3,8.1c-0.2,2.8-0.5,5.6-0.9,8.3c-1.1,7-2.8,13.7-5.2,20c-2.4,6.3-5.3,11-8.6,14.3c-0.4,0.4-0.8,0.8-1.3,1.1
	c-0.5,0.3-0.9,0.7-1.5,1.1c-3.6,2.7-7.4,4.9-11.4,6.4c-4,1.5-8.5,2.4-13.6,2.6h-66.4c-5.6,0-6.8-3.4-7-4.1c-0.3-1-0.6-1.8-0.1-3.9
	l7.5-29.5h4.1c-0.1,0.5-0.3,1-0.4,1.6c-0.1,0.5-0.2,1.1-0.2,1.8c0,1.2,0.1,2.4,0.6,3.3c2.5,4.3,7.6,4.7,7.6,4.7h14.2
	c6.6,0,13.1-0.2,19.3-0.5c6.2-0.3,11-1.2,14.3-2.6c3.1-1,5.5-3.1,7.2-6.3c1.7-3.1,2.9-6.5,3.6-10c0.1-0.7,0.2-1.3,0.3-2.1
	c0.1-0.7,0.1-1.4,0.3-2.1c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.3,0.2-0.6c0-0.1,0-0.3,0-0.5c0-0.2,0-0.4,0-0.5c-0.1-4.3-2.2-7.5-6.5-9.5
	c-4.3-2-9.2-3.7-14.8-5.2c-1-0.3-2.1-0.5-3-0.8c-1-0.3-2-0.6-3-1c-6.8-1.8-13.1-4.4-18.8-7.8c-5.7-3.4-8.6-9.1-8.7-17.2
	c0-0.3,0-0.5,0-0.6c0-0.1,0-0.3,0-0.6c0-0.1,0-0.3,0-0.5c0-0.2,0-0.4,0-0.5c0-0.3,0-0.5,0-0.8c0-0.3,0-0.5,0-0.8
	c0.4-7.8,1.3-15.6,2.6-23.2c1.3-7.6,4.9-15.2,10.8-22.8c0.6-0.8,1.4-1.6,2.2-2.5c0.8-0.9,1.8-1.8,2.8-2.7c2.2-2.1,5.1-4,8.8-5.8
	c3.7-1.8,8.6-2.6,14.7-2.6h0.5h0.5h57.7l-4.3,19.8C758.4,126.1,751.3,125.7,751.3,125.7" />
        <path
            className="st1"
            d="M826.9,227.8l4.4,0.1l-5.3,22.9c-0.1,0.3-0.3,0.6-0.4,0.9c-0.1,0.3-0.3,0.6-0.4,0.9c-0.4,0.7-0.8,1.2-1.2,1.8
	c-0.4,0.5-0.8,0.9-1.3,1.2c-0.3,0.1-0.5,0.2-0.6,0.2c-0.3,0.1-0.5,0.3-0.9,0.4c-0.3,0.1-0.7,0.3-1.1,0.4c-0.5,0.1-1.1,0.3-1.8,0.4
	c-0.7,0.1-1.4,0.2-2.2,0.2l-49.6,0.2c-0.6-0.1-1.3-0.3-2-0.6c-0.7-0.3-1.3-0.6-1.8-1c-0.1-0.3-0.3-0.5-0.5-0.6
	c-0.2-0.1-0.4-0.3-0.5-0.4c-0.8-0.7-1.4-1.5-2-2.7c-0.6-1.1-0.9-2.3-1.1-3.4v-0.5v-0.5l19.8-91.2h-10.4l4.4-22.4
	c3.7,0,7-0.4,9.8-1.2c2.8-0.8,5.6-1.9,8.5-3.3c0.9-0.4,1.8-0.9,2.7-1.4c0.9-0.5,1.8-1.1,2.7-1.6c3.7-2.2,6.9-5,9.4-8.5
	c2.6-3.4,4.6-7.1,6-11.2h10.6l-4.6,23.2h25.8l-4.3,19.9c-1.4,6.7-7.9,6.5-10.4,6.5h-10.4c-0.5,0-1,0-1.3,0.1
	c-0.4,0.1-0.8,0.1-1.2,0.1c-0.8,0.1-1.4,0.3-2,0.4c-0.6,0.1-1.1,0.4-1.4,0.8c-0.3,0.1-0.4,0.3-0.6,0.5c-0.1,0.2-0.3,0.4-0.4,0.5
	c-0.4,0.4-0.6,0.8-0.8,1.3c-0.1,0.5-0.3,1-0.4,1.5c-0.1,0.3-0.2,0.5-0.2,0.8c0,0.3-0.1,0.5-0.2,0.8l-13.7,59.8v0.9v0.9
	c0,0.7,0,1.3,0.1,2c0.1,0.7,0.2,1.1,0.5,1.4c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.1,0.3,0.1,0.4c0.4,0.8,1,1.5,1.7,2.2
	c0.8,0.7,1.7,1,2.7,1h20c0.6,0,2.1-0.1,3.3-0.8C826.4,229.9,826.9,227.8,826.9,227.8" />
        <path
            className="st1"
            d="M913,203.7c-3.4,14.5-5,19.5-7.3,23.2c-3.7,6.1-9.3,9.1-17.1,9.1c-9.5,0-15.2-5.1-15.2-13.7
	c0-4.1,2.2-17.4,7-38.1c3.7-16.6,4.9-19.3,5.9-21.7l0.3-0.7c3.3-6.7,9.8-10.4,18.3-10.4c9.4,0,15.2,5.3,15.2,13.7
	C920,169.3,915.3,194.7,913,203.7 M942.7,136.3c-6.7-3.6-18.1-5.4-35.1-5.4c-39.2,0-49.3,10-60.3,59.7c-4.3,19.5-6.1,31.6-6.1,40.4
	c0,16.3,7.3,26.3,43.5,26.3c41.1,0,50.2-8.5,61.2-57c4.5-19.5,6.4-32.2,6.4-42.6C952.2,147.4,948.8,139.8,942.7,136.3" />
        <polygon className="st2" points="596,199.9 600.1,180.6 650.8,180.6 646.5,199.9 " />
        <g>
            <path d="M159.2,131.7c2.4-5,3.5-7.6,3.5-9.4c0-2.8-2.2-4.6-6.3-5l1.3-5.5h58.4c5.5,0,7.8,0.9,9.4,3.3c0.9,1.7,1.1,3.3,1.3,10.2
		l5.7,116.9c0.4,5.9,2.8,8.5,8.5,9.6l-1.3,5.5h-40.3c-10,0-11.6-1.5-11.8-10.9l-0.7-23.3h-37.5l-7.6,17c-1.1,2.4-1.7,4.4-1.7,5.9
		c0,3.3,2,5,6.3,5.9l-1.1,5.5H107c-5.9,0-8.5-1.7-8.5-5.7c0-1.7,0.4-3.5,1.7-5.9L159.2,131.7z M186.2,197.8l-1.5-53l-24,53H186.2z" />
            <path d="M276,257.9c-20.5,0-27.9-4.4-27.9-16.1c0-2.6,0.4-6.1,1.5-10.7l16.6-72.4c0.9-3.5,1.1-4.6,1.1-6.5c0-3.5-2.4-5.7-6.5-6.1
		l1.1-5h37.7c7.4,0,10.7,2,10.7,6.8c0,1.3-0.2,2-0.9,5l-13.5,59.1c-1.1,5-1.3,6.3-1.3,7.8c0,4.4,2.6,6.5,7.6,6.5
		c8.5,0,12-3.5,14.8-15l12-52.6c0.7-3.3,0.9-4.8,0.9-6.3c0-3.5-2.4-5.9-6.5-6.3l1.3-5h37.5c7.4,0,10.7,2,10.7,6.5
		c0,1.3-0.2,3.7-0.7,5.2l-19.8,86.6c-0.9,3.5-1.1,5-1.1,6.5c0,3.5,2.4,5.9,6.5,6.3l-1.1,5H319c-7.4,0-10.7-2.2-10.7-6.8
		c0-1.3,1.3-6.1,2-10.5C300.2,255.5,295.2,257.9,276,257.9z" />
            <path d="M393.4,167.2h-10.2l5-22.5c18.5-1.1,32.7-10.2,39.7-25.7h18.5l-5,22h28.1l-3.7,16.6c-1.7,7.6-3.9,9.6-12,9.6h-9.8
		c-7.2,0-9.2,1.7-10.7,8.9l-8.7,37.9c-1.1,4.1-1.3,5.9-1.3,7.6c0,5,3.1,6.3,13.3,6.3c9.4,0,11.1-1.7,14.4-8.5h5.5l-6.8,28.8
		c-1.7,6.8-3.9,8.7-10.9,8.9h-47.1c-10.9,0-15.5-3.5-15.5-12c0-1.3,0.2-2.8,0.4-4.1L393.4,167.2z" />
            <path d="M571.7,144.8c7.4,3.7,11.3,11.6,11.3,21.8c0,8.9-1.7,20.3-6.5,38.8c-11.6,45.1-22.2,53.4-65.4,53.4
		c-34.2,0-47.5-7.4-47.5-26.6c0-8.3,2-19.4,6.3-36.9c11.6-46,22.9-55.8,64.5-55.8C552.8,139.5,564.5,141.3,571.7,144.8z
		 M517.6,173.3c-0.9,2-1.7,3.3-5.2,18.1c-4.6,18.5-6.1,28.6-6.1,31.2c0,5.5,3.5,8.5,10,8.5c5.5,0,9.4-1.7,12.2-6.1
		c1.7-2.6,3.1-6.3,6.1-18.5c2.2-8.1,6.1-28.6,6.1-31.6c0-5.5-3.7-8.5-10-8.5C524.6,166.4,520,168.8,517.6,173.3z" />
        </g>
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default AutoStopLogoColourSVG;
