/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import {
    DatePicker,
    Form,
    Input,
    Select,
    Col,
} from 'antd';
import { isEmpty, isUndefined } from 'underscore';
import Sticky from 'react-sticky-el';
import 'rc-color-picker/assets/index.css';
import { connect } from 'react-redux';
import moment from 'moment';

import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ColorPicker from 'rc-color-picker';

import * as dashboardActions from '../../../../core/dashboard/dashboardActions';
import * as smartWidgetActions from '../../../../core/smartWidget/smartWidgetActions';

import Icon from '../../../elements/Icon';
import ChartMultiDropDown from '../../ChartComponents/ChartMultiDropDown';
import IconWithValues from '../../ChartComponents/IconWithValues';
import ColorRule from '../ColorRule';
import IconPicker from '../IconPicker';
import { getChartIconList } from '../../../../core/utils/functions';

const { Option } = Select;
const FormItem = Form.Item;

class IconWithValuesForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    getPreviewData = () => {
        const { form } = this.props;
        const { getFieldValue } = form;

        const input_data = getFieldValue('input_data');
        const mappedIcons = getChartIconList();

        return input_data.map( (data) => (
            {
                ...data,
                icon: mappedIcons[data.icon],
            }
        ));
    }

    onInputUpdates = () => {
        const { getChartDataFromInput } = this.props;

        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            getChartDataFromInput();
        }, 700);
    }

    getDataSet = () => {
        const { form, getChartDataFromInput, data } = this.props;
        const { getFieldDecorator, getFieldValue } = form;

        const formulas = [
            {
                name: 'Highest value found',
                key: 1,
            },
            {
                name: 'Average (mean) * default',
                key: 2,
            },
            {
                name: 'Average (median)',
                key: 3,
            },
            {
                name: 'Max value in data',
                key: 4,
            },
            {
                name: 'Min value in data',
                key: 5,
            },
            {
                name: 'Sum of all values',
                key: 6,
            },

        ];
        const formats = [
            {
                name: 'Integer',
                key: 1,
            },
            {
                name: 'Decimal',
                key: 2,
            },
            {
                name: 'Hours & Mins',
                key: 3,
            },
            {
                name: 'Days & Hours',
                key: 4,
            },
            {
                name: 'Miles',
                key: 5,
            },
            {
                name: 'Km',
                key: 6,
            },
            {
                name: 'Kg',
                key: 7,
            },

        ];

        let fieldCounter = getFieldValue('input_dataList');
        const input_data = getFieldValue('input_data');
        let eventType = '';
        let displayReportCacheFields = true;

        if (isEmpty(fieldCounter)) {
            fieldCounter = [0, 1];
        }

        return fieldCounter.map((k, num) => {

            let label_text = '';
            let colorRuleData = [];
            let color_type = getFieldValue(`input_data[${k}][color_type]`);
            let number_format = null;
            let formula = null;
            let defaultIcon = 1;

            if(!isUndefined(data) && !isEmpty(data[k])) {
                label_text = data[k].label_text;
                colorRuleData = (data[k]?.color_type == 'smart-rule') ? data[k].rules : data[k].color;
                color_type = data[k]['color_type'];
                number_format = parseInt(data[k].number_format);
                formula = parseInt(data[k].formula);

                if (data[k].primary_icon) {
                    defaultIcon = parseInt(data[k].primary_icon, 10);
                }
            }

            if (!isUndefined(input_data[num])) {
                eventType = input_data[num].event_type;
            }
            displayReportCacheFields = [1, 2, 3, 4, 5, 6, 7, 8, 9].includes(parseInt(eventType, 10));

            return (
                <div key={k}>
                    <div className="ant-col ant-form-item ant-form-item-label">
                        <label title={`Data ${num + 1}`}>Data {num + 1}</label>
                    </div>

                    <div className="data-box">
                        <IconPicker
                            defaultIcon={defaultIcon}
                            onChange={() => getChartDataFromInput()}
                            form={form}
                            name={`input_data[${k}][icon]`} />
                        <br />

                        <FormItem label="label text">
                            {getFieldDecorator(`input_data[${k}][label_text]`, {
                                rules: [
                                    {
                                        // required: true,
                                        message: 'Please enter label text',
                                    },
                                ],
                                initialValue: label_text
                            })(
                                <Input
                                    placeholder="label text"
                                    onChange={() => this.onInputUpdates()} />,
                            )}
                        </FormItem>
                        <br />

                        <ColorRule
                            onChange={() => getChartDataFromInput()}
                            form={form}
                            mode={color_type}
                            data={colorRuleData}
                            name={`input_data[${k}]`}/>

                        {displayReportCacheFields ? (
                            <>
                                <FormItem label="Number Format">
                                    {getFieldDecorator(`input_data[${k}][number_format]`, {
                                        rules: [],
                                        initialValue: number_format,
                                    })(
                                        <Select
                                            onChange={() => getChartDataFromInput()}
                                            style={{ width: '100%' }}
                                            placeholder="Number Format"
                                            suffixIcon={
                                                <Icon name="triangle-down" />
                                            }>
                                            {
                                                formats.map((v, i) => (
                                                    /* eslint-disable-next-line react/no-array-index-key */
                                                    <Option key={i} value={v.key}>{v.name}</Option>
                                                ))
                                            }

                                        </Select>,
                                    )}
                                </FormItem>

                                <FormItem label="Formula">
                                    {getFieldDecorator(`input_data[${k}][formula]`, {
                                        rules: [],
                                        initialValue: formula,
                                    })(
                                        <Select
                                            onChange={() => getChartDataFromInput()}
                                            style={{ width: '100%' }}
                                            placeholder="Formula"
                                            suffixIcon={
                                                <Icon name="triangle-down" />
                                            }>
                                            {
                                                formulas.map((v, i) => (
                                                    /* eslint-disable-next-line react/no-array-index-key */
                                                    <Option key={i} value={v.key}>{v.name}</Option>
                                                ))
                                            }

                                        </Select>,
                                    )}
                                </FormItem>
                            </>
                        )

                            : <p style={{ fontWeight: 'bold', textAlign: 'left' }}>Formula = Event Count</p>}
                    </div>
                    <br />
                </div>
            );
        });
    }

    setColor(name, color) {
        const { form, getChartDataFromInput } = this.props;
        if (color && color.color) {
            form.setFieldsValue({ [name]: color.color });
            getChartDataFromInput();
        }
    }

    render() {
        const { form, saveDatatypeValues, getChartDataFromInput, division, driver, asset, setTimeframe, smartWidget, data } = this.props;
        const { getFieldDecorator, getFieldValue } = form;

        const dataSet = this.getDataSet();

        let preview = (
            <div className="smart-widget-preview">
                <h2>Widget Preview</h2>
                <FormItem className="widget-preview-date">
                    {getFieldDecorator('date', {
                        initialValue: moment(new Date(), 'DD/MM/YYYY'),
                        rules: [
                        ],
                    })(
                        <DatePicker
                            onChange={() => getChartDataFromInput()}
                            format="DD/MM/YYYY" />,
                    )}
                </FormItem>
                <br />

                <IconWithValues
                    heightRatio={2}
                    timeframe={smartWidget?.presetWidget?.timeframe || 'hourly'}
                    onTimeframeChange={(timeframe) => setTimeframe(timeframe)}
                    rightMenuItems={[]}
                    tooltipText={smartWidget?.presetWidget?.name || 'This is tooltip text'}
                    data={smartWidget.chartData}
                    // data1Label={getFieldValue('input_data[1][label_text]')}
                    // data1Icon={mappedIcons[getFieldValue('input_data[1][icon]')]}
                    // data2Label={getFieldValue('input_data[2][label_text]')}
                    // data2Icon={mappedIcons[getFieldValue('input_data[2][icon]')]}
                    onAllOptionChange={(optionsData) => { saveDatatypeValues(optionsData); getChartDataFromInput(); }}
                    divisions={[...division.divisionOptions]}
                    drivers={[...driver.driverList]}
                    assets={[...asset.options]} />

            </div>
        );

        const isSticky = window.innerWidth > 767;
        if(isSticky){
            preview = <Sticky>{ preview }</Sticky>;
        }

        let defaultIcon = 1;
        if(typeof data !== 'undefined') {
            defaultIcon = parseInt(data[0].icon);
        }

        let color = '#36c';
        if (data && data[0] && data[0].primary_icon_color) {
            color = data[0].primary_icon_color;
        }

        return (
            <>
                <Col className="gutter-row first-col" xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="input-data-col">


                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                            <IconPicker
                                defaultIcon={defaultIcon}
                                form={form}
                                onChange={() => getChartDataFromInput()}
                                name="input_data[0][primary_icon]"
                                label="Primary Icon" />

                            <FormItem className="icon-position-checkbox" style={{ marginTop: 45, marginLeft: 10 }}>
                                {getFieldDecorator('input_data[0][primary_icon_color]', {
                                    initialValue: color,
                                    rules: [],
                                })(
                                    <ColorPicker
                                        animation="slide-up"
                                        defaultColor={color}
                                        onClose={(newColor) => this.setColor('input_data[0][primary_icon_color]', newColor)} />
                                )}
                            </FormItem>
                        </div>

                        <br />

                        { dataSet }

                        <p className="field-label">Default data view</p>

                        <ChartMultiDropDown
                            onDataChange={(optionsData) => saveDatatypeValues(optionsData)}
                            divisions={[...division.divisionOptions]}
                            drivers={[...driver.driverList]}
                            assets={[...asset.options]} />
                    </div>
                </Col>
                <Col className="smart-widget-preview-row gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}>
                    { preview }
                </Col>
            </>
        );
    }
}

IconWithValuesForm.defaultProps = {
    saveDatatypeValues: () => {},
    getChartDataFromInput: () => {},
    setTimeframe: () => {},
};

IconWithValuesForm.propTypes = {
    actions: PropTypes.object.isRequired,
    smartWidget: PropTypes.object.isRequired,
    division: PropTypes.object.isRequired,
    driver: PropTypes.object.isRequired,
    asset: PropTypes.object.isRequired,
    saveDatatypeValues: PropTypes.func,
    getChartDataFromInput: PropTypes.func,
    setTimeframe: PropTypes.func,
    // newImageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        // newImageId: state.smartWidget.newImageId,
        smartWidget: state.smartWidget,
        division: state.division,
        driver: state.driver,
        asset: state.asset,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...dashboardActions,
            ...smartWidgetActions
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(IconWithValuesForm);
