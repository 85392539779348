/* eslint-disable react/destructuring-assignment */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DOM from './timesheetReport';

import * as eventsActions from '../../../../core/events/eventsActions';
import * as reportActions from '../../../../core/report/reportActions';
import * as assetActions from '../../../../core/asset/assetActions';
import moment from 'moment/moment';

class TimesheetReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {},
            filterSidebar: true,
            journeyOrDay: 'days',
            softJourneyOrDay: 'days',
            imperialUnit: false,
            reportHasBeenFetched: false,
            order: {},
        };
        this.view = DOM;
    }

    componentDidMount() {
        const { actions, dataEventToReportParams } = this.props;
        actions.resetReportsRequest();

        if (dataEventToReportParams !== null) {
            const params = {
                asset_or_driver: 'assets',
                ...dataEventToReportParams,
                page: 1,
            };

            if (dataEventToReportParams.asset_id) {
                params.asset_info = [dataEventToReportParams.asset_id];
            }

            if (dataEventToReportParams.driver_id) {
                params.driver = [dataEventToReportParams.driver_id];
            }

            actions.getTimesheetReportRequest(params);
            actions.setDataEventToReportParams(null);

            if (dataEventToReportParams.driver_id) {
                params.driver = [parseInt(dataEventToReportParams.driver_id)];
            }
            this.setState({ filters: params, filterSidebar: false });
        } else {
            this.onEventsTabFilterChange({
                asset_or_driver: 'assets',
                journey_or_day: 'days',
                page: 1,
                date_from: moment().subtract(1, 'weeks'),
                date_to: moment(),
            })
        }
    }

    toggleUnit = () => {
        const { imperialUnit } = this.state;
        this.setState({ imperialUnit: !imperialUnit });
    }

    onTableChange = (pagination, x, sorter) => {
        const {order} = this.state;
        const { actions } = this.props;
        const { filters } = this.state;

        filters.page = pagination.current;

        if (sorter.field) {
            // If we are doing a sort, Set the page back to 1
            const sortOrderFull = order.order === 'asc' ? 'ascend' : 'descend';
            if (sorter.field !== order.field || sorter.order !== sortOrderFull) {
                filters.page = 1;
            }

            order.field = sorter.field;
            order.order = sorter.order === 'ascend' ? 'asc' : 'desc';
            this.setState({ order });
        }

        // Merge the filters and order to the filters object
        const params = { ...filters, ...order };

        this.setState({ params });

        actions.getTimesheetReportRequest(params);
    }

    onEventsTabFilterChange = (values) => {
        const {order} = this.state;
        const filters = values;
        if (filters.date_from) {
            filters.date_from = values.date_from.format('YYYY-MM-DD');
        }
        if (filters.date_to) {
            filters.date_to = values.date_to.format('YYYY-MM-DD');
        }
        if (filters.asset_id) {
            filters.asset_info = values.asset_id;
        }
        filters.page = 1;

        const { actions } = this.props;
        const params = { ...filters };

        const requestParams = { ...params, ...order };

        actions.getTimesheetReportRequest(requestParams);
        this.setState({
            filters,
            journeyOrDay: this.state.softJourneyOrDay,
            reportHasBeenFetched: true,
        });
    }

    onChangeViewType = (journeyOrDay) => {
        this.setState({ softJourneyOrDay: journeyOrDay });
    }

    onExportCSV = () => {
        if (this.props.isExportingTimesheetReportCsv) {
            return false;
        }
        const { actions } = this.props;
        const { filters, imperialUnit } = this.state;
        filters.page = 1;
        filters.imperial_unit = imperialUnit ? 1 : 0;
        const params = { ...filters };

        actions.getTimesheetReportCsvRequest(params);
    }

    render() {
        return this.view(
            {
                update: (newVals) => (
                    this.props.actions.UpdateEventNotifications(newVals)
                ),
            },
        );
    }
}

TimesheetReport.propTypes = {
    actions: PropTypes.object.isRequired,
    timeSheetReport: PropTypes.array.isRequired,
    timeSheetReportPagination: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        timeSheetReport: state.report.timeSheetReport,
        timeSheetReportPagination: state.report.timeSheetReportPagination,
        events: state.events.companyEvents,
        user: state.user,
        filterSidebar: state.filterSidebar,
        dataEventToReportParams: state.global.dataEventToReportParams,
        uomPreference: state.user.profile.unit_of_measurement_preference,
        isExportingTimesheetReportCsv: state.report.isExportingTimesheetReportCsv,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...reportActions,
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TimesheetReport);
