/* global window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Spin, Empty, Switch } from 'antd';
import { isEmpty } from 'underscore';
import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';
import * as reportActions from '../../../../../core/report/reportActions';

const EMPTY_FIELD = '-';

class ReportTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sortOrder: null,
            sortField: null,
            hideEmptyRows: false,
        };
    }

    getAverage(ecoScoreReport, key, fixed) {
        if (!ecoScoreReport || !ecoScoreReport.length || !key) return EMPTY_FIELD;

        let total = 0;
        let count = 0;
        ecoScoreReport.forEach((r) => {
            if (r[key] === EMPTY_FIELD) total += 0;
            else {
                total += parseFloat(r[key]);
                count++;
            }
        });

        if (total === 0 || count === 0) return EMPTY_FIELD;
        const average = total / count;
        if (average === 0) return EMPTY_FIELD;
        return average.toFixed(fixed);
    }

    getAverageGrade(ecoScoreReport, key) {
        if (!ecoScoreReport || !ecoScoreReport.length || !key) return EMPTY_FIELD;

        let total = 0;
        let count = 0;
        ecoScoreReport.forEach((r) => {
            if (r[key] === EMPTY_FIELD) total += 0;
            else {
                total += parseFloat(r[key]);
                count++;
            }
        });

        if (total === 0 || count === 0) return EMPTY_FIELD;
        const average = total / count;
        if (average >= 90) return 'A';
        if (average >= 80) return 'B';
        if (average >= 70) return 'C';
        if (average >= 40) return 'D';
        return 'F';
    }

    handlePossiblyNaNFloat(value) {
        const newValue = parseFloat(value);
        if (isNaN(newValue)) return 0.00;
        return newValue;
    }

    handleTableSort = (pagination, filters, sorter) => {
        this.setState({
            sortOrder: sorter.order,
            sortField: sorter.field,
        });
    }

    toggleHideEmptyRows = () => {
        const current = this.state.hideEmptyRows;
        this.setState({ hideEmptyRows: !current });
    }

    render() {
        const { ecoScoreReport, isFetching } = this.props;
        const { sortOrder, sortField, hideEmptyRows } = this.state;

        let listReports = ecoScoreReport.map((r) => {
            delete r.id;
            delete r.driver_id;
            delete r.name;
            delete r.total_time_in_seconds;

            return {
                ...r,
                total_distance: r.total_distance === EMPTY_FIELD
                    ? r.total_distance
                    : `${this.handlePossiblyNaNFloat(r.total_distance).toFixed(2)}`,
                average_mpg: r.average_mpg === EMPTY_FIELD
                    ? r.average_mpg
                    : `${this.handlePossiblyNaNFloat(r.average_mpg).toFixed(2)}`,
                overall_score: r.overall_score === EMPTY_FIELD
                    ? r.overall_score
                    : `${this.handlePossiblyNaNFloat(r.overall_score).toFixed(2)}`,
            };
        });
        // if (listReports.length) listReports.push({
        //     driver: ' ',//'AVERAGES',
        //     division: ' ',
        //     total_distance: ' ',//this.getAverage(ecoScoreReport, 'total_distance', 0),
        //     total_time: ' ',//this.getAverage(ecoScoreReport, 'total_time_in_seconds', 0),
        //     average_mpg: ' ',//this.getAverage(ecoScoreReport, 'average_mpg', 2),
        //     harsh_braking_incidents: ' ',//this.getAverage(ecoScoreReport, 'harsh_braking_incidents', 0),
        //     harsh_braking_grade: ' ',//this.getAverageGrade(ecoScoreReport, 'harsh_braking_score'),
        //     harsh_acceleration_incidents: ' ',//this.getAverage(ecoScoreReport, 'harsh_acceleration_incidents', 0),
        //     harsh_acceleration_grade: ' ',//this.getAverageGrade(ecoScoreReport, 'harsh_acceleration_score'),
        //     harsh_cornering_incidents: ' ',//this.getAverage(ecoScoreReport, 'harsh_cornering_incidents', 0),
        //     harsh_cornerning_grade: ' ',//this.getAverageGrade(ecoScoreReport, 'harsh_cornering_score'),
        //     overall_score: ' ',//this.getAverage(ecoScoreReport, 'overall_score', 0),
        //     overall_grade: ' ',//this.getAverageGrade(ecoScoreReport, 'overall_score'),
        // });
        // Don't show the scores, we just use them to calculate the bottom row (averages)
        listReports = listReports.map((r) => {
            delete r.harsh_braking_score;
            delete r.harsh_acceleration_score;
            delete r.harsh_cornering_score;
            return {
                ...r,
            };
        });
        // Filter out empty rows if toggle is enabled
        if (hideEmptyRows) {
            listReports = listReports.filter((r) => (
                (r.total_distance != 0 && r.total_distance !== EMPTY_FIELD)
                    || (r.total_time !== '0 secs' && r.total_time !== EMPTY_FIELD)
                    || (r.average_mpg != 0 && r.average_mpg !== EMPTY_FIELD)
                    || r.harsh_braking_incidents != 0
                    || r.harsh_acceleration_incidents != 0
                    || r.harsh_cornering_incidents != 0
                    || r.overall_score !== EMPTY_FIELD
            ));
            if (listReports.length < 1) {
                listReports.push({
                    all_results_hidden: '',
                });
            }
        }
        if (sortOrder && sortField) {
            listReports = listReports.sort((a, b) => {
                if (sortOrder === 'descend') {
                    if (a[sortField] < b[sortField]) return 1;
                    if (a[sortField] > b[sortField]) return -1;
                    return 0;
                }
                if (a[sortField] > b[sortField]) return 1;
                if (a[sortField] < b[sortField]) return -1;
                return 0;
            });
        }
        const exportableColumns = listReports && listReports[0] ? Object.keys(listReports[0]) : [];
        const sortableColumns = listReports && listReports[0] ? Object.keys(listReports[0]) : [];

        const customEmpty = (
            <Empty
                description={(
                    <span style={{
                        color: '#64879c',
                        display: 'block',
                    }}>
                        <span style={{
                            fontSize: '180%',
                            fontWeight: 'bold',
                            marginBottom: '5px',
                            display: 'block',
                        }}>
                            Enter your search requirements to generate a report
                        </span>
                        <span style={{ display: 'block' }}>
                            Use the filter bar on the left to select Assets or Drivers,
                            the date and the frequency (if applicable) of the data you would like to display
                        </span>
                    </span>
                )}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{ display: 'none' }} />
        );

        return (
            <Spin spinning={isFetching}>
                <div className="report-table">
                    {isEmpty(listReports)
                        ? customEmpty
                        : (
                            <>
                                <Switch
                                    checkedChildren="Hiding Empty"
                                    unCheckedChildren="Showing All"
                                    checked={hideEmptyRows}
                                    onChange={() => this.toggleHideEmptyRows()}
                                    className="ml-2" />
                                <GridView
                                    showExportButton
                                    onChange={this.handleTableSort}
                                    exportableColumns={exportableColumns}
                                    sortableColumns={listReports.length && listReports.length === 1 && listReports[0].all_results_hidden !== null ? [] : sortableColumns}
                                    data={listReports}
                                    disablePagination />
                            </>
                        )}
                </div>
            </Spin>
        );
    }
}

ReportTab.defaultProps = {
    ecoScoreReport: [],
};

ReportTab.propTypes = {
    ecoScoreReport: PropTypes.array,
    isFetching: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        ecoScoreReport: state.report.ecoScoreReport,
        isFetching: state.report.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...reportActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
