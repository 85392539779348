import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Component } from 'react';

import DOM from './mapHistoricVideos';
import * as deviceActions from '../../core/device/deviceActions';
import { getMappedDevicePath } from '../../core/utils/functions';
import { getAddressFromLatLong } from '../../core/utils/api';

class MapHistoricVideos extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.getMappedDevicePath = getMappedDevicePath;
        this.device_id = null;
        this.state = {
            filter: {
                page: 1,
            },
            date_from: null,
            date_to: null,
            selectedAssetLocation: '',
        };
    }

    componentDidMount = () => {
        const { match, actions, mapSelectedDeviceInfo } = this.props;
        const { device } = match.params;
        this.device_imei = device;
        const { filter } = this.state;
        filter.page = 1;
        this.setState({ filter });
        const params = { device_imei: this.device_imei, ...filter };
        actions.getDeviceHistoricVideosRequest(params);
        const location = getAddressFromLatLong(mapSelectedDeviceInfo.lat, mapSelectedDeviceInfo.lng);
        this.setState({ selectedAssetLocation: location });
    }

    rangeSelected = (v) => {
        this.setState({
            date_from: v[0].format('YYYY-MM-DD HH:mm:ss'),
            date_to: v[1].format('YYYY-MM-DD HH:mm:ss'),
        });
        this.onPaginationChange(1, {
            date_from: v[0].format('YYYY-MM-DD HH:mm:ss'),
            date_to: v[1].format('YYYY-MM-DD HH:mm:ss'),
        });
    }

    onPaginationChange = (page, dates = { date_from: null, date_to: null }) => {
        const { actions } = this.props;
        const { filter } = this.state;
        const { date_from, date_to } = dates;
        filter.page = page;
        this.setState({ filter });
        const params = { device_imei: this.device_imei, ...filter };
        if (date_from) { params.date_from = date_from; }
        if (date_to) { params.date_to = date_to; }
        actions.getDeviceHistoricVideosRequest(params);
    }

    onTableChange = (pagination, filters, sorter) => {
        const { actions } = this.props;
        const { filter, date_from, date_to } = this.state;

        this.setState({ filter: [] });
        const params = { orderBy: `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`, device_imei: this.device_imei, ...filter };
        if (date_from) { params.date_from = date_from; }
        if (date_to) { params.date_to = date_to; }
        actions.getDeviceHistoricVideosRequest(params);
    }

    onFilterChange = (values) => {
        const { actions } = this.props;
        const { date_from, date_to } = this.state;
        const filters = values;
        filters.page = 1;
        this.setState({ filter: filters });
        const params = { device_imei: this.device_imei, page: 1, ...filters };
        if (date_from) { params.date_from = date_from; }
        if (date_to) { params.date_to = date_to; }
        actions.getDeviceHistoricVideosRequest(params);
    }

    render() {
        return this.view();
    }
}

MapHistoricVideos.propTypes = {
    actions: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        mapSelectedDeviceLocations: state.device.mapSelectedDeviceLocations,
        mapSelectedDeviceRecord: state.device.mapSelectedDeviceRecord,
        mapSelectedDeviceInfo: state.device.mapSelectedDeviceInfo,
        isFetching: state.asset.isFetching,
        userMapCenter: state.global.userMapCenter,
        assetInfo: state.asset.assetInfo,
        historicVideos: state.device.mapDeviceHistoricVideos,
        historicPagination: state.device.mapDeviceHistoricPagination,
        mapSelectedDeviceTelematicsData: state.device.mapSelectedDeviceTelematicsData,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...deviceActions,
            },
            dispatch,
        ),
    };
}

export const MapHistoricVideosTest = MapHistoricVideos;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MapHistoricVideos);
