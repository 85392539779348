import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DOM from './videoView';
import * as eventsActions from '../../core/events/eventsActions';
import * as globalActions from '../../core/global/globalActions';
import * as reportActions from '../../core/report/reportActions';

class VideoView extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.googleMapAccess = React.createRef();
        this.state = {
            maxAllowedTimeline: 60 * 30,
            sliderValue: null,
        };
    }

    componentDidMount() {
        /**
         * @todo make the route to use Auth
         */
        const { actions } = this.props;
        actions.setRetainFilter(true);
        this.requestEventInfo();
    }

    requestEventInfo = (startTime, endTime, sliderValue) => {
        const { actions, match } = this.props;
        const { videoId } = match.params;
        const params = {};
        params.videoId = videoId;
        params.startTime = startTime;
        params.endTime = endTime;
        actions.getEventInfoByVideoRequest(params);
        this.setState({ sliderValue });
    }

    render() {
        return this.view();
    }
}

VideoView.propTypes = {

};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        selectedEvent: state.events.selectedEvent,
        isFetching: state.events.isFetching,
        speedingReportBack: state.report.speedingReportBack,
        referrer: state.referrer,
        updateMapEventData: state.events.updateMapEventData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...eventsActions,
                ...globalActions,
                ...reportActions,
            },
            dispatch,
        ),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VideoView);
