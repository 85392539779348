/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Tabs } from 'antd';
import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import FilterForm from './FilterForm';
import './assets.scss';

const { TabPane } = Tabs;

export default function () {
    const {
        currentTabKey,
        divisionSearchParams,
    } = this.state;

    return (
        <Layout data-test="pages-assets" className="assets" title={<div>Assets</div>} headerTitle={<div>Assets Page</div>} showFooter={false}>
            <div className="content-wrap">
                <FilterSidebar
                    displayFilter={true}
                >
                    <FilterForm
                        key={`filter_form_${currentTabKey}`}
                        type={currentTabKey === '2' ? 'division' : 'asset'}
                        onSubmit={(filterData) => currentTabKey === '2' ? this.filterDivisions(filterData) : this.filterAssets(filterData)}
                    />
                </FilterSidebar>
                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            <Tabs
                                onTabClick={this.handleTabChange}
                                defaultActiveKey={currentTabKey}
                                activeKey={currentTabKey}
                                animated={false}
                                className="tabs-restyled">
                                <TabPane tab="Assets" key="1">
                                    <div className="section__body">
                                        {currentTabKey === '1' ? this.assetTabContent() : null}
                                    </div>
                                </TabPane>
                                <TabPane tab="Divisions" key="2">
                                    <div className="section__body">
                                        {currentTabKey === '2' ? this.divisionTabContent() : null}
                                    </div>
                                </TabPane>
                            </Tabs>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
