import React from 'react';

const AntiTamperHardwareSVG = () => (
    <svg width={64} height={64} xmlns="http://www.w3.org/2000/svg">
        <path
            stroke="null"
            d="M43.6 29.1h-2.9v-8.7a8.7 8.7 0 00-17.4 0v8.7h-2.9a2.9 2.9 0 00-2.9 2.9v17.4a2.9 2.9 0 002.9 2.9h23.2a2.9 2.9 0 002.9-2.9V32a2.9 2.9 0 00-2.9-2.9zm-17.4-8.7a5.8 5.8 0 0111.6 0v8.7H26.2v-8.7zm17.4 29H20.4V32h23.2v17.4z" />
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default AntiTamperHardwareSVG;
