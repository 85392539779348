/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../../../components/layout/Layout';
import FilterSidebar from '../../../../components/FilterSidebar';
import Icon from '../../../../components/elements/Icon';
import ReportFilter from '../../ReportFilter';
import ReportTab from './tabs/ReportTab';

export default function () {
    const {
        eventsTabLoaded, eventsTabFilter, filterSidebar, filters, imperialUnit
    } = this.state;

    return (
        <Layout
            className="events"
            title={<div>Timesheet Report</div>}
            headerTitle={<div>Timesheet Report</div>}
        >
            <div className="content-wrap">
                <FilterSidebar displayFilter={filterSidebar}>
                    <ReportFilter
                        onSubmit={this.onEventsTabFilterChange}
                        onChangeViewType={this.onChangeViewType}
                        filters={filters}
                        showDivision
                        showTimesheetReportRowType
                    />
                </FilterSidebar>
                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__header">
                                <div className="section__header-item">
                                    <Link
                                        to="/reports"
                                        className="d-flex align-center">
                                        <Icon name="arrow-left" />
                                        <span>All Reports</span>
                                    </Link>
                                </div>
                                <div className="section__header-item is-active">
                                    <span>Report: Timesheet Report</span>
                                </div>
                            </div>
                            <div className="section__body">
                                <div className="section__action-bar" />
                                <ReportTab
                                    toggleFilterSidebar={() => this.setState({ filterSidebar: !filterSidebar })}
                                    eventsTabFilter={eventsTabFilter}
                                    setLoadedState={this.eventsTabLoaded}
                                    eventsTabLoaded={eventsTabLoaded}
                                    imperialUnit={imperialUnit}
                                    onTableChange={this.onTableChange}
                                    toggleUnit={this.toggleUnit}
                                    onExportCSV={this.onExportCSV}
                                    reportHasBeenFetched={this.state.reportHasBeenFetched}
                                    journeyOrDay={this.state.journeyOrDay}
                                />
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
