import React, { Component } from 'react';
import PropTypes, { array } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Icon } from 'antd';
import { getAddressFromLatLong } from '../../core/utils/api';
import DOM from './mapLiveVideo';
import * as deviceActions from '../../core/device/deviceActions';
import * as assetActions from '../../core/asset/assetActions';
import * as globalActions from '../../core/global/globalActions';

class MapLiveVideo extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.isCallRunning = false;
        this.refreshInterval = null;
        this.state = {
            selectedAssetLocation: '',
        };
    }

    componentDidMount() {
        const {
            actions, match, userMapCenter, mapSelectedDeviceInfo,
        } = this.props;
        const { selectedDate, device } = match.params;
        // actions.getAssetLiveVideoUrlRequest({
        //     imei: device,
        // });
        this.refreshInterval = setInterval(() => this.updateAssetLocation(), 5000);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { mapSelectedDeviceInfo, deviceIsFetching } = this.props;
        const { selectedAssetLocation } = this.state;
        if (!deviceIsFetching) {
            this.isCallRunning = false;
        }
        if ((prevProps.mapSelectedDeviceInfo
            && prevProps.mapSelectedDeviceInfo !== mapSelectedDeviceInfo
            && mapSelectedDeviceInfo
            && (mapSelectedDeviceInfo.lat !== prevProps.mapSelectedDeviceInfo.lat))
            || selectedAssetLocation == '' && mapSelectedDeviceInfo.lat && mapSelectedDeviceInfo.lng

        ) {
            const location = getAddressFromLatLong(mapSelectedDeviceInfo.lat, mapSelectedDeviceInfo.lng);
            this.setState({ selectedAssetLocation: location });
        }
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    updateAssetLocation = () => {
        if (!this.isCallRunning) {
            const { actions, match } = this.props;
            const { device } = match.params;
            actions.mapDeviceLocationsRequest({
                imei: device,
                date: moment().format('YYYY-MM-DD'),
            });
            this.isCallRunning = true;
        }
    }

    getMappedDevicePath = () => {
        const zoom = 16;
        const { mapSelectedDeviceLocations } = this.props;
        // Map zoom bounds 3 - 22
        const countLocationDots = mapSelectedDeviceLocations.length;
        let threshold = parseInt(((((22 - zoom) / 22) * countLocationDots) / 10), 10);
        if (zoom > 10 && zoom <= 15) {
            threshold = parseInt(threshold / zoom, 10);
        } else if (zoom > 15) {
            threshold = parseInt(threshold / 20, 10);
        }
        if (threshold <= 0) {
            threshold = 1;
        }
        const filteredSelectedDeviceLocations = mapSelectedDeviceLocations.filter((mapSelectedDeviceLocation, mapSelectedDeviceLocationIndex) => {
            if (mapSelectedDeviceLocationIndex == 0) {
                return true;
            } if (mapSelectedDeviceLocationIndex % threshold == 0) {
                return true;
            } if (mapSelectedDeviceLocationIndex === mapSelectedDeviceLocations.length - 1) {
                return true;
            }
            return false;
        });

        return filteredSelectedDeviceLocations.map((path, pathIndex) => {
            let position = 'path';
            // position = pathIndex === 0 ? 'start' : position;
            position = pathIndex === (filteredSelectedDeviceLocations.length - 1) ? 'marker' : position;
            const temp = {
                id: path.id,
                lat: path.lat,
                lng: path.lng,
                position,
                title: path.time,
                content: <div>path.time</div>,
            };
            if (position === 'marker') {
                temp.currentMarker = { icon: <Icon type="flag" theme="filled" /> };
            }

            return temp;
        });
    }

    sidebarToolBarButtonOnclick = () => {
        this.setState({ showSidebar: true, showAssetsSidebar: true, showDeviceSidebar: false });
    }

    render() {
        return this.view();
    }
}

MapLiveVideo.propTypes = {
    actions: PropTypes.object.isRequired,
    mapSelectedDeviceLocations: array.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        mapSelectedDeviceLocations: state.device.mapSelectedDeviceLocations,
        mapSelectedDeviceRecord: state.device.mapSelectedDeviceRecord,
        mapSelectedDeviceInfo: state.device.mapSelectedDeviceInfo,
        isFetching: state.asset.isFetching,
        deviceIsFetching: state.device.isFetching,
        userMapCenter: state.global.userMapCenter,
        assetInfo: state.asset.assetInfo,
        assetLiveVideos: state.asset.assetLiveVideos,
        user: state.user,
        mapSelectedDeviceTelematicsData: state.device.mapSelectedDeviceTelematicsData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...deviceActions,
                ...assetActions,
                ...globalActions,
            },
            dispatch,
        ),
    };
}

export const MapLiveVideoTest = MapLiveVideo;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MapLiveVideo);
