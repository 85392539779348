import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import moment from 'moment';

import { convertFromSeconds } from '../../core/utils/functions';
import GridView from '../elements/GridView';
import Icon from '../elements/Icon';
import history from '../../core/utils/history';

import * as videoActions from '../../core/video/videoActions';
import LocationFetcher from '../elements/LocationFetcher';

const VideoHistory = ({
    loading,
    videos,
    pagination,
    handleTableChange,
    handlePaginationChange,
    sortableColumns,
    actions,
}) => {
    const columns = (videos && videos.map((v) => ({
        id: v.id,
        date: v.start_time && moment(v.start_time).format('DD/MM/YYYY'),
        time: v.start_time && v.start_time.substring(11),
        location: (
            <LocationFetcher
                key={v.id}
                lat={v.latitude}
                lon={v.longitude} 
            />
        ),
        duration: (
            <div className="d-flex align-center">
                <Icon name="clock" />
                <span className="ml-1">{convertFromSeconds(v.duration)} </span>
            </div>
        ),
        actions: (
            <a onClick={() => { window.open(`/video/view/${v.id}`, '_blank').focus() }}>
                <Icon name="chevron-right" className="ml-2" />
            </a>
        ),
    }))) || [];

    return (
        <Spin spinning={loading} style={{ width: '100%' }}>
            <GridView
                data={columns}
                onChange={handleTableChange}
                sortableColumns={sortableColumns}
                hiddenColumns={['id']}
                pagination={{
                    className: 'custom-pagination',
                    total: parseInt(pagination.totalRecords, 10),
                    pageSize: parseInt(pagination.perPageCount, 10),
                    showSizeChanger: false,
                    onChange: handlePaginationChange,
                }} />
        </Spin>
    );
};

VideoHistory.defaultProps = {
    loading: false,
    pagination: { page: 1, totalRecords: 16, perPageCount: 16 },
    handleTableChange: () => null,
    handlePaginationChange: () => null,
    sortableColumns: [],
};

VideoHistory.propTypes = {
    loading: PropTypes.bool,
    videos: PropTypes.array.isRequired,
    pagination: PropTypes.object,
    handleTableChange: PropTypes.func,
    handlePaginationChange: PropTypes.func,
    sortableColumns: PropTypes.arrayOf(PropTypes.string),
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...videoActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VideoHistory);
