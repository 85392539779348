/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Spin, DatePicker } from 'antd';

import Layout from '../../components/layout/Layout';
import './mapHistoricVideos.scss';
import '../../components/Filter/filter.scss';

import AssetSidebar from '../../components/AssetsSidebar';
import history from '../../core/utils/history';
import { getDistanceInMiles } from '../../core/utils/api';
import VideoHistory from '../../components/VideoHistory';

const { RangePicker } = DatePicker;


export default function () {
    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const menu = [{
        label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' },
    }];
    const {
        isFetching,
        historicVideos,
        match,
        mapSelectedDeviceInfo, historicPagination,
        userMapCenter,
        user,
        mapSelectedDeviceTelematicsData
    } = this.props;
    const { selectedDate, device } = match.params;
    const mappedDevicePath = this.getMappedDevicePath();
    const currentLocation = (mappedDevicePath && mappedDevicePath[mappedDevicePath.length - 1]) || null;
    const navigation = [{ label: 'Item 1', link: '/abc' }, { label: 'Item 1', link: '/abc' }];
    const { selectedAssetLocation } = this.state;

    // console.log("isFetching: ", isFetching);
    const currentDistance = currentLocation ? getDistanceInMiles({ lat: currentLocation.lat, lng: currentLocation.lng }, { lat: userMapCenter.lat, lng: userMapCenter.lng }) : 0;
    return (
        <Layout
            data-test="pages-map-historic-videos"
            className="map-historic-videos"
            title={<div>MapHistoricVideos</div>}
            headerTitle={<div>MapHistoricVideos Page</div>}
            menu={menu}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2019</div>}>

            <div className="content-wrap">
                <div className="content">
                    <Spin spinning={isFetching && isFetching.length > 0}>
                        <div style={{ display: 'flex', height: '83.7vh', flexDirection: 'row' }}>
                            <AssetSidebar
                                assetId={mapSelectedDeviceInfo.id}
                                backLinkClick={() => history.push('/map?showAssetList=true')}
                                device={device}
                                selectedDate={selectedDate || ''}
                                assetName={mapSelectedDeviceInfo.name || ''}
                                assetLastDataSent={mapSelectedDeviceInfo.last_data_received}
                                distance={mapSelectedDeviceInfo.distance || currentDistance || 0}
                                assetReg={mapSelectedDeviceInfo.registration || ''}
                                assetMake={mapSelectedDeviceInfo.make || ''}
                                assetModel={mapSelectedDeviceInfo.model || ''}
                                assetDriver={mapSelectedDeviceInfo.full_name || ''}
                                assetLocation={selectedAssetLocation || 'Unknown'}
                                assetPicture={mapSelectedDeviceInfo && mapSelectedDeviceInfo.asset_picture || ''}
                                profilePicture={mapSelectedDeviceInfo && mapSelectedDeviceInfo.profile_picture || ''}
                                telematicsData={mapSelectedDeviceTelematicsData}
                                lastDataReceived={mapSelectedDeviceInfo.last_data_received}
                                user={user} />

                            <div style={{ flex: 1, height: 'inherit', width: '100%' }}>
                                <div className="history">
                                    <div className="section mt-3">
                                        <div className="section__header">
                                            <div className="driver__name">
                                                <span className="indicator-activity" />
                                                <span>{mapSelectedDeviceInfo.name || ''}</span>
                                                <span className="ml-1 secondary-text">
                                                    ({mapSelectedDeviceInfo.distance || currentDistance || 0} Mi)
                                                </span>
                                            </div>
                                            <div className="driver-cars">
                                                <span>{mapSelectedDeviceInfo.registration || ''}</span>
                                                <span>{mapSelectedDeviceInfo.make || ''} {mapSelectedDeviceInfo.model || ''}</span>
                                            </div>
                                        </div>
                                        <div className="section__body">
                                            <div className="center-content">
                                                <RangePicker
                                                    showTime={{ format: 'HH:mm' }}
                                                    format="YYYY-MM-DD HH:mm"
                                                    placeholder={['Start Time', 'End Time']}
                                                    onChange={this.rangeSelected} />
                                            </div>
                                            <VideoHistory
                                                videos={historicVideos}
                                                pagination={historicPagination}
                                                handleTableChange={this.onTableChange}
                                                handlePaginationChange={this.onPaginationChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
            </div>

        </Layout>
    );
}
