/* global localStorage */
import {
    call, put, takeEvery, all, delay,
} from 'redux-saga/effects';
import { message, notification } from 'antd';
import lockr from 'lockr';
import { fetchApi, fetchApiAuth, fetchApiAuthCustom } from '../utils/api';
import history from '../utils/history';

import {
    deleteTokenSuccess,

    loginSuccess,
    loginFailure,

    logoutSuccess,
    logoutFailure,

    twoFactorAuthSuccess,
    twoFactorAuthFailure,

    resetPasswordSuccess,
    resetPasswordFailure,

    verifyResetPasswordSuccess,
    verifyResetPasswordFailure,

    getCompanyUsersSuccess,
    getCompanyUsersFailure,

    getUsersPageSuccess,
    getUsersPageFailure,

    getDivisionAssetPermissionsGridRequest,
    getDivisionAssetPermissionsGridSuccess,
    getDivisionAssetPermissionsGridFailure,

    getDivisionDriverPermissionsGridRequest,
    getDivisionDriverPermissionsGridSuccess,
    getDivisionDriverPermissionsGridFailure,

    getReportPermissionsGridRequest,
    getReportPermissionsGridSuccess,
    getReportPermissionsGridFailure,

    getAssetPermissionsGridRequest,
    getAssetPermissionsGridSuccess,
    getAssetPermissionsGridFailure,

    getReportAssetPermissionsGridRequest,
    getReportAssetPermissionsGridSuccess,
    getReportAssetPermissionsGridFailure,

    getDriverPermissionsGridRequest,
    getDriverPermissionsGridSuccess,
    getDriverPermissionsGridFailure,

    getAccountPermissionsGridSuccess,
    getAccountPermissionsGridFailure,

    getUserDetailSuccess,
    getUserDetailFailure,

    setUserDetailSuccess,
    setUserDetailFailure,

    updateUserCompanySuccess,
    updateUserCompanyFailure,

    updateUserPictureSuccess,
    updateUserPictureFailure,

    getProfileDetailsSuccess,
    getProfileDetailsFailure,

    setProfileDetailsSuccess,
    setProfileDetailsFailure,

    updateProfilePictureSuccess,
    updateProfilePictureFailure,

    changeUserAccessRequestSuccess,
    changeUserAccessRequestFailure,

    changeUserReportAccessRequestSuccess,
    changeUserReportAccessRequestFailure,

    changeUserPermissionSuccess,
    changeUserPermissionFailure,

    changeDriverUserPermissionSuccess,
    changeDriverUserPermissionFailure,

    updateUserProfileSuccess,
    updateUserProfileFailure,

    updateProfileImageSuccess,
    updateProfileImageFailure,

    changeResetPasswordSuccess,
    changePasswordSuccess,
    changePasswordFailure,

    addAccessToDivisionSuccess,
    addAccessToDivisionFailure,

    addReportAccessToDivisionSuccess,
    addReportAccessToDivisionFailure,

    createUserSuccess,
    createUserFailure,

    deleteUserSuccess,
    deleteUserFailure,

    uploadNewUserPictureSuccess,
    uploadNewUserPictureFailure,

    getMapPreferencesSuccess,
    getMapPreferencesFailure,

    setMapPreferencesSuccess,
    setMapPreferencesFailure,
    
} from './userActions';

import { resetMapLiveAssetsRequest } from '../device/deviceActions';
import { resetCompareAssetsTargetsRequest } from '../comparison/comparisonActions';
import { getListCompaniesRequest } from '../company/companyActions';
import { setShardId } from '../global/globalActions';

import {
    getDivisionsRequest,
} from '../asset/assetActions';

const {
    AUTH_KEY,
    API_LOGIN_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
    SHARD_KEY,
} = require('../constants').default;

const {
    GET_USERS_PAGE_REQUEST,
    GET_USERS_DEPARTMENT_REQUEST,
    GET_DIVISION_ASSET_PERMISSIONS_GRID_REQUEST,
    GET_DIVISION_DRIVER_PERMISSIONS_GRID_REQUEST,
    GET_REPORT_PERMISSIONS_GRID_REQUEST,
    GET_ASSET_PERMISSIONS_GRID_REQUEST,
    GET_REPORT_ASSET_PERMISSIONS_GRID_REQUEST,
    GET_DRIVER_PERMISSIONS_GRID_REQUEST,
    GET_ACCOUNT_PERMISSIONS_GRID_REQUEST,
    GET_USER_DETAIL_REQUEST,
    SET_USER_DETAIL_REQUEST,
    UPDATE_USER_COMPANY_REQUEST,
    UPDATE_USER_PICTURE_REQUEST,
    GET_PROFILE_DETAILS_REQUEST,
    SET_PROFILE_DETAILS_REQUEST,
    UPDATE_PROFILE_PICTURE_REQUEST,
    CHANGE_USER_ACCESS_REQUEST_REQUEST,
    CHANGE_USER_REPORT_ACCESS_REQUEST_REQUEST,
    CHANGE_USER_PERMISSION_REQUEST,
    CHANGE_DRIVER_USER_PERMISSION_REQUEST,
    UPDATE_USER_PROFILE_REQUEST,
    UPDATE_PROFILE_IMAGE_REQUEST,
    CHANGE_PASSWORD_REQUEST,
    ADD_ACCESS_TO_DIVISION_REQUEST,
    ADD_REPORT_ACCESS_TO_DIVISION_REQUEST,
    CREATE_USER_REQUEST,
    DELETE_USER_REQUEST,
    LOGIN_REQUEST,
    LOGOUT_REQUEST,
    TWO_FACTOR_AUTH_REQUEST,
    RESET_PASSWORD_REQUEST,
    GET_COMPANY_USERS_REQUEST,
    UPLOAD_NEW_USER_PICTURE_REQUEST,
    GET_MAP_PREFERENCES_REQUEST,
    SET_MAP_PREFERENCES_REQUEST,
    VERIFY_RESET_PASSWORD_REQUEST,
    CHANGE_RESET_PASSWORD_REQUEST,
} = require('./userActions').constants;

function* deleteSessionToken() {
    lockr.rm(AUTH_KEY);
    lockr.rm(API_LOGIN_KEY);
    lockr.rm(SHARD_KEY);
    yield put(deleteTokenSuccess());
}

function* saveSessionToken({ data }) {
    const { auth_token, defaultShardId } = data;
    if (auth_token) {
        const authDate = new Date();
        lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
        lockr.set(AUTH_KEY, auth_token);
    }
    if (defaultShardId) {
        lockr.set(SHARD_KEY, defaultShardId);
    }

    yield true;
}

function* makeLoginRequest(action) {
    try {
        localStorage.clear();
        yield delay(1000);
        
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/login',
            body: { 
                username: action.payload.username, 
                password: action.payload.password, 
            },
        });

        if (!response || !response.data || !response.data.success) {
            throw new Error('Login failed');
        }

        yield call(saveSessionToken, response);

        const { data } = response;
        const { defaultShardId, two_factor_auth } = data;

        yield put(setShardId(defaultShardId));
        if (two_factor_auth === 0) {
            yield put(getDivisionsRequest());
            yield put(resetMapLiveAssetsRequest());
            yield put(resetCompareAssetsTargetsRequest());
            yield put(getListCompaniesRequest());
            history.push('/map');
        } else {
            history.push(`/two-factor-auth/${response.data.ref}`);
        }
        yield put(loginSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : 'Video service temporarily unavailable, please contact support@fleetclear.com for further information.' });
        yield put(loginFailure(e));
    }
}

function* twoFactorAuth({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/two-factor-auth',
            body: payload,
        });
        yield call(saveSessionToken, response);

        yield put(getDivisionsRequest());
        yield put(resetMapLiveAssetsRequest());
        yield put(resetCompareAssetsTargetsRequest());
        yield put(getListCompaniesRequest());
        yield put(twoFactorAuthSuccess(response));
        history.push('/map');
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : 'Unable to verify code' });
        yield put(twoFactorAuthFailure(e));
    }
}

function* resetPasswordRequest(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/forgot-password',
            body: { email: action.payload.email },
        });
        notification.success({ message: 'Success', description: 'Please check your email to reset your password.' });
        yield put(resetPasswordSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(resetPasswordFailure(e));
    }
}

function* logoutRequest() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/logout',
        });

        yield put(logoutSuccess(response));
    } catch (e) {
        // We don't show error on fail logout we just force logout by clearing local storage and navigating to login page.
        //   This is because the logout request can fail if the token already expired but should not force the user
        //   to stay logged in.
        yield put(logoutFailure(e));
    }

    yield delay(1000);
    localStorage.clear();
    history.push('/');
}

function* getCompanyUsers(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/company/users',
        });
        yield put(getCompanyUsersSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getCompanyUsersFailure(e));
    }
}

function* getUsersPage(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/user-account/get-users',
            params: {
                page: ((action.payload && action.payload.page) || 1),
            },
            body: action.payload,
        });
        yield put(getUsersPageSuccess(response));
    } catch (e) {
        message.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getUsersPageFailure(e));
    }
}

function* getDivisionAssetPermissionsGrid(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/user-account/get-division-asset-permissions',
            params: {
                page: ((actions.payload && actions.payload.page) || 1),
            },
            body: actions.payload,
        });

        yield put(getDivisionAssetPermissionsGridSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getDivisionAssetPermissionsGridFailure(e));
    }
}

function* getDivisionDriverPermissionsGrid(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/user-account/get-division-driver-permissions',
            params: {
                page: ((actions.payload && actions.payload.page) || 1),
            },
            body: actions.payload,
        });

        yield put(getDivisionDriverPermissionsGridSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getDivisionDriverPermissionsGridFailure(e));
    }
}

function* getReportPermissionsGrid(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/user-account/get-report-permissions',
            params: {
                page: ((actions.payload && actions.payload.page) || 1),
            },
            body: actions.payload,
        });

        yield put(getReportPermissionsGridSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getReportPermissionsGridFailure(e));
    }
}

function* getReportAssetPermissionsGrid(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/user-account/get-report-asset-permissions-for-division',
            params: {
                page: ((actions.payload && actions.payload.page) || 1),
            },
            body: actions.payload,
        });

        yield put(getReportAssetPermissionsGridSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getReportAssetPermissionsGridFailure(e));
    }
}

function* getAssetPermissionsGrid(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/user-account/get-asset-permissions-for-division',
            params: {
                page: ((actions.payload && actions.payload.page) || 1),
            },
            body: actions.payload,
        });

        yield put(getAssetPermissionsGridSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getAssetPermissionsGridFailure(e));
    }
}

function* getDriverPermissionsGrid(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/user-account/get-driver-permissions-for-division',
            params: {
                page: ((actions.payload && actions.payload.page) || 1),
            },
            body: actions.payload,
        });

        yield put(getDriverPermissionsGridSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getDriverPermissionsGridFailure(e));
    }
}

function* getAccountPermissionsGrid(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/user-account/get-account-permissions',
            params: {
                page: ((actions.payload && actions.payload.page) || 1),
            },
            body: actions.payload,
        });

        yield put(getAccountPermissionsGridSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getAccountPermissionsGridFailure(e));
    }
}

function* getUserDetail(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'user-account/get-user-details',
            body: actions.payload,
        });

        yield put(getUserDetailSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getUserDetailFailure(e));
    }
}

function* setUserDetail(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'user-account/update-user-details',
            body: actions.payload,
        });

        message.success('User updated');
        yield put(setUserDetailSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(setUserDetailFailure(e));
    }
}

function* updateUserCompany({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'user-account/update-user-company',
            body: payload,
        });
        yield call(saveSessionToken, response);

        yield put(updateUserCompanySuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(updateUserCompanyFailure(e.response ? e.response.data.message : e));
    }
}

function* updateUserPicture(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'user-account/upload-image',
            body: actions.payload,
        });

        yield put(updateUserPictureSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(updateUserPictureFailure(e));
    }
}

function* getProfileDetails(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'user-account/get-profile',
            body: actions.payload,
        });

        yield put(getProfileDetailsSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getProfileDetailsFailure(e));
    }
}

function* setProfileDetails(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'user-account/set-profile',
            body: actions.payload,
        });

        yield put(setProfileDetailsSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(setProfileDetailsFailure(e));
    }
}

function* updateProfilePicture(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'user-account/upload-image',
            body: actions.payload,
        });

        yield put(updateProfilePictureSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(updateProfilePictureFailure(e));
    }
}

function* changeUserAccessRequest(actions) {
    // type: asset, access: newAccess, id: asset.asset_id
    const url = `user-access/${actions.payload.access ? 'grant' : 'revoke'}-${actions.payload.type}-permission`;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url,
            body: actions.payload,
        });

        yield put(changeUserAccessRequestSuccess(actions.payload));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(changeUserAccessRequestFailure(e));
    }
}

function* changeUserReportAccessRequest(actions) {
    // type: asset, access: newAccess, id: asset.asset_id
    const url = `user-access/${actions.payload.access ? 'grant' : 'revoke'}-report-${actions.payload.type}-permission`;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url,
            body: actions.payload,
        });

        yield put(changeUserReportAccessRequestSuccess(actions.payload));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(changeUserReportAccessRequestFailure(e));
    }
}

function* changeUserPermission(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'user-access/change-user-permission',
            body: actions.payload,
        });

        yield put(changeUserPermissionSuccess(actions.payload));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(changeUserPermissionFailure(e));
    }
}

function* changeDriverUserPermission(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'user-access/change-driver-user-permission',
            body: actions.payload,
        });

        yield put(changeDriverUserPermissionSuccess(actions.payload));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(changeDriverUserPermissionFailure(e));
    }
}

function* updateUserProfile(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'user-account/update-user-profile',
            body: actions.payload,
        });

        notification.success({ message: 'Success', description: 'Succesfully updated profile!' });
        yield put(updateUserProfileSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(updateUserProfileFailure(e));
    }
}

function* updateProfileImage(action) {
    try {
        const body = new FormData();
        body.append('image', action.payload.image);

        const response = yield call(fetchApiAuthCustom, {
            method: 'POST',
            url: 'user-account/update-user-profile-picture',
            body,
        });

        notification.success({ message: 'Success', description: 'Succesfully updated profile picture!' });
        yield put(updateProfileImageSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(updateProfileImageFailure(e));
    }
}

function* changePassword(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'user-account/change-password',
            body: actions.payload,
        });

        notification.success({ message: 'Success', description: 'Succesfully changed password!' });
        yield put(changePasswordSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(changePasswordFailure(e));
    }
}

function* changeResetPassword(actions) {
    try {
        yield delay(1000);
        localStorage.clear();
        const response = yield call(fetchApi, {
            method: 'POST',
            url: 'user-account/change-reset-password',
            body: actions.payload,
        });

        notification.success({ message: 'Success', description: 'Succesfully changed password!' });
        yield put(changeResetPasswordSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(changePasswordFailure(e));
    }
}

function* addAccessToDivision(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'user-access/add-access-to-division',
            body: actions.payload,
        });
        if (actions.payload.data_type === 'drivers') {
            yield put(getDivisionDriverPermissionsGridRequest(actions.payload));
            yield put(getDriverPermissionsGridRequest(actions.payload));
        } else {
            yield put(getDivisionAssetPermissionsGridRequest(actions.payload));
            yield put(getAssetPermissionsGridRequest(actions.payload));
        }
        yield put(addAccessToDivisionSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(addAccessToDivisionFailure(e));
    }
}

function* addReportAccessToDivision(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'user-access/add-report-access-to-division',
            body: actions.payload,
        });

        yield put(getReportPermissionsGridRequest(actions.payload));
        yield put(getReportAssetPermissionsGridRequest(actions.payload));
        yield put(addReportAccessToDivisionSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(addReportAccessToDivisionFailure(e));
    }
}

function* createUser(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'user-account/create-user',
            body: actions.payload,
        });

        notification.success({ message: 'Success', description: 'User succesfully created!' });
        history.push(`/users/view/${response.data.id}`);
        yield put(createUserSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(createUserFailure(e));
    }
}

function* deleteUser(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'user-account/delete-user',
            body: actions.payload,
        });

        notification.success({ message: 'Success', description: 'User succesfully deleted!' });
        yield put(deleteUserSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(deleteUserFailure(e));
    }
}

function* uploadNewUserPicture(action) {
    try {
        const body = new FormData();
        body.append('image', action.payload.image);

        const response = yield call(fetchApiAuthCustom, {
            method: 'POST',
            url: 'user-account/upload-new-user-picture',
            body,
        });

        yield put(uploadNewUserPictureSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(uploadNewUserPictureFailure(e));
    }
}

function* getMapPreferences() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'user-account/get-map-preferences',
        });

        yield put(getMapPreferencesSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getMapPreferencesFailure(e));
    }
}

function* setMapPreferences(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'user-account/set-map-preferences',
            body: actions.payload,
        });

        yield put(setMapPreferencesSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(setMapPreferencesFailure(e));
    }
}

function* verifyResetPasswordRequest(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/verify-reset-password',
            body: action.payload,
        });
        yield put(verifyResetPasswordSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(verifyResetPasswordFailure(e));
    }
}

/**
 * Watch actions
 */
export default function* userSaga() {
    yield all([
        takeEvery(LOGIN_REQUEST, makeLoginRequest),
        takeEvery(LOGOUT_REQUEST, logoutRequest),
        takeEvery(TWO_FACTOR_AUTH_REQUEST, twoFactorAuth),
        takeEvery(RESET_PASSWORD_REQUEST, resetPasswordRequest),
        takeEvery(GET_COMPANY_USERS_REQUEST, getCompanyUsers),
        takeEvery(GET_USERS_PAGE_REQUEST, getUsersPage),
        takeEvery(GET_USERS_DEPARTMENT_REQUEST, getUsersPage),
        takeEvery(GET_DIVISION_ASSET_PERMISSIONS_GRID_REQUEST, getDivisionAssetPermissionsGrid),
        takeEvery(GET_DIVISION_DRIVER_PERMISSIONS_GRID_REQUEST, getDivisionDriverPermissionsGrid),
        takeEvery(GET_REPORT_PERMISSIONS_GRID_REQUEST, getReportPermissionsGrid),
        takeEvery(GET_ASSET_PERMISSIONS_GRID_REQUEST, getAssetPermissionsGrid),
        takeEvery(GET_REPORT_ASSET_PERMISSIONS_GRID_REQUEST, getReportAssetPermissionsGrid),
        takeEvery(GET_DRIVER_PERMISSIONS_GRID_REQUEST, getDriverPermissionsGrid),
        takeEvery(GET_ACCOUNT_PERMISSIONS_GRID_REQUEST, getAccountPermissionsGrid),
        takeEvery(GET_USER_DETAIL_REQUEST, getUserDetail),
        takeEvery(SET_USER_DETAIL_REQUEST, setUserDetail),
        takeEvery(UPDATE_USER_COMPANY_REQUEST, updateUserCompany),
        takeEvery(UPDATE_USER_PICTURE_REQUEST, updateUserPicture),
        takeEvery(UPDATE_PROFILE_PICTURE_REQUEST, updateProfilePicture),
        takeEvery(GET_PROFILE_DETAILS_REQUEST, getProfileDetails),
        takeEvery(SET_PROFILE_DETAILS_REQUEST, setProfileDetails),
        takeEvery(CHANGE_USER_ACCESS_REQUEST_REQUEST, changeUserAccessRequest),
        takeEvery(CHANGE_USER_REPORT_ACCESS_REQUEST_REQUEST, changeUserReportAccessRequest),
        takeEvery(CHANGE_USER_PERMISSION_REQUEST, changeUserPermission),
        takeEvery(CHANGE_DRIVER_USER_PERMISSION_REQUEST, changeDriverUserPermission),
        takeEvery(UPDATE_USER_PROFILE_REQUEST, updateUserProfile),
        takeEvery(UPDATE_PROFILE_IMAGE_REQUEST, updateProfileImage),
        takeEvery(CHANGE_PASSWORD_REQUEST, changePassword),
        takeEvery(ADD_ACCESS_TO_DIVISION_REQUEST, addAccessToDivision),
        takeEvery(ADD_REPORT_ACCESS_TO_DIVISION_REQUEST, addReportAccessToDivision),
        takeEvery(CREATE_USER_REQUEST, createUser),
        takeEvery(DELETE_USER_REQUEST, deleteUser),
        takeEvery(UPLOAD_NEW_USER_PICTURE_REQUEST, uploadNewUserPicture),
        takeEvery(GET_MAP_PREFERENCES_REQUEST, getMapPreferences),
        takeEvery(SET_MAP_PREFERENCES_REQUEST, setMapPreferences),
        takeEvery(VERIFY_RESET_PASSWORD_REQUEST, verifyResetPasswordRequest),
        takeEvery(CHANGE_RESET_PASSWORD_REQUEST, changeResetPassword),
    ]);
}
