import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class HowenPlayer extends Component {
    constructor(props) {
        super(props);

        this.videosRefs = [];
        this.player = null;
    }

    componentDidMount() {
        const { id } = this.props;
        if (this?.props?.onReady) {
            this.props.onReady(id);
        }
    }

    componentWillUnmount() {
        const { id } = this.props;
        if (this?.props?.onRemoved) {
            this.props.onRemoved(id);
        }
    }

    createVideoRef = (ref, video_id) => {
        if (!this.videosRefs[video_id]) {
            this.videosRefs[video_id] = ref;
        }
    }

    render() {
        const {
            url, id, refs, videoKey, channelKey, minHeight, height,
        } = this.props;
        return (
            <iframe
                allowFullScreen
                ref={(ref) => {
                    this.createVideoRef(ref, id);
                    refs[channelKey] = { key: videoKey, element: ref };
                }}
                title="howen_video_iframe"
                width="100%"
                height="100%"
                frameBorder={0}
                scrolling="no"
                style={{ margin: '0 auto', overflow: 'hidden', minHeight: `${height}px` }}
                src={url} />
        );
    }
}

HowenPlayer.defaultProps = {
    id: 'customVideo',
    refs: [],
    videoKey: '',
    channelKey: '',
    height: 0,
};

HowenPlayer.propTypes = {
    url: PropTypes.string.isRequired,
    id: PropTypes.string,
    refs: PropTypes.array,
    videoKey: PropTypes.string,
    channelKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.number,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {

            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HowenPlayer);
