/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';

import Layout from '../../../../components/layout/Layout';
import FilterSidebar from '../../../../components/FilterSidebar';
import Icon from '../../../../components/elements/Icon';
import ReportFilter, { DATE_MODE_FREQUENCY } from '../../ReportFilter';
import ReportTab from './tabs/ReportTab';

import './mpgReport.scss';

export default function () {
    const {
        eventsTabLoaded,
        eventsTabFilter,
        filterSidebar,
        filters,
    } = this.state;

    return (
        <Layout
            className="events"
            title={<div>Fuel Report</div>}
            headerTitle={<div>Fuel Report Page</div>}>
            <div className="content-wrap">
                <FilterSidebar displayFilter={filterSidebar}>
                    <ReportFilter
                        onSubmit={this.onEventsTabFilterChange}
                        filters={filters}
                        showDivision
                        showFuelReportType
                        dateMode={DATE_MODE_FREQUENCY} />
                </FilterSidebar>
                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__header">
                                <div className="section__header-item">
                                    <Link
                                        to="/reports"
                                        className="d-flex align-center">
                                        <Icon name="arrow-left" />
                                        <span>All Reports</span>
                                    </Link>
                                </div>
                                <div className="section__header-item is-active">
                                    <span>Report: Fuel report</span>
                                </div>
                            </div>
                            <div className="section__body">
                                <div className="section__action-bar">
                                </div>
                                <ReportTab
                                    toggleFilterSidebar={() => this.setState({ filterSidebar: !filterSidebar })}
                                    eventsTabFilter={eventsTabFilter}
                                    setLoadedState={this.eventsTabLoaded}
                                    eventsTabLoaded={eventsTabLoaded}
                                    reportType={filters?.report_type || null}
                                    onTableChange={this.onEventsTabSortChange}
                                    onPageChange={this.onEventsTabPageChange} />
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
