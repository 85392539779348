import React from 'react';

const AutomaticHealthStatusReportsSVG = () => (
    <svg width={64} height={64} xmlns="http://www.w3.org/2000/svg">
        <path
            stroke="null"
            d="M45.333 8.67v3.333h3.334v10a6.667 6.667 0 01-13.334 0v-10h3.334V8.67H32v13.333a10.008 10.008 0 008.333 9.85v10.15a10 10 0 01-20 0V31.766a6.667 6.667 0 10-3.333 0v10.237a13.333 13.333 0 0026.667 0v-10.15A10.008 10.008 0 0052 22.003V8.67h-6.667zm-30 16.667a3.333 3.333 0 113.334 3.333 3.337 3.337 0 01-3.334-3.333z" />
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default AutomaticHealthStatusReportsSVG;
