import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';

import * as departmentActions from '../../../../core/department/departmentActions';

import DOM from './addDepartmentForm';

class UserAddDeparmentForm extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { actions } = this.props;
                actions.createDepartmentRequest(values);
            }
        });
    };

    render() {
        return this.view();
    }
}

UserAddDeparmentForm.propTypes = {
    form: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
};

const AddDeparmentForm = Form.create({
    name: 'AddDeparmentForm',
})(UserAddDeparmentForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...departmentActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDeparmentForm);
