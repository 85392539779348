import React, { Component } from 'react';
import {
    Form,
    DatePicker,
    Select,
    Input,
    Button,
    Row,
    Col,
} from 'antd';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { formatEventTypes } from '../../../core/utils/functions';
import * as assetActions from '../../../core/asset/assetActions';
import Icon from '../../../components/elements/Icon';
import { isoStandardDateTimeFormat } from '../../../core/utils/dateUtils';

const { RangePicker } = DatePicker;

class DriverEventHistoryFilterForm extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.getEventTypesRequest();
    }

    submitForm = (e) => {
        e.preventDefault();
        const { onSubmit } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    }

    render() {
        const { form, event_types, driverList } = this.props;
        const { getFieldDecorator } = form;

        const updatedEventTypes = formatEventTypes(event_types);
        const dateFormat = 'DD/MM/YYYY';

        return (
            <Form onSubmit={this.submitForm} layout="vertical">
                <Form.Item label="Date Range">
                    {getFieldDecorator('date_range', {
                        initialValue: [
                            moment(moment(new Date()).startOf('day').format(isoStandardDateTimeFormat)),
                            moment(new Date()),
                        ],
                    })(
                        <RangePicker
                            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                            placeholder="Date Range"
                            format={dateFormat} />,
                    )}
                </Form.Item>
                <Form.Item label="Event type">
                    {getFieldDecorator('event_type', {

                    })(
                        <Select
                            placeholder="Select event type"
                            suffixIcon={<Icon name="triangle-down" />}>
                            <Select.Option value="all">All</Select.Option>
                            {updatedEventTypes.map((eventType, eventTypeIndex) => <Select.Option key={eventTypeIndex} value={eventType.id}>{eventType.key}</Select.Option>)}
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Driver">
                    {getFieldDecorator('driver')(
                        <Select
                            mode="multiple"
                            placeholder="Select driver"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            suffixIcon={<Icon name="triangle-down" />}>
                            <Select.Option value="all">Any</Select.Option>
                            {driverList.map((dl) => <Select.Option key={dl.id} value={dl.id}>{dl.name}</Select.Option>)}
                        </Select>,
                    )}
                </Form.Item>
                {/* <Form.Item label="Division"> */}
                {/*    {getFieldDecorator('division', { */}

                {/*    })( */}
                {/*        <Input placeholder="Division" />, */}
                {/*    )} */}
                {/* </Form.Item> */}
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="Postcode">
                            {getFieldDecorator('postcode', {

                            })(
                                <Input placeholder="Postcode" />,
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Radius">
                            {getFieldDecorator('miles', {

                            })(
                                <Input placeholder="Miles" />,
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <div className="mt-2 text-center">
                    <Button
                        htmlType="submit"
                        type="primary"
                        className="btn extrawide"
                        size="large">
                        Filter
                    </Button>
                </div>
            </Form>
        );
    }
}

const DriverEventHistory = Form.create({ name: 'event_filter_form' })(DriverEventHistoryFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        event_types: state.asset.event_types,
        driverList: state.driver.driverList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DriverEventHistory);
