import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    Input,
    Switch,
    Icon,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as eventsActions from '../../../core/events/eventsActions';

class AlertsTab extends Component {
    componentDidMount() {
        const { actions } = this.props;
        actions.getCompanyEventsRequest();
    }

    change = (data) => {
        const { onChange } = this.props;
        onChange({
            id: data.key,
            name: data.name,
            email: data.email,
            notify: data.notify,
            icon: data.icon,
        });
    };

    switchChange = (val, obj) => {
        const myObj = obj;
        myObj.notify = val;
        this.change(myObj);
    };

    inputChange = (val, obj) => {
        const myObj = obj;
        myObj.email = val.target.value;
        this.change(myObj);
    };

    render() {
        const { onChange, value } = this.props;
        const columnsAlert = [
            {
                key: 'icon',
                align: 'right',
                render: (i) => {
                    if (i.icon !== '' && i.icon !== null) {
                        return <Icon type={i.icon} />;
                    }
                    return null;
                },
            },
            {
                title: 'telematics event',
                key: 'telematicsEvent',
                dataIndex: 'name',
                align: 'left',
            },
            {
                title: 'email alert',
                key: 'date',
                align: 'center',
                render: (val) => <Switch onChange={(n) => this.switchChange(n, val)} defaultChecked={val.notify} size="small" />,
            },
            {
                title: 'email',
                key: 'email',
                align: 'center',
                render: (val) => <Input onBlur={(n) => this.inputChange(n, val)} defaultValue={val.email} size="small" />,
            },
        ];
        const dataAlert = value.map((v) => ({
            key: v.id,
            name: v.name,
            email: v.email,
            notify: v.notify !== '0',
            icon: v.icon,
        }));

        return (
            <div
                style={{
                    maxWidth: 534,
                    padding: '24px 0',
                }}>
                <Table
                    columns={columnsAlert}
                    dataSource={dataAlert}
                    pagination={false} />
            </div>
        );
    }
}

AlertsTab.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(
        PropTypes.exact({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            name: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            notify: PropTypes.oneOf(['0', '1']).isRequired,
            icon: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        events: state.events.events,
        eventsPagination: state.events.eventsPagination,
        isFetching: state.events.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AlertsTab);
