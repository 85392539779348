/* eslint-disable react/no-unused-state */

import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DOM from './reports';
import * as globalActions from '../../core/global/globalActions';

class Reports extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
        };
    }

    render() {
        return this.view();
    }
}

Reports.defaultProps = {
};

Reports.propTypes = {
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        company: state.user.userCompany,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Reports);
