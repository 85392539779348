import React from 'react';

const RemoteTachographDownloadSVG = () => (
    <svg width={64} height={64} xmlns="http://www.w3.org/2000/svg">
        <g stroke="null">
            <path d="M50.929 32a18.793 18.793 0 00-2.157-8.742L45.94 26.09A15.045 15.045 0 0147.143 32h3.786zM46.034 20.643l-2.677-2.677-8.924 8.924A5.614 5.614 0 0032 26.321 5.679 5.679 0 1037.679 32a5.614 5.614 0 00-.57-2.433l8.925-8.924zM32 33.893A1.893 1.893 0 1133.893 32 1.895 1.895 0 0132 33.893zM32 16.857a15.051 15.051 0 015.91 1.203l2.832-2.832A18.903 18.903 0 0013.072 32h3.785A15.16 15.16 0 0132 16.857z" />
            <path d="M32 58.5A26.5 26.5 0 1158.5 32 26.53 26.53 0 0132 58.5zm0-49.214A22.714 22.714 0 1054.714 32 22.74 22.74 0 0032 9.286z" />
        </g>
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default RemoteTachographDownloadSVG;
