/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import GridViewBase from './GridViewBase';
import './gridView.scss';
import { Button } from 'antd';
import Icon from '../Icon';

const GridView = ({
    pagination, data, sortableColumns, onChange, disablePagination, exportableColumns,
    showExportButton, expandedRowRender, hiddenColumns,
}) => (
    <GridViewBase
        showExportButton={showExportButton}
        disablePagination={disablePagination}
        exportableColumns={exportableColumns}
        expandedRowRender={expandedRowRender}
        pagination={{
            ...pagination,
            itemRender: (current, type, originalElement) => {
                if (type === 'prev') {
                    return (
                        <Button
                            type="default"
                            className="btn btn-pagination-nav">
                            <Icon name="chevron-left" />
                            <span>
                                Prev
                            </span>
                        </Button>
                    );
                }
                if (type === 'next') {
                    return (
                        <Button
                            type="default"
                            className="btn btn-pagination-nav">
                            <span>Next</span>
                            <Icon name="chevron-right" />
                        </Button>
                    );
                }
                return originalElement;
            },
        }}
        onChange={onChange}
        data={data}
        sortableColumns={sortableColumns} 
        hiddenColumns={hiddenColumns}
    />
);

GridView.propTypes = {
    disablePagination: PropTypes.bool,
    pagination: PropTypes.object,
    data: PropTypes.array,
    sortableColumns: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    showExportButton: PropTypes.bool,
    exportableColumns: PropTypes.array,
    expandedRowRender: PropTypes.func,
    hiddenColumns: PropTypes.array,
};

GridView.defaultProps = {
    disablePagination: false,
    pagination: {
        total: 0,
    },
    data: [],
    sortableColumns: [],
    onChange: () => null,
    showExportButton: false,
    exportableColumns: [],
    expandedRowRender: null,
    hiddenColumns: [],
};

export default GridView;
