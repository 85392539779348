import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DOM from './departmentsView';

import * as DepartmentActions from '../../core/department/departmentActions';

class DepartmentsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            departmentUsersSearchParams: {},
            departmentUsersOrderParams: {},
        };
        this.view = DOM;
    }

    componentDidMount() {
        const { actions, departmentId } = this.props;
        actions.getDepartmentDetailsRequest({ department_id: departmentId });
        actions.getDepartmentUsersGridRequest({ department_id: departmentId });
        actions.getOtherUsersRequest({ department_id: departmentId });
    }

    gridChange = (pagination, filters, sorter) => {
        const { actions, departmentUsersPagination, departmentId } = this.props;
        const { departmentUsersSearchParams, departmentUsersOrderParams } = this.state;
        if (pagination && pagination.current === departmentUsersPagination.currentPage) {
            departmentUsersOrderParams[sorter.field] = sorter.order ? sorter.order : false;
            this.setState({ departmentUsersOrderParams });
            actions.getDepartmentUsersGridRequest({
                department_id: departmentId,
                page: departmentUsersPagination.currentPage,
                departmentUsersSearchParams,
                departmentUsersOrderParams,
            });
        }
    };

    paginationChange = (page) => {
        const { actions, departmentUsersPagination, departmentId } = this.props;
        const { departmentUsersSearchParams, departmentUsersOrderParams } = this.state;
        if (page !== departmentUsersPagination.currentPage) {
            actions.getDepartmentUsersGridRequest({
                department_id: departmentId,
                page,
                departmentUsersSearchParams,
                departmentUsersOrderParams,
            });
        }
    };

    removeUser(user_id) {
        const { actions, departmentId } = this.props;
        actions.unassignUserRequest({ department_id: departmentId, user_id });
    }

    assignUser(user_id) {
        const { actions, departmentId } = this.props;
        actions.assignUserRequest({ department_id: departmentId, user_id });
    }

    render() {
        return this.view();
    }
}

DepartmentsView.propTypes = {
    actions: PropTypes.object.isRequired,
    departmentUsersPagination: PropTypes.object.isRequired,
    departmentId: PropTypes.number.isRequired,
    departmentDetail: PropTypes.object.isRequired,
    otherUsers: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
    const departmentId = parseInt(ownProps.match.params.departmentId, 10);
    return {
        ...ownProps,
        departmentDetail: state.department.departmentDetail,
        departmentUsers: state.department.departmentUsers,
        departmentUsersPagination: state.department.departmentUsersPagination,
        departmentUsersIsFetching: state.department.isFetching,
        otherUsers: state.department.allUsers,
        departmentId,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...DepartmentActions,
            },
            dispatch,
        ),
    };
}
export const DepartmentsViewTest = DepartmentsView;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DepartmentsView);
