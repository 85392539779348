/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DOM from './users';
import UsersTab from './tabs/UsersTab';
// import DepartmentsTab from './tabs/DepartmentsTab';
import DivisionTab from './tabs/DivisionTab';

import * as AssetActions from '../../core/asset/assetActions';
import * as UserActions from '../../core/user/userActions';
import * as DivisionActions from '../../core/division/divisionActions';

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userCreateModalOpen: false,
            searchParams: {},
            orderParams: {},
            // departmentSearchParams: {},
            // departmentOrderParams: {},
            divisionSearchParams: {},
            divisionOrderParams: {},
            currentTabKey: '1',
            data: [],
            userListFilter: {
                asset_info: '',
                division: '',
                registration_year: '',
                make: '',
                fuel_type: '',
                current_location: '',
                page: 1,
            },
        };
        this.view = DOM;
        this.usersTabContent = UsersTab;
        // this.departmentsTabContent = DepartmentsTab;
        this.divisionsTabContent = DivisionTab;
    }

    componentDidMount() {
        const {
            actions, user, searchParams, orderParams,
        } = this.props;
        actions.getUsersPageRequest({ page: 1, searchParams, ...orderParams });
        // actions.getDepartmentsGridRequest();
        actions.getDivisionsGridRequest();
        actions.clearUserViewFormRequest();
    }

    filterUsers(searchParams) {
        const { actions, orderParams } = this.props;
        actions.getUsersPageRequest({ page: 1, searchParams, orderParams });
        actions.saveUserSearchParamsRequest(searchParams);
        this.setState({ searchParams });
    }

    filterDepartments(searchParams) {
        const { actions, divisionOrderParams } = this.props;
        actions.getDivisionsGridRequest({ page: 1, searchParams, divisionOrderParams });
    }

    removeUser(user_id) {
        const { actions } = this.props;
        actions.deleteUserRequest({ user_id });
    }

    paginationChange(page) {
        const {
            actions, user, searchParams, orderParams,
        } = this.props;

        if (page !== page.pagination.currentPage) {
            // actions.filterUsersRequest({ page, searchParams, orderParams });
            actions.getUsersPageRequest({ page: 1, searchParams, orderParams });
        }
    }

    render() {
        return this.view();
    }
}

Users.propTypes = {
    actions: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    usersPagination: PropTypes.object.isRequired,
    usersIsFetching: PropTypes.bool.isRequired,
    // departments: PropTypes.array.isRequired,
    // departmentsPagination: PropTypes.object.isRequired,
    // departmentsIsFetching: PropTypes.bool.isRequired,
    divisions: PropTypes.array.isRequired,
    divisionsPagination: PropTypes.object.isRequired,
    divisionIsFetching: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        users: state.user.users,
        usersPagination: state.user.usersPagination,
        usersIsFetching: state.user.isFetching,
        // departments: state.department.departments,
        // departmentsPagination: state.department.departmentsPagination,
        // departmentIsFetching: state.department.isFetching,
        divisions: state.division.divisions,
        divisionsPagination: state.division.divisionsPagination,
        divisionIsFetching: state.division.isFetching,
        division: state.division,
        user: state.user,
        orderParams: state.user.userOrderParams,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...AssetActions,
                ...UserActions,
                ...DivisionActions,
            },
            dispatch,
        ),
    };
}
export const UsersTest = Users;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Users);
