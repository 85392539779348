/* eslint-disable react/prop-types */
import React from 'react';

const UserOutlinedSVG = () => (
    <svg
        width={120}
        height={120}
        viewBox="0 0 357 300"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178 195C227.706 195 268 154.706 268 105C268 55.2944 227.706 15 178 15C128.294 15 88 55.2944 88 105C88 154.706 128.294 195 178 195ZM283 105C283 149.249 255.629 187.105 216.894 202.561C283.574 218.647 337.166 275.789 356.865 350H341.361C318.5 269.453 253.041 213 178.5 213C103.959 213 38.4998 269.453 15.6389 350H0.134613C19.7727 276.019 73.0942 219.002 139.486 202.712C100.55 187.352 73 149.393 73 105C73 47.0101 120.01 0 178 0C235.99 0 283 47.0101 283 105Z"
            fill="#8396A1" />
    </svg>
);

export default UserOutlinedSVG;
