import React from 'react';

const VideoFilledIconSvg = () => (
    <svg
        id="icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        x="0px"
        y="0px"
        width="20px"
        height="20px">
        <path fill="#707070" d="M21,26H4a2,2,0,0,1-2-2V8A2,2,0,0,1,4,6H21a2,2,0,0,1,2,2v4.06l5.42-3.87A1,1,0,0,1,30,9V23a1,1,0,0,1-1.58.81L23,19.94V24A2,2,0,0,1,21,26Z" transform="translate(0 0)" />
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default VideoFilledIconSvg;
