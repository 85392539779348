/* eslint-disable react/no-this-in-sfc */
import React from 'react';

import { isEmpty } from 'underscore';
import ChartHeader from '../ChartHeader';
import ChartMultiDropDown from '../ChartMultiDropDown';
import './bestDriverChart.scss';
import { Empty } from 'antd';

import history from '../../../../core/utils/history';

import defaultIcon from '../../../../assets/images/wheel-hands-icon.png';
import medalIcon from '../../../../assets/images/medal.png';

export default function () {
    const ordinal_suffix_of = (i) => {
        const j = i % 10;
        const k = i % 100;
        if (j == 1 && k != 11) {
            return `${i}st`;
        }
        if (j == 2 && k != 12) {
            return `${i}nd`;
        }
        if (j == 3 && k != 13) {
            return `${i}rd`;
        }
        return `${i}th`;
    };

    const {
        rightMenuItems,
        rightMenuOnClick,
        tooltipText,
        onFirstOptionChange,
        onSecondOptionChange,
        onThirdOptionChange,
        onFourOptionChange,
        defaultFirstOptionValue,
        defaultSecondOptionValue,
        defaultThirdOptionValue,
        defaultFourOptionValue,
        onAllOptionChange,
        userDivisions,
        drivers,
        assets,
        heightRatio,
        imageUrl,
        leftData,
        leftSubtitle,
        leftDataClass,
        rightData,
        rightSubtitle,
        rightDataClass,
        bottomTitle,
        bottomData,
        bottomDataClass,
        bestDriverChartData,
        chartKey,
    } = this.props;

    const { timeframe } = this.state;

    let widgetHeight = heightRatio * 200;
    if (heightRatio > 1) {
        widgetHeight += ((heightRatio - 1) * 95);
    }
    let maxImageWidth = 130 * heightRatio;
    let maxImageHeight = 130 * heightRatio;

    if (heightRatio > 1) {
        maxImageWidth -= 50;
        maxImageHeight -= 50;
    }

    const displayImage = !isEmpty(imageUrl) ? imageUrl : defaultIcon;

    return (
        <div className="new-chart-widget bar-chart-widget" style={{}}>
            <ChartHeader
                hideTimeframe
                timeframe={timeframe}
                onTimeframeChange={(timeframeData) => this.onTimeframeChange(timeframeData)}
                rightMenuOnClick={(rightMenuData) => rightMenuOnClick(rightMenuData)}
                rightMenuItems={rightMenuItems}
                tooltipText={tooltipText} />

            {bestDriverChartData.driver ? (
                <div className="best-driver-chart-content" style={{ width: '100%', height: widgetHeight, overflow: 'hidden' }} id={`modalContent_${chartKey}`}>
                    <div className="content-inner">
                        <div className="left-side">
                            <div className={`title ${leftDataClass}`}>{bestDriverChartData.total_average}%</div>
                            <div className="subtitle">{leftSubtitle}</div>
                        </div>
                        <div className="middle">
                            <img src={displayImage} alt="chart" className="chart-main-picture" style={{ maxWidth: maxImageWidth, maxHeight: maxImageHeight }} />
                        </div>
                        <div className="right-side">
                            <div className={`title ${rightDataClass}`}>{ordinal_suffix_of(bestDriverChartData.rank)} {rightData}</div>
                            <div className="subtitle"> {rightSubtitle}</div>
                        </div>
                    </div>
                    <div className="best-driver-chart-bottom-line" onClick={() => history.push(`/drivers?name=${bestDriverChartData.driver}`)}>
                        <img src={medalIcon} alt="chart" className="bottomIcon" />
                        <span className="title">
                            {bottomTitle} {bestDriverChartData.driver}
                            <span className={`data ${bottomDataClass}`}>{bestDriverChartData.driver_average}%</span>
                        </span>
                    </div>
                </div>
            )
                : (
                    <div className="best-driver-chart-content" style={{ width: '100%', height: widgetHeight, overflow: 'hidden' }} id={`modalContent_${chartKey}`}>
                        <Empty description="No driver found" />
                    </div>
                )}

            <div className="new-chart-widget-footer">
                <ChartMultiDropDown
                    onlyDriver
                    onFirstOptionChange={(firstOption) => onFirstOptionChange(firstOption)}
                    onSecondOptionChange={(secondOption) => onSecondOptionChange(secondOption)}
                    onThirdOptionChange={(thirdOption) => onThirdOptionChange(thirdOption)}
                    onFourOptionChange={(fourOption) => onFourOptionChange(fourOption)}
                    onDataChange={(optionsData) => onAllOptionChange(optionsData)}
                    defaultFirstOptionValue={defaultFirstOptionValue}
                    defaultSecondOptionValue={defaultSecondOptionValue}
                    defaultThirdOptionValue={defaultThirdOptionValue}
                    defaultFourOptionValue={defaultFourOptionValue}
                    userDivisions={userDivisions}
                    drivers={drivers}
                    assets={assets} />
            </div>
        </div>
    );
}
