/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    Spin, Empty, Button, Row, Col, Modal,
} from 'antd';
import { isEmpty } from 'underscore';
import { Link } from 'react-router-dom';
import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';
import Icon from '../../../../../components/elements/Icon';
import MapComponent from '../../../../../components/Map';
import GeoFenceForm from '../../../../Map/GeoFenceForm';

const { GOOGLE_MAP_API_KEY, MAP_SLIDER_INTERVAL } = require('../../../../../core/constants').default;

class ReportTab extends PureComponent {
    constructor(props) {
        super(props);

        this.mapToolBarButtons = [
            { key: 'side-bar', visible: false },
            { key: 'info-box', visible: false },
            { key: 'traffic', visible: false },
            { key: 'geo-fence', visible: false, onClick: this.props.toggleDrawGeoFence.bind(this) },
            { key: 'center-map', visible: false },
            { key: 'satellite', visible: false },
            { key: 'heat-map', visible: false },
            { key: 'live-map', visible: false },
            { key: 'video-search', visible: false },
        ];
    }

    componentDidMount() {
        this.props.handleMapRef(this.googleMapRef);
    }

    componentDidUpdate(prevProps) {
    }

    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange } = this.props;
        onTableChange(pagination, filters, sorter, extra);
    }

    render() {
        const {
            geofenceReport,
            isFetching,
            geofenceReportPagination,
            reportHasBeenFetched,
            googleMapRef,
            newGeoFencePath,
            divisions,
            showSaveGeofence,
        } = this.props;

        let listReports = [];
        if (geofenceReport && geofenceReport.length > 0) {
            listReports = geofenceReport.map((report) => {
                const tempReport = {
                    ...report,
                };

                let url = `/records/detailed-view/${report.record_id}`;
                if (newGeoFencePath?.[0]?.coordinates?.length > 0) {
                    const queryParams = newGeoFencePath[0].coordinates.map((point) => `geo_points[]=${encodeURIComponent(JSON.stringify(point))}`).join('&');
                    url += `?${queryParams}`;
                }

                tempReport.actions = (
                    <a onClick={() => { window.open(url, '_blank').focus() }}>
                        <Icon name="chevron-right" className="ml-2" />
                    </a>
                );
                return tempReport;
            });
        }

        const customEmpty = (
            <Empty
                description={(
                    <span style={{ color: '#64879c', display: 'block' }}>
                        <span style={{
                            fontSize: '180%', fontWeight: 'bold', marginBottom: '5px', display: 'block',
                        }}>
                            Enter your search requirements to generate a report
                        </span>
                        <span style={{ display: 'block' }}>
                            Use the filter bar on the left to select Assets or Drivers,
                            the date and the frequency (if applicable) of the data you would like to display
                        </span>
                    </span>
                )}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{ display: 'none' }} />
        );

        const exportableColumns = listReports && listReports[0] ? (Object.keys(listReports[0]).filter((oK) => oK.toLowerCase() != 'actions')) : [];
        return (
            <Spin spinning={isFetching}>
                <div className="report-table">
                    {isEmpty(listReports) ? null : (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                type="primary"
                                disabled={this.props.isExportingGeofenceReportCsv}
                                onClick={() => { this.props.onExportCSV(); }}>
                                <Spin size="small" spinning={this.props.isExportingGeofenceReportCsv}>Export CSV</Spin>
                            </Button>
                        </div>
                    )}
                    {isEmpty(listReports)
                        ? reportHasBeenFetched
                            ? <Empty />
                            : customEmpty
                        : (
                            <>
                                <GridView
                                    exportableColumns={exportableColumns}
                                    hiddenColumns={['record_id', 'asset_id']}
                                    data={listReports}
                                    onChange={this.onTableChange}
                                    pagination={{
                                        className: 'custom-pagination',
                                        total: geofenceReportPagination.totalRecords,
                                        pageSize: geofenceReportPagination.perPageCount,
                                        showSizeChanger: false,
                                        onChange: (page) => this.onPageChange(page),
                                    }} 
                                />

                            </>
                        )}
                    <Row>
                        <Col span={24}>
                            <div style={{
                                marginBottom: '10px',
                                display: 'flex',
                                height: '50vh',
                                flexDirection: 'row',
                            }}>
                                <MapComponent
                                    ref={(el) => {
                                        this.googleMapRef = el;
                                    }}
                                    mapToolBarButtons={this.mapToolBarButtons}
                                    markerOnMouseLeave={() => { }}
                                    geFenceCompleted={this.props.geFenceCompleted}
                                    hideDatePicker
                                    hideSlider
                                    showToolbar
                                    allowStreetView={false}
                                    showFindCenterButton={false}
                                    apiKey={GOOGLE_MAP_API_KEY}
                                    // markers={markers}
                                    polygonBoundary={newGeoFencePath}
                                    noLimit
                                    onMapLoaded={this.props.onMapMounted}
                                    zoom={15}
                                    autoZoom />
                            </div>
                            <Modal
                                visible={showSaveGeofence}
                                onCancel={() => this.props.cancelGeoFence(false)}
                                footer={false}
                                width={388}>
                                <div className="d-flex dir-column align-center text-center">
                                    {showSaveGeofence ? (
                                        <>
                                            <GeoFenceForm
                                                onSubmit={this.props.createNewTrigger}
                                                redrawGeofence={this.props.toggleDrawGeoFence}
                                                getDivisions={this.props.getDivisions}
                                                hideSelection
                                                divisions={divisions} />
                                            <Button
                                                type="link"
                                                onClick={this.props.cancelGeoFence}>Cancel
                                            </Button>
                                        </>
                                    )
                                        : <p>Processing...</p>}
                                </div>
                            </Modal>

                        </Col>
                    </Row>
                </div>
            </Spin>
        );
    }
}

ReportTab.defaultProps = {
    geofenceReport: [],
    geofenceReportPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 20,
    },
    onTableChange: () => null,
    onMapMounted: () => { },
};

ReportTab.propTypes = {
    geofenceReport: PropTypes.array,
    geofenceReportPagination: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
    onMapMounted: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        geofenceReport: state.report.geofenceReport,
        geofenceReportPagination: state.report.geofenceReportPagination,
        isFetching: state.report.isFetching,
        isExportingGeofenceReportCsv: state.report.isExportingGeofenceReportCsv,
        divisions: state.events.divisions,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
