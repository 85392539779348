/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/jsx-props-no-spreading */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { ReactFlvPlayer } from 'react-flv-player';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
    CoreRouter, AuthRoute, DefaultRoute,
} from './utils/coreRouter';
import AddAsset from '../pages/AddAsset';
import Assets from '../pages/Assets';
// import Compare from '../pages/CompareReports';
import Dashboard from '../pages/Dashboard';
import DataEvents from '../pages/DataEvents';
import DepartmentsView from '../pages/DepartmentsView';
import DivisionsView from '../pages/DivisionsView';
import Drivers from '../pages/Drivers';
import DriversView from '../pages/DriversView';
import EditAsset from '../pages/EditAsset';
import Events from '../pages/Events';
import EventsCustomView from '../pages/EventsCustomView';
import EventsViewPdf from '../pages/EventsViewPdf';
import EventShareCustomView from '../pages/EventShareCustomView';
import EventShareView from '../pages/EventShareView';
import JourneyView from '../pages/JourneyView';
import JourneysView from '../pages/JourneysView';
import Login from '../pages/Login';
import Map from '../pages/Map';
// import MapAlertHistory from '../pages/MapAlertHistory';
import MapHistoricVideos from '../pages/MapHistoricVideos';
import MapLiveVideo from '../pages/MapLiveVideo';
import ProfileView from '../pages/Profile';
import Reports from '../pages/Reports';
import CarbonReportView from '../pages/Reports/ReportPages/CarbonReport';
import DrivingTimeReportView from '../pages/Reports/ReportPages/DrivingTimeReport';
import IdleReportView from '../pages/Reports/ReportPages/IdleReport';
import MileageReportView from '../pages/Reports/ReportPages/MileageReport';
import MpgReportView from '../pages/Reports/ReportPages/MpgReport';
import PTOReportView from '../pages/Reports/ReportPages/PTOReport';
import TimesheetReportView from '../pages/Reports/ReportPages/TimesheetReport';
import TrueIdleReportView from '../pages/Reports/ReportPages/TrueIdleReport';
import NewPtoIdleReport from '../pages/Reports/ReportPages/NewPtoIdleReport';
import SpeedReportView from '../pages/Reports/ReportPages/SpeedReport';
import SpeedingReportView from '../pages/Reports/ReportPages/SpeedingReport';
import UnauthorisedJourneyView from '../pages/Reports/ReportPages/UnauthorisedJourneyReport';
import EcoScoreReportView from '../pages/Reports/ReportPages/EcoScoreReport';
import TrueOdometerReport from '../pages/Reports/ReportPages/TrueOdometerReport';
import ResetPassword from '../pages/ResetPassword';
import SmartWidget from '../components/SmartWidget';
import TwoFactorAuth from '../pages/TwoFactorAuth';
import Users from '../pages/Users';
import UsersView from '../pages/UsersView';
import UserCreate from '../pages/UserCreate';
import Video from '../pages/Video';
import VideoView from '../pages/VideoView';
import VideoCachedView from '../pages/VideoCachedView';
import ViewAsset from '../pages/ViewAsset';
import EventTrigger from '../pages/EventTrigger';
import UtilisationReport from '../pages/Reports/ReportPages/UtilisationReport';
import NoAccess from '../pages/NoAccess';
import EventReport from '../pages/Reports/ReportPages/EventReport';
import GeofenceReport from '../pages/Reports/ReportPages/GeofenceReport';
import RecordsView from '../pages/RecordsView';
import OutsideWorkingHoursReport from '../pages/Reports/ReportPages/OutsideWorkingHoursReport';
import NotMovedReport from '../pages/Reports/ReportPages/NotMovedReport';

// eslint-disable-next-line react/prefer-stateless-function
const adminManagerRoles = ['super-admin', 'admin', 'manager'];
const allUserRoles = ['super-admin', 'admin', 'manager', 'user'];

class Routes extends Component {
    render() {
        const { role, company } = this.props;
        return (
            <CoreRouter>
                <DefaultRoute exact path="/" component={Login} />
                <DefaultRoute exact path="/login" component={Login} />
                <DefaultRoute exact path="/two-factor-auth/:ref" component={TwoFactorAuth} />
                <DefaultRoute exact path="/reset-password/:token" component={ResetPassword} />

                <AuthRoute exact path="/map/:action?" component={Map} />
                <AuthRoute exact path="/no-access" component={NoAccess} />
                {/* <AuthRoute exact path="/map/:device/alert-history/:selectedDate?" component={MapAlertHistory} /> */}
                <AuthRoute exact path="/map/:device/live-video" component={MapLiveVideo} />
                <AuthRoute exact path="/map/:device/historic-video" component={MapHistoricVideos} />

                <AuthRoute exact path="/assets" component={Assets} role={role} allowedOnlyRoles={allUserRoles} />
                <AuthRoute exact path="/assets/view/:asset_id" component={ViewAsset} role={role} allowedOnlyRoles={allUserRoles} />
                <AuthRoute exact path="/assets/edit/:asset_id" component={EditAsset} role={role} allowedOnlyRoles={allUserRoles} />
                <AuthRoute exact path="/assets/new" component={AddAsset} role={role} allowedOnlyRoles={adminManagerRoles} />

                <AuthRoute exact path="/drivers" component={Drivers} role={role} allowedOnlyRoles={allUserRoles} />
                <AuthRoute exact path="/drivers/view/:driverId" component={DriversView} role={role} allowedOnlyRoles={allUserRoles} />

                <AuthRoute exact path="/users" component={Users} role={role} allowedOnlyRoles={adminManagerRoles} />
                <AuthRoute exact path="/users/create" component={UserCreate} role={role} allowedOnlyRoles={adminManagerRoles} />
                <AuthRoute exact path="/users/view/:userId" component={UsersView} role={role} allowedOnlyRoles={adminManagerRoles} />
                <AuthRoute exact path="/profile" component={ProfileView} role={role} />

                <AuthRoute exact path="/department/view/:departmentId" component={DepartmentsView} role={role} allowedOnlyRoles={adminManagerRoles} />
                <AuthRoute exact path="/division/view/:divisionId" component={DivisionsView} role={role} allowedOnlyRoles={allUserRoles} />
                <AuthRoute exact path="/divisions" component={Assets} role={role} allowedOnlyRoles={allUserRoles} />

                <DefaultRoute exact path="/event/:code" component={EventShareView} />
                <DefaultRoute exact path="/custom-event/:code" component={EventShareCustomView} />
                <AuthRoute exact path="/events/search" component={Events} />
                <AuthRoute exact path="/events/:reload?" component={Events} />
                <AuthRoute exact path="/events/custom-view/:eventId/:deviceId/:alertId?" component={EventsCustomView} />
                <AuthRoute exact path="/events-pdf/view/:eventId/:deviceId/:alertId?" component={EventsViewPdf} />

                <AuthRoute exact path="/journey/:journeyId" component={JourneyView} />
                <AuthRoute exact path="/journeys" component={JourneysView} />

                <AuthRoute exact path="/video/view/:videoId" component={VideoView} />
                <AuthRoute exact path="/cached-video/view/:videoReference" component={VideoCachedView} />
                <AuthRoute exact path="/video/:selectedTab/:assetId?" component={Video} />
                <AuthRoute exact path="/video/search" component={Video} />

                <AuthRoute exact path="/dashboard" component={Dashboard} />

                <AuthRoute exact path="/smart-widget" component={SmartWidget} />

                <AuthRoute exact path="/reports" component={Reports} />
                <AuthRoute exact path="/reports/data-events" component={DataEvents} />
                <AuthRoute exact path="/reports/mileage-report" component={MileageReportView} company={company} requiredFeaturePermission="mileage-report" />
                <AuthRoute exact path="/reports/fuel-report" component={MpgReportView} company={company} requiredFeaturePermission="fuel-report" />
                <AuthRoute exact path="/reports/driving-time-report" component={DrivingTimeReportView} company={company} requiredFeaturePermission="driving-time-report" />
                <AuthRoute exact path="/reports/pto-report" component={PTOReportView} company={company} requiredFeaturePermission="pto-report" />
                <AuthRoute exact path="/reports/idle-report" component={IdleReportView} company={company} requiredFeaturePermission="idle-report" />
                <AuthRoute exact path="/reports/true-idle-report" component={TrueIdleReportView} company={company} requiredFeaturePermission="true-idle-report" />
                <AuthRoute exact path="/reports/new-pto-idle-report" component={NewPtoIdleReport} company={company} requiredFeaturePermission="new-pto-idle-report" />
                <AuthRoute exact path="/reports/speed-report" component={SpeedReportView} company={company} requiredFeaturePermission="speed-report" />
                <AuthRoute exact path="/reports/timesheet-report" component={TimesheetReportView} company={company} requiredFeaturePermission="timesheet-report" />
                <AuthRoute exact path="/reports/speeding-report" component={SpeedingReportView} company={company} requiredFeaturePermission="speeding-report" />
                <AuthRoute exact path="/reports/carbon-report" component={CarbonReportView} company={company} requiredFeaturePermission="carbon-report" />
                <AuthRoute exact path="/reports/unauthorised-journey-report" component={UnauthorisedJourneyView} company={company} requiredFeaturePermission="unauthorised-journey-report" />
                <AuthRoute exact path="/reports/outside-working-hours-report" component={OutsideWorkingHoursReport} company={company} requiredFeaturePermission="outside-working-hours-report" />
                <AuthRoute exact path="/reports/not-moved-report" component={NotMovedReport} company={company} requiredFeaturePermission="not-moved-report" />
                {/* NOTE: these following will likely not behave as expected in staging but is essential to ensure we target
                    the correct company in live. This hack will soon be replaced by a config stored per company in DB. */}

                <AuthRoute exact path="/reports/eco-score-report" component={EcoScoreReportView} company={company} requiredFeaturePermission="eco-score-report" />
                <AuthRoute exact path="/reports/asset-odometer-report" component={TrueOdometerReport} company={company} requiredFeaturePermission="asset-odometer-report" />
                <AuthRoute exact path="/reports/utilisation-report" component={UtilisationReport} company={company} requiredFeaturePermission="utilisation-report" />
                <AuthRoute exact path="/reports/event-report" component={EventReport} company={company} requiredFeaturePermission="event-report" />
                <AuthRoute exact path="/reports/geofence-report" component={GeofenceReport} company={company} requiredFeaturePermission="geofence-report" />

                <AuthRoute exact path="/records/detailed-view/:recordId?" component={RecordsView} />

                <AuthRoute exact path="/triggers/view/:triggerId" component={EventTrigger} />

                <Route
                    exact
                    path="/flv"
                    render={(/* Can take props */) => (
                        <ReactFlvPlayer
                            url="https://api.iss-international.co.uk:8445/RealplayFlv.do?Channel=0&DevIDNO=0346080&uSession=FA0FFD07397F0D074862A977F035E99D"
                            isLive
                            height="800px"
                            width="800px"
                            isMuted />
                    )} />
            </CoreRouter>
        );
    }
}

Routes.defaultProps = {
    role: '',
};

Routes.propTypes = {
    role: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        role: state.user.role,
        company: state.user.userCompany,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({

        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
