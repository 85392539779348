import React from 'react';

const NearestVehicleAndPostcodeSearchSVG = () => (
    <svg width={64} height={64} xmlns="http://www.w3.org/2000/svg">
        <g stroke="null">
            <path d="M51.44 24.934l-2.491 2.491L53.506 32l-4.575 4.575 2.508 2.49L58.505 32l-7.066-7.066zM15.051 27.443l-2.49-2.509L5.495 32l7.066 7.066 2.508-2.491L10.494 32l4.557-4.557zM31.991 53.497l-4.575-4.575-2.49 2.509 7.065 7.065 7.066-7.065-2.49-2.491-4.576 4.557zM31.991 10.503l4.558 4.557 2.508-2.49-7.066-7.066-7.065 7.065 2.49 2.509 4.575-4.575zM42.59 32a10.598 10.598 0 10-4.47 8.638l6.748 6.747 2.509-2.508-6.748-6.748A10.598 10.598 0 0042.589 32zM31.99 39.066A7.066 7.066 0 1139.057 32a7.066 7.066 0 01-7.066 7.066z" />
        </g>
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default NearestVehicleAndPostcodeSearchSVG;
