import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spin, Tooltip } from 'antd';
import moment from 'moment';
import { fetchApiAuth } from '../../core/utils/api';

import './deviceTimeline.scss';

export const DEVICE_TIMELINE_STATUS_IDLE_KEY = 'idle';
export const DEVICE_TIMELINE_STATUS_IDLE_TEXT = 'Idle';
export const DEVICE_TIMELINE_STATUS_MOVING_KEY = 'moving';
export const DEVICE_TIMELINE_STATUS_MOVING_TEXT = 'Journey';
export const DEVICE_TIMELINE_STATUS_STOPPED_KEY = 'stopped';
export const DEVICE_TIMELINE_STATUS_STOPPED_TEXT = 'Stopped';

/**
 * Displays a timeline that represents a device's movement throughout the days as bars of moving, idle and stopped.
 * Accepts either predefined timeline data from the backend, or a device and date combination which the component will
 * use to fetch the data from the backend asynchronously.
 */
class DeviceTimeline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            fetchedTimeline: [],
            fetchedGeofenceTimeline: [],
        };
    }

    componentDidMount() {
        const { data, geofenceData } = this.props;

        if ((!data && !geofenceData) || (
            (data && !data.length) && (geofenceData && !geofenceData.length)
        )) {
            this.fetchTimelineData();
        }
    }

    fetchTimelineData = () => {
        const { deviceDetails } = this.props;

        if (!deviceDetails) return;
        if (!deviceDetails.date) return;

        const dateWithoutTime = deviceDetails.date.split(' ')[0];
        const body = {
            date: dateWithoutTime,
        };
        if (deviceDetails.assetId) body.asset_id = deviceDetails.assetId;
        else body.device_id = deviceDetails.deviceId;

        const timelineFetchPromise = new Promise((resolve, reject) => {
            fetchApiAuth({
                method: 'POST',
                url: '/device/timeline',
                body,
            })
                .then((response) => {
                    this.setState({ fetchedTimeline: response.data });
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
        const geofenceTimelineFetchPromise = new Promise((resolve, reject) => {
            fetchApiAuth({
                method: 'POST',
                url: '/device/geofence-timeline',
                body,
            })
                .then((response) => {
                    this.setState({ fetchedGeofenceTimeline: response.data });
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });

        Promise.all([timelineFetchPromise, geofenceTimelineFetchPromise]).then(() => {
            this.setState({ isFetching: false });
        })
    }

    render() {
        const {
            timelineWidth, scaleX, data, geofenceData, isInline,
        } = this.props;
        const {
            isFetching, fetchedTimeline, fetchedGeofenceTimeline,
        } = this.state;

        let timelineData = data;
        let geofenceTimelineData = geofenceData;
        if (!timelineData || !timelineData?.length) timelineData = fetchedTimeline || [];
        if (!geofenceTimelineData || !geofenceTimelineData?.length) geofenceTimelineData = fetchedGeofenceTimeline || [];
        let journeyIndex = 0;
        const timelineComponent = (
            <div
                className="device-timeline"
                style={{
                    width: timelineWidth * scaleX,
                    height: isInline ? '26px' : '32px',
                    position: isInline ? 'relative' : 'absolute',
                    top: isInline ? '0px' : '2px',
                    left: isInline ? '0px' : '6px',
                    marginTop: isInline ? '-4px' : '0px',
                    marginBottom: isInline ? '-4px' : '0px',
                    pointerEvents: isInline ? 'all' : 'none',
                }}
            >
                {timelineData ? timelineData.map((block, i) => {
                    const blockStyleKey = block.status === DEVICE_TIMELINE_STATUS_MOVING_KEY
                        ? DEVICE_TIMELINE_STATUS_MOVING_KEY
                        : (block.status === DEVICE_TIMELINE_STATUS_IDLE_KEY
                            ? DEVICE_TIMELINE_STATUS_IDLE_KEY
                            : DEVICE_TIMELINE_STATUS_STOPPED_KEY);
                    const blockTimeRange = `${moment(block.start_time).format('hh:mm:ss A')} - ${moment(block.end_time).format('hh:mm:ss A')}`;
                    const blockLocation = block.location;
                    if (blockStyleKey === DEVICE_TIMELINE_STATUS_MOVING_KEY) journeyIndex += 1;
                    const blockStatus = block.status === DEVICE_TIMELINE_STATUS_MOVING_KEY
                        ? `${DEVICE_TIMELINE_STATUS_MOVING_TEXT} ${journeyIndex}`
                        : (block.status === DEVICE_TIMELINE_STATUS_IDLE_KEY
                            ? DEVICE_TIMELINE_STATUS_IDLE_TEXT
                            : DEVICE_TIMELINE_STATUS_STOPPED_TEXT);
                    // If idle find where this block overlaps with moving blocks so we can display the prefix
                    let blockPrefix = null;
                    if (block.status === DEVICE_TIMELINE_STATUS_IDLE_KEY) {
                        for (let i = 0; i < timelineData.length; i++) {
                            const currentBlock = timelineData[i];
                            if (currentBlock.status === DEVICE_TIMELINE_STATUS_MOVING_KEY) {
                                if (block.start >= currentBlock.start && block.end <= currentBlock.end) {
                                    blockPrefix = {
                                        status: DEVICE_TIMELINE_STATUS_MOVING_TEXT,
                                        timeRange: `${moment(currentBlock.start_time).format('hh:mm:ss A')} - ${moment(currentBlock.end_time).format('hh:mm:ss A')}`,
                                    };
                                    break;
                                }
                            }
                        }
                    }
                    return (
                        <Tooltip
                            key={`timeline-block-tooltip-${i}-${block.start_time}`}
                            title={(
                                <div>
                                    {blockPrefix ? (
                                        <>
                                            <div style={{ textAlign: 'center' }}>{blockPrefix.status} {journeyIndex}</div>
                                            <div style={{
                                                textAlign: 'center',
                                                margin: '0px'
                                            }}>
                                                {blockPrefix.timeRange}
                                            </div>
                                        </>
                                    ) : null}
                                    <div style={{ textAlign: 'center' }}>{blockStatus}</div>
                                    <div style={{
                                        textAlign: 'center',
                                        margin: '0px'
                                    }}>
                                        {blockTimeRange}
                                    </div>
                                    {blockLocation ? (
                                        <div style={{ textAlign: 'center' }}>{blockLocation}</div>
                                    ) : null}
                                </div>
                            )}
                        >
                            <div
                                key={`timeline-block-${i}-${block.start_time}`}
                                className={`block-${blockStyleKey}`}
                                style={{
                                    width: (block.end - block.start) * scaleX,
                                    left: `${block.start * scaleX}px`,
                                    marginTop: isInline ? '-4px' : '0px',
                                    marginBottom: isInline ? '-4px' : '0px',
                                }}
                            />
                        </Tooltip>
                    );
                }) : null}
                {geofenceTimelineData ? geofenceTimelineData.map((block, i) => {
                    const blockTimeRange = `${moment(block.start_time).format('hh:mm:ss A')} - ${moment(block.end_time).format('hh:mm:ss A')}`;
                    return (
                        <Tooltip
                            key={`geofence-timeline-block-tooltip-${i}-${block.start_time}`}
                            title={(
                                <div>
                                    <div style={{ textAlign: 'center' }}>
                                        In Geo-fence{block.geofence_names.length > 1 ? 's' : ''}:
                                        <br />
                                        {block.geofence_names.join(', ')}
                                    </div>
                                    <span>{blockTimeRange}</span>
                                </div>
                            )}
                        >
                            <div
                                key={`geofence-timeline-block-${i}`}
                                className={`geofence-block${isInline ? ' inline' : ''}`}
                                style={{
                                    width: (block.end - block.start) * scaleX,
                                    left: `${block.start * scaleX}px`,
                                }}
                            />
                        </Tooltip>
                    );
                }) : null}
            </div>
        );

        if ((data && data.length) || (geofenceData && geofenceData.length)) return timelineComponent;
        return (
            <Spin spinning={isFetching}>
                {timelineComponent}
            </Spin>
        );
    }
}

DeviceTimeline.defaultProps = {
    timelineWidth: 86400, // seconds in a day
    scaleX: 1, // no scaling (width 86400)
    data: [],
    geofenceData: [],
    deviceDetails: null,
    isInline: false,
};

DeviceTimeline.propTypes = {
    timelineWidth: PropTypes.number, // length of timeline before scaling down to fit inside a target tag/div
    scaleX: PropTypes.number, // how much to scale the timeline by to fit into a target div, usually calculated by target width / timelineWidth
    data: PropTypes.array, // array of objects with start and end times and status, fetched from backend
    geofenceData: PropTypes.array, // array of objects with start and end times and status, fetched from backend
    deviceDetails: PropTypes.object, // consisting of deviceId/assetId and date, used to fetch the data on the fly, asynchronously
    isInline: PropTypes.bool, // whether to display the timeline inline or as an absolutely positioned block
};

export default DeviceTimeline;
