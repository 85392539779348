/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Input, Select, Form,
} from 'antd';

import * as DivisionActions from '../../../core/division/divisionActions';
import ColorPicker from 'rc-color-picker';
import DOM from '../../../components/Filter/filter';

const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;

class DivisionsLeftSide extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            color: props.division.divisionDetail.color,
        };
    }

    componentDidMount() {
        const { registerSubmitHandler } = this.props;
        registerSubmitHandler(this.submitDetails.bind(this));
    }

    componentDidUpdate(prevProps) {
        const { color } = this.state;
        const { division } = this.props;

        if (division.divisionDetail.color !== prevProps.division.divisionDetail.color) {
            this.setState({ color: division.divisionDetail.color });
        }
    }

    submitDetails() {
        const { actions, form, division } = this.props;
        form.validateFieldsAndScroll((err, v) => {
            if (!err) {
                // console.log({ v });
                const values = {
                    ...v,
                    division_id: division.divisionDetail.id,
                };
                // console.log({ values });
                actions.updateDivisionRequest(values);
                return true;
            }
            return false;
        });
    }

    setColor(name, color) {
        const { form } = this.props;
        if (color && color.color) {
            form.setFieldsValue({ [name]: color.color });
            this.setState({ color: color.color });
        }
    }

    render() {
        const { form, division } = this.props;
        const { color } = this.state;
        const { getFieldDecorator } = form;

        return (
            <>
                <label>
                    Name
                </label>
                <FormItem>
                    {getFieldDecorator('name', {
                        initialValue: division.divisionDetail.name,
                        rules: [
                            {
                                required: true,
                                message: 'Please enter division name',
                            },
                        ],
                    })(<Input placeholder="Division Name" />)}
                </FormItem>
                <label>
                    Description
                </label>
                <FormItem>
                    {getFieldDecorator('description', {
                        initialValue: division.divisionDetail.description,
                    })(<TextArea rows={4} style={{ height: 200 }} />)}
                </FormItem>
                <label>
                    Color
                </label>
                <FormItem>
                    {getFieldDecorator('color', {
                        initialValue: color,
                    })(
                        <ColorPicker
                            animation="slide-up"
                            color={color}
                            /* Must keep both onChange & onClose events */
                            onChange={(newColor) => this.setColor('color', newColor)}
                            onClose={(newColor) => this.setColor('color', newColor)} />
                    )}
                </FormItem>
                <label>
                    Division status
                </label>
                <FormItem>
                    {getFieldDecorator('status', {
                        initialValue: division.divisionDetail.status,
                        rules: [
                            {
                                required: true,
                                message: 'Please select status',
                            },
                        ],
                    })(
                        <Select style={{ width: '100%' }}>
                            <Option value={1}>Active</Option>
                            <Option value={0}>Inactive</Option>
                        </Select>,
                    )}
                </FormItem>
            </>
        );
    }
}

DivisionsLeftSide.defaultProps = {
    registerSubmitHandler: () => null,
};

DivisionsLeftSide.propTypes = {
    registerSubmitHandler: PropTypes.func,
    division: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
};

const DivisionMainForm = Form.create()(DivisionsLeftSide);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        division: state.division,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...DivisionActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DivisionMainForm);
