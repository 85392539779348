/* eslint-disable react/destructuring-assignment */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ExportToCsv } from 'export-to-csv';

import { isEmpty } from 'underscore';
import { notification } from 'antd';
import DOM from './speedReport';

import * as eventsActions from '../../../../core/events/eventsActions';
import * as reportActions from '../../../../core/report/reportActions';

class SpeedReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {},
            filterSidebar: true,
        };
        this.view = DOM;
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.resetReportsRequest();
    }

    onEventsTabFilterChange = (values) => {
        const filters = values;
        if (filters.date_from) {
            filters.date_from = values.date_from.format('YYYY-MM-DD');
        }
        if (filters.date_to) {
            filters.date_to = values.date_to.format('YYYY-MM-DD');
        }

        filters.page = 1;
        this.setState({ filters });
        const { actions } = this.props;
        const params = { ...filters };
        actions.getSpeedReportRequest(params);
    }

    exportCsv() {
        const { speedReport } = this.props;

        if (isEmpty(speedReport)) {
            notification.warning({ message: 'Warning', description: 'There is no data to export!' });
            return false;
        }

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Speed Report CSV',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: 'Speed Report',
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(speedReport);
    }

    render() {
        return this.view(
            {
                update: (newVals) => (
                    this.props.actions.UpdateEventNotifications(newVals)
                ),
            },
        );
    }
}

SpeedReport.propTypes = {
    actions: PropTypes.object.isRequired,
    speedReport: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        speedReport: state.report.speedReport,
        events: state.events.companyEvents,
        user: state.user,
        filterSidebar: state.filterSidebar,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...reportActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SpeedReport);
