import { Component } from 'react';
import PropTypes from 'prop-types';
import DOM from './filterSidebar';

class FilterSidebar extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            filterSidebar: props.displayFilter,
        };
        props.registerCloseSidebar(this.closeSidebar.bind(this));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { destroyFilter } = this.props;
        const { destroyFilter: prevDestroyFilter } = prevProps;
        if (destroyFilter !== prevDestroyFilter && destroyFilter) {
            this.setState({ filterSidebar: false });
        }
    }

    toggleFilterSidebar = () => {
        const { filterSidebar } = this.state;
        this.setState({
            filterSidebar: !filterSidebar,
        });
    };

    closeSidebar() {
        this.setState({
            filterSidebar: false,
        });
    }

    render() {
        return this.view(this.props);
    }
}

FilterSidebar.defaultProps = {
    registerCloseSidebar: () => null,
    displayFilter: false,
    destroyFilter: false,
};

FilterSidebar.propTypes = {
    registerCloseSidebar: PropTypes.func,
    displayFilter: PropTypes.bool,
    destroyFilter: PropTypes.bool,
};

export default FilterSidebar;
