import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Form,
    Select,
    Button,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Icon from '../../../components/elements/Icon';

class LiveVideoFilterForm extends Component {
    constructor(props) {
        super(props);
    }

    submitForm = (e) => {
        e.preventDefault();
        const { onSubmit } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    }

    render() {
        const {
            form, divisions, vehicleTypes, assetOptions, userRole,
        } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Form onSubmit={this.submitForm} layout="vertical">
                <Form.Item label="Asset name or reg">
                    {getFieldDecorator('asset_info', {

                    })(
                        <Select
                            mode="multiple"
                            showSearch
                            allowClear
                            filterOption={(txt, obj) => (obj.props.children.toLowerCase().includes(txt.toLowerCase()))}
                            placeholder="Asset name or reg"
                            suffixIcon={<Icon name="triangle-down" />}>
                            {assetOptions.map((a) => (
                                <Select.Option key={a.id} value={a.id}>
                                    {a.name === a.reg || a.reg === '' ? a.name : `${a.name} [${a.reg}]`}
                                </Select.Option>
                            ))}
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Division">
                    {getFieldDecorator('division', {
                        rules: [
                        ],
                    })(
                        <Select
                            placeholder="Select division"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            mode="multiple"
                            style={{ width: '100%' }}
                            suffixIcon={
                                <Icon name="triangle-down" />
                            }>
                            {
                                divisions.map((v, i) => (
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    <Select.Option key={`${i}`} value={v.id}>{v.name}</Select.Option>
                                ))
                            }

                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Vehicle type">
                    {getFieldDecorator('vehicle_type')(
                        <Select
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            placeholder="Select vehicle type"
                            mode="multiple"
                            suffixIcon={<Icon name="triangle-down" />}>
                            {
                                vehicleTypes.map((v, i) => (
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    <Select.Option key={`${i}`} value={v.id}>{v.title}</Select.Option>
                                ))
                            }
                        </Select>,
                    )}
                </Form.Item>
                {userRole === 'super-admin' && (
                    <Form.Item label="Video Api">
                        {getFieldDecorator('video_api', {

                        })(
                            <Select
                                placeholder="Select video api"
                                suffixIcon={<Icon name="triangle-down" />}>
                                <Select.Option value="all">All</Select.Option>
                                <Select.Option value="Icar">Icar</Select.Option>
                                <Select.Option value="Howen">Howen</Select.Option>
                            </Select>,
                        )}
                    </Form.Item>
                )}
                <div className="mt-2 text-center">
                    <Button
                        htmlType="submit"
                        type="primary"
                        className="btn extrawide"
                        size="large">
                        Filter
                    </Button>
                </div>
            </Form>
        );
    }
}

LiveVideoFilterForm.propTypes = {
    event_types: PropTypes.array.isRequired,
    divisions: PropTypes.array.isRequired,
    vehicleTypes: PropTypes.array.isRequired,
    assetOptions: PropTypes.array.isRequired,
    userRole: PropTypes.string.isRequired,
};

const LiveVideoFilter = Form.create({ name: 'event_filter_form' })(LiveVideoFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        event_types: state.asset.event_types,
        divisions: state.asset.divisions,
        vehicleTypes: state.asset.vehicleTypes,
        assetOptions: state.asset.options,
        userRole: state.user.role,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LiveVideoFilter);
