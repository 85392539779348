import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isUndefined } from 'underscore';
import DOM from './eventsView';
import * as eventsActions from '../../core/events/eventsActions';
import * as globalActions from '../../core/global/globalActions';

class EventsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            before_time: 15,
            after_time: 15,
        };
        this.view = DOM;
    }

    componentDidMount() {
        const { actions, match } = this.props;
        const {
            eventId, deviceId, alertId,
        } = match.params;
        const params = {};
        params.eventId = eventId;
        params.deviceId = deviceId;
        params.eventView = 'true';
        params.before_time = 900;
        params.after_time = 900;
        if (!isUndefined(alertId)) {
            params.speed = alertId;
        }
        // actions.getEventInfoRequest(params);
    }

    onTimeDataChange = (value, valueType) => {
        const { actions, match } = this.props;
        const {
            eventId, deviceId, alertId,
        } = match.params;
        const search = {};
        if (!isUndefined(alertId)) {
            search.speed = alertId;
        }
        if (valueType == 'before') {
            search.before_time = value;
            search.after_time = this.state.after_time;
            this.setState({ before_time: parseInt(value, 10) });
        } else if (valueType == 'after') {
            search.before_time = this.state.before_time;
            search.after_time = value;
            this.setState({ after_time: parseInt(value, 10) });
        }

        if (eventId) {
            actions.getEventInfoRequest({
                eventId,
                deviceId,
                ...search,
            });
        }
    }

    render() {
        return this.view();
    }
}

EventsView.propTypes = {
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        selectedEvent: state.events.selectedEvent,
        isFetching: state.events.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...eventsActions,
                ...globalActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EventsView);
