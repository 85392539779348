import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const GeneralInfo = (props) => {
    const {
        name,
        registration,
        year_registration,
        category,
        last_service,
        division,
        fuel_type,
        fuelTitle,
        make,
        model,
        chassis,
    } = props;
    const assetInfo = [
        { name: 'Asset Name:', value: name },
        { name: 'Asset Reg: ', value: registration },
        { name: 'Year Registered: ', value: year_registration.toString() },
        { name: 'Asset category: ', value: category },
        { name: 'Date last serviced: ', value: last_service != null ? moment(last_service).format('DD/MM/YYYY') : 'Unknown' },
        { name: 'Division:', value: division !== null ? division.name : 'None' },
        { name: 'Fuel Type:', value: fuelTitle },
        { name: 'Asset Make:', value: make },
        { name: 'Asset Model:', value: model },
        // { name: 'Asset Chassis:', value: chassis },
    ];

    return (
        <ul className="list">
            {assetInfo.map((item) => (
                <li
                    className="list__item"
                    key={item.name}>
                    <div className="list__item-name">
                        {item.name}
                    </div>
                    <div className="list__item-value">
                        {item.value}
                    </div>
                </li>
            ))}
        </ul>
    );
};

GeneralInfo.defaultProps = {
    name: 'Unknown',
    registration: 'Unknown',
    year_registration: 'Unknown',
    category: 'Unknown',
    last_service: '1970-1-1 00:00:00',
    division: { id: '0', company_id: '0', name: 'Unknown' },
    fuel_type: 'Unknown',
    make: 'Unknown',
    model: 'Unknown',
    chassis: 'Unknown',
};

GeneralInfo.propTypes = {
    name: PropTypes.string,
    registration: PropTypes.string,
    year_registration: PropTypes.string,
    category: PropTypes.string,
    last_service: PropTypes.string,
    division: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        company_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
    }),
    fuel_type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    make: PropTypes.string,
    model: PropTypes.string,
    chassis: PropTypes.string,
};

export default GeneralInfo;
