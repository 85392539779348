import Layout from "../../../../components/layout/Layout";
import React from "react";
import ReportFilter, {DATE_MODE_RANGE} from "../../ReportFilter";
import FilterSidebar from "../../../../components/FilterSidebar";
import {Link} from "react-router-dom";
import Icon from "../../../../components/elements/Icon";
import ReportTab from "./tabs/ReportTab";

export default function () {

    const {
        eventsTabLoaded, eventsTabFilter, filterSidebar, filters,
    } = this.state;

    return (
        <Layout
            className={"outside-working-hours-report"}
            title={<div>Inside/Outside Working Hours Report</div>}
            headerTitle={<div>Inside/Outside Working Hours Report</div>}
        >
            <div className="content-wrap">
                <FilterSidebar displayFilter={filterSidebar}>
                    <ReportFilter
                        onSubmit={this.onEventsTabFilterChange}
                        filters={filters}
                        showDivision
                        dateMode={DATE_MODE_RANGE}
                        insideOutsideSelection
                        showWorkingHoursRange
                    />
                </FilterSidebar>

                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__header">
                                <div className="section__header-item">
                                    <Link
                                        to="/reports"
                                        className="d-flex align-center">
                                        <Icon name="arrow-left"/>
                                        <span>All Reports</span>
                                    </Link>
                                </div>
                                <div className="section__header-item is-active">
                                    <span>Report: Inside/Outside Working Hours Report</span>
                                </div>
                            </div>
                            <div className="section__body">
                                <div className="section__action-bar">
                                </div>
                                <ReportTab
                                    toggleFilterSidebar={() => this.setState({filterSidebar: !filterSidebar})}
                                    eventsTabFilter={eventsTabFilter}
                                    setLoadedState={eventsTabLoaded}
                                    eventsTabLoaded={eventsTabLoaded}
                                />
                            </div>
                        </section>
                    </div>
                </div>

            </div>
        </Layout>
    )
}