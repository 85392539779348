import React, { Component } from 'react';
import {
    Form,
    Select,
    Button,
    DatePicker,
} from 'antd';
import moment from 'moment';
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { isEmpty } from 'underscore';

import Icon from '../../../components/elements/Icon';
import { isoStandardDateTimeFormat } from '../../../core/utils/dateUtils';
import * as driverActions from '../../../core/driver/driverActions';
import * as assetActions from '../../../core/asset/assetActions';

const { RangePicker } = DatePicker;

class assetJourneyHistoryFilterForm extends Component {
    componentDidMount() {
        const { actions, asset_id } = this.props;

        actions.getDriverListRequest({ asset_id });
    }

    submitForm = (e) => {
        e.preventDefault();
        const { actions, onSubmit } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
                const { date_range } = values;
                if (!isEmpty(date_range)) {
                    const dateRange = [
                        date_range[0].format(isoStandardDateTimeFormat),
                        date_range[1].format(isoStandardDateTimeFormat),
                    ];
                    values.dateRange = dateRange;
                }
                actions.setAssetJourneyFilters(values);
            }
        });
    }

    render() {
        const { form, driverList, assetJourneyFilters } = this.props;
        const { is_authorised, dateRange } = assetJourneyFilters;

        const { getFieldDecorator } = form;
        return (
            <Form onSubmit={this.submitForm} layout="vertical">
                <Form.Item label="Date Range">
                    {getFieldDecorator('date_range', {
                        initialValue: !isEmpty(dateRange)
                            ? [moment(dateRange[0]), moment(dateRange[1])]
                            : [],
                    })(
                        <RangePicker
                            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                            placeholder="Date Range"
                            format={isoStandardDateTimeFormat} />,
                    )}
                </Form.Item>
                <Form.Item label="Journey events">
                    {getFieldDecorator('journey_event', {
                    })(
                        <Select
                            placeholder="Select Journey events"
                            suffixIcon={<Icon name="triangle-down" />}>
                            <Select.Option value="all">All</Select.Option>
                            <Select.Option value="1">Yes</Select.Option>
                            <Select.Option value="0">No</Select.Option>
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Driver">
                    {getFieldDecorator('driver', {

                    })(
                        <Select
                            mode="multiple"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            placeholder="Select driver"
                            suffixIcon={<Icon name="triangle-down" />}>
                            {driverList.map((dl) => <Select.Option key={dl.id} value={dl.id}>{dl.name}</Select.Option>)}
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Authorised">
                    {getFieldDecorator('is_authorised', {
                        initialValue: is_authorised !== null && is_authorised !== undefined ? is_authorised : 'all',
                    })(
                        <Select
                            placeholder="Select authorised journeys"
                            suffixIcon={<Icon name="triangle-down" />}>
                            <Select.Option value="all">All</Select.Option>
                            <Select.Option value="1">Yes</Select.Option>
                            <Select.Option value="0">No</Select.Option>
                        </Select>,
                    )}
                </Form.Item>
                <div className="mt-2 text-center">
                    <Button
                        htmlType="submit"
                        type="primary"
                        className="btn extrawide"
                        size="large">
                        Filter
                    </Button>
                </div>
            </Form>
        );
    }
}

assetJourneyHistoryFilterForm.propTypes = {
    asset_id: PropTypes.string.isRequired,
    driverList: PropTypes.array.isRequired,
    assetJourneyFilters: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
};

const assetJourneyHistoryFilter = Form.create({ name: 'event_filter_form' })(assetJourneyHistoryFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        driverList: state.driver.driverList,
        assetJourneyFilters: state.asset.assetJourneyFilters,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...driverActions,
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(assetJourneyHistoryFilter);
