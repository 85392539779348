/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import {
    AutoComplete,
    Slider,
    Modal,
    Button,
    Radio,
    Tag,
    Tooltip,
    Input,
    notification,
} from 'antd';
import Icon from '../elements/Icon';

import './filter.css';

const { Option } = AutoComplete;

export default function () {
    const {
        filterSidebar,
        modalAlertVisible,
        modalOptionsVisible,
        modalCacheVisible,
        tags,
        inputValue,
    } = this.state;

    const saveInputRef = (input) => (this.input = input);
    this.showInfoMessage = () => {
        notification.open({
            message: (
                <div className="notification-inner">
                    <div className="notification__header">
                        <Icon name="bell" />
                        <h5 className="h-5">New video is available!</h5>
                    </div>
                    <div>
                        Video #10001 requested on 01/05/2019 to view.
                        {' '}
                        <a href="/" className="btn-link">
                            Click here to view
                        </a>
                    </div>
                </div>
            ),
            duration: 50,
        });
    };

    const mapFeaturesList = [
        { featureName: 'toggleFilterSidebar', iconName: 'hamb-with-dots' },
        { featureName: 'showInfoMessage', iconName: 'info' },
        { featureName: undefined, iconName: 'car' },
        { featureName: 'showModalAlert', iconName: 'earth' },
        { featureName: 'toggleModalOptions', iconName: 'target' },
        { featureName: 'toggleModalCache', iconName: 'satellite' },
        { featureName: undefined, iconName: 'fire' },
    ];
    const filterSidebarDataItems = [
        { label: 'Live Video feed', iconName: 'road', active: true },
        { label: 'Historic Videos', iconName: 'clock' },
        { label: 'Events History', iconName: 'warning' },
        { label: 'Telematics Data', iconName: 'data' },
        { label: 'Data Download', iconName: 'download' },
    ];
    const driverData = [
        { label: 'joe smith', status: 'last driven', iconName: 'clock' },
        {
            label: 'kightsbirdge london',
            status: 'Location',
            iconName: 'location',
        },
        { label: 'joe smith', status: 'last driver', iconName: 'clock' },
    ];
    const dataSource = [
        {
            title: 'Libraries',
        },
        {
            title: 'Solutions',
        },
        {
            title: 'Articles',
        },
    ];
    const options = dataSource.map((opt) => (
        <Option key={opt.title} value={opt.title}>
            {opt.title}
        </Option>
    ));
    return (
        <div data-test='component-filter' className={`filter ${filterSidebar ? 'sidebar-is-active' : ''}`}>
            {filterSidebar ? (
                <div className="filter__sidebar">
                    {/* <div className="filter__sidebar-inner">
            <div className="sidebar__header">
                <button type="button" className="btn btn-empty backlink">
                    <Icon name="arrow-left" />
                </button>
                <span>Assets</span>
            </div>
            <div className="sidebar__search">
                <AutoComplete
                    dropdownClassName="filter__sidebar-search-dropdown"
                    dropdownMatchSelectWidth={false}
                    size="large"
                    style={{ width: '100%' }}
                    dataSource={options}
                    optionLabelProp="value"
                >
                    <Input
                        placeholder="Find asset by name, reg or driver"
                        suffix={<Icon name="magnifier" className="sidebar__search-input" />}
                    />
                </AutoComplete>
            </div>
            <div className="filter__sidebar-data">
                {filterSidebarDataItems.map((item, itemIndex) => (
                    <div key={itemIndex} className="dataitem">
                        <span className="indicator-activity" />
                        <div className="dataitem__text">
                            <p className="dataitem__title">
                                Biffa 123
                                <span>(1 Mi)</span>
                            </p>
                            <p className="dataitem__subtitle">BX54 MXU - Volvo FH4</p>
                        </div>
                        <button type="button" className="btn btn-empty dataitem__next">
                            <Icon name="arrow-right" />
                        </button>
                    </div>
                ))}
            </div>
        </div> */}

                    <div className="filter__sidebar-inner">
                        <div className="driver__header">
                            <div className="driver__name">
                                <button
                                    type="button"
                                    className="btn btn-empty backlink">
                                    <Icon name="arrow-left" />
                                </button>
                                <span>Biffa 123 </span>
                                <span className="ml-1 secondary-text">
                                    (1 Mi)
                                </span>
                            </div>
                            <div className="driver-cars">
                                <span>BX64</span>
                                <span>Volvo fh4</span>
                            </div>
                        </div>
                        <div className="driver-info">
                            {driverData.map((item, itemIndex) => (
                                <div
                                    key={itemIndex}
                                    className="driver-info__cell">
                                    <span className="driver-info__status">
                                        <Icon name={item.iconName} />
                                        {item.status}
                                    </span>
                                    <span className="driver-info__label">
                                        {item.label}
                                    </span>
                                </div>
                            ))}
                        </div>
                        <div className="filter__sidebar-data">
                            {filterSidebarDataItems.map((item, itemIndex) => (
                                <div
                                    key={itemIndex}
                                    className={`dataitem ${
                                        item.active ? 'is-active' : ''
                                    }`}>
                                    <span className="dataitem__icon">
                                        <Icon name={item.iconName} />
                                    </span>
                                    <div className="dataitem__text">
                                        <p className="dataitem__title">
                                            {item.label}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ) : null}
            {/* <div className="breadcrumbs">
        <button type="button" className="btn btn-empty backlink">
            <Icon name="chevron-left" />
        </button>
        <Icon name="road" />
        <span className="ml-2">Live Video feed</span>
    </div> */}
            <ul className="filter__list">
                {mapFeaturesList.map((item, itemIndex) => (
                    <li
                        className={`${item.className || ''}`}
                        key={item.key || `nav-item-${itemIndex}`}>
                        <button
                            type="button"
                            className="btn btn-empty"
                            onClick={() => (item.featureName
                                ? this[item.featureName]()
                                : null)}>
                            {item.iconName ? (
                                <Icon name={item.iconName} />
                            ) : null}
                        </button>
                    </li>
                ))}
            </ul>
            <div className="filter__address">
                <AutoComplete
                    dropdownClassName="filter__address-dropdown"
                    dropdownMatchSelectWidth={false}
                    dataSource={options}>
                    <Input
                        placeholder="Address or postcode…"
                        className="filter__address-input"
                        suffix={<Icon name="magnifier" />} />
                </AutoComplete>
            </div>
            <div className="filter__date">
                <div className="date-slider">
                    <Slider defaultValue={100} />
                    <span className="slider-label">Live (Now)</span>
                </div>
                <Icon name="calendar" />
                <span className="ml-1">20/07/2019</span>
            </div>
            {/* <Button className="btn btn-white funnel-btn ml-2">
        <Icon name="funnel" />
    </Button> */}
            <Modal
                visible={modalAlertVisible}
                onCancel={this.handleCancelModalAlert}
                footer={false}
                width={388}>
                <div className="d-flex dir-column align-center text-center">
                    <h4 className="h-4">Create a geofence</h4>
                    <p className="mt-2">
                        Using the geofence tool you can create geographic
                        boundaries that will create an event if an asset enters
                        a particular area
                    </p>
                    <Button
                        type="primary"
                        className="btn extralarge extrawide mt-3">
                        Create
                    </Button>
                </div>
            </Modal>
            <Modal
                visible={modalCacheVisible}
                onCancel={this.toggleModalCache}
                footer={false}
                width={388}>
                <div className="d-flex dir-column align-center text-center">
                    <h4 className="h-4">Cache requested 03/09/2019 at 16:13</h4>
                    <p className="mt-2">
                        We will notify you via email once the video is cached
                        and is playable via our portal.
                    </p>
                </div>
            </Modal>
            <Modal
                visible={modalOptionsVisible}
                onCancel={this.toggleModalOptions}
                footer={false}
                width={388}>
                <div className="d-flex dir-column align-center">
                    <h4 className="h-4">Geofence alert options</h4>
                    <div className="options">
                        <div className="options__item">
                            <h5 className="h-5">Actions:</h5>
                            <div className="d-flex align-center">
                                <Button className="btn btn-link">Redraw</Button>
                                <Button className="btn btn-link ml-2">
                                    Delete
                                </Button>
                            </div>
                        </div>
                        <div className="options__item">
                            <h5 className="h-5">Assets:</h5>
                            <Radio.Group
                                onChange={this.changeAssetsType}
                                value={this.state.assetsType}>
                                <Radio value={1}>Any asset</Radio>
                                <Radio value={2}>Select divisions</Radio>
                            </Radio.Group>
                        </div>
                        <div className="options__item">
                            <h5 className="h-5">Divisions:</h5>
                            <div className="tags-area">
                                <div>
                                    {tags.map((tag, index) => {
                                        const isLongTag = tag.length > 20;
                                        const tagElem = (
                                            <Tag
                                                key={tag}
                                                closable={index !== 0}
                                                onClose={() => this.handleClose(tag)}>
                                                {isLongTag
                                                    ? `${tag.slice(0, 20)}...`
                                                    : tag}
                                            </Tag>
                                        );
                                        return isLongTag ? (
                                            <Tooltip title={tag} key={tag}>
                                                {tagElem}
                                            </Tooltip>
                                        ) : (
                                            tagElem
                                        );
                                    })}
                                    <div className="add-new-tag">
                                        <Input
                                            ref={saveInputRef}
                                            type="text"
                                            placeholder="Division X"
                                            className="add-new-tag__input"
                                            value={inputValue}
                                            onChange={this.handleInputChange}
                                            onBlur={this.handleInputConfirm}
                                            onPressEnter={
                                                this.handleInputConfirm
                                            } />
                                        <Button
                                            className="btn add-new-tag__submit"
                                            onClick={this.handleInputConfirm}>
                                            add
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Button
                        type="primary"
                        className="btn extralarge extrawide mt-4">
                        Create
                    </Button>
                    <Button
                        className="btn btn-link mt-2"
                        onClick={this.toggleModalOptions}>
                        Cancel
                    </Button>
                </div>
            </Modal>
        </div>
    );
}
