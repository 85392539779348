import React from 'react';

const RealTimeTrafficOverlaySVG = () => (
    <svg
        width={64}
        height={64}
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1">
        <g stroke="null">
            <path d="M22.179 41.821h11.786v3.929H22.179z" />
            <path d="M48.197 16.286l3.367 11.785H59.5v-3.928h-4.972l-2.554-8.935a3.944 3.944 0 00-3.777-2.85h-2.649L44.117 7.35A3.944 3.944 0 0040.34 4.5H15.803a3.944 3.944 0 00-3.777 2.85l-2.554 8.936H4.5v3.928h7.936L15.803 8.43H40.34l1.122 3.928H23.66a3.944 3.944 0 00-3.777 2.848l-1.992 6.974h-2.166a3.925 3.925 0 00-3.716 2.655L9.551 32H4.5v3.929h3.929v13.75a3.933 3.933 0 003.928 3.928V59.5h3.929v-5.893h23.571V59.5h3.929v-5.893a3.933 3.933 0 003.928-3.928v-13.75h3.929V32h-5.05l-2.458-7.165a3.926 3.926 0 00-3.717-2.656H21.976l1.684-5.893h24.537zm-4.411 21.607v3.928h-3.929v3.929h3.929v3.929H12.357V45.75h3.929v-3.929h-3.929v-3.928h31.429zm-.674-3.929H13.031l2.694-7.857h24.693l2.694 7.857z" />
        </g>
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default RealTimeTrafficOverlaySVG;
