import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isUndefined } from 'underscore';

import DOM from './eventsCustomView';
import * as eventsActions from '../../core/events/eventsActions';
import * as globalActions from '../../core/global/globalActions';
import * as reportActions from '../../core/report/reportActions';

class EventsCustomView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            referrer: '',
            sliderValue: null,
        };
        this.view = DOM;
    }

    componentDidMount() {
        this.requestEventInfo();
    }

    requestEventInfo = (startTime, endTime, sliderValue) => {
        const { actions, match } = this.props;
        const { eventId, deviceId, alertId } = match.params;
        const params = {};
        params.eventId = eventId;
        params.deviceId = deviceId;
        params.eventView = 'true';
        params.start_time = startTime;
        params.end_time = endTime;

        if (!isUndefined(alertId)) {
            params.speed = alertId;
        }
        actions.getEventInfoRequest(params);
        this.setState({ sliderValue });
    }

    render() {
        return this.view();
    }
}

EventsCustomView.propTypes = {
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        selectedEvent: state.events.selectedEvent,
        isFetching: state.events.isFetching,
        speedingReportBack: state.report.speedingReportBack,
        referrer: state.referrer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...eventsActions,
                ...globalActions,
                ...reportActions,
            },
            dispatch,
        ),
    };
}
export const EventsCustomViewTest = EventsCustomView;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EventsCustomView);
