import React from 'react';

const EventReportingSVG = () => (
    <svg width={64} height={64} xmlns="http://www.w3.org/2000/svg">
        <path
            stroke="null"
            d="M53.016 38.273l-4.478-4.477v-5.93A16.56 16.56 0 0033.654 11.41V8.019h-3.308v3.392a16.56 16.56 0 00-14.884 16.454v5.93l-4.478 4.478a1.654 1.654 0 00-.484 1.17v4.96a1.654 1.654 0 001.654 1.655H23.73v1.284a8.52 8.52 0 007.442 8.598 8.279 8.279 0 009.096-8.228v-1.654h11.577a1.654 1.654 0 001.654-1.654v-4.962a1.654 1.654 0 00-.484-1.169zm-16.054 9.439a4.962 4.962 0 01-9.924 0v-1.654h9.924v1.654zm13.23-4.962H13.808v-2.623l4.477-4.477a1.654 1.654 0 00.484-1.17v-6.615a13.23 13.23 0 0126.462 0v6.616a1.654 1.654 0 00.484 1.17l4.477 4.476v2.623z" />
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default EventReportingSVG;
