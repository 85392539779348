import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'antd';

import * as userActions from '../../core/user/userActions';
import DOM from './twoFactorAuthForm';

class TwoFactorAuthForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.view = DOM;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { form } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { actions, userRef} = this.props;
                let { verification_code } = values;

                // Trim the verification code
                verification_code = verification_code.trim();

                actions.twoFactorAuthRequest({
                    ref: userRef,
                    verification_code,
                });
            }
        });
    };

    render() {
        return this.view();
    }
}

TwoFactorAuthForm.propTypes = {
    userRef: PropTypes.string.isRequired,
    form: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
};

const MyForm = Form.create()(TwoFactorAuthForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.user.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MyForm);
