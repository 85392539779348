import React from 'react';
import {
    Col, Row, Icon as AntIcon, Spin, Button, Empty, Slider
} from 'antd';
import { StepBackwardOutlined, StepForwardOutlined, DownloadOutlined } from '@ant-design/icons';
import { map, isEmpty } from 'underscore';

import Icon from '../../../../components/elements/Icon';


import './trimStitchedVideo.scss';

export default function () {
    const { video } = this.props;
    const { currentVideoIndex, availablevideosCount, hideVideos, activeVideo, selectedVideos } = this.state;

    return (
        <div className="live-video">
            {activeVideo !== null ?
                <div className="overlay" />
                : null
            }
            <Row gutter={16}>
                <Button
                    className="back-button"
                    type="link"
                    onClick={() => {
                        const { actions, setShouldShowSidebar } = this.props;
                        actions.selectSingleSearchedVideoRequest({});
                        setShouldShowSidebar(true);
                    }}>
                    <Icon name="chevron-left" />
                    <span className="back-button-text">Back to video selection</span>
                </Button>
                <Spin spinning={video.isFetching} size="large" style={{ minHeight: 300, height: '100%' }}>

                    <div className="videoMainBlock">
                        <div className="videosContentWrapper">
                            <Spin spinning={hideVideos} size="large" style={{ minHeight: 300, height: '100%', width: '100%' }}>

                                <div className="video-buttons-block">
                                    {map(video.stitchedVideos, ((stitchedVideos, key) => (
                                        this.renderStitchedVideoButton(stitchedVideos, key)
                                    )))}
                                </div>

                                {!hideVideos ? (
                                    <div className="videosContentInner">
                                        <Row
                                            gutter={0}
                                            type="flex"
                                            justify="space-between"
                                            className="data-row">
                                            {map(selectedVideos, ((selectedVideo, key) => (
                                                selectedVideo ? this.renderVideo(selectedVideo, key) : null
                                            )))}
                                        </Row>

                                        {!video.isFetching && isEmpty(video.searchedSingleVideoChannels) ?
                                            <Empty description="Cannot load videos. Device is offline" />
                                            : null}
                                    </div>
                                )
                                    : null}
                            </Spin>
                        </div>
                    </div>
                </Spin>
            </Row>
        </div>
    );
}
