/* eslint-disable react/no-this-in-sfc */

import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import Icon from '../elements/Icon';

import './filter-sidebar.css';

export default function () {
    const { children, isReportEmpty } = this.props;
    const { filterSidebar } = this.state;
    const hasChildren = !!children;

    return (
        <div
            data-test="component-filter-sidebar"
            className={`filter  sidebar-is-protrusive ${
                (filterSidebar || isReportEmpty) ? 'sidebar-is-active' : ''
            }`}>
            <div className="filter__mobile-nav">
                <Link to="/" className="btn btn-empty backlink">
                    <Icon name="arrow-left" />
                </Link>
                {hasChildren
                    ? (
                        <Button
                            className="btn btn-white"
                            onClick={this.toggleFilterSidebar}>
                            <Icon name="funnel" />
                        </Button>
                    )
                    : null}
            </div>
            <div className="filter__sidebar">
                <div className="filter__sidebar-inner">
                    <div className="sidebar__header">
                        <div className="with-funnel">
                            <Button
                                className="btn btn-empty"
                                onClick={this.toggleFilterSidebar}>
                                <Icon name="close" />
                            </Button>
                            {hasChildren
                                ? (
                                    <Button
                                        className="btn btn-empty"
                                        onClick={this.toggleFilterSidebar}>
                                        <Icon name="funnel" />
                                    </Button>
                                )
                                : null}
                        </div>
                    </div>
                    {filterSidebar || isReportEmpty ? (
                        <div className="filter__sidebar-data">{children}</div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}
