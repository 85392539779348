/* eslint-disable react/no-unused-state */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DOM from './vorChart';
import * as smartWidgetActions from '../../../../core/smartWidget/smartWidgetActions';
import * as assetActions from '../../../../core/asset/assetActions';
import history from '../../../../core/utils/history';

class VorChart extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        const { actions } = props;
        actions.getVorChartDataRequest({ timeframe: props.timeframe });

        this.state = {
            timeframe: props.timeframe,
            datatype: {
                datatype_a: props.defaultFirstOptionValue,
                datatype_b: props.defaultSecondOptionValue,
                datatype_c: props.defaultThirdOptionValue,
                datatype_d: props.defaultFourOptionValue,
            },
            // vorChartData: props.vorChartData,
            colors: [
                '#cf2b28',
                '#64879c',
                // '#A7E093',
                // '#78885C',
                // '#257A34',
                // '#842ABD',
                // '#74B83F',
                // '#176C89',
                // '#8C28A3',
                // '#B31617',
                // '#54D193',
                // '#17A92F',
                // '#C270E0',
                // '#0DDF3A',
                // '#DAE63B',
                // '#501CCA',
                // '#FF5365',
                // '#D8E849',
                // '#F27AC2',
                // '#E6F9F4',
                // '#660787'
            ],
        };
    }

    componentDidMount() {
        const { timeframe, datatype } = this.state;
        const { actions } = this.props;
        actions.getVorChartDataRequest({ timeframe, datatype });
    }

    onTimeframeChange(timeframe, datatype) {
        const { onTimeframeChange } = this.props;

        this.setState({
            timeframe,
        });

        onTimeframeChange(timeframe, datatype);
    }

    goToAsset(assets) {
        const { actions } = this.props;
        actions.saveAssetSearchParamsRequest({ asset: assets });
        history.push('/assets');
    }

    render() {
        return this.view();
    }
}

VorChart.defaultProps = {
    onTimeframeChange: () => null,
    rightMenuOnClick: () => null,
    rightMenuItems: [],
    tooltipText: null,
    onFirstOptionChange: () => null,
    onSecondOptionChange: () => null,
    onThirdOptionChange: () => null,
    onFourOptionChange: () => null,
    defaultFirstOptionValue: 'All',
    defaultSecondOptionValue: 'All',
    defaultThirdOptionValue: 'All',
    defaultFourOptionValue: 'All',
    divisions: [],
    userDivisions: [],
    drivers: [],
    assets: [],
    heightRatio: 1,
    leftSubtitle: 'vehicles have not been seen for at least ',
    leftTitle: 'VOR',
};

VorChart.propTypes = {
    // timeframe: PropTypes.oneOf(['hourly', 'daily', 'weekly', 'monthly', 'yearly']).isRequired,
    rightMenuItems: PropTypes.array,
    onTimeframeChange: PropTypes.func,
    rightMenuOnClick: PropTypes.func,
    tooltipText: PropTypes.string,
    onFirstOptionChange: PropTypes.func,
    onSecondOptionChange: PropTypes.func,
    onThirdOptionChange: PropTypes.func,
    onFourOptionChange: PropTypes.func,
    defaultFirstOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultSecondOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultThirdOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultFourOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    divisions: PropTypes.array,
    userDivisions: PropTypes.array,
    drivers: PropTypes.array,
    assets: PropTypes.array,
    heightRatio: PropTypes.oneOf([1, 2]),
    leftSubtitle: PropTypes.string,
    leftTitle: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        vorChartData: state.smartWidget.vorChartData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...smartWidgetActions,
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VorChart);
