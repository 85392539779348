import React from 'react';

const OperationalAlertsSVG = () => (
    <svg width={64} height={64} xmlns="http://www.w3.org/2000/svg">
        <g stroke="null">
            <path d="M32 45.464a3.107 3.107 0 103.107 3.107A3.107 3.107 0 0032 45.464zM29.929 22.679h4.143v18.643h-4.143z" />
            <path d="M58.929 59.964H5.07a2.071 2.071 0 01-1.837-3.027L30.162 5.151a2.071 2.071 0 013.676 0l26.928 51.786a2.071 2.071 0 01-1.837 3.027zM8.49 55.821h47.018l.004-.006-23.509-45.21h-.008l-23.51 45.21.005.006z" />
        </g>
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default OperationalAlertsSVG;
