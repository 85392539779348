/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';

const PolygonIcon = ({ color }) => (
    <svg width="26" height="15" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 15L0.00961876 0.75L25.9904 0.75L13 15Z" fill={color || '#FF6900'} />
    </svg>

);

export default PolygonIcon;
