import React from 'react';

const RouteOptimisationSVG = () => (
    <svg width={64} height={64} xmlns="http://www.w3.org/2000/svg">
        <path
            stroke="null"
            d="M53.55 12.836a4.043 4.043 0 00-2.879-1.193H34.036V3.5h-4.072v20.357H13.33a4.043 4.043 0 00-2.879 1.193L3.5 32l6.95 6.95a4.043 4.043 0 002.879 1.193h16.635V60.5h4.072V27.929H50.67a4.043 4.043 0 002.879-1.193l6.95-6.95-6.95-6.95zM13.329 36.07L9.257 32l4.072-4.071h16.635v8.142H13.33zM50.67 23.857H34.036v-8.143H50.67l4.072 4.072-4.072 4.071z" />
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default RouteOptimisationSVG;
