/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
    Icon as AntIcon, Tooltip, Spin, Row, Col, Select, Button, Input, Card, Progress,
} from 'antd';
import moment from 'moment';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import {
    Bar, BarChart, CartesianGrid, XAxis, YAxis, Tooltip as ToolTipChart,
} from 'recharts';
import MapComponent from '../../components/Map';
import { isEmpty, isNull } from 'underscore';
import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import Icon from '../../components/elements/Icon';
import './journeyView.scss';
import DefaultDrivePicture from '../../assets/images/default-image.png';
import DefaultAssetPicture from '../../assets/images/truck.jpg';
import GridView from '../../components/elements/GridView';
import ProgressCircle from '../../components/elements/ProgressCircle';
import journeyScore from '../../assets/images/journey-score.png';
import journeyTime from '../../assets/images/clock.png';
import journeySpeed from '../../assets/images/speed.png';
import cyclearLogo from '../../assets/images/cyclear-logo.png';
import averageMpgIcon from '../../assets/images/average-mpg.png';
import fuelIcon from '../../assets/images/fuel.png';
import icUserIcon from '../../assets/images/ic-user.png';
import idClearLogo from '../../assets/images/id-clear-no-trademark.jpg';
import reclearLogo from '../../assets/images/reclear-logo.png';
import history from '../../core/utils/history';
import { secondsToHms } from '../../core/utils/functions';
import { requestMarkerDetails, setMarkerPopoverToLoading } from '../../core/utils/mapUtils';

const { GOOGLE_MAP_API_KEY, DEBUG } = require('../../core/constants').default;

export default function () {
    const {
        selectedJourney, match, actions, isFetching, journeyBackLink, eventJourneyFetched,
    } = this.props;

    const { sliderValue, centerMap, markerPopover } = this.state;
    const { journeyId } = match.params;
    const assetTitle = `${selectedJourney && selectedJourney.info && (selectedJourney.info.registration || selectedJourney.info.name)}`;
    const mappedDevicePath = this.mapDevicePath();
    const driverImage = (selectedJourney && selectedJourney.info && selectedJourney.info.profile_picture) || DefaultDrivePicture;
    const assetImage = (selectedJourney && selectedJourney.info && selectedJourney.info.asset_picture) || DefaultAssetPicture;
    let backLink = selectedJourney && selectedJourney.info && selectedJourney.info.asset_id ? `/assets/view/${selectedJourney.info.asset_id}` : '/assets';
    let backLinkText = `Asset: ${assetTitle}`;
    const startArea = (selectedJourney && selectedJourney.info && selectedJourney.info.start_area_address) || null;
    const endArea = (selectedJourney && selectedJourney.info && selectedJourney.info.end_area_address) || null;
    const journeyStart = selectedJourney && selectedJourney.info && selectedJourney.info.start ? moment(selectedJourney.info.start, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') : '[not-set]';
    const journeyEnd = selectedJourney && selectedJourney.info && selectedJourney.info.end ? moment(selectedJourney.info.end, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') : '[not-set]';
    if (startArea) {
        const tempStart = startArea.split(',');
        // startArea = `${getAddressFromLatLong(tempStart[0], tempStart[1])} (${startArea})`;
    }
    if (endArea) {
        const tempEnd = endArea.split(',');
        // endArea = `${getAddressFromLatLong(tempEnd[0], tempEnd[1])} (${endArea})`;
    }
    if (!isEmpty(journeyBackLink)) {
        if (journeyBackLink.link) {
            backLink = journeyBackLink.link;
        }
        if (journeyBackLink.text) {
            backLinkText = journeyBackLink.text;
        }
    }
    const { updateSliderValue } = this;
    const mapToolBarButtons = [
        { key: 'side-bar', visible: false },
        { key: 'info-box', visible: true },
        { key: 'traffic', visible: false },
        { key: 'geo-fence', visible: false },
        { key: 'center-map', visible: false },
        { key: 'satellite', visible: true },
        { key: 'heat-map', visible: false },
        { key: 'live-map', visible: false },
        { key: 'video-search', visible: false },
    ];

    const assets = this.getAssetCurrentPin(mappedDevicePath);

    let idleTime = 0;
    let trueIdleTime = 0;
    let score = 0;
    let timeDrivenFormatted = 0;
    let averageSpeed = 0;
    let averageMpg = 0;
    // let fuelLevel = 0;
    let startInterval = 0;
    let endInterval = 0;
    let driverAverageScore = 0;

    if (!isEmpty(selectedJourney)) {
        idleTime = selectedJourney.info.idle_time;
        trueIdleTime = selectedJourney.info.true_idle_time;
        score = selectedJourney.info.score;
        timeDrivenFormatted = selectedJourney.info.time_driven_formatted;
        averageSpeed = selectedJourney.info.average_speed;
        averageMpg = selectedJourney.info.mpg;
        // fuelLevel = selectedJourney.info.fuel_level;
        startInterval = selectedJourney.startInterval;
        endInterval = selectedJourney.endInterval;
        driverAverageScore = selectedJourney.driverAverageScore;
    }

    const chartData = [
        {
            name: 'Idle Time', pv: idleTime || 0,
        },
        {
            name: 'True Idle', pv: trueIdleTime || 0,
        },
    ];

    let profile_picture = '';
    if (selectedJourney && selectedJourney.info) {
        profile_picture = (
            <img
                className="driver-info-image"
                alt={selectedJourney.info.driver || '[not-set]'}
                src={selectedJourney.info.profile_picture || DefaultDrivePicture} />
        );
    }

    let asset_picture = '';
    if (selectedJourney && selectedJourney.info) {
        asset_picture = (
            <img
                className="driver-info-image"
                alt={selectedJourney.info.registration || '[not-set]'}
                src={selectedJourney.info.asset_picture || DefaultAssetPicture} />
        );
    }

    return (
        <Layout
            data-test="pages-journey-view"
            className="events-view"
            title={<div>Journey View</div>}
            headerTitle={<div>Journey View</div>}
            showFooter={false}>
            <div className="content-wrap">
                <FilterSidebar />
                <div className="content">
                    <Spin spinning={isFetching || !selectedJourney || isNull(selectedJourney)}>
                        <div className="content-inner">
                            <section className="section">
                                <div className="section__header">
                                    <div className="section__header-item">
                                        <Link
                                            to="#"
                                            onClick={
                                                (e) => {
                                                    e.preventDefault();
                                                    history.goBack();
                                                }
                                            }
                                            className="d-flex align-center">
                                            <Icon name="arrow-left" />
                                            <span>Back</span>
                                        </Link>
                                    </div>
                                    <div className="section__header-item is-active">
                                        <span>Journey: {journeyId}</span>
                                    </div>
                                </div>
                                <div className="section__body">
                                    <Row
                                        gutter={40}
                                        type="flex"
                                        justify="space-between"
                                        className="data-row map-row">
                                        <Col xl={24} style={{ height: 350, width: '100%' }}>
                                            <MapComponent
                                                ref={this.googleMapAccess}
                                                // onChange={this.onMapChange}
                                                markerOnClick={this.markerOnClick}
                                                // onDateChange={this.mapOnDateChange}
                                                // zoom={8}
                                                markerOnMouseEnter={(marker) => { setMarkerPopoverToLoading(this); requestMarkerDetails(marker, this); }}
                                                markerPopover={markerPopover}
                                                mapDate={moment().subtract('1', 'days').format('DD/MM/YYYY')}
                                                apiKey={GOOGLE_MAP_API_KEY}
                                                mapToolBarButtons={mapToolBarButtons}
                                                sliderStep={1}
                                                sliderValue={parseInt(sliderValue, 10)}
                                                sliderMinValue={startInterval}
                                                sliderMaxValue={endInterval}
                                                sliderValueUnit="seconds"
                                                hideDatePicker
                                                center={centerMap}
                                                onSliderChange={this.updateSliderValue}
                                                // heatMap={heatMap}
                                                assets={assets}
                                                // showToolbar={false}
                                                allowStreetView
                                                showFindCenterButton={false}
                                                path={mappedDevicePath} />
                                        </Col>
                                    </Row>
                                    <br />
                                    <br />

                                    <Row className="data-row" />
                                    <Row gutter={[24, 24]} className="data-row event-view-driver-info" ref={this.pdfDocumentRef}>
                                        <Col xs={24} sm={24} md={24} lg={5} xl={6} style={{ verticalAlign: 'top' }}>
                                            <div className="data-row-header">DRIVER INFORMATION</div>
                                            <div className="data-block">

                                                <Row gutter={[24, 24]} className="" style={{ minHeight: 120 }}>
                                                    <Col xs={12} sm={4} md={12} lg={10}>
                                                        {profile_picture}
                                                    </Col>

                                                    <Col xs={12} sm={4} md={12} lg={14}>
                                                        <div className="title">
                                                            <p> {selectedJourney && selectedJourney.info && selectedJourney.info.user_id
                                                                ? (
                                                                    <Link
                                                                        to={`/drivers/view/${(selectedJourney && selectedJourney.info && selectedJourney.info.user_id) || null}`}>{(selectedJourney.info && selectedJourney.info.driver) || '[not-set]'}
                                                                    </Link>
                                                                )
                                                                : (selectedJourney && selectedJourney.info && selectedJourney.info.driver) || '[not-set]'}
                                                            </p>
                                                        </div>
                                                        <p><span className="driver">Driver</span></p>
                                                    </Col>
                                                </Row>

                                            </div>
                                            <div className="data-block">

                                                <Row gutter={[24, 24]} className="" style={{ minHeight: 120 }}>
                                                    <Col xs={12} sm={4} md={12} lg={10}>
                                                        {asset_picture}
                                                    </Col>
                                                    <Col xs={12} sm={4} md={12} lg={14}>
                                                        <div className="title">
                                                            {selectedJourney && selectedJourney.info && selectedJourney.info.asset_id
                                                                ? (
                                                                    <Link
                                                                        to={`/assets/view/${(selectedJourney && selectedJourney.info && selectedJourney.info.asset_id) || null}`}>{(selectedJourney.info && selectedJourney.info.registration) || '[not-set]'}
                                                                    </Link>
                                                                )
                                                                : (selectedJourney && selectedJourney.info && selectedJourney.info.registration) || '[not-set]'}
                                                        </div>
                                                        <Row gutter={[24, 24]} className="">
                                                            <Col xs={12} sm={4} md={24} lg={12}>
                                                                <div className="asset-info-heading"><strong>Last service:</strong></div>
                                                            </Col>

                                                            <Col xs={12} sm={4} md={12} lg={12}>
                                                                <div className="asset-info-heading"><strong>Registration</strong></div>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={[24, 24]} className="">
                                                            <Col xs={12} sm={4} md={24} lg={12}>
                                                                <div
                                                                    className="asset-info">{(selectedJourney && selectedJourney.info && selectedJourney.info.last_service) || 'Unknown'}
                                                                </div>
                                                            </Col>

                                                            <Col xs={12} sm={4} md={24} lg={12}>
                                                                <div
                                                                    className="asset-info">{(selectedJourney && selectedJourney.info && selectedJourney.info.registration) || '[not-set]'}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                            </div>

                                            <div className="data-block">
                                                <div className="data-row-header">JOURNEY INFORMATION</div>
                                                <Row className="journey-info-row">
                                                    <Col xs={12}><strong>Journey Start</strong></Col>
                                                    <Col xs={12}>{journeyStart}</Col>
                                                </Row>
                                                <Row className="journey-info-row">
                                                    <Col xs={12}><strong>Journey Start Area</strong></Col>
                                                    <Col xs={12} style={{ wordBreak: 'break-all' }}>{startArea}</Col>
                                                </Row>
                                                <Row className="journey-info-row">
                                                    <Col xs={12}><strong>Journey End</strong></Col>
                                                    <Col xs={12}>{journeyEnd}</Col>
                                                </Row>
                                                <Row className="journey-info-row">
                                                    <Col xs={12}><strong>Journey End Area</strong></Col>
                                                    <Col xs={12} style={{ wordBreak: 'break-all' }}>{endArea}</Col>
                                                </Row>
                                                <Row className="journey-info-row">
                                                    <Col xs={12}><strong>Journey Score</strong></Col>
                                                    <Col xs={12}>{selectedJourney && selectedJourney.info && selectedJourney.info.score}</Col>
                                                </Row>
                                                <Row className="journey-info-row">
                                                    <Col xs={12}><strong>Journey Events</strong></Col>
                                                    <Col xs={12}>{selectedJourney && selectedJourney.info && selectedJourney.info.events}</Col>
                                                </Row>

                                            </div>

                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={19} xl={18}>
                                            <div className="data-row-header">TELEMATICS DATA</div>

                                            <Row>
                                                <Col xs={24} sm={24} md={12} lg={9} xl={6} className="telematics-data-card-col">
                                                    <div className="telematics-data-card-title">
                                                        <span
                                                            className="telematics-data-card-title-text">Driver Score
                                                        </span>
                                                    </div>
                                                    <Card className="telematics-data-card" style={{ height: 230 }}>
                                                        <ProgressCircle
                                                            width="10rem"
                                                            percent={driverAverageScore}
                                                            type="circle"
                                                            strokeColor="#FF8733"
                                                            strokeWidth={11} />
                                                    </Card>
                                                </Col>

                                                <Col xs={24} sm={24} md={18} lg={12} xl={10} className="telematics-data-card-col">
                                                    <div className="telematics-data-card-title">
                                                        <span
                                                            className="telematics-data-card-title-text">Journey Info
                                                        </span>
                                                    </div>
                                                    <Card className="telematics-data-card">
                                                        <table className="journey-info">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div className="journey-score-icon"><img
                                                                            src={journeyScore}
                                                                            alt="journey score" />
                                                                        </div>
                                                                        Journey Score
                                                                    </td>
                                                                    <td>{score}%</td>
                                                                    {/* <td><ArrowUpOutlined style={{ color: '#52c41a' }} /></td> */}
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className="journey-score-icon"><img
                                                                            src={journeyTime}
                                                                            alt="journey score" />
                                                                        </div>
                                                                        Journey time
                                                                    </td>
                                                                    <td>{timeDrivenFormatted}</td>
                                                                    {/* <td><ArrowDownOutlined style={{ color: 'red' }} /></td> */}
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className="journey-score-icon"><img
                                                                            src={journeySpeed}
                                                                            alt="journey score" />
                                                                        </div>
                                                                        Journey Average speed
                                                                    </td>
                                                                    <td>{averageSpeed}</td>
                                                                    {/* <td><ArrowUpOutlined style={{ color: '#52c41a' }} /></td> */}
                                                                </tr>
                                                            </tbody>

                                                        </table>
                                                    </Card>
                                                </Col>

                                                <Col xs={24} sm={24} md={6} lg={2} xl={4} className="telematics-data-card-col">
                                                    <div className="telematics-data-card-title">
                                                        <span
                                                            className="telematics-data-card-title-text">Idle
                                                        </span>
                                                    </div>
                                                    <Card className="telematics-data-card" style={{ width: 250, height: 230 }}>
                                                        <BarChart
                                                            className="idle-chart"
                                                            width={260}
                                                            height={200}
                                                            data={chartData}
                                                            barSize={30}
                                                            barGap={5}
                                                            margin={{}}>
                                                            <CartesianGrid strokeDasharray="3 3" />
                                                            <XAxis dataKey="name" />
                                                            <YAxis />
                                                            <ToolTipChart formatter={(value) => secondsToHms(value)} />
                                                            <Bar dataKey="pv" stackId="a" fill="#FF8733" />
                                                            <Bar dataKey="uv" stackId="a" fill="#eff3f5" />
                                                        </BarChart>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col xs={24} sm={24} md={24} lg={5} xl={18} style={{ verticalAlign: 'top' }} />

                                        <Col xs={24} sm={24} md={24} lg={24} xl={18}>
                                            <Row>
                                                <Col xs={24} sm={24} md={6} lg={5} className="telematics-data-card-col first">
                                                    <div className="telematics-data-card-title">
                                                        <span
                                                            className="telematics-data-card-title-text">Activations
                                                        </span>
                                                    </div>
                                                    <Card className="telematics-data-card" style={{ height: 100 }}>

                                                        <Row gutter={[24, 24]}>
                                                            <Col xs={8} sm={4} md={6} lg={6}>
                                                                <div className="activations-icon">
                                                                    <img src={cyclearLogo} alt="journey score" />
                                                                </div>
                                                            </Col>
                                                            <Col xs={8} sm={4} md={6} lg={14}>
                                                                <div className="activations-title">
                                                                    Cyclear Activations
                                                                </div>
                                                            </Col>
                                                            <Col xs={8} sm={4} md={6} lg={2}>
                                                                <div className="activations-stat">
                                                                    0
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col>

                                                <Col xs={24} sm={24} md={6} lg={5} className="telematics-data-card-col middle">
                                                    <Card style={{ height: 121 }}>
                                                        <div className="telematic-box-stat-icon">
                                                            <img src={averageMpgIcon} alt="journey score" />
                                                        </div>
                                                        <div className="telematic-box-stat-text">
                                                            AVERAGE MPG
                                                        </div>
                                                        <div className="telematic-box-stat">
                                                            {averageMpg}
                                                        </div>
                                                    </Card>
                                                </Col>

                                                {/* <Col xs={32} sm={4} md={6} lg={5} className="telematics-data-card-col fuel-progress middle"> */}
                                                {/*    <Card style={{ height: 121 }}> */}
                                                {/*        <div className="telematic-box-stat-icon"> */}
                                                {/*            <img src={fuelIcon} alt="journey score" /> */}
                                                {/*        </div> */}
                                                {/*        <div className="telematic-box-stat-text"> */}
                                                {/*            FUEL LEVEL */}
                                                {/*        </div> */}
                                                {/*        <div className="telematic-box-stat"> */}
                                                {/*            {fuelLevel}% */}
                                                {/*            <Progress */}
                                                {/*                className="telematics-fuel-level-progress" */}
                                                {/*                type="line" */}
                                                {/*                percent={parseFloat(fuelLevel)} */}
                                                {/*                showInfo={false} */}
                                                {/*                strokeColor="#FF8733" */}
                                                {/*                strokeLinecap="square" */}
                                                {/*                strokeWidth={10} /> */}

                                                {/*        </div> */}

                                                {/*    </Card> */}
                                                {/* </Col> */}

                                                {/* <Col xs={32} sm={4} md={6} lg={5} xl={5} className="telematics-data-card-col first">
                                                    <div className="telematics-data-card-title">
                                                        <span
                                                            className="telematics-data-card-title-text">Activations
                                                        </span>
                                                    </div>
                                                    <Card className="telematics-data-card" style={{ height: 100 }}>

                                                        <Row gutter={[24, 24]}>

                                                            <Col xs={8} sm={4} md={6} lg={14}>
                                                                <div className="activations-title">
                                                                    Reverse Activations
                                                                </div>
                                                            </Col>
                                                            <Col xs={8} sm={4} md={6} lg={2}>
                                                                <div className="activations-stat">
                                                                    2
                                                                </div>
                                                            </Col>

                                                        </Row>

                                                    </Card>
                                                </Col> */}
                                                <Col xs={24} sm={24} md={6} lg={5} className="telematics-data-card-col first">
                                                    <div className="telematics-data-card-title">
                                                        <span
                                                            className="telematics-data-card-title-text">Activations
                                                        </span>
                                                    </div>
                                                    <Card className="telematics-data-card" style={{ height: 100 }}>

                                                        <Row gutter={[24, 24]}>
                                                            <Col xs={8} sm={4} md={6} lg={8}>
                                                                <div className="activations-icon">
                                                                    <img src={idClearLogo} alt="journey score" />
                                                                </div>
                                                            </Col>
                                                            <Col xs={8} sm={4} md={6} lg={12}>
                                                                <div className="activations-title">
                                                                    IDClear Activations
                                                                </div>
                                                            </Col>
                                                            <Col xs={8} sm={4} md={6} lg={2}>
                                                                <div className="activations-stat">
                                                                    0
                                                                </div>
                                                            </Col>

                                                        </Row>

                                                    </Card>
                                                </Col>

                                                <Col xs={24} sm={24} md={6} lg={5} className="telematics-data-card-col">
                                                    <div className="telematics-data-card-title">
                                                        <span
                                                            className="telematics-data-card-title-text">Activations
                                                        </span>
                                                    </div>
                                                    <Card className="telematics-data-card" style={{ height: 100 }}>

                                                        <Row gutter={[24, 24]}>
                                                            <Col xs={8} sm={4} md={6} lg={8}>
                                                                <div className="activations-icon">
                                                                    <img src={reclearLogo} alt="journey score" />
                                                                </div>
                                                            </Col>
                                                            <Col xs={8} sm={4} md={6} lg={12}>
                                                                <div className="activations-title">
                                                                    Reaclear Activations
                                                                </div>
                                                            </Col>
                                                            <Col xs={8} sm={4} md={6} lg={2}>
                                                                <div className="activations-stat">
                                                                    0
                                                                </div>
                                                            </Col>

                                                        </Row>

                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col xs={24} sm={24} md={24} lg={5} style={{ verticalAlign: 'top' }} />

                                        <Col xs={24} sm={24} md={24} lg={28}>
                                            <Row />
                                        </Col>
                                    </Row>
                                </div>
                            </section>
                        </div>
                    </Spin>
                </div>
            </div>
        </Layout>
    );
}
