import React, { useContext } from 'react';
import { getMinutes, getSeconds } from 'date-fns';
import PropTypes from 'prop-types';
import { css } from 'glamor';

import defaultStyles from './styles';
import TimeRangeContext from '../../context';

const Tick = ({
    tick, count, format, minutesToDisplay, secondsToDisplay,
}) => {
    const { styles } = useContext(TimeRangeContext);

    const minutes = getMinutes(tick.value);
    const seconds = getSeconds(tick.value);
    const show = minutesToDisplay.includes(minutes) && secondsToDisplay.includes(seconds);

    const tickLabelStyle = {
        width: `${100 / count}%`,
        left: `${tick.percent}%`,
    };

    const markerApplyStyles = show ? defaultStyles.tickMarkerLarge : defaultStyles.tickMarker;
    const receivedMarkerApplyStyles = show ? styles.tickMarkerLarge : styles.tickMarker;

    return (
        <>
            <div
                className="tickMarker"
                {...css(markerApplyStyles, { left: `${tick.percent}%` }, receivedMarkerApplyStyles)} />
            {show && (
                <div
                    className="tickLabel"
                    {...css(defaultStyles.tickLabel, tickLabelStyle, styles.tickLabel)}
                    style={tickLabelStyle}>
                    {format(tick.value)}
                </div>
            )}
        </>
    );
};

Tick.propTypes = {
    tick: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    count: PropTypes.number.isRequired,
    format: PropTypes.func.isRequired,
    minutesToDisplay: PropTypes.array.isRequired,
    secondsToDisplay: PropTypes.array.isRequired,
};

Tick.defaultProps = {
    format: (d) => d,
};

export default Tick;
