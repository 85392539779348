/* global window */
import {
    call, put, takeEvery, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { saveAs } from 'file-saver';
import lockr from 'lockr';
import { fetchApiAuth, fetchApi } from '../utils/api';
import history from '../utils/history';

import {
    getCompanyEventsSuccess,
    getCompanyEventsFailure,

    getEventsSuccess,
    getEventsFailure,

    getDataEventsSuccess,
    getDataEventsFailure,

    updateCompanyEventsSuccess,
    updateCompanyEventsFailure,

    getAllTriggersSuccess,
    getAllTriggersFailure,

    getUserDivisionsSuccess,
    getUserDivisionsFailure,

    getSelectedEventTriggerSuccess,
    getSelectedEventTriggerFailure,

    getSelectedTriggerEventsSuccess,
    getSelectedTriggerEventsFailure,

    deleteTriggerSuccess,
    deleteTriggerFailure,

    getEventInfoSuccess,
    getEventInfoFailure,

    getEventInfoByVideoSuccess,
    getEventInfoByVideoFailure,

    getEventInfoByVideoReferenceSuccess,
    getEventInfoByVideoReferenceFailure,

    shareWebReportSuccess,
    shareWebReportFailure,

    verifyReportCodeSuccess,
    verifyReportCodeFailure,

    downloadPdfReportSuccess,
    downloadPdfReportFailure,

    updateTriggerSuccess,
    updateTriggerFailure,

    getJourneyInfoSuccess,
    getJourneyInfoFailure,

    getJourneysInfoSuccess,
    getJourneysInfoFailure,

    getEventsCsvSuccess,
    getEventsCsvFailure,

    renewSharedReportSuccess,
    renewSharedReportFailure,

    getSideEventsSuccess,
    getSideEventsFailure,
} from './eventsActions';

const {
    GET_COMPANY_EVENTS_REQUEST,
    GET_EVENTS_REQUEST,
    GET_DATA_EVENTS_REQUEST,
    UPDATE_COMPANY_EVENTS_REQUEST,
    GET_ALL_TRIGGERS_REQUEST,
    GET_USER_DIVISIONS_REQUEST,
    GET_SELECTED_EVENT_TRIGGER_REQUEST,
    GET_SELECTED_TRIGGER_EVENTS_REQUEST,
    DELETE_TRIGGER_REQUEST,
    GET_EVENT_INFO_REQUEST,
    GET_EVENT_INFO_BY_VIDEO_REQUEST,
    GET_EVENT_INFO_BY_VIDEO_REFERENCE_REQUEST,
    SHARE_WEB_REPORT_REQUEST,
    VERIFY_REPORT_CODE_REQUEST,
    DOWNLOAD_PDF_REPORT_REQUEST,
    UPDATE_TRIGGER_REQUEST,
    GET_JOURNEY_INFO_REQUEST,
    GET_JOURNEYS_INFO_REQUEST,
    GET_EVENTS_CSV_REQUEST,
    RENEW_SHARED_REPORT_REQUEST,
    GET_SIDE_EVENTS_REQUEST,
} = require('./eventsActions').constants;

const { SHARD_KEY } = require('../constants').default;

function* getCompanyEvents(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/events/company',
            params: action.payload,
        });
        yield put(getCompanyEventsSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getCompanyEventsFailure(e));
    }
}

function* getEvents(action) {
    const params = action.payload.page ? { page: action.payload.page } : {};
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/events/list',
            params,
            body: action.payload,
        });
        yield put(getEventsSuccess(response?.data || {}));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getEventsFailure(e));
    }
}

function* getDataEvents(action) {
    const params = action.payload.page ? { page: action.payload.page } : {};
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/events/get-data-events',
            params,
            body: action.payload,
        });
        yield put(getDataEventsSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getDataEventsFailure(e));
    }
}

function* getTriggers(action) {
    const params = action.payload.page ? { page: action.payload.page } : {};
    params.expand = "divisions,assets";
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/events/triggers',
            params,
            body: action.payload,
        });
        yield put(getAllTriggersSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getAllTriggersFailure(e));
    }
}

function* getUserDivisions(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/company/divisions',
        });
        yield put(getUserDivisionsSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getUserDivisionsFailure(e));
    }
}

function* updateCompanyEvents(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/events/update-company',
            body: action.payload,
        });
        yield put(updateCompanyEventsSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(updateCompanyEventsFailure(e));
    }
}

function* getSelectedTrigger(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/trigger/${action.payload.triggerId}/details`,
        });
        if (response && response.data && response.data.length === 0) {
            notification.error({ message: 'Error', description: 'The specified Geo-fence alert was not found.' });
            history.push('/events');
        }
        yield put(getSelectedEventTriggerSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getSelectedEventTriggerFailure(e));
    }
}

function* getSelectedTriggerEvents(action) {
    try {
        const params = action.payload.page ? { page: action.payload.page } : {};
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/trigger/${action.payload.triggerId}/events`,
            params,
            body: action.payload,
        });
        yield put(getSelectedTriggerEventsSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getSelectedTriggerEventsFailure(e));
    }
}

function* deleteTrigger(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/trigger/${action.payload.triggerId}/delete`,
        });
        yield put(deleteTriggerSuccess(response));
        notification.success({
            message: 'Success',
            description: 'Geo-fence alert deleted successfully',
        });
        history.push('/events');
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(deleteTriggerFailure(e));
    }
}

function* getEventInfo({ payload }) {
    const { isLoggedIn = true, eventId } = payload;

    try {
        const fetchFn = isLoggedIn ? fetchApiAuth : fetchApi;
        const url = isLoggedIn ? `/events/${eventId}/info` : `/events/${eventId}/info-share`;
        const response = yield call(fetchFn, {
            method: 'GET',
            url,
            params: payload,
        });

        if (response.data) {
            const { deviceGroupId } = response.data;
            if (deviceGroupId) {
                const shardId = lockr.get(SHARD_KEY);
                if (deviceGroupId !== shardId) {
                    lockr.set(SHARD_KEY, deviceGroupId);
                    window.location.reload();
                }
            }
        }

        yield put(getEventInfoSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getEventInfoFailure(e));
    }
}

function* getEventInfoByVideo(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/events/${action.payload.videoId}/video-info`,
            params: action.payload,
        });
        yield put(getEventInfoByVideoSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getEventInfoByVideoFailure(e));
    }
}

function* getEventInfoByVideoReference(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'GET',
            url: `/events/${action.payload.videoReference}/cached-video-info`,
            params: action.payload,
        });
        yield put(getEventInfoByVideoReferenceSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getEventInfoByVideoReferenceFailure(e));
    }
}

function* createShareReport(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/events/share-report',
            body: action.payload,
        });
        notification.success({
            message: 'Success',
            description: 'Web report generated successfully',
        });
        yield put(shareWebReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(shareWebReportFailure(e));
    }
}

function* verifyWebReport(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/events/verify-report',
            body: action.payload,
        });

        if (response.data && response.data.error) {
            notification.error({ message: 'Error', description: response.data.error });
        }

        yield put(verifyReportCodeSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(verifyReportCodeFailure(e));
    }
}

function* downloadPDFReport(action) {
    const params = action.payload;
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/events/generate-pdf',
            body: params,
            responseType: 'blob',
        });
        const blob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(blob, action.payload.file);
        notification.success({
            message: 'Success',
            description: 'File downloaded successfully.',
        });
        yield put(downloadPdfReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(downloadPdfReportFailure(e));
    }
}

function* saveTrigger(action) {
    try {
        const params = { expand: "divisions,assets" };
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/trigger/${action.payload.id}/update`,
            params,
            body: action.payload,
        });
        notification.success({
            message: 'Success',
            description: 'Geo-fence alert saved successfully',
        });
        yield put(updateTriggerSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(updateTriggerFailure(e));
    }
}

function* getJourneyInfo(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/journey/${action.payload.journeyId}`,
        });
        yield put(getJourneyInfoSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getJourneyInfoFailure(e));
    }
}

function* getJourneysInfo(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/get-journeys',
            body: action.payload,
        });
        yield put(getJourneysInfoSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getJourneysInfoFailure(e));
    }
}

function* getEventsCsv(action) {
    const params = action.payload;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/events/get-events-csv',
            body: params,
            responseType: 'blob',
        });
        const blob = new Blob([response.data], { type: 'application/csv' });
        saveAs(blob, 'Events.csv');
        notification.success({
            message: 'Success',
            description: 'File downloaded successfully. We allow to download up to 100 000 records.',
        });
        yield put(getEventsCsvSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getEventsCsvFailure(e));
    }
}

function* renewSharedReport(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'events/renew-shared-report',
            body: action.payload,
        });

        yield put(renewSharedReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(renewSharedReportFailure(e));
    }
}

function* getSideEvents(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: 'events/get-side-events',
            body: action.payload,
        });

        yield put(getSideEventsSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getSideEventsFailure(e));
    }
}

/**
 * Watch actions
 */
export default function* eventsSaga() {
    yield all([
        takeEvery(GET_COMPANY_EVENTS_REQUEST, getCompanyEvents),
        takeEvery(GET_EVENTS_REQUEST, getEvents),
        takeEvery(GET_DATA_EVENTS_REQUEST, getDataEvents),
        takeEvery(UPDATE_COMPANY_EVENTS_REQUEST, updateCompanyEvents),
        takeEvery(GET_ALL_TRIGGERS_REQUEST, getTriggers),
        takeEvery(GET_USER_DIVISIONS_REQUEST, getUserDivisions),
        takeEvery(GET_SELECTED_EVENT_TRIGGER_REQUEST, getSelectedTrigger),
        takeEvery(GET_SELECTED_TRIGGER_EVENTS_REQUEST, getSelectedTriggerEvents),
        takeLatest(DELETE_TRIGGER_REQUEST, deleteTrigger),
        takeLatest(GET_EVENT_INFO_REQUEST, getEventInfo),
        takeLatest(GET_EVENT_INFO_BY_VIDEO_REQUEST, getEventInfoByVideo),
        takeLatest(GET_EVENT_INFO_BY_VIDEO_REFERENCE_REQUEST, getEventInfoByVideoReference),
        takeEvery(SHARE_WEB_REPORT_REQUEST, createShareReport),
        takeEvery(VERIFY_REPORT_CODE_REQUEST, verifyWebReport),
        takeEvery(DOWNLOAD_PDF_REPORT_REQUEST, downloadPDFReport),
        takeEvery(UPDATE_TRIGGER_REQUEST, saveTrigger),
        takeLatest(GET_JOURNEY_INFO_REQUEST, getJourneyInfo),
        takeLatest(GET_JOURNEYS_INFO_REQUEST, getJourneysInfo),
        takeEvery(GET_EVENTS_CSV_REQUEST, getEventsCsv),
        takeEvery(RENEW_SHARED_REPORT_REQUEST, renewSharedReport),
        takeEvery(GET_SIDE_EVENTS_REQUEST, getSideEvents),
    ]);
}
