/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import {
    ResponsiveContainer,
} from 'recharts';
import { Avatar as AntAvatar, Icon } from 'antd';

import ChartHeader from '../ChartHeader';
import ChartMultiDropDown from '../ChartMultiDropDown';
import './iconWithValues.scss';
import { convertFromSeconds, getChartIconList } from '../../../../core/utils/functions';

export default function () {
    const {
        data,
        rightMenuItems,
        rightMenuOnClick,
        tooltipText,
        onFirstOptionChange,
        onSecondOptionChange,
        onThirdOptionChange,
        onFourOptionChange,
        onAllOptionChange,
        defaultFirstOptionValue,
        defaultSecondOptionValue,
        defaultThirdOptionValue,
        defaultFourOptionValue,
        divisions,
        userDivisions,
        drivers,
        assets,
        heightRatio,
        chartIcon,
        chartKey,
        dotOnClick,
    } = this.props;

    const { timeframe } = this.state;
    // const accessors = Object.keys(data[0]).filter((k) => k !== 'x');
    // const xAxisAngle = (['hourly', 'monthly'].includes(timeframe) ? -80 : 0);
    // let interval = 0;
    // // console.log('#############', data, accessors);
    // if (timeframe === 'daily') {
    //     interval = 'preserveStartEnd';
    // }

    let widgetHeight = heightRatio * 200;
    if (heightRatio > 1) {
        widgetHeight += ((heightRatio - 1) * 95);
    }

    let primaryIcon = null;

    const displayChartIcon = null;
    let firstItemIcon = null;
    let firstItemLabel = null;
    let firstItemValue = '';
    let firstIconColor = 'grey';

    let secondItemIcon = null;
    let secondItemLabel = null;
    let secondItemValue = '';
    let secondIconColor = 'grey';

    let primaryIconColor = '#36c';

    const mappedIcons = getChartIconList();

    if (data[0]) {
        if (data[0].primary_icon_color) {
            primaryIconColor = data[0].primary_icon_color;
        }

        if (data[0].primary_icon_id && mappedIcons[data[0].primary_icon_id]) {
            primaryIcon = mappedIcons[data[0].primary_icon_id];
            if (data[0].primary_icon_id > 16) {
                primaryIcon = <Icon component={primaryIcon} style={{ fill: primaryIconColor, fontSize: 120 }} />;
            }
        }

        if (data[0].icon) {
            firstItemIcon = data[0].icon;
        }

        if (data[0].name) {
            firstItemLabel = data[0].name;
        }

        if (data[0].value) {
            // if(data[0].unit == 'h:m:s') {
            //     firstItemValue = convertFromSeconds(data[0].value);
            // }else{
            //     firstItemValue = data[0].value;
            // }

            firstItemValue = data[0].value;
        }

        if (data[0].color) {
            firstIconColor = data[0].color;
        }

        if (data[0].icon_id && mappedIcons[data[0].icon_id]) {
            firstItemIcon = mappedIcons[data[0].icon_id];
            if (data[0].icon_id > 16) {
                firstItemIcon = <Icon component={firstItemIcon} style={{ fill: firstIconColor, fontSize: 80 }} />;
            }
        }
    }

    if (data[1]) {
        if (data[1].icon) {
            secondItemIcon = data[1].icon;
        }

        if (data[1].name) {
            secondItemLabel = data[1].name;
        }

        if (data[1].value) {
            // if(data[1].unit == 'h:m:s') {
            //     secondItemValue = convertFromSeconds(data[1].value);
            // } else{
            //     secondItemValue = data[1].value;
            // }

            secondItemValue = data[1].value;
        }

        if (data[1].color) {
            secondIconColor = data[1].color;
        }

        if (data[1].icon_id && mappedIcons[data[0].icon_id]) {
            secondItemIcon = mappedIcons[data[1].icon_id];
            if (data[1].icon_id > 16) {
                secondItemIcon = <Icon component={secondItemIcon} style={{ fill: secondIconColor, fontSize: 80 }} />;
            }
        }
    }

    return (
        <div className="new-chart-widget bar-chart-widget" style={{}}>
            <ChartHeader
                timeframe={timeframe}
                onTimeframeChange={(timeframeData) => this.onTimeframeChange(timeframeData)}
                rightMenuOnClick={(rightMenuData) => rightMenuOnClick(rightMenuData)}
                rightMenuItems={rightMenuItems}
                tooltipText={tooltipText} />
            <div
                style={{
                    width: '100%', height: widgetHeight, alignItems: 'center', justifyContent: 'center', display: 'flex',
                }}
                id={`modalContent_${chartKey}`}>
                <div className="new-chart-content">
                    <div className="left-side">
                        <AntAvatar shape="square" size={120} icon={primaryIcon} style={{ backgroundColor: '#fff', color: primaryIconColor }} />
                    </div>
                    <div className="right-side">
                        <div className="data-value-container" onClick={() => dotOnClick(timeframe, 0)}>
                            <AntAvatar shape="square" size={80} icon={firstItemIcon} style={{ backgroundColor: '#fff', color: firstIconColor }} />
                            <div className="data-value">
                                {firstItemValue}
                                <div className="data-value-unit">{ firstItemLabel }</div>
                            </div>
                        </div>
                        <div className="data-value-container" onClick={() => dotOnClick(timeframe, 1)}>
                            <AntAvatar shape="square" size={80} icon={secondItemIcon} style={{ backgroundColor: '#fff', color: secondIconColor }} />
                            <div className="data-value">
                                {secondItemValue}
                                <div className="data-value-unit">{ secondItemLabel }</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="new-chart-widget-footer">
                <ChartMultiDropDown
                    onFirstOptionChange={(firstOption) => onFirstOptionChange(firstOption)}
                    onSecondOptionChange={(secondOption) => onSecondOptionChange(secondOption)}
                    onThirdOptionChange={(thirdOption) => onThirdOptionChange(thirdOption)}
                    onFourOptionChange={(fourOption) => onFourOptionChange(fourOption)}
                    onDataChange={(optionsData) => onAllOptionChange(optionsData)}
                    defaultFirstOptionValue={defaultFirstOptionValue}
                    defaultSecondOptionValue={defaultSecondOptionValue}
                    defaultThirdOptionValue={defaultThirdOptionValue}
                    defaultFourOptionValue={defaultFourOptionValue}
                    divisions={divisions}
                    userDivisions={userDivisions}
                    drivers={drivers}
                    assets={assets} />
            </div>
        </div>
    );
}
