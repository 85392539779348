/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import {
    DatePicker,
    Form,
    Col, Input, Select,
} from 'antd';
import Sticky from 'react-sticky-el';
import 'rc-color-picker/assets/index.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import * as dashboardActions from '../../../../core/dashboard/dashboardActions';
import * as smartWidgetActions from '../../../../core/smartWidget/smartWidgetActions';

import ChartMultiDropDown from '../../ChartComponents/ChartMultiDropDown';
import BarChart from '../../ChartComponents/BarChart';
import { isEmpty, isUndefined } from 'underscore';
import ColorRule from '../ColorRule';
import Icon from '../../../elements/Icon';

const { Option } = Select;

const FormItem = Form.Item;
class BarChartForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    getDataSet = () => {
        const { form, getChartDataFromInput, data } = this.props;
        const { getFieldDecorator, getFieldValue } = form;
        const fieldCounter = getFieldValue('input_dataList');

        const formulas = [
            {
                name: 'Highest value found',
                key: 1,
            },
            {
                name: 'Average (mean) * default',
                key: 2,
            },
            {
                name: 'Average (median)',
                key: 3,
            },
            {
                name: 'Max value in data',
                key: 4,
            },
            {
                name: 'Min value in data',
                key: 5,
            },
            {
                name: 'Sum of all values',
                key: 6,
            },

        ];

        return fieldCounter.map((k, num) => {

            let colorRuleData = [];
            let color_type = 'fixed';
            let formula = null;

            if(!isUndefined(data) && !isEmpty(data[k])) {
                colorRuleData = (data[k]?.color_type == 'smart-rule') ? data[k].rules : data[k].color;
                color_type = data[k]['color_type'];
                formula = parseInt(data[k].formula);
            }

            return <div key={k}>
                <div className="ant-col ant-form-item ant-form-item-label">
                    <label title={`Data ${num + 1}`}>Data {num + 1}</label>
                </div>

                <div className="data-box">
                    <ColorRule
                        excludeConditions = {['=', '!=']}
                        onChange={() => getChartDataFromInput()}
                        form={form}
                        mode={color_type}
                        data={colorRuleData}
                        name={`input_data[${k}]`}/>

                    <FormItem label="Formula">
                        {getFieldDecorator(`input_data[${k}][formula]`, {
                            rules: [],
                            initialValue: formula,
                        })(
                            <Select
                                onChange={() => getChartDataFromInput()}
                                style={{ width: '100%' }}
                                placeholder="Formula"
                                suffixIcon={
                                    <Icon name="triangle-down" />
                                }>
                                {
                                    formulas.map((v, i) => (
                                        /* eslint-disable-next-line react/no-array-index-key */
                                        <Option key={i} value={v.key}>{v.name}</Option>
                                    ))
                                }

                            </Select>,
                        )}
                    </FormItem>
                </div>
                <br/>
            </div>
        });
    }

    render() {
        const { form, saveDatatypeValues, getChartDataFromInput, division, driver, asset, setTimeframe, smartWidget, timeframe, } = this.props;
        const { getFieldDecorator } = form;

        const dataSet = this.getDataSet();

        let preview = (
            <div className="smart-widget-preview">
                <h2>Widget Preview</h2>
                <FormItem className="widget-preview-date">
                    {getFieldDecorator('date', {
                        initialValue: moment(new Date(), 'DD/MM/YYYY'),
                        rules: [
                        ],
                    })(
                        <DatePicker
                            onChange={() => getChartDataFromInput()}
                            format="DD/MM/YYYY" />,
                    )}
                </FormItem>
                <br />
                <BarChart
                    heightRatio={2}
                    timeframe={smartWidget?.presetWidget?.timeframe || timeframe}
                    onTimeframeChange={(timeframe) => setTimeframe(timeframe)}
                    rightMenuItems={[]}
                    tooltipText={smartWidget?.presetWidget?.name || 'This is tooltip text'}
                    data={smartWidget.chartData}
                    onAllOptionChange={(optionsData) => { saveDatatypeValues(optionsData); getChartDataFromInput(); }}
                    divisions={[...division.divisionOptions]}
                    drivers={[...driver.driverList]}
                    assets={[...asset.options]} />
            </div>
        );

        const isSticky = window.innerWidth > 767;
        if(isSticky){
            preview = <Sticky>{ preview }</Sticky>;
        }

        return (
            <>
                <Col className="gutter-row first-col" xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="input-data-col">
                        { dataSet }

                        <br/>
                        <p className="field-label">Default data view</p>

                        <ChartMultiDropDown
                            onDataChange={(optionsData) => saveDatatypeValues(optionsData)}
                            divisions={[...division.divisionOptions]}
                            drivers={[...driver.driverList]}
                            assets={[...asset.options]} />
                    </div>
                </Col>
                <Col className="smart-widget-preview-row gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}>
                    { preview }
                </Col>
            </>
        );
    }
}

BarChartForm.defaultProps = {
    inputData: [],
    saveDatatypeValues: () => {},
    getChartDataFromInput: () => {},
    setTimeframe: () => {},
};

BarChartForm.propTypes = {
    actions: PropTypes.object.isRequired,
    smartWidget: PropTypes.object.isRequired,
    division: PropTypes.object.isRequired,
    driver: PropTypes.object.isRequired,
    asset: PropTypes.object.isRequired,
    saveDatatypeValues: PropTypes.func,
    getChartDataFromInput: PropTypes.func,
    setTimeframe: PropTypes.func,
    // newImageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        inputData: state.smartWidget.inputData,
        smartWidget: state.smartWidget,
        division: state.division,
        driver: state.driver,
        asset: state.asset,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...dashboardActions,
            ...smartWidgetActions
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BarChartForm);
