import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { CheckOutlined, CloseOutlined, CheckSquareOutlined } from '@ant-design/icons';
import {
    Input, Checkbox, Button, Spin, notification,
} from 'antd';
import _ from 'underscore';
import {
    CheckOutlined, CloseOutlined, CheckSquareOutlined, BorderOutlined,
} from '@ant-design/icons';
import GridView from '../../../components/elements/GridView/index';
import Icon from '../../../components/elements/Icon';

import * as userActions from '../../../core/user/userActions';

class ReportPermissions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportPermissionsSearchParams: {},
            reportPermissionsOrderParams: {},
            reportAssetPermissionsSearchParams: {
                has_access: false,
            },
            reportAssetPermissionsOrderParams: {},
        };

        this.timeout = 0;
    }

    componentDidMount() {
        const { actions, userId } = this.props;
        actions.getReportPermissionsGridRequest({ user_id: userId });
        // actions.getAssetPermissionsGridRequest({ division_id: 1 });
    }

    selectDivision = (division) => {
        const { actions, userId } = this.props;
        actions.saveReportRequest(division);
        this.setState({ divisionId: division.division_id });
        actions.getReportAssetPermissionsGridRequest({
            division_id: parseInt(division.division_id),
            user_id: userId,
            division_name: division.name,
        });
    };

    changeAccess = (asset, access) => {
        const { actions, userId, singleReportDivision } = this.props;
        const newAccess = access === 1 ? 0 : 1;

        actions.changeUserReportAccessRequestRequest({
            type: 'asset',
            access: newAccess,
            user_id: userId,
            asset_id: asset.asset_id,
            division_id: singleReportDivision.division_id,
        });
    };

    addAccessToAllDivisionReport(division, event) {
        event.stopPropagation();
        const {
            actions,
            userId,
        } = this.props;
        actions.saveReportRequest(division);
        actions.addReportAccessToDivisionRequest({
            division_id: division.division_id,
            division_name: division.name,
            user_id: userId,
            type: 'add',
        });
    }

    removeAccessFromAllDivisionReport(division, event) {
        event.stopPropagation();
        const {
            actions,
            userId,
        } = this.props;
        actions.saveReportRequest(division);
        actions.addReportAccessToDivisionRequest({
            division_id: division.division_id,
            division_name: division.name,
            user_id: userId,
            type: 'remove',
        });
    }

    searchDivisions(keyword) {
        const {
            actions,
            userId,
        } = this.props;
        if (this.timeout) clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            actions.getReportPermissionsGridRequest({
                user_id: userId,
                search: keyword,
            });
        }, 1000);
    }

    searchAssets(keyword) {
        const {
            actions,
            userId,
            singleReportDivision,
        } = this.props;
        const { reportAssetPermissionsSearchParams } = this.state;
        if (this.timeout) clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            reportAssetPermissionsSearchParams.search = keyword;
            actions.getReportAssetPermissionsGridRequest({
                user_id: userId,
                division_id: singleReportDivision.division_id,
                reportAssetPermissionsSearchParams,
            });

            this.setState(reportAssetPermissionsSearchParams);
        }, 1000);
    }

    changeAccessSetting(access) {
        const {
            actions,
            userId,
            singleReportDivision,
        } = this.props;
        const { reportAssetPermissionsSearchParams } = this.state;

        reportAssetPermissionsSearchParams.has_access = access;
        if (_.isEmpty(singleReportDivision)) {
            return notification.error({
                message: 'Error',
                description: 'Please select a division',
            });
        }
        actions.getReportAssetPermissionsGridRequest({
            user_id: userId,
            division_id: singleReportDivision.division_id,
            reportAssetPermissionsSearchParams,
        });

        this.setState(reportAssetPermissionsSearchParams);
    }

    render() {
        const {
            reportPermissions,
            reportAssetPermissions,
            singleReportDivision,
            loading,
        } = this.props;
        const {
            actions,
            reportAssetPermissionsPagination,
        } = this.props;
        const { reportPermissionsPagination } = this.props;

        const leftData = reportPermissions.map((d) => {
            let color = 'black';
            const user_has_access = parseInt(d.user_has_access, 10);
            const total_assets = parseInt(d.total_assets, 10);

            if (user_has_access > 0 && user_has_access === total_assets) {
                color = 'green';
            }

            if (user_has_access > 0 && user_has_access < total_assets) {
                color = 'yellow';
            }

            return ({
                division_id: d.division_id,
                name: (
                    <div className={color}>
                        {color !== 'green' ? (
                            <>
                                <Button
                                    className="btn btn-empty ml-2"
                                    onClick={(event) => this.addAccessToAllDivisionReport(d, event)}
                                >
                                    <BorderOutlined style={{ fontSize: 18 }}/>
                                </Button>
                                <span className="ml-1" style={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                }}>{d.name}</span>
                            </>
                        ) : (
                            <>
                                <Button
                                    className="btn btn-empty ml-2"
                                    onClick={(event) => this.removeAccessFromAllDivisionReport(d, event)}>
                                    <CheckSquareOutlined style={{ fontSize: 18 }}/>
                                </Button>
                                <span className="ml-1" style={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                }}>{d.name}</span>
                            </>
                        )}
                    </div>
                ),
                total_assets: `${d.user_has_access}/${d.total_assets}`,
                actions: (
                    <Button
                        onClick={() => this.selectDivision(d)}
                        className="btn btn-empty ml-2">
                        <Icon name="chevron-right"/>
                    </Button>
                ),
            });
        });

        const rightData = reportAssetPermissions.map((d) => ({
            ...d,
            access: <Button type="link" onClick={() => this.changeAccess(d, parseInt(d.access, 10))}>{parseInt(d.access, 10) === 1 ? <CheckOutlined style={{ color: '#389e0d' }}/> : <CloseOutlined style={{ color: '#f5222d' }}/>}</Button>,
        }));

        const gridChangeReportPermissions = (pagination, filters, sorter) => {
            const {
                reportPermissionsSearchParams,
                reportPermissionsOrderParams,
            } = this.state;
            const { userId } = this.props;
            if (pagination && pagination.current === reportPermissionsPagination.currentPage) {
                reportPermissionsOrderParams[sorter.field] = sorter.order ? sorter.order : false;
                this.setState({ reportPermissionsOrderParams });
                actions.getDivisionAssetPermissionsGridRequest({
                    user_id: userId,
                    page: reportPermissionsPagination.currentPage,
                    reportPermissionsSearchParams,
                    reportPermissionsOrderParams,
                });
            }
        };

        const paginationChangeReportPermissions = (page) => {
            const {
                reportPermissionsSearchParams,
                reportPermissionsOrderParams,
            } = this.state;
            const { userId } = this.props;
            if (page !== reportPermissionsPagination.currentPage) {
                actions.getReportPermissionsGridRequest({
                    user_id: userId,
                    page,
                    reportPermissionsSearchParams,
                    reportPermissionsOrderParams,
                });
            }
        };

        const gridChangeAssetPermissions = (pagination, filters, sorter) => {
            const {
                reportAssetPermissionsOrderParams,
            } = this.state;
            const {
                singleReportDivision,
                userId,
            } = this.props;
            if (pagination && pagination.current === reportAssetPermissionsPagination.currentPage) {
                reportAssetPermissionsOrderParams[sorter.field] = sorter.order ? sorter.order : false;
                this.setState({ reportAssetPermissionsOrderParams });
                actions.getReportAssetPermissionsGridRequest({
                    division_id: parseInt(singleReportDivision.division_id),
                    user_id: userId,
                    division_name: singleReportDivision.name,
                });
            }
        };

        const paginationChangeAssetPermissions = (page) => {
            const {
                reportAssetPermissionsSearchParams,
                reportAssetPermissionsOrderParams,
            } = this.state;
            const {
                singleReportDivision,
                userId,
                divisionId,
            } = this.props;
            if (page !== reportAssetPermissionsPagination.currentPage) {
                actions.getReportAssetPermissionsGridRequest({
                    division_id: parseInt(singleReportDivision.division_id),
                    user_id: userId,
                    division_name: singleReportDivision.name,
                    page,
                });
            }
        };

        return (
            <div className="division-tab-wraper">
                <div className="left-side">
                    <Input placeholder="Search for a division" onChange={(data) => this.searchDivisions(data.target.value)}/>
                    <Spin spinning={loading}>
                        <GridView
                            data={leftData}
                            onChange={gridChangeReportPermissions}
                            sortableColumns={['start_time', 'end_time', 'time_driven', 'driver', 'area_driven', 'journey_score', 'journey_events']}
                            pagination={{
                                total: parseInt(reportPermissionsPagination.totalRecords, 10),
                                showSizeChanger: false,
                                pageSize: reportPermissionsPagination.perPage,
                                onChange: paginationChangeReportPermissions,
                            }}/>
                    </Spin>
                </div>

                <div className="right-side">
                    <h4>Set report asset permissions within {singleReportDivision.name}</h4>
                    <div className="search-bar">
                        <Input placeholder="Search by asset reg" onChange={(data) => this.searchAssets(data.target.value)}/>
                        <Checkbox onChange={(data) => this.changeAccessSetting(data.target.checked)}>Show only report assets user has access to</Checkbox>
                    </div>
                    <Spin spinning={loading}>
                        <GridView
                            data={rightData}
                            onChange={gridChangeAssetPermissions}
                            sortableColumns={['start_time', 'end_time', 'time_driven', 'driver', 'area_driven', 'journey_score', 'journey_events']}
                            pagination={{
                                total: parseInt(reportAssetPermissionsPagination.totalRecords, 10),
                                showSizeChanger: false,
                                pageSize: reportAssetPermissionsPagination.perPage,
                                onChange: paginationChangeAssetPermissions,
                            }}/>
                    </Spin>
                </div>
            </div>
        );
    }
}

ReportPermissions.defaultProps = {
    reportPermissions: [],
    reportAssetPermissions: [],
    userId: null,
};

ReportPermissions.propTypes = {
    actions: PropTypes.object.isRequired,
    reportPermissions: PropTypes.array,
    reportPermissionsPagination: PropTypes.object.isRequired,
    reportAssetPermissions: PropTypes.array,
    reportAssetPermissionsPagination: PropTypes.object.isRequired,
    userId: PropTypes.number,
    singleReportDivision: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,

        reportPermissions: state.user.reportPermissions,
        reportPermissionsPagination: state.user.reportPermissionsPagination,
        reportPermissionsIsFetching: state.user.isFetching,

        reportAssetPermissions: state.user.reportAssetPermissions,
        reportAssetPermissionsPagination: state.user.reportAssetPermissionsPagination,
        reportAssetPermissionsIsFetching: state.user.isFetching,
        singleReportDivision: state.user.singleReportDivision,

        loading: state.user.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportPermissions);
