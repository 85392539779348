import React from 'react';

const FuelOptimisationSVG = () => (
    <svg width={64} height={64} xmlns="http://www.w3.org/2000/svg">
        <g stroke="null">
            <path d="M31.056 21.611h-4.723V16.89h-3.777v4.722h-4.723v3.778h4.723v4.722h3.777V25.39h4.723z" />
            <path d="M56.367 17.833L46.922 8.39l-2.644 2.644 5.666 5.667v8.689c0 2.078 1.7 3.778 3.778 3.778V49c0 1.511-1.322 2.833-2.833 2.833S48.056 50.511 48.056 49V32.944c0-1.133-.756-1.888-1.89-1.888h-7.555v-18.89c0-1.133-.755-1.888-1.889-1.888H12.167c-1.134 0-1.89.755-1.89 1.889v39.666H6.5v3.778h35.889v-3.778H38.61v-17h5.667v13.978c0 3.211 2.078 5.856 5.289 6.611 3.589.756 7.177-1.51 7.933-5.1V20.478c0-.945-.378-1.89-1.133-2.645zm-21.534 34H14.056V14.056h20.777v37.777z" />
        </g>
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default FuelOptimisationSVG;
