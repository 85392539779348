/* global window */

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';

import moment from 'moment';
import {
    Button, Input, Spin, Popconfirm,
} from 'antd';
import { Link } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';

import * as videoActions from '../../../core/video/videoActions';
import Icon from '../../../components/elements/Icon';
import checkIcon from '../../../assets/images/tick.svg';
import GridView from '../../../components/elements/GridView';
import { secondsToHms } from '../../../core/utils/functions';
import { convertUtcToBst, getMomentDateFromString } from '../../../core/utils/dateUtils';
import LocationFetcher from '../../../components/elements/LocationFetcher';

class CachedVideoTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            search: {},
            order: {},
            selectedVideoId: null,
            videoFailed: false,
            selectedRequestVideoId: null,
            reference_text: null,
            loading_dvrs_status: false,
        };
        props.registerCallback(this.filterDrivers);
    }

    async componentDidMount() {
        await this.setState({ loading_dvrs_status: true });
        await this.props.checkDvrStatus();

        this.setState({ loading_dvrs_status: false });
        const {
            actions, assetReg, mapDate, assetId,
        } = this.props;
        const { page, search, order } = this.state;
        let startdate = moment();
        startdate = startdate.subtract(10, 'years');
        startdate = startdate.format('YYYY-MM-DD');
        search.date_from = startdate;
        if (window.location.pathname.includes('search')) {
            if (assetReg) {
                search.registration = assetReg;
            }
            if (mapDate) {
                // If user comes from main map screen then use the map date to show events
                search.date_from = moment(mapDate).format('YYYY-MM-DD 00:00:00');
            }
        }
        actions.getRequestedVideosRequest({ page, search, order });
    }

    componentDidUpdate(prevProps) {
        const { forceDownloadFetching, actions } = this.props;
        if (prevProps.forceDownloadFetching && !forceDownloadFetching) {
            const { page, search, order } = this.state;
            actions.getRequestedVideosRequest({ page, search, order });
        }
    }

    componentWillUnmount() {
    }

    videoFailed = () => {
        this.setState({ videoFailed: true });
    }

    handleTableChange = (pagination, filters, sorter, extra) => {
        const { search } = this.state;
        const { actions, pagination: p } = this.props;
        const { currentPage: page } = p;
        const order = [];
        // checking when only order changes
        if (pagination && pagination.current === page) {
            if (!isEmpty(sorter.order)) {
                order[sorter.field] = sorter.order ? sorter.order : 'ascend';
                this.setState({ order });
            }
            actions.getRequestedVideosRequest({ page: 1, search, order });
        }
    }

    editReference = (v) => {
        this.setState({ selectedRequestVideoId: v.internal_reference, reference: v.reference });
    }

    saveReference = (v) => {
        const { actions } = this.props;
        const {
            reference, page, search, order,
        } = this.state;

        actions.updateVideoReferenceRequest({
            id: v.internal_reference, reference, page, search, order,
        });
        this.setState({ selectedRequestVideoId: null, reference: null });
    }

    filterDrivers = (s) => {
        const { actions, assetReg, mapDate } = this.props;
        const { order } = this.state;
        const date_from = s.date_range && s.date_range[0] && s.date_range[0].format('YYYY-MM-DD HH:mm:ss');
        const date_to = s.date_range && s.date_range[1] && s.date_range[1].format('YYYY-MM-DD HH:mm:ss');

        const search = {
            ...s,
            date_from,
            date_to,
            date_range: false,
        };

        this.setState({ search });
        actions.getRequestedVideosRequest({ page: 1, search, order });
    }

    paginationChange = (page) => {
        const { actions, pagination } = this.props;
        const { currentPage } = pagination;
        const { search, order } = this.state;

        if (page !== currentPage) {
            this.setState({ page });
            actions.getRequestedVideosRequest({ page, search, order });
        }
    }

    /**
     * Converts seconds since midnight to a formatted MM:SS or HH:MM:SS string
     * @param {*} secondsSinceMidnight Number of seconds since midnight
     */
    convertFromSeconds(secondsSinceMidnight) {
        const t = secondsSinceMidnight;
        const hours = Math.floor(t / 3600);
        const minutes = `${Math.floor((t / 60) % 60)}`.padStart(2, '0');
        const seconds = `${Math.floor(t % 60)}`.padStart(2, '0');
        if (hours !== 0) {
            return `${hours}:${minutes}:${seconds}`;
        }
        return `${minutes}:${seconds}`;
    }

    handleRefreshClick = (v) => {
        const { actions } = this.props;
        const {
            asset_id, device_id, user_id, date, start_time, end_time,
        } = v;
        actions.postForceDownloadRequest({
            asset_id,
            device_id,
            user_id,
            date,
            start_time,
            end_time,
        });
    }

    showStatus = (v) => {
        const {
            status, online_status, last_check,
        } = v;
        const lastCheckDate = convertUtcToBst(getMomentDateFromString(last_check), null, false);
        const currentDate = new moment();
        const minutesDifference = currentDate.diff(lastCheckDate, 'minutes');
        let label = '';

        switch (status) {
            case 'queued':
            case 'selected':
                label = 'In Queue';
                break;
            case 'download-failed':
                label = 'Download failed';
                break;
            case 'device-offline':
                label = 'Device was offline';
                break;
            case 'reconnecting':
                label = 'Reconnecting';
                break;
            case 'downloading':
                label = 'Downloading';
                break;
            case 'uploading':
                label = 'Uploading';
                break;
            case 'pending-file-conversion':
                label = 'Pending file conversion';
                break;
            case 'file-conversion-in-progress':
                label = 'File conversion in progress';
                break;
            case 'file-conversion-failed':
                label = 'Conversion Failed';
                break;
            case 'cached':
                label = 'Cached';
                break;
            case 'partial-cached':
                label = 'Partially cached';
                break;
            case 'not-available':
                label = 'Not available';
                break;
            default:
                label = 'Discarded';
        }

        if (online_status != '1' && ['queued', 'device-offline', 'selected', 'download-failed', 'file-conversion-failed', 'reconnecting'].includes(status)) {
            label = 'Device Offline';
        }

        let showRefreshButton = false;

        if (online_status == 1 && ['device-offline', 'download-failed', 'file-conversion-failed'].includes(status)) {
            showRefreshButton = true;
        }

        if (online_status == 1 && minutesDifference >= 5 && ['downloading', 'selected', 'queued', 'reconnecting'].includes(status)) {
            showRefreshButton = true;
        }

        if (minutesDifference >= 15 && ['file-conversion-in-progress'].includes(status)) {
            showRefreshButton = true;
        }

        return (
            <div
                className="d-flex align-center"
                onClick={(e) => { e.stopPropagation(); }}
            >
                <Icon name={`link-${status === 'cached' ? 'cached' : 'uncached'}`} />
                <span className="ml-1">{label}</span>
                {showRefreshButton && (
                    <Button type="link" style={{ display: 'inline-block', padding: 0, marginLeft: '8px' }} onClick={() => { this.handleRefreshClick(v); }}>
                        <Icon name="refresh" onClick={() => { this.handleRefreshClick(v); }} />
                    </Button>
                )}
            </div>
        );
    }

    handleExtendClick = (v) => {
        const { actions } = this.props;
        actions.extendStitchedVideoByIdRequest({ id: v.id });
    }

    render() {
        const {
            selectedVideoId, videoFailed, selectedRequestVideoId, reference, loading_dvrs_status
        } = this.state;
        const {
            videos, video_url, videos_loading, singleVideo, assetId, isFetching, pagination, actions, user,
        } = this.props;

        const onlineIcon = (
            <svg height={12} width={12}>
                <circle cx={6} cy={6} r={3} fill="#2e9d0d" />
            </svg>
        );

        const offlineIcon = (
            <svg height={12} width={12}>
                <circle cx={6} cy={6} r={3} fill="#a4a4a4" />
            </svg>
        );

        const columns = (videos && videos.map((v) => {
            let channel_names = [];
            if (v && v.channel_names) {
                channel_names = v.channel_names.split(',');
            }

            const timeFrom = parseInt(moment(v?.start_time).format('X'), 10);
            let timeTo = timeFrom + parseInt(v.duration, 10);
            timeTo = moment.unix(timeTo).format('HH:mm:ss');
            let daysRemaining = !Number(v.date_diff) ? '(Not Set)' : `${v.date_diff} days`;
            if (v.date_diff && v.date_diff <= 0) {
                daysRemaining = (
                    <Button type="link" onClick={() => { this.handleExtendClick(v); }} style={{ padding: 0 }}>
                        Extend
                    </Button>
                );
            }

            let temp = {
                id: v.id,
                reference: (
                    selectedRequestVideoId != v.internal_reference ? (
                        <>
                            <div
                                onClick={e => {
                                    e.stopPropagation();
                                    this.editReference(v);
                                }}
                                style={{ display: 'inline-block' }}
                            >
                                {v.reference || '(Not Set)'}
                                <Icon
                                    name="pencil"
                                    className="ml-2"
                                />
                            </div>
                        </>
                    ) : (
                        <div onClick={e => {
                            e.stopPropagation();
                        }}>
                            <Input
                                defaultValue={v.reference}
                                size="small"
                                placeholder="Reference"
                                onChange={(e) => this.setState({ reference: e.target.value })}
                            />
                            <div
                                onClick={e => {
                                    this.saveReference(v);
                                }}
                                style={{ display: 'inline-block' }}
                            >
                                <Icon
                                    name="tick"
                                    className="ml-2"
                                    style={{
                                        marginLeft: '-15px',
                                    }}
                                />
                            </div>
                        </div>
                    )
                ),
                asset: (
                    v.asset_id === null ? '(Not Set)'
                        : v.asset_deleted === '1' ? (
                            <span>{v.online_status == '1' ? onlineIcon : offlineIcon} {v.asset_registration}</span>
                        ) : (
                            <div onClick={e => { e.stopPropagation(); }}>
                                <Link to={`/assets/view/${v.asset_id}`} className="btn-link">
                                    {v.online_status == '1' ? onlineIcon : offlineIcon} {v.asset_registration}
                                </Link>
                            </div>
                        )
                ),
                driver: (
                    v.user_id === null ? '(Not Set)'
                        : v.user_deleted === '1' ? v.user_name : (
                            <div onClick={e => { e.stopPropagation(); }}>
                                <Link to={`/drivers/view/${v.user_id}`} className="btn-link">
                                    {v.user_name}
                                </Link>
                            </div>
                        )
                ),
                date: v.date && moment(v.date).format('DD/MM/YYYY'),
                start_time: secondsToHms(v.start_time, 'time'),
                end_time: secondsToHms(v.end_time, 'time'),
                location: (
                    <LocationFetcher
                        key={v.id}
                        lat={v.latitude}
                        lon={v.longitude} 
                    />
                ),
                duration: (
                    <div className="d-flex align-center">
                        <Icon name="clock" />
                        <span className="ml-1">{secondsToHms(v.duration, 'time')} </span>
                    </div>
                ),
                requested_time: v.requested_time && moment(v.requested_time).format('DD/MM/YYYY HH:mm:ss'),
                requested_by: v.requested_user_name,
                status: this.showStatus(v),
                // requested_at: v.requested_time && moment(v.requested_time).format('DD/MM/YYYY HH:mm:ss'),
                days_remaining: daysRemaining,
                actions: (
                    <a onClick={() => { window.open(`/cached-video/view/${v.internal_reference}`, '_blank').focus() }}>
                        <Icon name="chevron-right" className="ml-2" />
                    </a>
                ),
            };
            if (user.role === 'admin' || user.role === 'super-admin') {
                temp.admin_actions = (
                    <div style={{ minWidth: 100 }}>
                        <Popconfirm
                            title="Are you sure to delete this cached video?"
                            onConfirm={(e) => {
                                e.stopPropagation();
                                actions.deleteCachedVideoRequest({ internal_reference: v.internal_reference })
                            }}
                            onCancel={(e) => {
                                e.stopPropagation();
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <div onClick={e => { e.stopPropagation(); }}>
                                <DeleteOutlined />
                            </div>
                        </Popconfirm>
                    </div>
                );
            }
            return temp;
        })) || [];

        return (
            <Spin spinning={videos_loading || loading_dvrs_status}>
                <GridView
                    data={columns}
                    onChange={this.handleTableChange}
                    sortableColumns={['video_id', 'date', 'requested_time', 'time', 'location', 'driver', 'asset_reg', 'status', 'duration', 'channel']}
                    hiddenColumns={['id']}
                    pagination={{
                        total: parseInt(pagination.totalRecords, 10) || 0,
                        current: parseInt(pagination.currentPage, 10),
                        pageSize: parseInt(pagination.perPageCount, 10),
                        showSizeChanger: false,
                        onChange: this.paginationChange,
                    }}
                />
            </Spin>
        );
    }
}

CachedVideoTab.defaultProps = {
    registerCallback: () => null,
    assetId: 0,
};

CachedVideoTab.propTypes = {
    actions: PropTypes.object.isRequired,
    videos: PropTypes.array.isRequired,
    video_url: PropTypes.object.isRequired,
    videos_loading: PropTypes.bool.isRequired,
    pagination: PropTypes.object.isRequired,
    singleVideo: PropTypes.object.isRequired,
    forceDownloadFetching: PropTypes.bool.isRequired,
    registerCallback: PropTypes.func,
    setShouldShowSidebar: PropTypes.func,
    assetId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    assetReg: PropTypes.string,
    mapDate: PropTypes.string,
    user: PropTypes.object.isRequired,
    checkDvrStatus: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.video.isFetching,
        videos: state.video.requested,
        videos_loading: state.video.requestedVideosIsFetching,
        video_url: state.video.video_url,
        pagination: state.video.requestedPagination,
        singleVideo: state.video.singleVideo,
        forceDownloadFetching: state.video.forceDownloadFetching,
        assetReg: state.global.videoSearchFilters.assetReg,
        mapDate: state.global.mapDate,
        goPreviousPage: state.video.goPreviousPage,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...videoActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CachedVideoTab);
