import React from 'react';

const UKInHouseDevelopmentTeamSVG = () => (
    <svg width={64} height={64} xmlns="http://www.w3.org/2000/svg">
        <path
            stroke="null"
            d="M33.204 6.854a1.986 1.986 0 00-2.444 0L2.5 28.89l2.444 3.091L8.4 29.288v24.345a3.941 3.941 0 003.933 3.934h39.334a3.94 3.94 0 003.933-3.934V29.306L59.056 32l2.444-3.091L33.204 6.854zm2.73 46.78h-7.867V37.9h7.866v15.733zm3.933 0V37.9a3.938 3.938 0 00-3.934-3.933h-7.866a3.937 3.937 0 00-3.934 3.933v15.733h-11.8V26.221L32 10.9l19.667 15.34v27.392h-11.8z" />
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default UKInHouseDevelopmentTeamSVG;
