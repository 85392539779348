/* eslint-disable react/prop-types */

import React from 'react';
import { Button } from 'antd';
import moment from 'moment';


export default function ({ cacheCallback, latestDownloadQue }) {
    return (
        <>
            <h4 className="h-4">Video cannot be played right now</h4>
            <p className="mt-2 padd-me">
                    We cannot connect to the asset to play the video. This might
                    be because the vehicle is offline, not connected to a power
                    source, or has a weak signal
            </p>
            <p className="mt-2 padd-me">
                    We can attempt to connect to the asset in the background and
                    store a copy of this video on our servers so that it is
                    available even when the asset is offline
            </p>
            <Button onClick={cacheCallback} className="btn btn-bordered extralarge extrawide mt-2">
                    Request for later
            </Button>

            {latestDownloadQue && latestDownloadQue.created_at
                ? <p className="mt-2 padd-me">Last time requested at: {moment(latestDownloadQue.created_at).format('DD/MM/YYYY HH:mm:ss')}</p>
                : null}
        </>
    );
}
