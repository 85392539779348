/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import {
    DatePicker,
    Form,
    Input,
    Select,
    InputNumber,
    notification,
} from 'antd';

import { isEmpty, isNull } from 'underscore';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';

import * as assetActions from '../../../core/asset/assetActions';
import Icon from '../../../components/elements/Icon';

const { Option } = Select;
const FormItem = Form.Item;

const cityData = {
    Zhejiang: ['Hangzhou', 'Ningbo', 'Wenzhou'],
    Jiangsu: ['Nanjing', 'Suzhou', 'Zhenjiang'],
};
let make = null;
let model = null;
class AssetForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // make: null,
        };
    }

    componentDidMount() {
        const { actions } = this.props;
    }

    handleMakeChange = (value) => {
        const { actions } = this.props;

        // this.setState({ make: value });
        make = parseInt(value, 10);
        model = null;
        if (value) {
            actions.getVehicleMakeModelsRequest({ make_id: value });
        }

        // setCities(cityData[value]);
        // setSecondCity(cityData[value][0]);
    };

    handleModelChange = (value) => {
        model = parseInt(value, 10);
    };

    saveAsset = (e) => {
        const {
            form, actions, updateForm, newAssetImage
        } = this.props;
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                if (!updateForm) {
                    values.picture_id = newAssetImage;
                    actions.createAssetRequest(values);
                    make = null;
                }
            }
        });
    }

    render() {
        const {
            form, divisions, vehicleTypes, vehicleMakes, vehicleModels,
        } = this.props;
        // const { make } = this.state;
        const { getFieldDecorator } = form;

        return (
            <Form id="asset-form" onSubmit={this.saveAsset} layout="vertical">
                <FormItem label="Asset name">
                    {getFieldDecorator('name', {
                        rules: [
                            {
                                required: true,
                                message: 'Please enter a name for the asset',
                            },
                        ],
                    })(
                        <Input
                            placeholder="Asset name" />,
                    )}
                </FormItem>
                <FormItem label="Asset reg">
                    {getFieldDecorator('registration', {
                        rules: [
                            {
                                required: true,
                                message: 'Please enter registration',
                            },
                        ],
                    })(
                        <Input
                            placeholder="Asset reg" />,
                    )}

                </FormItem>
                <FormItem label="Year vehicle registered">
                    {getFieldDecorator('year_registration', {
                        rules: [
                        ],
                        initialValue: moment().year(),
                    })(
                        <InputNumber
                            min={1900}
                            max={moment().years()} />,
                    )}

                </FormItem>
                <FormItem label="Asset category">
                    {getFieldDecorator('category', {
                        rules: [
                            {
                                required: true,
                                message: 'Please enter an asset category',
                            },
                        ],
                    })(
                        <Input
                            placeholder="Asset category" />,
                    )}

                </FormItem>
                <FormItem label="Date last serviced">
                    {getFieldDecorator('lastService', {
                        rules: [
                        ],
                    })(
                        <DatePicker
                            format="DD/MM/YYYY" />,
                    )}

                </FormItem>
                <FormItem label="Fuel type">
                    {getFieldDecorator('fuel_type', {
                        rules: [
                        ],
                    })(
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Select fuel type"
                            suffixIcon={
                                <Icon name="triangle-down" />
                            }>
                            {
                                [
                                    {
                                        name: 'Diesel',
                                        key: 1,
                                    },
                                    {
                                        name: 'Petrol',
                                        key: 2,
                                    },
                                    {
                                        name: 'Electric',
                                        key: 4,
                                    },
                                    {
                                        name: 'Hydrogen',
                                        key: 5,
                                    },
                                    {
                                        name: 'Hybrid',
                                        key: 6,
                                    },
                                ].map((v, i) => (
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    <Option key={i} value={v.key}>{v.name}</Option>
                                ))
                            }

                        </Select>,
                    )}

                </FormItem>
                <FormItem label="Asset make">
                    {getFieldDecorator('make_id', {
                        rules: [
                        ],
                    })(
                        <Select
                            style={{ width: '100%' }}
                            onChange={this.handleMakeChange}
                            placeholder="Select Asset make"
                            suffixIcon={
                                <Icon name="triangle-down" />
                            }>
                            {vehicleMakes.map((v, i) => (
                                /* eslint-disable-next-line react/no-array-index-key */
                                <Option key={i} value={v.id}>{v.title}</Option>
                            ))}
                        </Select>,
                    )}

                </FormItem>
                <FormItem label="Asset model">
                    {getFieldDecorator('model_id', {
                        rules: [
                        ],
                    })(
                        <Select
                            style={{ width: '100%' }}
                            onChange={this.handleModelChange}
                            placeholder="Select Asset model"
                            suffixIcon={
                                <Icon name="triangle-down" />
                            }>
                            {vehicleModels.map((v, i) => (
                                /* eslint-disable-next-line react/no-array-index-key */
                                <Option key={i} value={v.id}>{v.title}</Option>
                            ))}
                        </Select>,
                    )}
                </FormItem>
                <FormItem label="Division">
                    {getFieldDecorator('division_id', {
                        rules: [
                        ],
                    })(
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Select Division"
                            suffixIcon={
                                <Icon name="triangle-down" />
                            }>
                            {
                                divisions.map((v, i) => (
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    <Option key={`${i}`} value={v.id}>{v.name}</Option>
                                ))
                            }

                        </Select>,
                    )}
                </FormItem>
                <FormItem label="Vehicle Type">
                    {getFieldDecorator('vehicle_type_id', {
                        rules: [
                        ],
                    })(
                        <Select
                            placeholder="Select Vehicle Type"
                            style={{ width: '100%' }}
                            suffixIcon={
                                <Icon name="triangle-down" />
                            }>
                            {
                                vehicleTypes.map((v, i) => (
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    <Option key={`${i}`} value={v.id}>{v.title}</Option>
                                ))
                            }

                        </Select>,
                    )}
                </FormItem>
            </Form>
        );
    }
}

const WrappedAssetForm = Form.create({
    name: 'register',
})(AssetForm);

WrappedAssetForm.defaultProps = {
    updateForm: false,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        divisions: state.asset.divisions,
        vehicleTypes: state.asset.vehicleTypes,
        vehicleMakes: state.asset.vehicleMakes,
        vehicleModels: state.asset.vehicleModels,
        newAssetImage: state.asset.newAssetImage,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WrappedAssetForm);
