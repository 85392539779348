/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Tabs } from 'antd';
import { isEmpty, isNull, isUndefined } from 'underscore';
import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import EventFilter from './forms/EventFilter';
import EventsTab from './tabs/EventsTab';
import TriggersTab from './tabs/TriggersTab';
import TriggersFilter from './forms/TriggersFilter';
import AlertsTab from './tabs/AlertsTab';
import './events.scss';

const { TabPane } = Tabs;

export default function () {
    const {
        events, user, eventsSearchParams, reload, assetId, dateFrom,
    } = this.props;
    const {
        currentTabKey,
        eventsTabLoaded,
        triggersTabLoaded,
        filterSidebar,
        filterPressed,
    } = this.state;
    let filterContent = null;
    let eventsTabContent = null;
    let triggersTabContent = null;
    let alertsTabContent = null;

    if (currentTabKey === '1') {
        filterContent = <EventFilter eventsTabFilter={eventsSearchParams} onSubmit={this.onEventsTabFilterChange} />;
        eventsTabContent = (
            <EventsTab
                eventsTabFilter={eventsSearchParams}
                setLoadedState={this.eventsTabLoaded}
                eventsTabLoaded={eventsTabLoaded}
                onTableChange={this.onEventsTableChange}
                onPageChange={this.onEventsTabPageChange}
                reload={reload} />
        );
        triggersTabContent = null;
        alertsTabContent = null;
    } else if (currentTabKey === '2') {
        filterContent = <TriggersFilter onSubmit={this.onTriggersTabFilterChange} />;
        eventsTabContent = null;
        triggersTabContent = (
            <TriggersTab
                setLoadedState={this.triggersTabLoaded}
                triggersTabLoaded={triggersTabLoaded}
                onTableChange={this.onTriggersTabSortChange}
                onPageChange={this.onTriggersTabPageChange} />
        );
        alertsTabContent = null;
    } else if (currentTabKey === '3') {
        filterContent = null;
        eventsTabContent = null;
        triggersTabContent = null;
        alertsTabContent = (
            <AlertsTab
                onChange={this.updateEvents}
                value={events} />
        );
    }

    return (
        <Layout
            data-test="pages-events"
            className="events"
            title={<div>Events</div>}
            headerTitle={<div>Events Page</div>}>
            <div className="content-wrap">
                <FilterSidebar displayFilter={filterSidebar} registerCloseSidebar={(f) => { this.closeSidebar = f; }}>
                    {filterContent}
                </FilterSidebar>
                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            <Tabs
                                onTabClick={(key, event) => { this.setState({ currentTabKey: key }); if (key === '3') { this.closeSidebar(); } }}
                                activeKey={currentTabKey}
                                animated={false}
                                className="tabs-restyled">
                                {user.permissions.event_searching === true
                                    ? (
                                        <TabPane tab="Events" key="1">
                                            <div className="section__body">
                                                {eventsTabContent}
                                            </div>
                                        </TabPane>
                                    )
                                    : null}
                                <TabPane tab="Geo-fence Alerts" key="2">
                                    <div className="section__body">
                                        {triggersTabContent}
                                    </div>
                                </TabPane>
                                {user.permissions.email_alert_creation === true
                                    ? (
                                        <TabPane tab="Alerts" key="3">
                                            <div className="section__body">
                                                {alertsTabContent}
                                            </div>
                                        </TabPane>
                                    )
                                    : null}
                            </Tabs>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
