import React from 'react';

const CarbonReportSVG = () => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 396.9 396.9"
        width="1em"
        height="1em"
        xmlSpace="preserve">
        <g>
            <path
                d="M341.2,109.7c-3.3-22.5-14.6-43.1-31.7-58c-17.4-15.1-39.7-23.4-62.9-23.4c-15.9,0-31.7,4-45.6,11.6
		c-14.3-9-30.9-13.8-47.9-13.8c-21.2,0-41.9,7.6-58.1,21.4C79,61,68.3,79.7,64.7,100.3c-18.3,6.7-33.8,18.5-44.9,34.3
		c-11.3,16.1-17.3,35-17.3,54.8c0,52.3,42.6,94.9,94.9,94.9h33.8l-13.9,86.5l123.3-86.5h62.4c50.4,0,91.5-41,91.5-91.5
		C394.4,157.1,373.6,124.6,341.2,109.7z M136,340.8l11.3-70.3H97.3c-44.7,0-81.1-36.4-81.1-81.1c0-35.6,22.8-66.7,56.7-77.4l4.2-1.3
		l0.6-4.4c4.8-37.9,37.2-66.5,75.5-66.5c15.7,0,30.8,4.8,43.6,13.8l3.7,2.6l3.8-2.3c12.7-7.7,27.4-11.8,42.3-11.8
		c42,0,77,31.4,81.4,73.1l0.4,4.1l3.8,1.6c29.4,12,48.4,40.3,48.4,72c0,42.9-34.9,77.7-77.7,77.7h-66.8L136,340.8z" />
            <path
                d="M228.7,119.8c-29.7,0-53.8,24.1-53.8,53.8c0,29.7,24.1,53.8,53.8,53.8c29.7,0,53.8-24.1,53.8-53.8
		C282.5,143.9,258.4,119.8,228.7,119.8z M268.7,173.6c0,22.1-18,40-40,40c-22.1,0-40-18-40-40c0-22.1,18-40,40-40
		C250.8,133.6,268.7,151.5,268.7,173.6z" />
            <path
                d="M134.9,133.6c9.8,0,19.3,3.6,26.7,10.2l1.1,1l9.2-10.3l-1.1-1c-9.9-8.8-22.6-13.7-35.8-13.7
		c-29.7,0-53.8,24.1-53.8,53.8c0,29.7,24.1,53.8,53.8,53.8c13.2,0,25.9-4.9,35.8-13.7l1.1-1l-9.2-10.3l-1.1,1
		c-7.3,6.6-16.8,10.2-26.7,10.2c-22.1,0-40-18-40-40C94.9,151.5,112.8,133.6,134.9,133.6z" />
            <path
                d="M327.2,235.6l-30.7,0v-1.4c0-2,1.7-3.7,3.7-3.7l10.5,0c9.1,0,16.5-7.4,16.5-16.5v-4.2c0-4.4-1.7-8.6-4.8-11.7
		c-3.1-3.1-7.3-4.8-11.7-4.8l-10.4,0c-9.6,0-17.5,7.8-17.5,17.5v1.4h13.8v-1.4c0-2,1.7-3.7,3.7-3.7l10.4,0c0.7,0,1.4,0.3,1.9,0.8
		c0.5,0.5,0.8,1.2,0.8,1.9v4.2c0,1.5-1.2,2.8-2.8,2.8l-10.5,0c-9.6,0-17.5,7.8-17.5,17.5v15.1l44.4,0V235.6z" />
        </g>
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default CarbonReportSVG;
