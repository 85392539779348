import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Button, Checkbox, Col, Form, Input, Radio, Row, Select,
    TimePicker,
} from 'antd';

import { isEmpty } from 'underscore';
import moment from 'moment';

class GeoFence extends Component {
    constructor(props) {
        super(props);
        this.weekdays = moment.weekdays();
    }
    componentDidUpdate(prevProps) {
    }

    submitForm = (e) => {
        e.preventDefault();
        const { onSubmit } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    }

    render() {
        const {
            divisions, assets, form, initializeValues,
        } = this.props;

        const { getFieldDecorator } = form;
        const initialDaysActive = this.weekdays.reduce((carry, day) => {
            if (initializeValues?.days_active?.[day] == true) {
                carry.push(day);
            }
            return carry;
        }, []);
        let initialDivisions = [];
        let initialAssets = [];
        let initalEmails = [];
        if (!isEmpty(initializeValues?.divisions)) {
            //divisions id need to be string to match the options since props divisions has id as string....
            initialDivisions = initializeValues.divisions.map((division) => "" + division.id); 
        }

        if (!isEmpty(initializeValues?.assets)) {
            initialAssets = initializeValues.assets.map((asset) => asset.id);
        }

        if (!isEmpty(initializeValues?.emails)) {
            initalEmails = initializeValues.emails.split(',');
        }

        return (
            <>
                <h5 className="h-5">Geo-fence alert options</h5>
                <div style={{ textAlign: 'left', alignItems: 'left', width: '100%' }}>
                    <Form onSubmit={this.submitForm}>
                        <Row style={{ marginTop: '20px' }}>
                            <Col>
                                <strong>Name:</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Item label={null}>
                                    {getFieldDecorator('trigger_name', {
                                        initialValue: initializeValues && initializeValues.name ? initializeValues.name : null,
                                    })(
                                        <Input type="text" placeholder="Enter Geo-fence name" />,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col>
                                <strong>Emails:</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Item label="">

                                {getFieldDecorator('emails', {
                                    initialValue: initalEmails,
                                    rules: [
                                        {
                                            type: 'array',
                                            message: "The input is not valid E-mail!",
                                            defaultField: { type: 'email', message: "The input is not valid E-mail!", },
                                        }
                                    ]
                                })(
                                    <Select
                                        mode="tags"
                                        placeholder="Please enter emails"
                                        style={{
                                            width: '100%',
                                        }}
                                        tokenSeparators={[';']}
                                        options={[]}
                                    />
                                )}
                            </Form.Item>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col>
                                <strong>Alerts will be sent:</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={1} />
                            <Col span={23}>
                                <Form.Item label={null}>
                                    {getFieldDecorator('days_active', {
                                        initialValue: initialDaysActive,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please select at least one day',
                                            },
                                        ]
                                    })(
                                        <Checkbox.Group style={{ width: '100%' }}>
                                            <Row>
                                                {this.weekdays.map(day => {
                                                    return (
                                                        <Col span={8} key={'active-days-' + day}>
                                                            <Checkbox value={day}>{day}</Checkbox>
                                                        </Col>
                                                    )
                                                })}

                                            </Row>
                                        </Checkbox.Group>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Form.Item label="">
                                {getFieldDecorator('active_inside', {
                                    initialValue: initializeValues.active_inside,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select if it should be inside or outside the hours',
                                        },
                                    ],
                                })(

                                    < Radio.Group >
                                        <Radio value={1}>Between</Radio>
                                        <Radio value={0}>Outside of</Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                            <Form.Item label="" style={{ display: "inline-block", width: "50%", paddingRight: "5px" }}>
                                {getFieldDecorator('time_active_start', {
                                    initialValue: moment(initializeValues.time_active_start, 'HH:mm:ss'),
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select the hours from',
                                        },
                                    ],
                                })(
                                    <TimePicker format='HH:mm:ss' size="large" />
                                )}
                            </Form.Item>
                            <Form.Item label="" style={{ display: "inline-block", width: "50%", paddingLeft: "5px" }}>
                                {getFieldDecorator('time_active_end', {
                                    initialValue: moment(initializeValues.time_active_end, 'HH:mm:ss'),
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select the hours to',
                                        },
                                    ],
                                })(
                                    <TimePicker format='HH:mm:ss' size="large" />
                                )}
                            </Form.Item>
                        </Row>


                        <Row style={{ marginTop: '20px' }}>
                            <Col>
                                <strong>Affects:</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={1} />
                            <Col span={23}>
                                <Form.Item label={null}>
                                    {getFieldDecorator('filter_by', {
                                        initialValue: initializeValues?.filter_by ?? '',
                                    })(
                                        <Radio.Group>
                                            <Radio value={''}>All assets</Radio>
                                            <Radio value='division'>Selected Divisions</Radio>
                                            <Radio value='asset'>Selected Assets</Radio>
                                        </Radio.Group>,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        {form.getFieldValue('filter_by') == "division"
                            ? (
                                <Row style={{ marginTop: '10px' }}>
                                    <Col span={1} />
                                    <Col span={23}>
                                        <Form.Item label={null} >
                                            {getFieldDecorator('divisions', {
                                                initialValue: initialDivisions,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please select divisions!',
                                                    },
                                                ],
                                            })(
                                                <Select
                                                    mode="multiple"
                                                    value={initialDivisions}
                                                    style={{ width: '100%' }}
                                                    placeholder="Select division(s)"
                                                    filterOption={(txt, obj) => (obj.props.children.toLowerCase().includes(txt.toLowerCase()))}
                                                >
                                                    {divisions ? divisions.map((division, divisionIndex) => <Select.Option key={`index-${divisionIndex}`} value={division.id}>{division.name}</Select.Option>) : null}
                                                </Select>,
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )
                            : null}
                        {form.getFieldValue('filter_by') == "asset"
                            ? (
                                <Row style={{ marginTop: '10px' }}>
                                    <Col span={1} />
                                    <Col span={23}>
                                        <Form.Item label={null}>
                                            {getFieldDecorator('assets', {
                                                initialValue: initialAssets,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please select assets!',
                                                    },
                                                ],
                                            })(
                                                <Select
                                                    mode="multiple"
                                                    style={{ width: '100%' }}
                                                    placeholder="Select assets(s)"
                                                    filterOption={(txt, obj) => (obj.props.children.toLowerCase().includes(txt.toLowerCase()))}
                                                >
                                                    {assets ? assets.map((asset, assetsIndex) => <Select.Option key={`index-${assetsIndex}`} value={asset.id}>{asset.name}</Select.Option>) : null}
                                                </Select>,
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )
                            : null}
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                htmlType="submit"
                                type="primary"
                                className="btn extralarge extrawide mt-3">
                                Save
                            </Button>
                        </div>
                    </Form>
                </div>
            </>
        );
    }
}

GeoFence.propTypes = {
    divisions: PropTypes.array,
    assets: PropTypes.array,
    initializeValues: PropTypes.object,
};

GeoFence.defaulProps = {
    divisions: [],
    assets: [],
};

const GeoFenceForm = Form.create({ name: 'event_filter_form' })(GeoFence);

export default GeoFenceForm;
