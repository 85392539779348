import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'antd';
import { isObject } from 'underscore';
import Breadcrumb from '../Breadcrumb';
import Header from '../Header';
import Footer from '../Footer';
import './layout.css';
import icon from '../../../assets/favicon.ico';
import AdminNotificationBar from '../../AdminNotificationBar';

const {
    APP_NAME,
} = require('../../../core/constants').default;

export default function () {
    const {
        children, title, toolbar, breadcrumb, className, menu, backLink,
        showHeader, beforeHeader, afterHeader, floatingHeader, headerTitle,
        showFooter, beforeFooter, afterFooter, floatingFooter, footerContent,
        menuPlacement, menuWidth, user,
    } = this.props;
    return (
        <>
            {(user.role === 'super-admin' && user.isLoggedIn) ? <AdminNotificationBar /> : null}
            <div className={`page-wrap ${className}`}>
                <Helmet
                    title={!isObject(title) ? (title.charAt(0).toUpperCase() + title.slice(1)) : title.props.children}
                    titleTemplate={`%s - ${APP_NAME}`}
                    defaultTitle={`${APP_NAME}`}
                    meta={[
                        { name: 'description', content: `${APP_NAME} website` },
                        { property: 'og:type', content: 'website' },
                    ]}>
                    <link rel="shortcut icon" href={icon} />
                </Helmet>
                {
                    showHeader
                        ? (
                            <Header
                                beforeheader={beforeHeader}
                                afterHeader={afterHeader}
                                floating={floatingHeader}
                                title={headerTitle || title}
                                toolbar={toolbar}
                                backLink={backLink}
                                menu={menu}
                                menuPlacement={menuPlacement}
                                menuWidth={menuWidth} />
                        )
                        : null
                }
                <Layout.Content className="main-content">
                    {breadcrumb ? <Breadcrumb navigation={breadcrumb} /> : null}
                    {children}
                </Layout.Content>
                {
                    showFooter
                        ? (
                            <Footer
                                beforeFooter={beforeFooter}
                                afterFooter={afterFooter}
                                floating={floatingFooter}>
                                {footerContent}
                            </Footer>
                        )
                        : null
                }
            </div>
        </>
    );
}
