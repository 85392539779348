/* global: localStorage */
import {
    call,
    put,
    takeEvery,
    all,
    takeLatest,
    cancelled,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { fetchApiAuth, fetchApi } from '../utils/api';

import {
    getAssetsVideoListSuccess,
    getAssetsVideoListFailure,

    getHistoricVideosSuccess,
    getHistoricVideosFailure,

    getRequestedVideosSuccess,
    getRequestedVideosFailure,

    getVideoUrlSuccess,
    getVideoUrlFailure,

    getVideoCameraChannelsLinkSuccess,
    getVideoCameraChannelsLinkFailure,

    getVideoLiveLocationSuccess,
    getVideoLiveLocationFailure,

    getVideoLiveTabSuccess,
    getVideoLiveTabFailure,

    queueVideoByIdSuccess,
    queueVideoByIdFailure,

    extendVideoByIdSuccess,
    extendVideoByIdFailure,

    extendStitchedVideoByIdSuccess,
    extendStitchedVideoByIdFailure,

    getVideosAroundEventSuccess,
    getVideosAroundEventFailure,

    getStitchedVideoSuccess,
    getStitchedVideoFailure,

    updateVideoReferenceSuccess,
    updateVideoReferenceFailure,
    getRequestedVideosRequest,

    checkDvrOnlineStatusSuccess,
    checkDvrOnlineStatusFailure,

    searchHistoricVideosSuccess,
    searchHistoricVideosFailure,

    getChannelsAndVideosSuccess,
    getChannelsAndVideosFailure,

    cacheVideosSuccess,
    cacheVideosFailure,

    queueSearchedVideoSuccess,
    queueSearchedVideoFailure,

    getStitchedVideosSuccess,
    getStitchedVideosFailure,

    trimAndCacheVideoSuccess,
    trimAndCacheVideoFailure,

    resetTrimAndCacheVideoSuccess,
    resetTrimAndCacheVideoFailure,

    postForceDownloadSuccess,
    postForceDownloadFailure,

    deleteCachedVideoSuccess,
    deleteCachedVideoFailure,
} from './videoActions';

import { setEventDeviceOffline } from '../events/eventsActions';
import history from '../utils/history';

const {
    GET_ASSETS_VIDEO_LIST_REQUEST,
    GET_HISTORIC_VIDEOS_REQUEST,
    GET_REQUESTED_VIDEOS_REQUEST,
    GET_VIDEO_URL_REQUEST,
    GET_VIDEO_CAMERA_CHANNELS_LINK_REQUEST,
    GET_VIDEO_LIVE_LOCATION_REQUEST,
    GET_VIDEO_LIVE_TAB_REQUEST,
    QUEUE_VIDEO_BY_ID_REQUEST,
    EXTEND_VIDEO_BY_ID_REQUEST,
    EXTEND_STITCHED_VIDEO_BY_ID_REQUEST,
    GET_VIDEOS_AROUND_EVENT_REQUEST,
    GET_STITCHED_VIDEO_REQUEST,
    UPDATE_VIDEO_REFERENCE_REQUEST,
    CHECK_DVR_ONLINE_STATUS_REQUEST,
    SEARCH_HISTORIC_VIDEOS_REQUEST,
    GET_CHANNELS_AND_VIDEOS_REQUEST,
    CACHE_VIDEOS_REQUEST,
    QUEUE_SEARCHED_VIDEO_REQUEST,
    GET_STITCHED_VIDEOS_REQUEST,
    TRIM_AND_CACHE_VIDEO_REQUEST,
    RESET_TRIM_AND_CACHE_VIDEO_REQUEST,
    POST_FORCE_DOWNLOAD_REQUEST,
    DELETE_CACHED_VIDEO_REQUEST,
} = require('./videoActions').constants;

function* getLiveVideoList(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/video/get-company-dvrs',
            params: action.payload,
        });
        yield put(getAssetsVideoListSuccess(response?.data || {}));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getAssetsVideoListFailure(e));
    }
}

function* getLiveCameraLinks(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/video/get-camera-links',
            body: action.payload,
        });
        yield put(getVideoCameraChannelsLinkSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getVideoCameraChannelsLinkFailure(e));
    }
}

function* getLiveCameraLocation(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/video/get-camera-location',
            body: action.payload,
        });
        yield put(getVideoLiveLocationSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getVideoLiveLocationFailure(e));
    }
}

function* getLiveCameraTab(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/video/get-camera-tab',
            body: action.payload,
        });
        const { data } = response;
        if (!data || data.data.length === 0 || data.imei === null) {
            notification.info({ message: 'Live Video Information', description: 'Live video is not available for this asset.' });
            if (!action.payload.redirectToFeed || (action.payload.redirectToFeed && action.payload.redirectToFeed !== true)) history.push('/map');
        } else if (action.payload.redirectToFeed && action.payload.redirectToFeed === true) {
            setTimeout(() => history.push(`/map/${data.imei}/live-video`), 1000);
        }
        yield put(getVideoLiveTabSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getVideoLiveTabFailure(e));
    }
}

function* getHistoricVideos(action) {
    try {
        const d = action.payload;
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/historic-video/get-videos?page=${d.page}`,
            body: { page: d.page, searchParams: d.search, orderParams: d.order },
        });

        yield put(getHistoricVideosSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getHistoricVideosFailure(e));
    }
}

function* getRequestedVideos(action) {
    try {
        const d = action.payload;
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/historic-video/get-cached-videos',
            body: { page: d.page, searchParams: d.search, orderParams: d.order },
        });

        yield put(getRequestedVideosSuccess(response?.data || {}));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getRequestedVideosFailure(e));
    }
}

function* postForceDownload({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/historic-video/force-download',
            body: payload,
        });

        yield put(postForceDownloadSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(postForceDownloadFailure(e.response ? e.response.data.message : e));
    }
}

function* getVideoUrl(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/historic-video/get-video-link',
            body: { video_id: action.payload },
        });

        yield put(getVideoUrlSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getVideoUrlFailure(e));
    }
}

function* queueVideoById(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/download-queue/queue-video',
            body: action.payload,
        });

        notification.success({ message: 'Success', description: 'Video successfully added to the queue' });
        yield put(queueVideoByIdSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(queueVideoByIdFailure(e));
    }
}

function* extendVideoById(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/download-queue/extend-video',
            body: action.payload,
        });

        notification.success({ message: 'Success', description: 'Video successfully extended' });
        yield put(extendVideoByIdSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(extendVideoByIdFailure(e));
    }
}

function* extendStitchedVideoById({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/stitched-video/extend-video',
            body: payload,
        });

        notification.success({ message: 'Success', description: 'Video successfully extended' });
        yield put(extendStitchedVideoByIdSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(extendStitchedVideoByIdFailure(e));
    }
}

function* getVideosAroundEvent(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: `/historic-video/get-event-videos?page=${action.payload.page}`,
            body: action.payload,
        });

        yield put(getVideosAroundEventSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getVideosAroundEventFailure(e));
    }
}

function* getStitchedVideo(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: `/historic-video/get-stitched-videos?page=${action.payload.page}`,
            body: action.payload,
        });

        yield put(getStitchedVideoSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getStitchedVideoFailure(e));
    }
}

function* updateVideoReference(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/historic-video/update-reference',
            body: action.payload,
        });

        yield put(updateVideoReferenceSuccess(response));
        yield put(getRequestedVideosRequest(action.payload));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(updateVideoReferenceFailure(e));
    }
}

function* getDvrOnlineStatus(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/video/get-dvr-online-status',
        });

        yield put(checkDvrOnlineStatusSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(checkDvrOnlineStatusFailure(e));
    }
}

function* searchHistoricVideos(action) {
    try {
        const d = action.payload;
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/historic-video/search-historic-videos?page=${d.page}`,
            body: { page: d.page, searchParams: d.search, orderParams: d.order },
        });

        response.search = d.search;

        yield put(searchHistoricVideosSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(searchHistoricVideosFailure(e));
    }
}

function* getChannelsAndVideos(action) {
    const abortController = new AbortController();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/historic-video/get-channels-and-videos',
            body: action.payload,
            signal: abortController.signal,
        });

        yield put(getChannelsAndVideosSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        if (e?.response?.status === 409) {
            yield put(setEventDeviceOffline());
        }
        yield put(getChannelsAndVideosFailure(e));
    } finally {
        if (yield (cancelled())) {
            abortController.abort();
        }
    }
}

function* cacheVideos(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/download-queue/cache-videos',
            body: action.payload,
        });

        notification.success({ message: 'Success', description: 'Video cache successfully requested' });
        yield put(cacheVideosSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(cacheVideosFailure(e));
    }
}

function* queueSearchedVideo(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/stitched-video/queue-searched-video',
            body: action.payload,
        });

        notification.success({ message: 'Success', description: 'Video trim successfully requested' });
        yield put(queueSearchedVideoSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(queueSearchedVideoFailure(e));
    }
}

function* getStitchedVideos(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/stitched-video/get-stitched-videos',
            body: action.payload,
        });

        yield put(getStitchedVideosSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getStitchedVideosFailure(e));
    }
}

function* trimAndCacheVideos(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/video/trim-and-cache',
            body: action.payload,
        });
        yield put(trimAndCacheVideoSuccess(response));
        notification.success({ message: 'Success', description: 'Your request is in progress. Once videos are cached we will notify you by email.' });
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(trimAndCacheVideoFailure(e));
    }
}

function* resetTrimAndCacheVideos(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/video/reset-trim-and-cache',
            body: action.payload,
        });
        yield put(resetTrimAndCacheVideoSuccess(response));
        notification.success({ message: 'Success', description: 'Your request is in progress. Once videos are cached we will notify you by email.' });
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(resetTrimAndCacheVideoFailure(e));
    }
}

function* deleteCachedVideo(action) {
    try {
        yield call(fetchApiAuth, {
            method: 'POST',
            url: '/video/delete-cached-video',
            body: action.payload,
        });
        yield put(deleteCachedVideoSuccess(action.payload));
        notification.success({ message: 'Success', description: 'The video has been successfully deleted.' });
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(deleteCachedVideoFailure(e));
    }
}

/**
 * Watch actions
 */
export default function* videoSaga() {
    yield all([
        takeEvery(GET_ASSETS_VIDEO_LIST_REQUEST, getLiveVideoList),
        takeEvery(GET_HISTORIC_VIDEOS_REQUEST, getHistoricVideos),
        takeEvery(GET_REQUESTED_VIDEOS_REQUEST, getRequestedVideos),
        takeEvery(GET_VIDEO_URL_REQUEST, getVideoUrl),
        takeEvery(GET_VIDEO_CAMERA_CHANNELS_LINK_REQUEST, getLiveCameraLinks),
        takeEvery(GET_VIDEO_LIVE_LOCATION_REQUEST, getLiveCameraLocation),
        takeEvery(GET_VIDEO_LIVE_TAB_REQUEST, getLiveCameraTab),
        takeEvery(QUEUE_VIDEO_BY_ID_REQUEST, queueVideoById),
        takeEvery(EXTEND_VIDEO_BY_ID_REQUEST, extendVideoById),
        takeEvery(EXTEND_STITCHED_VIDEO_BY_ID_REQUEST, extendStitchedVideoById),
        takeEvery(GET_VIDEOS_AROUND_EVENT_REQUEST, getVideosAroundEvent),
        takeEvery(GET_STITCHED_VIDEO_REQUEST, getStitchedVideo),
        takeEvery(UPDATE_VIDEO_REFERENCE_REQUEST, updateVideoReference),
        takeEvery(CHECK_DVR_ONLINE_STATUS_REQUEST, getDvrOnlineStatus),
        takeEvery(SEARCH_HISTORIC_VIDEOS_REQUEST, searchHistoricVideos),
        takeLatest(GET_CHANNELS_AND_VIDEOS_REQUEST, getChannelsAndVideos),
        takeEvery(CACHE_VIDEOS_REQUEST, cacheVideos),
        takeEvery(QUEUE_SEARCHED_VIDEO_REQUEST, queueSearchedVideo),
        takeEvery(GET_STITCHED_VIDEOS_REQUEST, getStitchedVideos),
        takeEvery(TRIM_AND_CACHE_VIDEO_REQUEST, trimAndCacheVideos),
        takeEvery(RESET_TRIM_AND_CACHE_VIDEO_REQUEST, resetTrimAndCacheVideos),
        takeEvery(POST_FORCE_DOWNLOAD_REQUEST, postForceDownload),
        takeEvery(DELETE_CACHED_VIDEO_REQUEST, deleteCachedVideo),
    ]);
}
