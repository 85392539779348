/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Spin, Empty, Button } from 'antd';
import { isEmpty, size } from 'underscore';
import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';
import * as assetsActions from '../../../../../core/asset/assetActions';

class ReportTab extends PureComponent {
    constructor(props) {
        super(props);
    }

    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange } = this.props;
        onTableChange(pagination, filters, sorter, extra);
    }

    render() {
        const {
            newPtoIdleReport, isFetching, newPtoIdleReportPagination, isExportingNewPtoIdleReportCsv, onExportCSV,
        } = this.props;

        const listReports = newPtoIdleReport.map((report) => {
            // Calculate PTO whist Idle for True Idle Calculation
            // TODO: move this to backend without breaking pagination
            let ptoWhilstIdle;
            const idleStarts = report.idle_starts && report.idle_starts.length ? report.idle_starts.split(',') : [];
            const idleEnds = report.idle_ends && report.idle_ends.length ? report.idle_ends.split(',') : [];
            const ptoStarts = report.pto_starts && report.pto_starts.length ? report.pto_starts.split(',') : [];
            const ptoEnds = report.pto_ends && report.pto_ends.length ? report.pto_ends.split(',') : [];
            if (idleStarts.length < 1 || ptoStarts.length < 1) {
                // One or both event types have no events for this day and asset/driver, so PTO whilst Idle is 0
                ptoWhilstIdle = 0;
            } else {
                ptoWhilstIdle = 0;
                // Calculate where PTO is activated WHILST Idle for this day & asset (row)
                for (let i = 0; i < ptoStarts.length; i++) {
                    const ptoStart = ptoStarts[i];
                    const ptoEnd = ptoEnds[i];
                    for (let j = 0; j < idleStarts.length; j++) {
                        const idleStart = idleStarts[j];
                        const idleEnd = idleEnds[j];
                        // Calculate overlap, if overlap is less than 0 then it shows distance, we don't need to set back to 0
                        let overlap = Math.min(ptoEnd, idleEnd) - Math.max(ptoStart, idleStart);
                        if (overlap < 0) overlap = 0;
                        ptoWhilstIdle += overlap;
                    }
                }
            }
            const idleTime = report.idle_time;
            const trueIdle = idleTime - ptoWhilstIdle;
            const trimmedReport = {
                ...report,
                shift_time: moment.utc(report.shift_time * 1000).format('HH:mm:ss'),
                driving_time: moment.utc(report.driving_time * 1000).format('HH:mm:ss'),
                idle_time: moment.utc(report.idle_time * 1000).format('HH:mm:ss'),
                pto_time: moment.utc(report.pto_time * 1000).format('HH:mm:ss'),
                true_idle: moment.utc(trueIdle * 1000).format('HH:mm:ss'),
            };

            // Cleanup report for display
            delete trimmedReport.key;
            delete trimmedReport.idle_starts;
            delete trimmedReport.idle_ends;
            delete trimmedReport.pto_starts;
            delete trimmedReport.pto_ends;
            return trimmedReport;
        });
        const customEmpty = (
            <Empty
                description={(
                    <span style={{ color: '#64879c', display: 'block' }}>
                        <span style={{
                            fontSize: '180%', fontWeight: 'bold', marginBottom: '5px', display: 'block',
                        }}>
                            Enter your search requirements to generate a report
                        </span>
                        <span style={{ display: 'block' }}>
                            Use the filter bar on the left to select Assets or Drivers,
                            the date and the frequency (if applicable) of the data you would like to display
                        </span>
                    </span>
                )}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{ display: 'none' }} />
        );
        const exportableColumns = listReports && listReports[0] ? Object.keys(listReports[0]) : [];

        return (
            <Spin spinning={isFetching}>
                <div className="report-table">
                    {isEmpty(listReports)
                        ? customEmpty
                        : (
                            <>
                                {size(listReports) === 0
                                    ? <Empty />
                                    : (
                                        <>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                            }}>
                                                <Button
                                                    type="primary"
                                                    disabled={this.props.isExportingEventReportCsv}
                                                    onClick={() => { if (onExportCSV) onExportCSV(); }}>
                                                    <Spin
                                                        size="small"
                                                        spinning={isExportingNewPtoIdleReportCsv}>
                                                        Export CSV
                                                    </Spin>
                                                </Button>
                                            </div>
                                            <GridView
                                                data={listReports}
                                                onChange={this.onTableChange}
                                                pagination={{
                                                    className: 'custom-pagination',
                                                    total: Number(newPtoIdleReportPagination.totalRecords),
                                                    pageSize: Number(newPtoIdleReportPagination.perPageCount),
                                                    showSizeChanger: false,
                                                    onChange: this.onPageChange,
                                                }} />
                                        </>
                                    )}
                            </>
                        )}
                </div>
            </Spin>
        );
    }
}

ReportTab.defaultProps = {
    newPtoIdleReport: [],
    onPageChange: () => null,
    onTableChange: () => null,
};

ReportTab.propTypes = {
    newPtoIdleReport: PropTypes.array,
    isFetching: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        newPtoIdleReport: state.report.newPtoIdleReport,
        newPtoIdleReportPagination: state.report.newPtoIdleReportPagination,
        isExportingNewPtoIdleReportCsv: state.report.isExportingNewPtoIdleReportCsv,
        isFetching: state.report.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...assetsActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
