import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';

import defaultStyles from './styles';
import defaultColours from '../../colours';
import TimeRangeContext from '../../context';

const getTrackConfig = ({
    error, source, target, disabled, index, colours,
}) => {
    const basicStyle = {
        left: `${source.percent}%`,
        width: index === 0 ? `calc(${target.percent - source.percent}%)` : `calc(${target.percent - source.percent}% - 1px)`,
    };

    if (disabled) return basicStyle;

    const coloredTrackStyle = error
        ? {
            backgroundColor: colours.trackNotValid || defaultColours.trackNotValid,
            borderLeft: index === 1 ? `1px dashed ${colours.handleLineColorOverError || defaultColours.handleLineColorOverError}` : `1px solid ${defaultColours.borderColor}`,
            borderRight: index === 0 ? '' : `1px solid ${defaultColours.borderColor}`,
        }
        : {
            backgroundColor: colours.trackValid || defaultColours.trackValid,
            borderLeft: index === 1 ? `1px dashed ${colours.handleLineColor || defaultColours.handleLineColor}` : `1px solid ${defaultColours.borderColor}`,
            borderRight: index === 0 ? '' : `1px solid ${defaultColours.borderColor}`,
        };

    return { ...basicStyle, ...coloredTrackStyle };
};

const Track = ({
    error, source, target, getTrackProps, disabled, childrens, index,
}) => {
    const { styles, colours } = useContext(TimeRangeContext);

    const applyStyle = disabled ? defaultStyles.trackDisabled : defaultStyles.track;
    const receivedApplyStyle = disabled ? styles.trackDisabled : styles.track;

    return (
        <div
            className="track"
            {...css(applyStyle, receivedApplyStyle)}
            style={getTrackConfig({
                error, source, target, disabled, index, colours,
            })}
            {...getTrackProps()}>
            {childrens}
        </div>
    );
};

Track.propTypes = {
    error: PropTypes.bool,
    source: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.number,
        percent: PropTypes.number,
    }).isRequired,
    target: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.number,
        percent: PropTypes.number,
    }).isRequired,
    getTrackProps: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    styles: PropTypes.object,
    childrens: PropTypes.array,
    index: PropTypes.number,
    handleLineColor: PropTypes.string,
    handleLineColorOverError: PropTypes.string,
};

Track.defaultProps = {
    disabled: false,
    styles: {},
    childrens: [],
    index: null,
    handleLineColor: null,
    handleLineColorOverError: null,
};

export default Track;
