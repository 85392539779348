import React from 'react';

const RoadRelatedSpeedingAlertsSVG = () => (
    <svg width={64} height={64} xmlns="http://www.w3.org/2000/svg">
        <g stroke="null">
            <path d="M7.571 29.964H32v4.071H7.571zM3.5 19.786h20.357v4.071H3.5z" />
            <path d="M60.335 31.199l-6.107-14.25a2.033 2.033 0 00-1.87-1.235H46.25v-4.071a2.036 2.036 0 00-2.036-2.036H11.643v4.072h30.536v25.56a8.127 8.127 0 00-3.783 4.975H25.604a8.143 8.143 0 100 4.072h12.792a8.103 8.103 0 0015.708 0h4.36A2.036 2.036 0 0060.5 46.25V32a2.029 2.029 0 00-.165-.801zM17.75 50.32a4.071 4.071 0 114.071-4.071 4.076 4.076 0 01-4.071 4.071zm28.5-30.535h4.765l4.363 10.178H46.25V19.786zm0 30.535a4.071 4.071 0 114.071-4.071 4.076 4.076 0 01-4.071 4.071zm10.179-6.107h-2.325a8.133 8.133 0 00-7.854-6.107v-4.071h10.179v10.178z" />
        </g>
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default RoadRelatedSpeedingAlertsSVG;
