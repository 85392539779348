import React from 'react';

const MpgSVG = () => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 396.9 396.9"
        width="1em"
        height="1em"
        xmlSpace="preserve">
        <g>
            <path d="M238.4,62.7H116.9c-4,0-7.3,3.3-7.3,7.3v59.6c0,4,3.3,7.3,7.3,7.3h121.6c4,0,7.3-3.3,7.3-7.3V70.1
		C245.8,66,242.5,62.7,238.4,62.7z M231.1,122.3H124.2V77.4h106.9V122.3z" />
            <path d="M345.7,136.2c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3-0.1-0.4c0-0.1,0-0.2-0.1-0.3
		c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.2-0.1-0.3
		c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.2-0.4c0,0-0.1-0.1-0.1-0.1l-33.7-45.5
		c-2.4-3.3-7-3.9-10.2-1.5c-3.2,2.4-3.9,7-1.5,10.2l25,33.8h-19.2c-4,0-7.3,3.3-7.3,7.3v47c0,4,3.3,7.3,7.3,7.3H331v71.1
		c0,10.7-5.4,17.2-16,19.3c-13.2,2.6-28.9-3.1-32.9-9.7V58.7c0-18.7-15.2-33.9-33.9-33.9h-141c-18.7,0-33.9,15.2-33.9,33.9v260.8
		H58.5c-4,0-7.3,3.3-7.3,7.3v38c0,4,3.3,7.3,7.3,7.3h238.3c4,0,7.3-3.3,7.3-7.3v-38c0-4-3.3-7.3-7.3-7.3h-14.7V290
		c7.7,4.1,17.3,6.3,26.3,6.3c3.3,0,6.5-0.3,9.5-0.9c17.4-3.4,27.8-16,27.8-33.7v-78.4v-47C345.7,136.3,345.7,136.2,345.7,136.2z
		 M289.5,357.5H65.8v-23.4h14.7h194.3h14.7V357.5z M87.8,319.5V58.7c0-10.6,8.7-19.3,19.3-19.3h141c10.6,0,19.3,8.7,19.3,19.3V273
		c0,0,0,0,0,0v46.4H87.8z M312,143.6H331V176H312V143.6z" />
            <path d="M220,172.1h-84.7c-4,0-7.3,3.3-7.3,7.3c0,4,3.3,7.3,7.3,7.3H220c4,0,7.3-3.3,7.3-7.3
		C227.3,175.4,224,172.1,220,172.1z" />
        </g>
    </svg>
);

// eslint-disable-next-line import/prefer-default-export
export default MpgSVG;
